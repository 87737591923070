import axios from "axios";
import {
  Category_Id,
  Category_Ids,
} from "../../../components/Carousel/ProductDetailsCarousel";

import {
  Product_Qty,
  Clear,
  Refresh,
  PriceShortHightToLow,
  PriceShortLowToHight,
  WhatsNew,
  Popularity,
  Voucher,
  ProductsFilter,
  IsBestSeller,
  IsReadyStock,
} from "../../../views/MarketPlace";
import { Pagination_Count } from "../../../pages/Marketplace/ProductList";
// import {
//   Search,
//   Tag,
//   ProductDescriptionSearch,
//   ProductCompanyNameSearch,
//   ProductSubtittleSearch,
// } from "../../../components/Header/Header";

import {
  product_Fail,
  product_Request,
  product_Success,
} from "../../reduser/Home-Filter/products";

import {
  MediaFilter,
  SelectedCompany,
  PriceRange,
  States,
  Tag,
  ProductSubtittleSearch,
  ProductDescriptionSearch,
  ProductCompanyNameSearch,
} from "../../../components/Filters/SearchBar";
// Get All Products
export const getProduct =
  (
    Search = "",
    pricerange = "",
    sortBy = "",
    productQty = "",
    clear = "",
    categoryIds = "",
    refresh = "",
    SellerCompanyId = "",
    priceShortHightToLow,
    priceShortLowToHight,
    whatsNew = "",
    selectedCompany = "",
    currentPage = "",
    popularity = false,
    voucher,
    tag = "",
    productsFilter,
    IsBestSeller,
    IsReadyStock
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: product_Request.toString() });
      if (sortBy === "sort") {
        sortBy = "-createdAt";
      }

      let limit = "";
      let rating = "";
      let deliveryType = "";

      const { data } = await axios.get(
        `product/get_products?Search=${Search.trim()}&PriceRange=${PriceRange}&ProductLocationState=${States}&ProductRating=${rating}&ProductQty=${Product_Qty}&ProductDeliveryType=${deliveryType}&ProductCategory=${Category_Ids}&Clear=${Clear}&Short=${sortBy}&Category_Id=${Category_Id}&Refresh=${Refresh}&SellerCompanyId=${SellerCompanyId}&priceShortHightToLow=${priceShortHightToLow}&priceShortLowToHight=${priceShortLowToHight}&whatsNew=${WhatsNew}&companyName=${SelectedCompany}&popularity=${Popularity}&Voucher=${voucher}&Tag=${Tag}&DescriptionSearch=${ProductDescriptionSearch}&SellerCompanyNameSearch=${ProductCompanyNameSearch}&ProductSubtittleSearch=${ProductSubtittleSearch}&productsFilter=${productsFilter}&IsBestSeller=${IsBestSeller}&IsReadyStock=${IsReadyStock}&page=${Pagination_Count}&limit=${limit}&mediaFilter=${MediaFilter}`,
        {
          withCredentials: true,
        }
      );

      dispatch({
        type: product_Success.toString(),
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: product_Fail.toString(),
        payload: error.response.data.message,
      });
    }
  };
