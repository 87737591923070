import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import Icon from "../assets/icon.png";
import useGetLoggedInUser from "../Hooks/LoggedInUser/useGetLoggedInUser";
import Rectanglee from "../assets/Images/Toast/Rectangle.png";
import Cross from "../assets/Images/Toast/Vector.svg";
import Smileywithbox from "../assets/Images/Toast/Group 1000003296.svg";

const Toast = ({ PageLocation }) => {
  const [allToast, setAllToast] = useState([]);
  const [loginUser, setLoginUser] = useState(null);

  const { data: loggedInUserData } = useGetLoggedInUser();

  let login_User = loggedInUserData?.data?._id;
  let Toasts = loggedInUserData?.data?.Toast;

  const fetchAllToasts = async () => {
    try {
      const response = await axios.get("ToastRoute/Get_toast", {
        withCredentials: true,
      });
      const { data } = response;
      setAllToast(data);
    } catch (error) {
      console.error("Error fetching toasts:", error);
    }
  };

  const updateToast = async (ToastId) => {
    try {
      const response = await axios.put(
        `ToastRoute/update_toast?ToastId=${ToastId}`,
        { withCredentials: true }
      );
      const { data } = response;
      return data.Toast;
    } catch (error) {
      console.error("Error updating toast:", error);
      throw error;
    }
  };

  const GetCompanyByID = async (id) => {
    try {
      const response = await axios.get(`/company/get_company/${login_User}`, {
        withCredentials: true,
      });
      const data = response.data;
      setLoginUser(data);
    } catch (error) {}
  };

  const showToasts = async () => {
    for (const item of allToast) {
      const isToastIdPresent = Toasts?.some(
        (toast) => toast.ToastId === item._id
      );

      if (!isToastIdPresent && PageLocation === item.page) {
        const toastContent = (
          <Box
            key={item._id}
            sx={{
              // backgroundImage: `url(${Rectanglee})`,
              background:"F3F6F9",
              width: "470px",
              height: "auto",
              minHeight: "85px",
              background : "#F5F9FB",
              backgroundPosition: "center",
              border : "1px solid #156DB6" ,
              backgroundSize: "cover",
              ml: "30px",
              backgroundRepeat: "no-repeat",
              position: "relative",
              borderRadius: "25px",
              display: "flex", // Added to create a flex container
              justifyContent: "center", // Center the content horizontally
              alignItems: "center", // Center the content vertically
              flexDirection: "column", // Added to stack the content vertically
              color: "#6B7A99", // Set the text color to white
            }}
          >
            <img
              src={Cross}
              alt="Icon"
              style={{
                width: "11px",
                height: "11px",
                position: "absolute",
                color : "#156DB6" ,
                right: "20px",
                top: "8px",
                // top: "20px", // Adjusted the position to center it vertically
                // left: "15px", // Adjusted the position to center it horizontally
              }}
            />

            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                textAlign: "center",
                lineHeight: "30px",
                width: "90%",
                ml: "auto",
              }}
            >
              Hello ! {loginUser?.companyName}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: "center",
                lineHeight: "16.8px",
                width: "90%",
                ml: "auto",
                // bgcolor: "red",
              }}
            >
              {item.Message}
            </Typography>
            <img
              src={Smileywithbox}
              alt="Icon"
              style={{
                width: "158px",
            height: "158px",
            position: "absolute",
            top: "-40px",
            left: "-83px",
              }}
            />
          </Box>
        );

        toast.dark(toastContent, {
          onClose: async () => {
            try {
              await updateToast(item._id);
            } catch (error) {
              console.error("Error updating toast:", error);
            }
          },
          position: item.Position,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
           theme: 'colored',
          style: {
            width: "520px",
            height: "120px",
            // maxWidth: "430px",
            // maxHeight: "100px",
            // minWidth: "430px",
            // minHeight: "100px",
            background: "transparent",
            boxShadow: "none",
          },
        });

        loginUser.Toast.push({ ToastId: item._id });
      }
    }
  };

  useEffect(() => {
    fetchAllToasts();
    GetCompanyByID();
  }, []);

  useEffect(() => {
    if (loginUser && Array.isArray(loginUser.Toast)) {
      showToasts();
    }
  }, [allToast, loginUser, PageLocation]);

  return <></>;
};

export default Toast;