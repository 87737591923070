/* eslint-disable react/prop-types */
import { Container, Typography } from "@mui/material";
import React from "react";

const Transaction_Facilitation_Terms = (props) => {
  return (
    <Container style={{ padding: "20px" }}>
      <Typography variant="h5" gutterBottom>
        Transaction Facilitation Terms
      </Typography>
      <Typography sx={termstext} paragraph>
        I, the Member, am fully aware of and understand the following terms and
        conditions regarding transactions facilitated on the BXI platform:
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        1. Commission and TDS Payments
      </Typography>
      <Typography sx={termstext} paragraph>
        We understand that when a Buyer Member pays commission and TDS, these
        funds are initially credited to the account of the Seller Member. BXI
        subsequently collects this BXI commission and TDS from the Seller Member
        account using the eNACH system.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        2. Authorization for Auto-Debit
      </Typography>
      <Typography sx={termstext} paragraph>
        I am aware of and authorize BXI and eNACH to auto-debit and collect
        commission and TDS associated with my transactions on the platform, with
        a maximum limit of INR 3,00,000 per transaction.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        3. Variable Commission and TDS Amounts
      </Typography>
      <Typography sx={termstext} paragraph>
        I understand that the actual BXI commission and TDS amounts may vary per
        transaction.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        4. Cumulative Collection Limit
      </Typography>
      <Typography sx={termstext} paragraph>
        I am aware that the total BXI commission and TDS collected from multiple
        transactions over the two-year validity period of this agreement may
        exceed the per-transaction limit of INR 3,00,000.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        5. eNACH Debit Authorization
      </Typography>
      <Typography sx={termstext} paragraph>
        I understand and agree that BXI will use eNACH to automatically debit my
        account for commission and TDS reimbursement only upon completion of a
        transaction on the platform.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        6. Validity of e-Mandate Agreement
      </Typography>
      <Typography sx={termstext} paragraph>
        I am aware that this e-Mandate agreement will remain valid for two years
        from the date of my signature.
      </Typography>

      <Typography sx={termstextBold} gutterBottom>
        7. Notification of Debit
      </Typography>
      <Typography sx={termstext} paragraph>
        I will receive a notification at least 24 hours before the commission
        and TDS reimbursement amount is debited from my account.
      </Typography>

      <Typography sx={termstext} paragraph>
        By accepting the above mentioned, I confirm that I have read,
        understood, and agree to all the terms and conditions stated above.
      </Typography>

      <Typography sx={termstext} paragraph>
        Date: {props.Date}
      </Typography>
      <Typography sx={termstext} paragraph>
        Time: {props.Time}
      </Typography>
      <Typography sx={termstext} paragraph>
        Place: {props.Place}
      </Typography>
    </Container>
  );
};

export default Transaction_Facilitation_Terms;

const termstext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "13px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "justify",
  color: "#7D8BA6",
};

const termstextBold = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "15px",
    lg: "15px",
    md: "13px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "justify",
  color: "#000",
};
