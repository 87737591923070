import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const PackagingInfor = (props) => {
  console.log("Packaging", props);

  return (
    <React.Fragment>
      <Typography
        mt={2}
        sx={{
          ...product,
          fontSize: "18px",
          color: "#156DB6",
        }}
      >
        Packaging Information
      </Typography>
      <Box
        mt={1.5}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "95%",
        }}
      >
        {props?.data?.map((val) => {
          const newVal = Number(val?.val).toFixed(2);
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "12%",
                gap: "25px",
              }}
            >
              <Box
                component="img"
                src={val.img}
                style={{
                  height: "auto",
                  minWidth: "40px",
                  maxWidth: "40px",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  minWidth: "145px",
                }}
              >
                <Typography sx={{ ...packHead, color: "grey" }}>
                  {val.name}
                </Typography>
                <Typography sx={{ ...packVal }}>
                  {props?.ProductData?.WeightBeforePackingPerUnitMeasurUnit ===
                    undefined ||
                  props?.ProductData?.WeightBeforePackingPerUnitMeasurUnit ===
                    null ? (
                    <Typography sx={packVal}>
                      {newVal}
                      {props?.ProductData?.UnitOfWeight}
                    </Typography>
                  ) : props?.ProductData
                      ?.WeightBeforePackingPerUnitMeasurUnit === "Grams" ? (
                    <Typography sx={packVal}>
                      {newVal} {val.measure}
                    </Typography>
                  ) : (
                    <Typography sx={packVal}>
                      {newVal} {val.measure}
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box
        mt={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0px",
        }}
      >
        {props?.ProductData?.ProductTechInfo?.PackagingInfoPerUnit ===
          undefined ||
        props?.ProductData?.ProductTechInfo?.PackagingInfoPerUnit ===
          null ? null : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              mt: 3,
            }}
          >
            <Typography
              sx={{
                ...packHead,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                // color: "#6B7A99",
                color: "#156DB6",
                fontSize: "18px",
              }}
            >
              Packaging Information Per Unit
            </Typography>

            <Typography
              sx={{
                ...packHead,
                color: "#6B7A99",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {props?.ProductData?.ProductTechInfo?.PackagingInfoPerUnit}
            </Typography>
          </Box>
        )}

        {props?.ProductData?.ProductTechInfo?.LegalInformation === undefined ||
        props?.ProductData?.ProductTechInfo?.LegalInformation ===
          null ? null : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              mt: 3,
            }}
          >
            <Typography
              sx={{
                ...packHead,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                // color: "grey",
                color: "#156DB6",
                fontSize: "18px",
              }}
            >
              Legal Information
            </Typography>

            <Typography
              sx={{
                ...packHead,
                color: "#6B7A99",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {props?.ProductData?.ProductTechInfo?.LegalInformation}
            </Typography>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default PackagingInfor;

const packHead = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#ADB8CC",
};

const packVal = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#6B7A99",
};

const product = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1.2rem",
    sm: "1.2rem",
    xs: "1rem",
  },
  color: "#6B7A99",
};
