import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { alpha, styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetInvoices } from "../../Hooks/Invoices/useGetInvoices.js";
import SearchIcon from "../../assets/search.svg";
import CompanyName from "../../components/CompanyName";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import getAllInvoices from "../../redux/action/invoices";
import SkeletonComponent from "../../components/SkeletonComponent.jsx";
import Nodatafound from "../../components/NoDataFound/NoDataFound";
import CommaSeparator from "../../components/CommaSeprator.js";
import BXITokenIcon from "../../assets/BXITokenIcon.png";

const InvoiceTypes = [
  "Proforma",
  "Final",
  "Pickrr",
  "BXI",
  "OrderSummary",
  "MembershipInvoice",
];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const InvoicePage = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = React.useState(1);
  const [invoice, setInvoice] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [purchase, setPurchase] = React.useState(false);
  const [sell, setSell] = React.useState(false);
  const [isActiveButton, setIsActiveButton] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [loadingInvoice, setLoadingInvoice] = React.useState(true);
  const dispatch = useDispatch();
  const { invoices, loading } = useSelector((state) => state.invoices);
  let paginationCount = invoices?.finalCount;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openfl = Boolean(anchorEl);
  const handleClickfl = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosefl = () => {
    setAnchorEl(null);
  };

  const handleSearchfl = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleChange = (event) => {
    setInvoice(event.target.value);
    setOpen(false);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [
      pad(d.getDate()), // Day
      pad(d.getMonth() + 1), // Month (adding 1 to convert to 1-indexed)
      d.getFullYear(), // Year
    ].join("/");
  }

  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/home/taxinvoice/` + id);
  };

  useEffect(() => {
    dispatch(getAllInvoices(search, sell, purchase, invoice, currentPage));
  }, [dispatch, search, sell, purchase, invoice, currentPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingInvoice(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Paper elevation={0} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
        <BreadCrumbHeader MainText={"Invoice"} />

        {loadingInvoice ? (
          <>
            <SkeletonComponent />
          </>
        ) : (
          <>
            <Grid container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "17px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "2rem",
                }}
                gap={2}
              >
                <Grid
                  container
                  sx={{ display: "flex", flexdirection: "row" }}
                  gap={2}
                >
                  <Button
                    onClick={() => {
                      setIsActiveButton(0);
                      setPurchase(true);
                      setSell(false);
                    }}
                    sx={{
                      background: "#fff",
                      color: isActiveButton === 0 ? "#445FD2" : "#000",
                      borderRadius: "10px",
                      width: "120px",
                      height: "50px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "14px",
                      textTransform: "none",
                      border:
                        isActiveButton === 0
                          ? "1px solid #445FD2"
                          : "1px solid #F5F5F5",
                      "&:hover": {
                        color: "#445FD2",
                        border: "1px solid #445FD2",
                      },
                    }}
                  >
                    Purchase
                  </Button>
                  <Button
                    onClick={() => {
                      setIsActiveButton(1);
                      setPurchase(false);
                      setSell(true);
                    }}
                    sx={{
                      background: "#fff",
                      color: isActiveButton === 1 ? "#445FD2" : "#000",
                      borderRadius: "10px",
                      width: "80px",
                      height: "50px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 4000,
                      fontSize: "14px",
                      textTransform: "none",
                      border:
                        isActiveButton === 1
                          ? "1px solid #445FD2"
                          : "1px solid #F5F5F5",
                      "&:hover": {
                        color: "#445FD2",
                        border: "1px solid #445FD2",
                      },
                    }}
                  >
                    Sale
                  </Button>
                  <TextField
                    sx={searchbox}
                    placeholder="Search Invoices"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            src={SearchIcon}
                            sx={{
                              height: "auto",
                              width: {
                                xl: "20px",
                                lg: "20px",
                                md: "20px",
                                sm: "15px",
                                xs: "15px",
                              },
                            }}
                            alt="Search"
                          />
                        </InputAdornment>
                      ),
                    }}
                    value={search}
                    onChange={handleSearchfl}
                  />

                  <Button
                    id="demo-customized-button"
                    aria-controls={openfl ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openfl ? "true" : undefined}
                    // variant="contained"
                    disableElevation
                    onClick={handleClickfl}
                    variant="outlined"
                    sx={{
                      ...filterbutton,
                      "&:hover": {
                        color: "#445FD2",
                      },
                      color: openfl ? "#445FD2" : "#1B212D",
                      border: openfl
                        ? "1px solid #445FD2"
                        : "1px solid #F5F5F5",
                    }}
                    startIcon={<FilterListIcon />}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "1.4rem",
                        textTransform: "none",
                        m: 1,
                      }}
                    >
                      Filters
                    </Typography>
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={openfl}
                    onClose={handleClosefl}
                    onChange={handleChange}
                  >
                    {InvoiceTypes?.map((res, idx) => {
                      return (
                        <MenuItem
                          value={res}
                          key={idx}
                          onClick={() => {
                            if (invoice.includes(res.text)) {
                              setInvoice(
                                invoice.filter((item) => item !== res.text)
                              );
                              handleClosefl();
                            } else {
                              setInvoice([...invoice, res.text]);
                              handleClosefl();
                            }
                          }}
                        >
                          {res}
                        </MenuItem>
                      );
                    })}
                  </StyledMenu>
                </Grid>
                {loading === true ? (
                  <Paper
                    sx={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      backgroundColor: "transparent",
                      zIndex: 1000,
                    }}
                    elevation={0}
                  >
                    <CircularProgress
                      disableShrink
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                        height: "10%",
                        width: "15%",
                      }}
                    />
                  </Paper>
                ) : (
                  <TableContainer>
                    <Table sx={{ minWidth: 650, "& td": { border: 0 } }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" sx={tablehead}>
                            No
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            Name/Client
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            Date
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            {/* Quantity */}
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            Amount
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            Tax
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            {/* Status */}
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoices?.Data?.length > 0 ? (
                          invoices?.Data?.map((el, idx) => {
                            return (
                              <TableRow key={idx}>
                                <TableCell align="left">
                                  {" "}
                                  <Typography
                                    sx={{
                                      fontFamily: "Kumbh Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: 13,
                                      color: "#929EAE",
                                    }}
                                  >
                                    {idx + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                    gap={2}
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontFamily: "Kumbh Sans",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: 14,
                                          color: "#1B212D",
                                        }}
                                      >
                                        <CompanyName
                                          CompanyId={
                                            sell
                                              ? el?.BuyerDetails
                                                  ?.BuyerCompanyName
                                              : el?.SellerDetails
                                                  ?.SellerCompanyName
                                          }
                                          CompanyName={
                                            sell
                                              ? el?.BuyerDetails
                                                  ?.BuyerCompanyName
                                              : el?.SellerDetails
                                                  ?.SellerCompanyName
                                          }
                                        />
                                      </Typography>
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    sx={{
                                      fontFamily: "Kumbh Sans",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      color: "#1B212D",
                                    }}
                                  >
                                    {convertDate(el.PoDate)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">
                                  <Typography
                                    sx={{
                                      fontFamily: "Kumbh Sans",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      color: "#1B212D",
                                    }}
                                  >
                                    <img
                                      src={BXITokenIcon}
                                      style={{
                                        width: "18px",
                                        height: "auto",
                                        marginTop: "5px",
                                      }}
                                      alt="BXITokenIcon"
                                    />
                                    &nbsp;
                                    <CommaSeparator
                                      Price={el?.TotalInvoiceValue}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    sx={{
                                      fontFamily: "Kumbh Sans",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      color: "#1B212D",
                                    }}
                                  >
                                    <CommaSeparator Price={el?.TotalTaxValue} />
                                  </Typography>
                                </TableCell>

                                <TableCell align="left"></TableCell>
                                <TableCell align="left">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    sx={{ backgroundColor: "#445FD2" }}
                                    onClick={() => handleClick(el._id)}
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "Kumbh Sans",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: "1.4rem",
                                        textTransform: "none",
                                        m: 1,
                                      }}
                                    >
                                      View
                                    </Typography>
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow width="100%">
                            <TableCell colSpan={8} align="center">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Nodatafound />
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Grid>
            {invoices?.Data?.length > 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
                <Stack spacing={2}>
                  <StyledPagination
                    count={paginationCount}
                    color="primary"
                    page={currentPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </Box>
            ) : null}
          </>
        )}
      </Paper>
    </>
  );
};

export default InvoicePage;

const searchbox = {
  width: {
    xl: "70%",
    lg: "70%",
    md: "65%",
    sm: "60%",
    xs: "40%",
  },
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  background: "#F8F8F8",
  borderRadius: "15px",
  "& fieldset": { border: "none" },
};

const filterbutton = {
  borderRadius: "10px",
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "1.4rem",
  textTransform: "none",
  cursor: "pointer",
  "&:hover": {
    color: "#445FD2",
  },
};

const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1.2rem",
  color: "#929EAE",
};
