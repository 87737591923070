import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Table,
  Paper,
  Button,
  Dialog,
  TableRow,
  TableCell,
  Accordion,
  TableHead,
  Typography,
  DialogTitle,
  DialogContent,
  TableContainer,
  AccordionDetails,
  AccordionSummary,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import useStyles from "../CartPage/Styles";
import Skeleton from "@mui/material/Skeleton";
import CallIcon from "@mui/icons-material/Call";
import Pagination from "@mui/material/Pagination";
import sendEvents from "../../utils/sendEvents.js";
import { useSelector, useDispatch } from "react-redux";
import CloseIconModel from "@mui/icons-material/Close";
import CompanyName from "../../components/CompanyName";
import CloseIconDialog from "@mui/icons-material/Close";
import { useParams, useNavigate } from "react-router-dom";
import { useGetLoggedInUser } from "./OrdersInvoicesHooks";
import stackofcoins from "../../assets/Stack of Coins.svg";
import CommaSeprator from "../../components/CommaSeprator";
import stackofcoin from "../../assets/CartPage/unnamed 1.svg";
import ChatIcon from "../../assets/HeaderIcon/companychat.png";
import DownIcon from "../../assets/Images/CommonImages/Down.png";
import * as Styles from "../../components/common/Styled/Styles.js";
import Nodatafound from "../../components/NoDataFound/NoDataFound";
import { sendMassage } from "../../redux/action/Chat/Send-Massages";
import Checkboxbase from "../../assets/HeaderIcon/Checkboxbase.svg";
import Featuredicon from "../../assets/HeaderIcon/Featuredicon.svg";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { notifications } from "../../redux/action/Notification/notification";
import { OrderTracking } from "../../redux/action/Order-Tracking/Order-Tracking-Status";
import StyledTypography from "../../components/common/StyledTypography.jsx";
import ImageAndProductCarousel from "../../components/Carousel/ImageAndProductCarousel";
import GlobalSearchFilter from "../../components/SearchFilter/GlobalSearchFilter.jsx";

// table Header Data
let SellerDataTableHeadArray = [
  "Sr No",
  "Date",
  "Buyer Name",
  "Order Id",
  "Total Products",
  "Barter Coins",
  "Inr Total",
  "Order Status",
  "Order Summary",
  "Seller Contact",
];

// steps
const step = [
  {
    id: 1,
    message: "Raise a ticket with BXI ",
  },
  {
    id: 2,
    message: "Issue with Pricing",
  },
  {
    id: 3,
    message: "Issue with Barter coin",
  },

  {
    id: 4,
    message: "Issue with Invoicing ",
  },
  {
    id: 5,
    message: "Product not Delivered ",
  },
  {
    id: 6,
    message: "Product Defective/Damaged",
  },
];

const AllOrderList = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { orderTracking } = useSelector((state) => state.orderTracking);
  const { data: loggedInUserData } = useGetLoggedInUser();
  let login_User = loggedInUserData?.data?._id;

  const GetCompanyByID = async (id) => {
    try {
      const response = await axios.get(`/company/get_company/${id}`, {
        withCredentials: true,
      });
      const data = response.data;
      setSellarData(data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (orderTracking?.invoiceId?.SellerDetails?.SellerCompanyId) {
      GetCompanyByID(orderTracking.invoiceId.SellerDetails.SellerCompanyId);
    }
  }, [orderTracking?.invoiceId?.SellerDetails?.SellerCompanyId]);

  useEffect(() => {
    dispatch(OrderTracking(id));
  }, [dispatch]);

  const classes = useStyles();
  let navigate = useNavigate();
  const [Databyid, setDatabyid] = useState();
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [Infoopen, setInfoOpen] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [userOrders, setUserOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sellerOrders, setSellerOrders] = useState([]);
  const [messageOption, setMessageOption] = useState("");
  const [chatComapnyID, setChatCompanyId] = useState("");
  const [SellerLoading, setSellerLoading] = useState(false);
  const [childData, setChildData] = useState(null);

  // Active pannel
  const [activePanel, setActivePanel] = useState(
    Location?.state ? Location?.state : 0
  );

  const handlePageChange = (event, page) => {
    if (activePanel === 0) {
      GetUserOrders(page);
    } else {
      GetSellerOrders(page);
    }
    setCurrentPage(page);
  };

  const handleClose = () => {
    setOpenChat(false);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const handleClickInfoOpen = () => {
    setInfoOpen(true);
  };

  // GET Purchase Orders Data
  const GetUserOrders = async (props) => {
    setLoading(true);
    return await axios
      .get(`order/get-user-orders`, {
        params: {
          page: currentPage,
          limit: 10,
        },
      })
      .then((res) => {
        if (res?.data) {
          setLoading(false);
        }
        setUserOrders(res?.data);
      })
      .catch((err) => {});
  };

  // GET Seller User Data
  const GetSellerOrders = async (props) => {
    setSellerLoading(true);
    return await axios
      .get(`order/get-seller-orders`, {
        params: {
          page: currentPage,
          limit: 10,
        },
      })
      .then((res) => {
        if (res?.data) {
          setSellerLoading(false);
        }
        setSellerOrders(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (activePanel === 0) {
      GetUserOrders();
    } else {
      GetSellerOrders();
    }
  }, [currentPage]);

  const itemsPerPage = 10;
  const totalItems = activePanel === 0 ? userOrders.total : sellerOrders.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleChildData = (data) => {
    setChildData(data);
  };

  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
        boxShadow: "none",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText="My Orders" />

      {/* <GlobalSearchFilter sendDataToParent={handleChildData} /> */}

      {loading ? (
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {Array.from(new Array(10)).map((item, index) => {
            return <SkeletonFun />;
          })}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Grid
            container
            sx={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              p: 1.5,
            }}
          >
            <Grid
              item
              xl={0.5}
              lg={0.5}
              md={0.5}
              sm={0.5}
              xs={0.5}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography sx={Styles.tableheading}>Sr No</Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={Styles.tableheading}>
                Date
              </Typography>
            </Grid>
            <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
              <Typography sx={Styles.tableheading}>Product</Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={Styles.tableheading}>
                Order Id
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={Styles.tableheading}>
                Total Products
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={Styles.tableheading}>
                Barter Coins
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={Styles.tableheading}>
                Inr Total
              </Typography>
            </Grid>

            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={Styles.tableheading}>
                Order Status
              </Typography>
            </Grid>

            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
              <Typography align="center" sx={Styles.tableheading}>
                Order Summary
              </Typography>
            </Grid>
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
              <Typography align="center" sx={Styles.tableheading}>
                Seller Contact
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: "100%",
            }}
          >
            {userOrders?.data?.length <= 0 ? (
              <Nodatafound />
            ) : (
              userOrders?.data?.map((data, idx) => {
                const dataId = data?.SellerCompanyId;
                let totalPrice = 0;

                totalPrice += data?.BXITokenDetails
                  ? data?.BXITokenDetails?.TotalBXiCoins +
                      data?.BXITokenDetails?.TotalAdCostInBxiWithoutGST ||
                    data?.BXITokenDetails?.totalProductTokens +
                      data?.BXITokenDetails?.totalAdCostTokens
                  : data?.POTotals?.TotalCoinsWithAdditionalCost;
                function convertDate(inputFormat) {
                  function pad(s) {
                    return s < 10 ? "0" + s : s;
                  }
                  var d = new Date(inputFormat);
                  return [
                    pad(d.getDate()),
                    pad(d.getMonth() + 1),
                    d.getFullYear(),
                  ].join("/");
                }

                return (
                  <>
                    <Accordion
                      sx={{
                        boxShadow: "none",
                        border: "none",
                        borderBottom: "1px solid #F5F5F5",
                        "&::before": {
                          display: "none",
                        },
                        mt: 0.5,
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <img src={DownIcon} style={{ width: "9px" }} />
                        }
                        sx={{
                          m: 0,
                          width: "100%",
                          height: "70px",
                        }}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid container sx={{ height: "auto" }}>
                          <Grid
                            item
                            xl={0.5}
                            lg={0.5}
                            md={0.5}
                            sm={0.5}
                            xs={0.5}
                            sx={{
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <StyledTypography
                              text={idx + 1}
                              style={Styles.TableBodyTextStyling}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <StyledTypography
                              text={
                                data?.IsVoucher === false
                                  ? convertDate(data?.PurchaseOrderData?.PoDate)
                                  : convertDate(
                                      data?.PurchaseOrderData?.OrderDate
                                    )
                              }
                              style={Styles.TableBodyTextStyling}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={2.5}
                            lg={2.5}
                            md={2.5}
                            sm={2.5}
                            xs={2.5}
                            sx={{
                              maxWidth: "300px",
                            }}
                          >
                            <ImageAndProductCarousel
                              carouselData={
                                data?.PurchaseOrderData?.ProductData
                              }
                              MaxWidth={
                                data?.IsVoucher || data?.IsMedia ? true : false
                              }
                            />
                            {/* <StyledTypography
                              text={
                                <CompanyName
                                  CompanyId={
                                    data?.PurchaseOrderData?.SellerCompanyId
                                  }
                                  CompanyName={
                                    data?.PurchaseOrderData?.SellerDetails
                                      ?.SellerCompanyName
                                  }
                                />
                              }
                              style={Styles.TableBodyTextStyling}
                            /> */}
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <StyledTypography
                              text={data?.OrderId}
                              style={Styles.TableBodyTextStyling}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <StyledTypography
                              text={
                                <CommaSeprator
                                  Price={
                                    data?.PurchaseOrderData?.ProductData?.length
                                  }
                                />
                              }
                              style={Styles.TableBodyTextStyling}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <StyledTypography
                              text={
                                <>
                                  <img
                                    src={stackofcoin}
                                    alt="rupieicon"
                                    style={{
                                      width: "15px",
                                      height: "auto",
                                    }}
                                  />
                                  <CommaSeprator Price={totalPrice} />
                                </>
                              }
                              style={Styles.TableBodyTextStyling}
                              textlength={2}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <StyledTypography
                              text={
                                (
                                  data?.INRDetails
                                    ? data?.INRDetails?.TotalInrToPay
                                    : data?.PurchaseOrderData?.POTotals
                                        ?.TotalGstAmount +
                                      data?.PurchaseOrderData?.POTotals
                                        ?.TotalAdditionalCostWtihoutGSTInRupee
                                )
                                  ? (data?.INRDetails
                                      ? data?.INRDetails?.TotalInrToPay
                                      : data?.PurchaseOrderData?.POTotals
                                          ?.TotalGstAmount +
                                        data?.PurchaseOrderData?.POTotals
                                          ?.TotalAdditionalCostWtihoutGSTInRupee
                                    )?.toFixed(2)
                                  : "--"
                              }
                              style={Styles.TableBodyTextStyling}
                            />
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyTextStyling,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "2px",
                                width: "130px",
                                height: "30px",
                                borderRadius: "5px",
                                backgroundColor: (() => {
                                  const status =
                                    data?.OrderStatus ||
                                    (data?.IsVoucher && !data?.VoucherStatus
                                      ? "InActive"
                                      : data?.MediaStatus ||
                                        data?.VoucherStatus ||
                                        "Pending");

                                  const colorMapping = {
                                    "Out For delivery": "#FFEFEF",
                                    "Ready To Ship": "#FFF1E5",
                                    Progress: "#FFF1E5",
                                    "In Transit": "#FFF1E5",
                                    "Reached Nearest hub": "#FFF1E5",
                                    Delivered: "#D9FFE9",
                                    Shipped: "#D9FFE9",
                                    Completed: "#D9FFE9",
                                    "Creative Approved": "#D9FFE9",
                                    InActive: "#FFEFEF",
                                    Pending: "#FFF380",
                                  };

                                  return colorMapping[status] || "#FFF380";
                                })(),
                                color: (() => {
                                  const status =
                                    data?.OrderStatus ||
                                    (data?.IsVoucher && !data?.VoucherStatus
                                      ? "InActive"
                                      : data?.MediaStatus ||
                                        data?.VoucherStatus ||
                                        "Pending");

                                  const textMapping = {
                                    "Out For delivery": "#EB5757",
                                    "Ready To Ship": "#F2994A",
                                    Progress: "#F2994A",
                                    "In Transit": "#F2994A",
                                    "Reached Nearest hub": "#F2994A",
                                    Delivered: "#27AE60",
                                    Shipped: "#27AE60",
                                    Completed: "#27AE60",
                                    "Creative Approved": "#27AE60",
                                    InActive: "#EB5757",
                                    Pending: "black",
                                  };

                                  return textMapping[status] || "black";
                                })(),
                              }}
                            >
                              {data.OrderStatus ||
                                (data?.IsVoucher && !data?.VoucherStatus
                                  ? "InActive"
                                  : data?.MediaStatus ||
                                    data?.VoucherStatus ||
                                    "Pending")}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Box
                              onClick={() => {
                                navigate(
                                  `/home/orderdetails/${data?._id}/${data?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyId}/buyer`
                                );
                                sendEvents("Navigate");
                              }}
                              sx={{
                                cursor: "pointer",
                                width: "100px",
                                height: "30px",
                                background: "#2261A2",
                                borderRadius: "4.39877px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                            >
                              <StyledTypography
                                text="View"
                                style={{
                                  ...Styles.TableBodyTextStyling,
                                  color: "#fff",
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {data?.WalletData === undefined ||
                            data.WalletData === null ? (
                              "--"
                            ) : (
                              <Box
                                component="img"
                                src={ChatIcon}
                                onClick={() => {
                                  setChatCompanyId(data?.SellerCompanyId);
                                  setOpenChat(true);
                                }}
                                sx={{
                                  ...HeaderIconStyle,
                                  width: "35px",
                                  height: "auto",
                                  cursor: "pointer",
                                  color: "red",
                                }}
                              />
                            )}
                            {data?.WalletData === undefined ||
                            data.WalletData === null ? (
                              "--"
                            ) : (
                              <Button
                                sx={{
                                  width: "50px",
                                  height: "auto",
                                  cursor: "pointer",
                                  color: "#445FD2",
                                }}
                                onClick={() => {
                                  handleClickInfoOpen();
                                  setDatabyid(data);
                                }}
                              >
                                <CallIcon
                                  sx={{
                                    color: "primary.main",
                                    fontSize: "20px",
                                  }}
                                />
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0, pb: 2 }}>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            p: 0,
                            ml: 1,
                            width: "30%",
                          }}
                        >
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <StyledTypography
                              text={
                                <CompanyName
                                  CompanyId={
                                    data?.PurchaseOrderData?.SellerCompanyId
                                  }
                                  CompanyName={
                                    data?.PurchaseOrderData?.SellerDetails
                                      ?.SellerCompanyName
                                  }
                                />
                              }
                              style={Styles.TableBodyTextStyling}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })
            )}
          </Box>
        </Box>
      )}
      <Dialog
        open={openChat}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        sx={{
          backdropFilter: "blur(2px)",
        }}
        PaperProps={{
          sx: {
            width: "50%",
            maxHeight: 500,
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "90%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "#6B7A99",
                }}
              >
                Please select your Query
                <br />
              </Typography>
            </Box>
            <Box>
              <CloseIconModel
                sx={{
                  color: "#667085",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (openChat === false) {
                    setOpenChat(true);
                  } else {
                    setOpenChat(false);
                  }
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "20px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "orange",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "red",
              borderRadius: "2px",
            },
          }}
        >
          {step.map((item, idx) => {
            return (
              <div key={idx}>
                <Box
                  sx={{
                    border: "1.5px solid #E4E7EC",
                    borderRadius: "10px",
                    height: "auto",
                    minHeight: "48px",
                    mt: 1.5,
                    cursor: "pointer",
                    "&:hover": {
                      border: "1.5px solid #445FD2",
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onMouseEnter={() => {
                    setDisplay(item.message);
                  }}
                  onMouseLeave={() => {
                    setDisplay(false);
                  }}
                  onClick={() => {
                    const userId = async (companyId) => {
                      try {
                        const user = await axios.get(
                          `support/getuser?companyId=${companyId}`,
                          {
                            withCredentials: true,
                          }
                        );
                        const userId = user.data._id;

                        await UpdateNotifications(userId);
                        await SendNotifications(SellerCompanyId, login_User);
                      } catch (error) {}
                    };

                    const UpdateNotifications = async (
                      userId,
                      admin = "650ac9e0b29820230639b197"
                    ) => {
                      try {
                        const res1 = await axios.post(
                          `support/create_room`,
                          { userId, admin },
                          { withCredentials: true }
                        );
                        const roomId = res1.data._id;
                        const messageType = "text";
                        const messageContent = item.message;
                        await dispatch(
                          sendMassage(messageContent, roomId, messageType)
                        );
                        navigate("/home/message");
                      } catch (err) {}
                    };

                    const SendNotifications = async (receiver, sender) => {
                      try {
                        const message = `${companyName} Company has contacted you`;
                        const type = "messages";
                        await dispatch(
                          notifications(receiver, sender, message, type)
                        );
                      } catch (err) {}
                    };
                    userId(chatComapnyID);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "97%",
                    }}
                    onClick={() => {
                      setMessageOption(item.message);
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: "30px",
                        width: "10%",
                        opacity: item.message === display ? "1" : "0.5",
                      }}
                      src={Featuredicon}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "15px",
                        color: "#6B7A99",
                        width: "80%",
                      }}
                    >
                      {item.message}
                    </Typography>
                    {item.message === display ? (
                      <Box
                        component="img"
                        sx={{
                          height: "15px",
                          width: "10%",
                        }}
                        src={Checkboxbase}
                      />
                    ) : null}
                  </Box>
                </Box>
              </div>
            );
          })}
        </DialogContent>
      </Dialog>
      <Dialog
        open={Infoopen}
        onClose={handleInfoClose}
        fullWidth
        maxWidth="sm"
        sx={{
          backdropFilter: "blur(2px)",
        }}
        PaperProps={{
          sx: {
            width: "60%",
            maxHeight: 600,
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "80%",
                mx: "auto",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "#6B7A99",
                  textAlign: "center",
                }}
              >
                More information
              </Typography>
            </Box>
            <Box>
              <CloseIconDialog
                sx={{
                  color: "#667085",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (open === false) {
                    setInfoOpen(true);
                  } else {
                    setInfoOpen(false);
                  }
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              // border: "1px solid #EDEFF2",
              // borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // p: 2,
            }}
          >
            <Box sx={infoboxStyle}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "20px",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    ...HeadText,
                    bgcolor: "#fff",
                    zIndex: "1",
                  }}
                >
                  Seller Details
                </Typography>
                <span
                  style={{
                    borderTop: "0.5px solid #6B7A99",
                    width: "75%",
                    position: "absolute",
                    top: "50%",
                    left: "25%",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  ...SubDataHeadText,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Name :{" "}
                <span
                  style={{
                    ...SubDataText,
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Box
                    src={
                      Databyid?.PurchaseOrderData?.SellerDetails
                        ?.SellerCompanyLogo
                    }
                    component={"img"}
                    sx={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "30px",
                    }}
                  />
                  {
                    Databyid?.PurchaseOrderData?.SellerDetails
                      ?.SellerCompanyName
                  }
                </span>
              </Typography>
              <Typography sx={SubDataHeadText}>
                Email :{" "}
                <span style={SubDataText}>
                  {
                    Databyid?.PurchaseOrderData?.SellerDetails
                      ?.SellerCompanyEmail
                  }
                </span>
              </Typography>
              <Typography sx={SubDataHeadText}>
                Phone :{" "}
                <span style={SubDataText}>
                  {
                    Databyid?.PurchaseOrderData?.SellerDetails
                      ?.SellerCompanyContact
                  }
                </span>
              </Typography>
              <Typography sx={SubDataHeadText}>
                Address :{" "}
                <span style={SubDataText}>
                  {
                    Databyid?.PurchaseOrderData?.SellerDetails?.Address
                      ?.AddressLine
                  }
                </span>
              </Typography>
              <Typography sx={SubDataHeadText}>
                Product Name :{" "}
                <span style={SubDataText}>
                  {Databyid?.PurchaseOrderData?.ProductData?.at(0)?.ProductName}
                </span>
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {activePanel === 1 &&
        (SellerLoading ? (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {Array.from(new Array(10)).map((item, index) => {
              return <SkeletonFun />;
            })}
          </Box>
        ) : (
          <React.Fragment>
            <TableContainer>
              <Table sx={{ borderBottom: "none" }}>
                <TableHead>
                  <TableRow>
                    {SellerDataTableHeadArray.map((data, idx) => {
                      return (
                        <TableCell
                          key={idx}
                          sx={{
                            ...Styles.tableheading,
                            pl: 2,
                            textAlign: "left",
                            width: data?.width,
                            fontSize: "12px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            width: data === "Buyer Name" ? "250px" : "auto",
                          }}
                        >
                          {data}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>

            {sellerOrders?.data?.length <= 0 ? (
              <Nodatafound />
            ) : (
              sellerOrders?.data?.map((data, idx) => {
                let totalPrice = 0;

                totalPrice += data?.BXITokenDetails
                  ? data?.BXITokenDetails?.TotalBXiCoins +
                      data?.BXITokenDetails?.TotalAdCostInBxiWithoutGST ||
                    data?.BXITokenDetails?.totalProductTokens +
                      data?.BXITokenDetails?.totalAdCostTokens
                  : data?.POTotals?.TotalCoinsWithAdditionalCost;

                function convertDate(inputFormat) {
                  function pad(s) {
                    return s < 10 ? "0" + s : s;
                  }
                  var d = new Date(inputFormat);
                  return [
                    pad(d.getDate()),
                    pad(d.getMonth() + 1),
                    d.getFullYear(),
                  ].join("/");
                }

                return (
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      border: "none",
                      borderBottom: "1px solid #F5F5F5",
                      "&::before": {
                        display: "none",
                      },
                      mt: 0.5,
                      // px: 1,
                      borderRadius: "5px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <img src={DownIcon} style={{ width: "9px" }} />
                      }
                      sx={{
                        // p: 0,
                        m: 0,
                        width: "100%",
                        height: "70px",
                      }}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid
                        container
                        sx={{
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Grid
                          item
                          xl={0.5}
                          lg={0.5}
                          md={0.5}
                          sm={0.5}
                          xs={0.5}
                          sx={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <StyledTypography
                            text={idx + 1}
                            style={Styles.TableBodyTextStyling}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <StyledTypography
                            text={
                              data?.IsVoucher === false
                                ? convertDate(data?.PurchaseOrderData?.PoDate)
                                : convertDate(
                                    data?.PurchaseOrderData?.OrderDate
                                  )
                            }
                            style={Styles.TableBodyTextStyling}
                          />
                        </Grid>
                        <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                          <StyledTypography
                            text={
                              <CompanyName
                                CompanyId={
                                  data?.PurchaseOrderData?.BuyerCompanyId
                                }
                                CompanyName={
                                  data?.PurchaseOrderData?.BuyerDetails
                                    ?.BuyerCompanyName
                                }
                              />
                            }
                            style={Styles.TableBodyTextStyling}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <StyledTypography
                            text={data?.OrderId}
                            style={Styles.TableBodyTextStyling}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <StyledTypography
                            text={
                              <CommaSeprator
                                Price={
                                  data?.PurchaseOrderData?.ProductData?.length
                                }
                              />
                            }
                            style={Styles.TableBodyTextStyling}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <StyledTypography
                            text={
                              <>
                                <img
                                  src={stackofcoin}
                                  alt="rupieicon"
                                  style={{
                                    width: "15px",
                                    height: "auto",
                                  }}
                                />
                                <CommaSeprator Price={totalPrice} />
                              </>
                            }
                            style={Styles.TableBodyTextStyling}
                            textlength={2}
                          />
                        </Grid>

                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <StyledTypography
                            text={
                              (
                                data?.INRDetails
                                  ? data?.INRDetails?.TotalInrToPay
                                  : data?.PurchaseOrderData?.POTotals
                                      ?.TotalGstAmount +
                                    data?.PurchaseOrderData?.POTotals
                                      ?.TotalAdditionalCostWtihoutGSTInRupee
                              )
                                ? (data?.INRDetails
                                    ? data?.INRDetails?.TotalInrToPay
                                    : data?.PurchaseOrderData?.POTotals
                                        ?.TotalGstAmount +
                                      data?.PurchaseOrderData?.POTotals
                                        ?.TotalAdditionalCostWtihoutGSTInRupee
                                  )?.toFixed(2)
                                : "--"
                            }
                            style={Styles.TableBodyTextStyling}
                          />
                        </Grid>

                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "start",
                            display: "flex",
                            pl: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              ...Styles.TableBodyTextStyling,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "2px",
                              width: "100px",
                              height: "30px",
                              bgcolor:
                                data?.OrderStatus === "Out For delivery" ||
                                data?.MediaStatus === "Cancelled" ||
                                data?.VoucherStatus === "InActive"
                                  ? "#FFEFEF"
                                  : data?.OrderStatus === "Ready To Ship" ||
                                    data?.OrderStatus === "Progress" ||
                                    data?.OrderStatus === "In Transit" ||
                                    data?.MediaStatus === "Executed" ||
                                    data?.OrderStatus === "Reached Nearest hub"
                                  ? "#FFF1E5"
                                  : data?.OrderStatus === "Delivered" ||
                                    data?.OrderStatus === "Shipped" ||
                                    data?.MediaStatus === "Completed" ||
                                    data?.VoucherStatus === "Active" ||
                                    data?.MediaStatus === "Creative Approved"
                                  ? "#D9FFE9"
                                  : "#FFF380",
                              borderRadius: "5px",
                              color:
                                data?.OrderStatus === "Out For delivery" ||
                                data?.MediaStatus === "Cancelled" ||
                                data?.VoucherStatus === "InActive"
                                  ? "#EB5757"
                                  : data?.OrderStatus === "Ready To Ship" ||
                                    data?.OrderStatus === "Progress" ||
                                    data?.MediaStatus === "Executed" ||
                                    data?.OrderStatus === "In Transit" ||
                                    data?.OrderStatus === "Reached Nearest hub"
                                  ? "#F2994A"
                                  : data?.OrderStatus === "Delivered" ||
                                    data?.OrderStatus === "Shipped" ||
                                    data?.MediaStatus === "Completed" ||
                                    data?.VoucherStatus === "Active" ||
                                    data?.MediaStatus === "Creative Approved"
                                  ? "#27AE60"
                                  : "black",
                            }}
                          >
                            {data.OrderStatus
                              ? data.OrderStatus
                              : data?.IsVoucher && !data?.VoucherStatus
                              ? "InActive"
                              : data?.MediaStatus ||
                                data?.VoucherStatus ||
                                "Pending"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "start",
                            display: "flex",
                          }}
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                              width: "100px",
                              height: "30px",
                              background: "#2261A2",
                              borderRadius: "4.39877px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                            onClick={() => {
                              navigate(
                                `/home/orderdetails/${data?._id}/${data?.PurchaseOrderData?.SellerDetails?.SellerCompanyId}/seller`
                              );
                            }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyTextStyling,
                                color: "#fff",
                              }}
                            >
                              View
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {data?.WalletData === undefined ||
                          data.WalletData === null ? (
                            "--"
                          ) : (
                            <Button
                              sx={{
                                width: "50px",
                                height: "auto",
                                cursor: "pointer",
                                color: "#445FD2",
                              }}
                              onClick={() => {
                                handleClickInfoOpen();
                                setDatabyid(data);
                              }}
                            >
                              <CallIcon
                                sx={{
                                  color: "primary.main",
                                  fontSize: "20px",
                                }}
                              />
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0, pb: 2 }}>
                      {data?.PurchaseOrderData?.ProductData?.length > 0 &&
                        data?.PurchaseOrderData?.ProductData?.map(
                          (data, idx) => {
                            return (
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  p: 0,
                                  ml: 1,
                                  width: "100%",
                                }}
                              >
                                <Grid
                                  item
                                  xl={1}
                                  lg={1}
                                  md={1}
                                  sm={1}
                                  xs={1}
                                  sx={{
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  <StyledTypography
                                    text={idx + 1}
                                    style={Styles.TableBodyTextStyling}
                                  />
                                </Grid>
                                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                  <Box
                                    component="img"
                                    src={data?.ProductImage}
                                    sx={{
                                      width: "50px",
                                      height: "auto",
                                      mx: "auto",
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xl={2}
                                  lg={2}
                                  md={2}
                                  sm={2}
                                  xs={2}
                                  sx={{
                                    textAlign: "left",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    display: "flex",
                                  }}
                                >
                                  <StyledTypography
                                    text={data?.ProductName}
                                    style={{
                                      ...Styles.TableBodyTextStyling,
                                      textTransform: "capitalize",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "200px",
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xl={1}
                                  lg={1}
                                  md={1}
                                  sm={1}
                                  xs={1}
                                  sx={{
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  <StyledTypography
                                    text={
                                      <CommaSeprator
                                        Price={data?.ProductQuantity}
                                      />
                                    }
                                    style={Styles.TableBodyTextStyling}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xl={1}
                                  lg={1}
                                  md={1}
                                  sm={1}
                                  xs={1}
                                  sx={{
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  <StyledTypography
                                    text={
                                      <>
                                        <Box
                                          component="img"
                                          src={stackofcoins}
                                          alt="coins"
                                          sx={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                        />
                                        <CommaSeprator
                                          Price={data?.DiscountedPrice}
                                        />
                                      </>
                                    }
                                    style={Styles.TableBodyTextStyling}
                                    textlength={2}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xl={2}
                                  lg={2}
                                  md={2}
                                  sm={2}
                                  xs={2}
                                  sx={{
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  <StyledTypography
                                    text={data?.OrderStatus}
                                    style={{
                                      ...Styles.TableBodyTextStyling,
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "2px",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                    </AccordionDetails>
                  </Accordion>
                );
              })
            )}
          </React.Fragment>
        ))}
      <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
        <Stack spacing={2}>
          <StyledPagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

export default AllOrderList;

const HeaderIconStyle = {
  height: "auto",
  width: {
    xl: "25px",
    lg: "25px",
    md: "25px",
    sm: "20px",
    xs: "20px",
  },
  mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
};

const infoboxStyle = {
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "8px",
};

const HeadText = {
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 500,
  color: "#191919CC",
  textAlign: "left",
  py: 1,
  width: "auto",
  MinWidth: "25%",
  pr: 1,
};

const SubDataHeadText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
  textAlign: "left",
};
const SubDataText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "left",
};

const StyledPagination = styled(Pagination)({
  "& .MuiPagination-ul li:last-child": {
    marginLeft: "16px",
  },
  "& .MuiPagination-ul li:last-child button::before": {
    content: "'Last'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "9px",
    lineHeight: "14px",
    color: "#445FD2",
    marginRight: "8px",
  },
  "& .MuiPagination-ul li:first-child": {
    marginRight: "16px",
  },
  "& .MuiPagination-ul li:first-child button::after": {
    content: "'First'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "9px",
    lineHeight: "14px",
    color: "#445FD2",
    marginLeft: "8px",
  },
  "& .MuiPaginationItem-icon": {
    color: "#445FD2",
    fontWeight: 800,
  },
});

const SkeletonFun = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={"1300px"}
      height={"60px"}
      animation="wave"
      sx={{
        borderRadius: "5px",
        mt: 1,
        width: "100%",
        bgcolor: "#edf1f5",
        mx: "auto",
      }}
    />
  );
};
