import { Box, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import ImagePoster from "../components/Cards/ImagePoster";
import ProductList from "../pages/Marketplace/ProductList";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCarousel from "../components/Carousel/ProductDetailsCarousel";
import { getProduct } from "../redux/action/Home-Filter/products";
import axios from "axios";
import useGetLoggedInUser from "../Hooks/LoggedInUser/useGetLoggedInUser";
import { getCompanyDetails } from "../redux/action/CompanyActions";
import { MarketPlaceStyle } from "../styles/common.styles";
import SearchBar from "../components/Filters/SearchBar";

export let States = null;
export let PriceRange = null;
export let Product_Qty = null;
export let Clear = null;
export let Refresh = null;
export let PriceShortHightToLow = null;
export let PriceShortLowToHight = null;
export let WhatsNew = null;
export let Popularity = null;
export let Voucher = null;
export let ProductsFilter = null;
export let IsBestSeller = null;
export let IsReadyStock = null;

const MarketPlace = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const [childData, setChildData] = useState(null);

  const { data: loggedInUserData } = useGetLoggedInUser();

  const GetCompanyData = async () => {
    try {
      await axios
        .get(`auth/getauthsCompany`, { withCredentials: true })
        .then((res) => {
          GetCompanyTypeData(res?.data?.companyType);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const GetCompanyTypeData = async (props) => {
    try {
      await axios.get(`company_type/get_companyType/${props}`).then((res) => {
        localStorage.setItem("companyType", res?.data?.CompanyTypeName);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetCompanyData();
  }, []);

  const handleChildData = (data) => {
    setChildData(data);
  };

  const { company: CompanyDetails } = useSelector(
    (state) => state.companyDetails
  );

  window.onload = () => {
    setRefresh(true);
  };

  States = "";
  PriceRange = "";
  Product_Qty = "";
  Clear = "";
  Refresh = refresh;
  PriceShortHightToLow = "";
  PriceShortLowToHight = "";
  WhatsNew = "";
  Popularity = "";
  Voucher = "";
  ProductsFilter = "";
  IsBestSeller = "";
  IsReadyStock = "";

  useEffect(() => {
    if (
      loggedInUserData &&
      loggedInUserData.data &&
      loggedInUserData.data._id
    ) {
      dispatch(
        getProduct(
          "",
          "",
          "",
          "",
          "",
          CompanyDetails ? CompanyDetails._id : "",
          refresh,
          loggedInUserData.data._id,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        )
      );
    }
  }, [
    loggedInUserData,
    dispatch,
    "",
    "",
    "",
    "",
    "",
    CompanyDetails,
    refresh,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  useEffect(() => {
    dispatch(getCompanyDetails());
  }, [dispatch]);

  const Profile_Visit = async () => {
    try {
      await axios
        .put("communcations/updateCompanyCommunications", {
          template: "bxi-marketplace",
        })
        .then((response) => {
          console.log(response);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Profile_Visit();
    localStorage.removeItem("myArray");
  }, []);

  return (
    <Paper
      sx={{
        width: "92%",
        mx: "auto",
        background: "transparent",
        borderRadius: "5px",
      }}
      elevation={0}
    >
      <Box sx={MarketPlaceStyle.BoxStyle}>
        <SearchBar sendDataToParent={handleChildData} />
      </Box>
      <Grid container sx={{ width: "97%", mx: "auto", mt: "35px" }}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={10.5}
          xs={10}
          sx={{
            bgcolor: "transparent",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ProductDetailsCarousel />
        </Grid>
      </Grid>

      <ImagePoster />
      <ProductList taxToggle={childData} />
    </Paper>
  );
};

export default MarketPlace;
