import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ToastContainer, toast } from "react-toastify";
import print from "../../../src/assets/Images/CommonImages/PrintInvoice.svg";
import download from "../../../src/assets/Images/CommonImages/downloadinvoice.svg";
import { useUpdatePurchaseOrder } from "../../Hooks/OrderActions/useUpdatePurchaseOrder";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { getOrderSummary } from "../../redux/action/OrderSummaryActions";
import axios from "axios";
import { useGetCompanyById } from "../../Hooks/Auth";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import NumberToWord from "../../components/NumberToWord";
import useGetMemberships from "../../Hooks/Plan/membershipData";
import state_code from "../../utils/state_code.json";
import CommaSeparator from "../../components/CommaSeprator";
import BXIStamp from "../../assets/BXIStamp.png";

const AdvanceMemvershipInvoice = (props) => {
  const componentRef = useRef();
  const [StateCodeData, setStateCodeData] = useState("");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownload = () => {
    setTimeout(() => {
      html2canvas(componentRef.current)
        .then((canvas) => {
          const pdf = new jsPDF();
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight
          );
          pdf.save("print.pdf");
        })
        .catch((error) => {});
    }, 1000);
  };

  const { data: CompanyData, isLoading, error } = useGetLoggedInUser();

  return (
    <Paper
      sx={{
        bgcolor: "#fff",
        boxShadow: "none",
        p: 3,
        borderRadius: "20px",
        height: "auto",
        minHeight: "520px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "30px",
      }}
      elevation={0}
    >
      <Box
        sx={{
          bgcolor: "transparent",
          width: "100%",
          height: "100%",
        }}
        elevation={0}
      >
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <Box
          sx={{
            width: "100%",
            bgcolor: "white",
            mx: "auto",
            borderRadius: "17px",
            py: "40px",
          }}
          elevation={1}
          ref={componentRef}
        >
          <Box
            sx={{
              width: "95%",
              mx: "auto",
              borderLeft: "1px solid #cdcdcd",
              borderRight: "1px solid #cdcdcd",
              borderBottom: "1px solid #cdcdcd",
              borderTop: "1px solid #cdcdcd",
              px: "0px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "auto",
                mx: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                }}
              >
                <img
                  src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/image+3.png"
                  style={{ height: "85px", width: "auto" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "1px solid #cdcdcd",
                  height: "130px",
                  width: "40%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 600,
                    width: "98%",
                    color: "#000000",
                    textAlign: "center",
                  }}
                >
                  BXI WORLD LLP
                </Typography>
                <Typography
                  sx={{
                    ...TextLastStyle,
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 500,
                    width: "98%",
                    textAlign: "center",
                  }}
                >
                  501 , 5th Floor Meadows Tower , Sahar Plaza Complex,
                </Typography>
                <Typography
                  sx={{
                    ...TextLastStyle,
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 500,
                    width: "98%",
                    textAlign: "center",
                  }}
                >
                  Andheri East , Mumbai - 400059
                </Typography>
                <Typography
                  sx={{
                    ...TextLastStyle,
                    width: "98%",
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Tel : 022 - 49646776
                </Typography>
                <Typography
                  sx={{
                    ...TextLastStyle,
                    width: "98%",
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 500,
                    mt: 0.5,
                    textAlign: "center",
                  }}
                >
                  GSTIN: 27AAXFB2929C1ZA
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  // borderRight: "1px solid #cdcdcd",
                  borderLeft: "1px solid #cdcdcd",
                  height: "130px",
                  color: "#156DB6",
                  width: "30%",
                }}
              >
                <a
                  href="http://www.bxiworld.com/"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{
                      ...TextLastStyle,
                      width: "98%",
                      color: "#",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    www.bxiworld.com
                  </Typography>
                </a>
                {/* <Typography
                  sx={{
                    ...TextLastStyle,
                    width: "98%",
                    color: "#6B7A99",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  Email ID
                </Typography> */}
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "42px",
                mx: "auto",
                background: "#156DB6",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  ...CommongTextStyle,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: "100%",
                  fontWeight: 500,
                }}
              >
                Membership Fee Tax Invoice
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  mx: "auto",
                  borderRight: "1px solid #CDCDCD",
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          Invoice No : {props.MemberShipData?.MembershipId}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          Invoice Date :{" "}
                          {new Date(
                            props.MemberShipData?.InvoiceDate
                          ).toLocaleDateString("en-GB")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          {" "}
                          State : {props?.MemberShipData?.CompanyAddress?.State}
                        </Typography>
                        <Typography sx={TextLastStyle}>
                          {" "}
                          Code : {props.MemberShipData?.StateCode}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          &nbsp; &nbsp; &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  mx: "auto",
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      {/* <TableCell
                      sx={{
                        p: 0.2,
                        borderBottom: "none",
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Place of Supply :
                      </Typography>
                    </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          p: 0.2,
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={TextLastStyle}>&nbsp;</Typography>
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                    <TableCell
                      sx={{
                        p: 0.2,
                        borderBottom: "none",
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Purchase order no :
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        p: 0.2,
                        borderBottom: "none",
                      }}
                    >
                      <Typography sx={TextLastStyle}>&nbsp;</Typography>
                    </TableCell>
                  </TableRow> */}
                  </TableBody>
                </Table>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                mx: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "35px",
                  background: "#156DB6",
                  borderRadius: "3px 0px 0px 3",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                  borderRadius: "3px",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "35px",
                    borderRight: "1px solid #F3F2F3",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      fontSize: "14px",
                      color: "white",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    &nbsp; Bill to Party
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "35px",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      color: "white",
                      fontSize: "14px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    &nbsp; Ship to Party
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                  py: 0,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                  }}
                >
                  <Table sx={{ p: 0 }}>
                    <TableBody sx={{ p: 0 }}>
                      <TableRow sx={{ p: 0, width: "100%" }}>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "150px",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            Buyer Legal Name :
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {CompanyData?.data?.companyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "170px",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            Buyer Legal Address :{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {CompanyData?.data?.CompanyAddress?.AddressLine}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}> GSTIN : </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {CompanyData?.data?.gst}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}> State :</Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {props?.MemberShipData?.CompanyAddress?.State}
                          </Typography>
                          <Typography sx={{ ...TextLastStyle, width: "50%" }}>
                            {" "}
                            Code : {props.MemberShipData?.StateCode}
                          </Typography>{" "}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "150px",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            Buyer Name :
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {CompanyData?.data?.companyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography sx={TextLastStyle}>Address : </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {CompanyData?.data?.CompanyAddress?.AddressLine}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}> GSTIN : </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {CompanyData?.data?.gst}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextLastStyle}> State :</Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.2,
                            borderBottom: "none",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {props?.MemberShipData?.CompanyAddress?.State}
                          </Typography>
                          <Typography sx={{ ...TextLastStyle, width: "50%" }}>
                            {" "}
                            Code : {props.MemberShipData?.StateCode}
                          </Typography>{" "}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>

            <TableContainer
              component={Paper}
              sx={{
                maxWidth: "100%",
                mx: "auto",
                boxShadow: "none",
                borderRadius: "0px",
              }}
            >
              <Table
                sx={{
                  minWidth: "700px",
                  boxShadow: "none",
                  borderRight: "none",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow sx={{ height: "33px", bgcolor: "#156DB6" }}>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, width: "50px" }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        Sr. No
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      rowSpan={5}
                      sx={{ ...tablecell, width: "250px" }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        Particulars
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, width: "80px" }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                          width: "170px",
                        }}
                      >
                        HSN / SAC
                      </Typography>
                    </TableCell>
                    {/* <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, width: "50px" }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        QTY
                      </Typography>
                    </TableCell> */}
                    {/* <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, width: "50px" }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        Rate
                      </Typography>
                    </TableCell> */}
                    <TableCell
                      align="center"
                      colSpan={2}
                      rowSpan={1}
                      sx={{ ...tablecell, width: "150px" }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          height: "100%",
                        }}
                      >
                        Amount ( Taxable Value )
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>1</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                        BXI Membership Fees
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                        999599
                      </Typography>
                    </TableCell>

                    {/* <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>1</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>
                        <CommaSeparator Price={props.MemberShipData.MembershipAmount} />
                      </Typography>
                    </TableCell> */}

                    <TableCell
                      align="right"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                        <CommaSeparator
                          Price={props.MemberShipData.MembershipAmount}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* {memberShipData?.map((x, y) => {
                  return (
                  
                  );
                })} */}
                  {/* <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>
                        BXI Membership Deposit for
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    ></TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>1</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>
                        {props.MemberShipData?.Membership !== "PLATINIUM"
                          ? props.MemberShipData?.MembershipAmount
                          : 120000}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography sx={TextLastStyle}>
                        {props.MemberShipData?.Membership !== "PLATINIUM"
                          ? props.MemberShipData?.MembershipAmount
                          : 120000}
                      </Typography>
                    </TableCell>
                  </TableRow> */}
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={{
                        ...tablecell,
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontStyle: "normal",
                          fontStyle: "italic",
                        }}
                      >
                        {props.MemberShipData.MemberShipPlanName}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={{
                        p: 0,
                        maxWidth: "100px",
                        borderRight: "1px solid #CDCDCD",
                        mx: "auto",
                      }}
                    ></TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    ></TableCell>
                    <TableCell
                      align="left"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    ></TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={3}
                      rowSpan={1}
                      sx={{ ...tablecell, bgcolor: "#156DB6" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#FFFFFF",
                          textAlign: "center",
                        }}
                      >
                        Total Amount Before Tax
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        {/* {props.MemberShipData.HSN} */}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          textAlign: "right",
                        }}
                      >
                        <CommaSeparator
                          Price={props.MemberShipData.MembershipAmount}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={3}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {" "}
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        Terms & Conditions
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        CGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        {!props.MemberShipData?.IGST
                          ? props?.MemberShipData?.GSTPercentage / 2 + "%"
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        {!props.MemberShipData?.IGST ? (
                          <CommaSeparator
                            Price={props?.MemberShipData?.MembershipGST / 2}
                          />
                        ) : (
                          ""
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={3}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {" "}
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#6B7A99",
                          fontWeight: 500,
                        }}
                      >
                        The payment should be in favor of{" "}
                        <strong style={{ color: "#000" }}>
                          "BXI WORLD LLP"
                        </strong>
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        SGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        {!props.MemberShipData?.IGST
                          ? props?.MemberShipData?.GSTPercentage / 2 + "%"
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        {!props?.MemberShipData?.IGST ? (
                          <CommaSeparator
                            Price={props?.MemberShipData?.MembershipGST / 2}
                          />
                        ) : (
                          ""
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={3}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      {" "}
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#6B7A99",
                          fontWeight: 500,
                        }}
                      >
                        Payments should be done through Electronic mode only
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        IGST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        {props.MemberShipData?.IGST
                          ? props.MemberShipData?.GSTPercentage + "%"
                          : ""}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        {props.MemberShipData?.IGST ? (
                          <CommaSeparator
                            Price={props?.MemberShipData?.MembershipGST}
                          />
                        ) : (
                          ""
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={3}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#023e8a",
                          fontWeight: 500,
                        }}
                      >
                        Kindly deposit the same.
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        Total GST
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#6B7A99",
                          fontWeight: 500,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        <CommaSeparator
                          Price={props.MemberShipData.MembershipGST}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={3}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                          fontSize: "14px",
                          fontStyle: "italic",
                        }}
                      >
                        Total amount payable ( In words )
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        Round Off
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#6B7A99",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#6B7A99",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      ></Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={3}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                          fontStyle: "italic",
                        }}
                      >
                        <NumberToWord
                          number={parseFloat(props.MemberShipData.TotalAmount)}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        Invoice Total
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#6B7A99",
                          fontWeight: 500,
                        }}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ ...tablecell, borderBottom: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        <CommaSeparator
                          Price={props.MemberShipData.TotalAmount}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              sx={{
                width: "100%",
                mx: "auto",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            HSN / SAC
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            Taxable Value
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>

                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            CGST Tax
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                            width: "25%",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "13px",
                            }}
                          >
                            Rate
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "25%",
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "13px",
                            }}
                          >
                            Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            SGST Tax
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                            width: "25%",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "13px",
                            }}
                          >
                            Rate
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "25%",
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "13px",
                            }}
                          >
                            Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",

                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            Integrated Tax
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                            width: "25%",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "13px",
                            }}
                          >
                            Rate
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "25%",
                            borderRight: "1px solid #CDCDCD",
                            borderBottom: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "13px",
                            }}
                          >
                            Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            border: "none",
                            p: 0,
                            height: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TextLastStyle,
                              color: "#000",
                              fontSize: "15px",
                              fontWeight: 500,
                            }}
                          >
                            Total Tax Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {props?.MemberShipData?.HSN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                          px: 0,
                        }}
                      >
                        <TableCell
                          align="right"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            <CommaSeparator
                              Price={props?.MemberShipData?.MembershipAmount}
                            />
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "200px",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            width: "100px",
                            borderBottom: "none",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {!props.MemberShipData?.IGST
                              ? props?.MemberShipData?.GSTPercentage / 2 + "%"
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            borderBottom: "none",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {" "}
                            {props.MemberShipData?.IGST ? (
                              "-"
                            ) : (
                              <CommaSeparator
                                Price={props?.MemberShipData?.MembershipGST / 2}
                              />
                            )}
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "200px",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            width: "100px",
                            borderBottom: "none",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {!props.MemberShipData?.IGST
                              ? props?.MemberShipData?.GSTPercentage / 2 + "%"
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            borderBottom: "none",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {" "}
                            {props.MemberShipData?.IGST ? (
                              "-"
                            ) : (
                              <CommaSeparator
                                Price={props?.MemberShipData?.MembershipGST / 2}
                              />
                            )}
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "200px",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            width: "100px",
                            borderBottom: "none",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {props.MemberShipData?.IGST
                              ? props?.MemberShipData?.GSTPercentage + "%"
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            borderBottom: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {props.MemberShipData?.IGST ? (
                              <CommaSeparator
                                Price={props?.MemberShipData?.MembershipGST}
                              />
                            ) : (
                              ""
                            )}
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>

                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                          px: 0,
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            border: "none",
                            p: 0,
                            height: "20px",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            <CommaSeparator
                              Price={props?.MemberShipData?.MembershipGST}
                            />
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography
                            sx={{ ...TextLastStyle, color: "#000" }}
                          ></Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                          px: 0,
                        }}
                      >
                        <TableCell
                          align="right"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "200px",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            width: "100px",
                            borderBottom: "none",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{ ...TextLastStyle, color: "#000" }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            borderBottom: "none",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "200px",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            width: "100px",
                            borderBottom: "none",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{ ...TextLastStyle, color: "#000" }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            borderBottom: "none",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "200px",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            width: "100px",
                            borderBottom: "none",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{ ...TextLastStyle, color: "#000" }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            borderBottom: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>

                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                          px: 0,
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            border: "none",
                            p: 0,
                            height: "20px",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {" "}
                            Total{" "}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "180px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                          px: 0,
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "180px",
                            p: 0,
                            height: "20px",
                            border: "none",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {" "}
                            {props.MemberShipData?.IGST ? (
                              "-"
                            ) : (
                              <CommaSeparator
                                Price={props?.MemberShipData?.MembershipAmount}
                              />
                            )}
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "200px",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            width: "100px",
                            borderBottom: "none",
                          }}
                        >
                          {" "}
                          <Typography sx={TextLastStyle}></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            borderBottom: "none",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {" "}
                            {props.MemberShipData?.IGST ? (
                              "-"
                            ) : (
                              <CommaSeparator
                                Price={props?.MemberShipData?.MembershipGST / 2}
                              />
                            )}
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "200px",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            width: "100px",
                            borderBottom: "none",
                          }}
                        >
                          {" "}
                          <Typography sx={TextLastStyle}></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            borderBottom: "none",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {" "}
                            {props.MemberShipData?.IGST ? (
                              "-"
                            ) : (
                              <CommaSeparator
                                Price={props?.MemberShipData?.MembershipGST / 2}
                              />
                            )}
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "200px",
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            width: "100px",
                            borderBottom: "none",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{ ...TextLastStyle, color: "#000" }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            borderRight: "1px solid #CDCDCD",
                            p: 0,
                            height: "20px",
                            borderBottom: "none",
                            textAlign: "right",
                          }}
                        >
                          {" "}
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            {" "}
                            {props.MemberShipData?.IGST ? (
                              <CommaSeparator
                                Price={(props?.MemberShipData?.MembershipGST).toFixed(
                                  2
                                )}
                              />
                            ) : (
                              ""
                            )}
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>

                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                        borderRight: "1px solid #CDCDCD",
                      }}
                      align="center"
                    >
                      <TableRow
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          alignItems: "center",
                          px: 0,
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={2}
                          sx={{
                            width: "200px",
                            border: "none",
                            p: 0,
                            height: "20px",
                            textAlign: "right",
                          }}
                        >
                          <Typography sx={{ ...TextLastStyle, color: "#000" }}>
                            <CommaSeparator
                              Price={(props?.MemberShipData?.MembershipGST).toFixed(
                                2
                              )}
                            />
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={6}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                borderRight: "1px solid #CDCDCD",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        bgcolor: "#156DB6",
                        p: 0.2,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "#ffffff",
                          fontSize: "14px",
                        }}
                      >
                        Bank Details
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={4}
                      rowSpan={1}
                      sx={tablecell}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          width: "393px",
                          textAlign: "end",
                          fontSize: "12px",
                        }}
                      >
                        For BXI WORLD LLP
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      height: "21px",
                    }}
                  >
                    <TableCell
                      align="left"
                      colSpan={4}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Name : BXI WORLD LLP
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={4}
                      rowSpan={6}
                      sx={tablecell}
                    >
                      <img
                        src={BXIStamp}
                        style={{
                          maxHeight: "160px",
                          height: "auto",
                          width: "auto",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Account No. :007863300005298
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        {" "}
                        IFS Code : YESB0000078
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        Branch Name : Andheri(East)
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography sx={TextLastStyle}>
                        {" "}
                        Bank Name : YES BANK
                      </Typography>{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        borderBottom: "none",
                        p: 0.2,
                      }}
                    >
                      <Typography
                        sx={{
                          ...TextLastStyle,
                          color: "#000",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        Ceritified that the particulars given above are true and
                        correct (E&OE)
                      </Typography>{" "}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box
                sx={{
                  borderTop: "1px solid #CDCDCD",
                  height: "22px",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    ...TextLastStyle,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: 400,
                  }}
                >
                  This is a System Generated Invoice by BXI WORLD LLP
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            pb: 1,
          }}
        >
          <Box
            sx={{
              maxWidth: "360px",
              mx: "auto",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            <Button variant="outlined" sx={btnsx} onClick={handlePrint}>
              <Box component={"img"} src={print} sx={ButtonIconStyle} />{" "}
              &nbsp;Print
            </Button>

            <Button variant="outlined" sx={btnsx} onClick={handleDownload}>
              <Box component={"img"} src={download} sx={ButtonIconStyle} />{" "}
              &nbsp;Download
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default AdvanceMemvershipInvoice;

const CommongTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",

  color: "#6B7A99",
};

const ButtonIconStyle = {
  width: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "20px",
    xs: "18px",
  },
  height: "auto",
};

const TextStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "left",
  color: "#6B7A99",
  opacity: 0.7,
};

const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "20px",
  color: "#FFFFFF",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "20px",

  color: "#505050",
};

const TableTotaltextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#050505",
  opacity: 1,
};

const TextLastStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#000",
  fontWeight: 500,
  // fontSize: "12px",
  // opacity: 0.7,
};

const BanktText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "11px",
  color: "#505050",
};

const BanktbottomText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "9px",
  lineHeight: "11px",
  color: "#505050",
};

const btnsx = {
  width: "100%",
  height: "51px",
  maxWidth: "168px",
  borderRadius: "10px",
  borderColor: "#156DB6",
  color: "#156DB6",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const tablecell = { borderRight: "1px solid #CDCDCD", p: 0.2 };
