/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdatePurchaseOrder } from "../../Hooks/OrderActions/useUpdatePurchaseOrder";
import { useFetchPurchaseOrder } from "../../Hooks/OrderActions/useUpdatePurchaseOrder";
import CheckboxIcon from "../../assets/Images/CommonImages/CheckboxIcon.svg";
import CheckedBoxIcon from "../../assets/Images/CommonImages/CheckedBoxIcon.svg";
import CheckedCheckboxIcon from "../../assets/Images/CommonImages/CheckedCheckboxIcon.svg";
import CloseIcon from "../../assets/Images/CommonImages/CloseIcon.svg";
import DocDownloadImg from "../../assets/Images/CommonImages/DocDownload.png";
import LeftArrowIcon from "../../assets/Images/CommonImages/GoLeft.png";
import PrintPurchaseOrder from "../../assets/Images/CommonImages/Print.png";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { getCompanyById } from "../../redux/action/CompanyActions";
import { getOrderSummary } from "../../redux/action/OrderSummaryActions";
import StateData from "../../utils/StateCityArray.json";
import BxiCoin from "../../assets/Images/CommonImages/BXIToken.svg";
import { CircularProgress } from "@material-ui/core";
import NumberToWord from "../../components/NumberToWord";
import { MdKeyboardBackspace } from "react-icons/md";
import CommaSeprator from "../../components/CommaSeprator";
import { useReactToPrint } from "react-to-print";
import PageLoader from "../../components/LoadingButton/PageLoader";
import FinalValueCommaSeprator from "../../components/FinalValueCommaSeprator";
import MediaContentUpload from "./MediaContentUpload";
import HtmlToPdfConverter from "../../utils/HtmlToPdfConverter";
import axios from "axios";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";
import { useGetAllCompanyProducts } from "../../views/Dashboard/useGetAllCompanyProducts.js";
import radiouncheck from "../../assets/Dashboard/radiouncheck.svg";
import radiocheck from "../../assets/Dashboard/radiocheck.svg";
import OrderProgressBar from "../../components/ProgressBar/OrderProgressBar.jsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import sendEvent from "../../utils/sendEvents.js";
import PrivateDealAgreement from "../../components/PrivateDealAgrrement.jsx";

const PurchaseOrderDetails = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [stateArray, setStateArray] = useState();
  const [Address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [BuyerShippingAddress, setBuyerShippingAddress] = useState();
  const [CityArray, setCityArray] = useState();
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [ProductsOpen, setProductsOpen] = useState(false);
  const [ProductsOpenSec, setProductsOpenSec] = useState(false);
  const [openTextarea, setOpenTextarea] = useState("");
  const [Codechecked, setCodeChecked] = useState(false);
  const [CouponCodeData, setCouponCodeData] = useState();
  const [CouponCode, setCouponCode] = useState();
  const [balance, setBalance] = useState("");
  const [StoreOrderId, setStoreOrderId] = useState();
  const [StoreOrderSummaryData, setStoreOrderSummaryData] = useState();
  const [ProductId, setProductId] = useState();
  const [ProductData, setProductData] = useState();
  const [agreecheck, setagreeCheck] = useState(false);
  const [BtnLoader, setBtnLoader] = useState(false);

  const [buyerHubType, setBuyerHubType] = useState();

  useEffect(() => {
    reqBalance();
    const userHubData = localStorage.getItem("userhubtype");
    setBuyerHubType(userHubData);
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const [dataFromChild, setDataFromChild] = useState("");
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  // logged user
  const { data: IAMUserData } = useGetAuthUser();

  // here we GET wallet balance
  const reqBalance = async () => {
    await axios
      .get("wallet/get_wallet", {
        withCredentials: true,
      })
      .then((res) => {
        setBalance(res?.data?.body);
      });

    await axios
      .get(
        "auth/profilecompletion",
        {
          withCredentials: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {});
  };

  async function GetOrderByInvoice() {
    await axios.get(`order/get_order_by_orderSummaryId/${id}`).then((res) => {
      setStoreOrderId(res?.data?._id);
      setStoreOrderSummaryData(res?.data);
    });
  }

  async function UpdateCouponAgreements() {
    try {
      const response = await axios.put(
        `/private_deal/update_agreements/${CouponCodeData?._id}`,
        {
          Order_Id: StoreOrderSummaryData?._id,
          ReadyStock_Product: ProductData,
          Order_Number: StoreOrderSummaryData?.OrderId,
        }
      );

      // console.log("response", response?.data);
    } catch (error) {
      console.error("Error:", error.message);
    }
  }

  async function UpdateProductReadyStock() {
    try {
      await axios.put("product/product_ready_stock_update", {
        id: ProductId,
      });
    } catch (error) {
      console.error("Error:", error.message);
    }
  }

  async function UpdateInstantBalance(CouponCode) {
    if (!CouponCode) {
      toast.error("Please enter a coupon code", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      try {
        await axios.put("wallet/update_instant_balance", {
          OrderId: StoreOrderId,
          CouponId: CouponCode?._id,
          balance: Number(CouponCode?.TokenAmount),
          OnetoMany: CouponCode?.OnetoMany,
        });
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  }

  const handleDownload = () => {
    setTimeout(() => {
      html2canvas(componentRef.current)
        .then((canvas) => {
          const pdf = new jsPDF();
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight
          );
          pdf.save("print.pdf");
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    }, 1000);
  };

  useEffect(() => {
    GetOrderByInvoice();
    reqBalance();
  }, []);

  const { data: CompaniesProducts } = useGetAllCompanyProducts();

  const calculateSums = (productVariations) => {
    let totalValueSum = 0;
    let maxOrderQtySum = 0;

    productVariations?.forEach((item) => {
      const currentValue = item?.MaxOrderQuantity * item?.DiscountedPrice;

      totalValueSum += currentValue;
      maxOrderQtySum += item?.MaxOrderQuantity;
    });

    return {
      totalValueSum,
      maxOrderQtySum,
    };
  };

  async function GetAllCoupons() {
    if (!CouponCode) {
      toast.error("Please enter a coupon code", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      try {
        const response = await axios.post("private_deal/validate_coupon", {
          SellerCompanyId: OrderSummarydata?.SellerDetails?.SellerCompanyId,
          BuyercompanyId: OrderSummarydata?.BuyerDetails?.BuyerCompanyId,
          CouponCode: CouponCode,
          Token: OrderSummarydata?.POTotals?.TotalCoinsWithAdditionalCost,
        });
        response?.data?.message?.includes("is Valid")
          ? toast.success(response?.data?.message, {
              position: "top-center",
              autoClose: 2000,
            })
          : toast.error(response?.data?.message, {
              position: "top-center",
              autoClose: 2000,
            });
        setCouponCodeData(response?.data?.data?.at(0));
        if (response?.data?.data?.at(0)?.OnetoMany) {
          setProductsOpen(true);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  }

  useEffect(() => {
    setBuyerShippingAddress({
      PinCode: pincode,
      City: city,
      State: state,
      Address: Address,
    });
  }, [pincode, city, state, Address]);

  const { OrderSummary: OrderSummarydata, loading: OrderSummaryDataLoading } =
    useSelector((state) => state.OrderSummaryD);

  useEffect(() => {
    dispatch(getCompanyById(OrderSummarydata?.SellerCompanyId));
    dispatch(getOrderSummary(id));
  }, [dispatch, id]);

  let storeDataIds = [];
  let TotalQuantity = 0;

  OrderSummarydata?.ProductData?.map((item) => {
    storeDataIds.push(item);
    TotalQuantity += item.ProductQuantity;
  });

  OrderSummarydata?.ProductData?.map((datah) => {
    datah?.AdditionCostArray?.map(() => {});
  });

  const handleCouponCheckChange = (event) => {
    setCodeChecked(event.target.checked);
  };

  const {
    data: updatePurchaseOrderData,
    isLoading: updatePurchaseOrderLoading,
    error: updatePurchaseOrderError,
    mutate: updatePurchaseOrderMutate,
  } = useUpdatePurchaseOrder();

  const {
    data: fetchPurchaseOrder,
    isLoading: PurchaseOrderLoading,
    refetch: PurchaseOrderRefetch,
  } = useFetchPurchaseOrder(id);

  async function mutatePurchaseOrder() {
    PurchaseOrderRefetch();
    let MedialengthCount = 0;

    OrderSummarydata?.ProductData?.forEach((item) => {
      if (item.BXISpace === true) {
        MedialengthCount += 1;
      }
    });
    if (CouponCodeData?._id) {
      if (
        OrderSummarydata?.IsMedia &&
        OrderSummarydata?.MediaContentUrls?.length !== MedialengthCount &&
        (OrderSummarydata?.MediaContentUrls?.length || MedialengthCount >= 1) &&
        dataFromChild !== true
      ) {
        toast.error("Please upload media content", {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        if (checked) {
          if (
            !BuyerShippingAddress?.PinCode ||
            !BuyerShippingAddress?.City ||
            !BuyerShippingAddress?.State ||
            !BuyerShippingAddress?.Address
          ) {
            toast.error("Please fill all the fields", {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            setBtnLoader(true);

            let DataOfS3 = await HtmlToPdfConverter(componentRef.current);
            await updatePurchaseOrderMutate({
              status: "Accepted",
              OrderSummaryId: id,
              CouponData: CouponCodeData,
              BuyerShippingAddress: BuyerShippingAddress
                ? BuyerShippingAddress
                : "",
              InvoiceUrl: DataOfS3.data.file,
            });
            UpdateInstantBalance(CouponCodeData);
            if (CouponCodeData?.OnetoMany) {
              UpdateProductReadyStock();
              if (ProductData) {
                UpdateCouponAgreements();
              }
            }
            PurchaseOrderRefetch();
          }
        } else {
          setBtnLoader(true);

          let DataOfS3 = await HtmlToPdfConverter(componentRef.current);

          await updatePurchaseOrderMutate({
            status: "Accepted",
            CouponData: CouponCodeData,
            OrderSummaryId: id,
            BuyerShippingAddress: "",
            InvoiceUrl: DataOfS3.data.file,
          });
          UpdateInstantBalance(CouponCodeData);
          if (CouponCodeData?.OnetoMany) {
            UpdateProductReadyStock();
            if (ProductData) {
              UpdateCouponAgreements();
            }
          }
          PurchaseOrderRefetch();
        }
      }
    } else if (!balance?.balance) {
      toast.error("Your Wallet balance is low", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      if (
        IAMUserData?.data?.superAdmin &&
        balance?.balance <
          Number(OrderSummarydata?.POTotals?.TotalProductCoins) +
            Number(
              OrderSummarydata?.POTotals?.TotalAdditionalCostWtihoutGSTInBXI
            )
      ) {
        toast.error("Your Wallet balance is low", {
          position: "top-center",
          autoClose: 2000,
        });
      } else if (
        OrderSummarydata?.POTotals?.GrandTotal >
          IAMUserData?.data?.tokenlimit &&
        !IAMUserData?.data?.superAdmin
      ) {
        toast.error("You do not have permission to Burn that much token", {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        if (
          OrderSummarydata?.IsMedia &&
          OrderSummarydata?.MediaContentUrls?.length !== MedialengthCount &&
          (OrderSummarydata?.MediaContentUrls?.length ||
            MedialengthCount >= 1) &&
          dataFromChild !== true
        ) {
          toast.error("Please upload media content", {
            position: "top-center",
            autoClose: 2000,
          });
        } else {
          if (checked) {
            if (
              !BuyerShippingAddress?.PinCode ||
              !BuyerShippingAddress?.City ||
              !BuyerShippingAddress?.State ||
              !BuyerShippingAddress?.Address
            ) {
              toast.error("Please fill all the fields", {
                position: "top-center",
                autoClose: 2000,
              });
            } else {
              setBtnLoader(true);

              let DataOfS3 = await HtmlToPdfConverter(componentRef.current);
              await updatePurchaseOrderMutate({
                status: "Accepted",
                CouponData: CouponCodeData,
                OrderSummaryId: id,
                BuyerShippingAddress: BuyerShippingAddress
                  ? BuyerShippingAddress
                  : "",
                InvoiceUrl: DataOfS3.data.file,
              });
              PurchaseOrderRefetch();
            }
          } else {
            setBtnLoader(true);

            let DataOfS3 = await HtmlToPdfConverter(componentRef.current);

            await updatePurchaseOrderMutate({
              status: "Accepted",
              CouponData: CouponCodeData,
              OrderSummaryId: id,
              BuyerShippingAddress: "",
              InvoiceUrl: DataOfS3.data.file,
            });
            PurchaseOrderRefetch();
          }
        }
      }
    }
  }

  async function UpdateInrInOrder() {
    try {
      const response = await axios.put(
        "media/media_order_inr_update",
        {
          PurchaseOrderId: id,
        },
        {
          withCredentials: true,
        }
      );

      if (response.data) {
        toast.success("Order Updated", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (updatePurchaseOrderData?.status === "Accepted") {
      if (CouponCodeData?._id) {
        UpdateInrInOrder();
      }
      toast.success("Order Accepted", {
        position: "top-center",
        autoClose: 3000,
      });
      setTimeout(() => {
        setBtnLoader(false);
        navigate("/home/mediapilist");
      }, 3000);
    } else if (updatePurchaseOrderData?.status === "Rejected") {
      toast.error("Order Declined", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/home/mediapurchaseorder");
      }, 2000);
    }
  }, [dispatch, updatePurchaseOrderData]);

  async function mutatePurchaseOrderRejected() {
    PurchaseOrderRefetch();
    const confirmed = window.confirm(
      "Are you sure you want to reject this order?"
    );
    if (confirmed) {
      await updatePurchaseOrderMutate({
        status: "Rejected",
        OrderSummaryId: id,
        BuyerShippingAddress: "",
      });
    }
  }

  const downloadRef = useRef(null);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (stateArray) {
      let stateData = StateData?.filter((item) => item?.name === stateArray);
      setCityArray(stateData[0]?.data);
    }
  }, [stateArray]);

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [
      pad(d.getDate()), // Day
      pad(d.getMonth() + 1), // Month (adding 1 to convert to 1-indexed)
      d.getFullYear(), // Year
    ].join("/");
  }
  const GetTimeline = (timeline, boughtDates) => {
    let result = [];
    if (timeline === "Month") {
      const months = Math.ceil(boughtDates.length / 30);
      for (let i = 0; i < months; i++) {
        const startIndex = i * 30;
        const endIndex = Math.min((i + 1) * 30, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Week") {
      const weeks = Math.ceil(boughtDates.length / 7);
      for (let i = 0; i < weeks; i++) {
        const startIndex = i * 7;
        const endIndex = Math.min((i + 1) * 7, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Day") {
      result = boughtDates.map((date) => ({
        startDate: date,
        endDate: date,
      }));
    } else if (timeline === "Years") {
      const years = Math.ceil(boughtDates.length / 365);
      for (let i = 0; i < years; i++) {
        const startIndex = i * 365;
        const endIndex = Math.min((i + 1) * 365, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    }

    return result;
  };

  let AdCostInrTotal = 0;
  let AdCostBxiTotal = 0;
  OrderSummarydata?.ProductData?.map((item) => {
    if (item?.AdditionCost.AdditionCostArrayData?.length > 0) {
      item?.AdditionCost.AdditionCostArrayData.forEach((item) => {
        if (item?.currencyType === "₹") {
          AdCostInrTotal += item?.GstPrice;
        } else if (item?.currencyType === "BXITokens") {
          AdCostBxiTotal += item?.GstPrice;
        }
      });
    }
  });

  const buyerSellerHub = localStorage.getItem("userhubtype");
  console.log("buyerSellerHub", buyerSellerHub);

  if (OrderSummaryDataLoading) {
    return <PageLoader />;
  }
  return (
    <Box
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"Media Purchase Order"} />
      <OrderProgressBar type={"mediapurchaseorder"} Data={OrderSummarydata} />
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          mx: "auto",
          borderRadius: "17px",
          pb: "40px",
        }}
        elevation={1}
      >
        <Paper
          sx={{
            width: "95%",
            mx: "auto",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
          elevation={0}
        >
          <Box
            component="img"
            src={LeftArrowIcon}
            sx={{ width: "25px", cursor: "pointer" }}
            onClick={() =>
              navigate(
                buyerSellerHub === "seller"
                  ? "/home/sellermediapo"
                  : "/home/purchaseorderlist"
              )
            }
          />
          <Box
            sx={{
              display: "flex",
              width: "60px",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="img"
              src={PrintPurchaseOrder}
              sx={{ width: "22px", height: "auto", cursor: "pointer" }}
              onClick={handlePrint}
            />
            <Box
              component="img"
              src={DocDownloadImg}
              sx={{ width: "21px", height: "auto", cursor: "pointer" }}
              onClick={handleDownload}
            />
          </Box>
        </Paper>
        <Box ref={componentRef}>
          <Box
            sx={{
              width: "95%",
              mx: "auto",
              borderLeft: "1px solid #cdcdcd",
              borderRight: "1px solid #cdcdcd",
              borderBottom: "1px solid #cdcdcd",
              borderTop: "1px solid #cdcdcd",
              px: "0px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "auto",
                mx: "auto",
              }}
              ref={downloadRef}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                }}
              >
                <img
                  src={OrderSummarydata?.BuyerDetails?.BuyerCompanyLogo}
                  style={{ width: "100px", height: "100px" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "1px solid #cdcdcd",
                  height: "130px",
                  width: "40%",
                }}
              >
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    color: "rgba(107, 122, 153, 1)",
                    opacity: 1,
                    fontWeight: 600,
                  }}
                >
                  {OrderSummarydata?.BuyerDetails?.BuyerCompanyName}
                </Typography>
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    textAlign: "center",
                    fontSize: "11px",
                    color: "rgba(107, 122, 153, 1)",
                    opacity: 1,
                    fontWeight: 600,
                  }}
                >
                  {OrderSummarydata?.BuyerDetails?.BuyerCompanyAddress}
                </Typography>
                <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                  Tel:{OrderSummarydata?.BuyerDetails?.BuyerCompanyContact}
                </Typography>
                <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                  GSTIN:{OrderSummarydata?.BuyerDetails?.GSTIN}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                  borderLeft: "1px solid #cdcdcd",
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "42px",
                mx: "auto",
                background: buyerHubType === "seller" ? "#c64091" : "#2261A2",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  ...CommongTextStyle,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: "100%",
                }}
              >
                Purchase Order
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                mx: "auto",
                height: "40px",
                borderRight: "1px solid #cdcdcd",
                borderLeft: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  borderRight: "1px solid #F3F2F3",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  PO Number: {OrderSummarydata?.PoNumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  pl: 1,
                }}
              >
                <Typography sx={CommongTextStyle}>
                  PO Date: {convertDate(OrderSummarydata?.PoDate)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                border: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "21px",
                  background: buyerHubType === "seller" ? "#c64091" : "#2261A2",
                  borderRadius: "3px 0px 0px 3",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                    borderRight: "1px solid #F3F2F3",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      fontSize: "10px",
                      color: "white",
                    }}
                  >
                    Buyer Details
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      color: "white",
                      fontSize: "10px",
                    }}
                  >
                    Seller Details
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                  py: 0,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                    p: 2,
                  }}
                >
                  <Table sx={{ p: 0 }}>
                    <TableBody sx={{ p: 0 }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            width: "90px",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Buyer Name
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {" "}
                            {OrderSummarydata?.BuyerDetails?.BuyerCompanyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> Address </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {
                              OrderSummarydata?.BuyerDetails?.Address
                                ?.AddressLine
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> GSTIN </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.BuyerDetails?.GSTIN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> State</Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            position: "relative",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {" "}
                            {OrderSummarydata?.BuyerDetails?.Address?.State}
                          </Typography>{" "}
                          <Typography
                            sx={{
                              ...TextStyleTwo,
                              textAlign: "right",
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            Code: {OrderSummarydata?.BuyerDetails?.StateCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> CUID </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.Buyer_CUID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box sx={{ width: "100%", mx: "auto", p: 2 }}>
                  <Table>
                    <TableBody>
                      <TableRow sx={{ p: 0 }}>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            width: "90px",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Seller Name
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.SellerDetails?.SellerCompanyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> Address </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {
                              OrderSummarydata?.SellerDetails?.Address
                                ?.AddressLine
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> GSTIN </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.SellerDetails?.GSTIN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> State: </Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            position: "relative",
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.SellerDetails?.Address?.State}
                          </Typography>
                          <Typography
                            sx={{
                              ...TextStyleTwo,
                              textAlign: "right",
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            Code: {OrderSummarydata?.SellerDetails?.StateCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> CUID </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.Seller_CUID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>

            <TableContainer
              component={Paper}
              sx={{
                maxWidth: "100%",
                mx: "auto",
              }}
            >
              <Table sx={{ minWidth: "700px" }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    sx={{
                      height: "42px",
                      bgcolor:
                        buyerHubType === "seller" ? "#c64091" : "#2261A2",
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        width: "20px",
                        maxWidth: "20px",
                      }}
                    >
                      <Typography sx={TableTextStyle}>No.</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={2}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>
                        Product / Service Description
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        width: "10px",
                        maxWidth: "10px",
                      }}
                    >
                      <Typography sx={TableTextStyle}>HSN</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>QTY</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>Rate</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>Amount</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        width: "50px",
                        maxWidth: "50px",
                      }}
                    >
                      <Typography sx={TableTextStyle}>Taxable Value</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "150px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                            }}
                          >
                            <Typography sx={TableTextStyle}>IGST</Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "150px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                            }}
                          >
                            <Typography sx={TableTextStyle}>CGST</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "150px",
                              p: 0,
                              height: "35px",
                            }}
                          >
                            <Typography sx={TableTextStyle}>SGST</Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                              width: "25%",
                            }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>%</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                            }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>Rs.</Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                              width: "25%",
                            }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>%</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                            }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>Rs.</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "100%",
                            }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>%</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ p: 0, height: "100%", width: "25%" }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>Rs.</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        bgcolor:
                          buyerHubType === "seller" ? "#c64091" : "#2261A2",
                        width: "170px",
                        borderLeft: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>Total</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {OrderSummarydata?.ProductData?.map((row, idx) => {
                    let TotalSec = row?.TotalSec;
                    return (
                      <React.Fragment key={idx}>
                        <TableRow
                          sx={{
                            height: "42px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {idx + 1}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontSize: "14px",
                                width: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row?.ProductName}
                            </Typography>

                            {row?.TotalSec && (
                              <Typography
                                sx={{
                                  color: "rgba(80, 80, 80, 1)",
                                  opacity: 1,
                                }}
                              >
                                {row?.ProductTypeId === "MediaOffline" ||
                                row?.ProductTypeId === "News Papers / Magazines"
                                  ? `(${row?.TimelineToBought} ${row?.Timeline})`
                                  : `(${TotalSec * 10} sec) (${
                                      row?.TimelineToBought
                                    } ${row?.Timeline})`}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {row?.HSN}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={row?.ProductQuantity} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={row?.DiscountedPrice} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator
                                Price={row?.TotalPriceWithoutGST}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {" "}
                              <CommaSeprator
                                Price={row?.TotalPriceWithoutGST}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "200px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {row?.GST}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={row?.TotalGSTAmount}
                                    />
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {row?.GST / 2}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={row?.TotalGSTAmount / 2}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {row?.GST / 2}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={row?.TotalGSTAmount / 2}
                                    />
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator
                                Price={
                                  row?.TotalPriceWithoutGST +
                                  row?.TotalGSTAmount
                                }
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {row?.AdditionCost?.AdditionCostArrayData?.map(
                          (res, index) => {
                            let GstOfAdCost = Number(res?.AdCostGST);
                            return (
                              <TableRow
                                sx={{
                                  height: "25px",
                                  padding: 0,
                                  margin: 0,
                                }}
                                key={index}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                ></TableCell>
                                <TableCell
                                  align="left"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                    px: 1,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      width: "500px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {res?.ReasonOfCost} {"  "}({" "}
                                    {res?.AdCostApplicableOn}){" "}
                                    {res?.currencyType === "₹" ? (
                                      "₹"
                                    ) : (
                                      <img
                                        src={BxiCoin}
                                        style={{ width: "20px" }}
                                      />
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography>{res?.AdCostHSN}</Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography>-</Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography>
                                    <CommaSeprator
                                      Price={res?.PriceWithoutGST}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography>
                                    <CommaSeprator
                                      Price={res?.TotalTaxableAmount}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography>
                                    <CommaSeprator
                                      Price={res?.TotalTaxableAmount}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    maxWidth: "200px",
                                    mx: "auto",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                  align="center"
                                >
                                  {OrderSummarydata &&
                                  OrderSummarydata?.IsIGST ? (
                                    <TableRow>
                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "11px" }}>
                                          {GstOfAdCost}
                                        </Typography>
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "11px" }}>
                                          {res?.GstPrice}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    <TableRow>
                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "11px" }}>
                                          {res?.AdCostGST / 2}
                                        </Typography>
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "11px" }}>
                                          {res?.GstPrice / 2}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "11px" }}>
                                          {res?.AdCostGST / 2}
                                        </Typography>
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "11px" }}>
                                          {res?.GstPrice / 2}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                    height: "10px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "11px" }}>
                                    <CommaSeprator Price={res?.TotalWithGst} />
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}
                  <TableRow
                    sx={{
                      height: "42px",
                      backgroundColor: "#F7F7F7",
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={3}
                      rowSpan={3}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        background:
                          buyerHubType === "seller" ? "#c64091" : "#2261A2",
                      }}
                    >
                      <Typography sx={{ ...TableBottomtext, color: "white" }}>
                        Total
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator Price={TotalQuantity} />
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        {/* <CommaSeprator
                          Price={
                            OrderSummarydata?.POTotals?.TotalProductCoins +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInBXI +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInRupee
                          }
                        /> */}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator
                          Price={
                            OrderSummarydata?.POTotals?.TotalProductCoins +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInBXI +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInRupee
                          }
                        />
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator
                          Price={
                            OrderSummarydata?.POTotals?.TotalProductCoins +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInBXI +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInRupee
                          }
                        />
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              -
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              <CommaSeprator
                                Price={
                                  OrderSummarydata?.POTotals?.TotalGstAmount +
                                  AdCostInrTotal +
                                  AdCostBxiTotal
                                }
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              -
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              <CommaSeprator
                                Price={
                                  OrderSummarydata?.POTotals?.TotalGstAmount +
                                  AdCostInrTotal +
                                  AdCostBxiTotal
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              -
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              <CommaSeprator
                                Price={
                                  OrderSummarydata?.POTotals?.TotalGstAmount +
                                  AdCostInrTotal +
                                  AdCostBxiTotal
                                }
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator
                          Price={OrderSummarydata?.POTotals?.GrandTotal}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                mx: "auto",
                border: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "60%",
                  height: "100%",
                  borderRight: "1px solid #CDCDCD",
                }}
              >
                <Box
                  sx={{
                    background:
                      buyerHubType === "seller" ? "#c64091" : "#2261A2",
                    borderRadius: "3px 0px 0px 3",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={TableTextStyle}>
                    Total amount in words
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    minHeight: "100px",
                  }}
                >
                  <Typography
                    sx={{
                      ...TableTotaltextStyle,
                      fontWeight: 600,
                      fontSize: "13px",
                    }}
                  >
                    <NumberToWord
                      number={Number(OrderSummarydata?.POTotals?.GrandTotal)}
                    />
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "40%",
                }}
              >
                <Table
                  sx={{
                    height: "150px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <TableRow sx={{ width: "100%", display: "flex" }}>
                    <TableCell
                      sx={{
                        pl: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                        whiteSpace: "nowrap",
                      }}
                      colSpan={1}
                    >
                      <Typography sx={TableTotaltextStyle}>
                        Total Amount to be paid in Barter Coins
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                      align="right"
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator
                          Price={
                            OrderSummarydata?.POTotals?.TotalProductCoins +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInBXI
                          }
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ width: "100%", display: "flex" }}>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                    >
                      <Typography sx={TableTotaltextStyle}>
                        Total GST to be paid in INR
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                      align="right"
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator
                          Price={Number(
                            OrderSummarydata?.POTotals?.TotalGstAmount +
                              AdCostBxiTotal +
                              AdCostInrTotal
                          )}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ width: "100%", display: "flex" }}>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                    >
                      <Typography sx={TableTotaltextStyle}>
                        Additional cost to be paid in INR
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                      align="right"
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator
                          Price={Number(
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInRupee
                          )}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ width: "100%", display: "flex" }}>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography sx={TableTotaltextStyle}>
                        Additional cost to be paid in Barter Coins
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                      align="right"
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator
                          Price={Number(
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInBXI
                          )}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Table>
                <Box
                  sx={{
                    background:
                      buyerHubType === "seller" ? "#c64091" : "#2261A2",
                    borderRadius: "3px 0px 0px 3",
                    display: "flex",
                    justifyContent: "space-between",
                    px: 1,
                  }}
                >
                  <Typography sx={TableTextStyle}>
                    Total amount after Tax :
                  </Typography>
                  <Typography sx={TableTextStyle}>
                    <FinalValueCommaSeprator
                      Price={OrderSummarydata?.POTotals?.GrandTotal}
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              justifyContent: "space-around",
              border: "1px solid #cdcdcd",
              alignItems: "center",
              flexDirection: "row",
              minHeight: "150px",
              display: "flex",
              height: "auto",
              width: "95%",
              mx: "auto",
              mt: 2,
              py: 1,
            }}
          >
            {OrderSummarydata?.ProductData &&
              OrderSummarydata?.ProductData?.map((item, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      minHeight: "150px",
                      height: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        ...TableBottomtext,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        fontSize: "14px",
                        color: "black",
                        width: "100%",
                      }}
                    >
                      Bought Dates for : {item?.ProductName}
                    </Typography>
                    <Typography
                      sx={{
                        ...TableBottomtext,
                        fontSize: "14px",
                      }}
                    >
                      {GetTimeline(item?.Timeline, item?.BoughtDates).map(
                        (el, idx) => {
                          if (item.Timeline === "Day") {
                            return (
                              <Typography
                                key={idx}
                                sx={{
                                  ...TableBottomtext,
                                  textAlign: "center",
                                }}
                              >
                                {idx + 1}).{" "}
                                {new Date(el.startDate).toDateString()}
                              </Typography>
                            );
                          } else {
                            return (
                              <>
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    textAlign: "center",
                                  }}
                                >
                                  {idx + 1}). Start Date :{" "}
                                  {new Date(el.startDate).toDateString()} -- End
                                  Date : {new Date(el.endDate).toDateString()}
                                </Typography>
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    textAlign: "center",
                                  }}
                                ></Typography>
                              </>
                            );
                          }
                        }
                      )}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
          {!props?.SellerPage
            ? OrderSummarydata?.ProductData?.map((item, idx) => {
                const productMedia = fetchPurchaseOrder?.MediaContentUrls?.find(
                  (media) => media.productId === item?.ProductId
                );

                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                    }}
                    key={idx}
                  >
                    <Typography
                      sx={{
                        ...TableBottomtext,
                        fontSize: "14px",
                        width: "300px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Media Name : {item?.ProductName}
                    </Typography>
                    {productMedia ? (
                      <Typography
                        sx={{
                          ...TableBottomtext,
                        }}
                      >
                        Already Uploded
                      </Typography>
                    ) : (
                      <>
                        {!item?.BXISpace ? (
                          <Link
                            to={item?.UploadLink}
                            target="_blank"
                            style={{
                              textDecoration: "none",
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                navigate(`/${item?.UploadLink}`);
                              }}
                            >
                              Upload Content
                            </Button>
                          </Link>
                        ) : (
                          <MediaContentUpload
                            id={id}
                            productId={item?.ProductId}
                            sendDataToParent={handleDataFromChild}
                          />
                        )}
                      </>
                    )}
                  </Box>
                );
              })
            : null}

          {(props?.SellerPage === true && !OrderSummarydata?.IsMedia) ||
          OrderSummarydata?.ProductData?.at(0)?.ProductTypeName ===
            "Media" ? null : (
            <Box sx={{ width: "95%", mx: "auto" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    name="checked"
                    color="primary"
                  />
                }
                label={
                  <span style={{ fontSize: "14px" }}>
                    Click here to add new delivery location
                  </span>
                }
              />
            </Box>
          )}
          {props?.SellerPage === true || OrderSummarydata?.IsMedia
            ? null
            : checked && (
                <React.Fragment>
                  <Box
                    sx={{
                      width: "95%",
                      mx: "auto",
                      height: "20px",
                      bgcolor:
                        buyerHubType === "seller" ? "#c64091" : "#2261A2",
                      alignContent: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Typography sx={TableTextStyle}>
                      Fill the Address Details : Ship To / Delivery Location
                      Details
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      bgcolor: "transparent",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "95%",
                      mx: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <label style={AddressInputTextStyle}>State</label>
                      <Select
                        sx={{
                          width: "100%",
                          maxWidth: "300px",
                          height: "35px",
                          borderRadius: "5px",
                          border: "1px solid #cdcdcd",
                          outline: "none",
                          "&:focus": {
                            border: "1px solid #2261A2",
                          },
                        }}
                        onChange={(e) => {
                          setStateArray(e.target.value);
                          setState(e.target.value);
                        }}
                        name="state"
                        value={stateArray}
                        id="state"
                        required
                        style={AddressInputStyle}
                      >
                        {StateData?.sort((a, b) => a.name.localeCompare(b.name)) // Sort the data alphabetically by 'name'
                          .map((res, index) => (
                            <MenuItem key={index} value={res?.name}>
                              {res?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <label style={AddressInputTextStyle}>City</label>
                      <Select
                        sx={{
                          width: "100%",
                          maxWidth: "300px",
                          height: "35px",
                          borderRadius: "5px",
                          border: "1px solid #cdcdcd",
                          outline: "none",
                          "&:focus": {
                            border: "1px solid #2261A2",
                          },
                        }}
                        onChange={(e) => setCity(e.target.value)}
                        name="state"
                        value={CityArray}
                        id="state"
                        required
                        style={AddressInputStyle}
                      >
                        {CityArray?.map((res, index) => (
                          <MenuItem key={index} value={res}>
                            {res}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <label style={AddressInputTextStyle}>Pincode</label>
                      <input
                        type="text"
                        placeholder=" "
                        className="inp"
                        onChange={(e) => setPincode(e.target.value)}
                        required
                        style={{ ...AddressInputStyle, width: "250px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        width: "400px",
                      }}
                    >
                      <label style={AddressInputTextStyle}>Address</label>
                      <input
                        type="text"
                        placeholder=" "
                        onChange={(e) => setAddress(e.target.value)}
                        className="inp"
                        required
                        style={{ ...AddressInputStyle, width: "400px" }}
                      />
                    </Box>
                  </Box>
                </React.Fragment>
              )}

          {OrderSummarydata?.CouponData ? (
            <Box sx={{ width: "95%", mx: "auto" }}>
              <label
                style={{
                  ...AddressInputTextStyle,
                  fontSize: "18px",
                  lineHeight: "15px",
                }}
              >
                Already Coupon Applied
              </label>
            </Box>
          ) : (
            <Box>
              {props?.SellerPage === true ? null : (
                <>
                  {fetchPurchaseOrder?.BuyerOrderStatus === "Accepted" &&
                  !PurchaseOrderLoading ? null : (
                    <Box sx={{ width: "95%", mx: "auto" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Codechecked}
                            onChange={handleCouponCheckChange}
                            name="checked"
                            color="primary"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                width: "24px",
                                height: "24px",
                              },
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2" sx={{ fontSize: "12px" }}>
                            Click here to Apply Coupon
                          </Typography>
                        }
                      />
                    </Box>
                  )}
                </>
              )}

              {props?.SellerPage === true ? null : Codechecked ? (
                <Box>
                  {!CouponCodeData?._id ? (
                    <Box
                      sx={{
                        width: "95%",
                        mx: "auto",
                        alignItems: "center",
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "5px",
                        mt: 1,
                      }}
                    >
                      <label style={AddressInputTextStyle}>
                        Apply Coupon Code :
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setCouponCode(e.target.value)}
                        required
                        style={{
                          ...AddressInputStyle,
                          width: "120px",
                          height: "25px",
                          border: "0.5px solid black",
                        }}
                      />

                      <Button
                        onClick={() => GetAllCoupons()}
                        variant="contained"
                        sx={{
                          width: "auto",
                          textTransform: "none",
                          height: "25px",
                          bgcolor:
                            buyerHubType === "seller" ? "#c64091" : "#2261A2",
                          borderRadius: "6px",
                        }}
                      >
                        Apply Coupon Code
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "95%",
                        mx: "auto",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "5px",
                        mt: 1,
                      }}
                    >
                      <label
                        style={{
                          ...AddressInputTextStyle,
                          fontSize: "18px",
                          lineHeight: "5px",
                        }}
                      >
                        Coupon Applied
                      </label>
                    </Box>
                  )}
                </Box>
              ) : null}
            </Box>
          )}

          <Box
            sx={{
              width: "95%",
              mx: "auto",
              height: "20px",
              alignItems: "flex-start",
              display: "flex",
              justifyContent: "flex-start",
              mt: 2,
              mb: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                color: "#28282B",
              }}
            >
              <img
                src={BxiCoin}
                style={{
                  width: "12px",
                  height: "auto",
                }}
              />{" "}
              - This symbol denotes Barter Coin that can be used only for
              transactions within the BXI marketplace.
              <br />₹ - This symbol denotes the Indian Rupee involved in the
              Transaction.
            </Typography>
          </Box>
        </Box>
      </Box>

      {props?.SellerPage === true ? null : (
        <Box
          sx={{
            width: "100%",
            mt: 3,
            pb: 3,
          }}
        >
          <Box
            sx={{
              maxWidth: "500px",
              mx: "auto",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            {fetchPurchaseOrder?.BuyerOrderStatus === "Pending" &&
            !PurchaseOrderLoading ? (
              <Box
                sx={ButtonStyleForAcceptAndReject}
                onClick={() => {
                  if (BtnLoader) {
                    return;
                  } else {
                    mutatePurchaseOrder();
                  }
                }}
              >
                {BtnLoader ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Accept"
                )}
              </Box>
            ) : fetchPurchaseOrder?.BuyerOrderStatus === "Accepted" &&
              !PurchaseOrderLoading ? (
              <Box
                sx={ButtonStyleForAcceptAndReject}
                onClick={() => navigate("/home/mediapilist")}
              >
                Next Step {"->"}
              </Box>
            ) : fetchPurchaseOrder?.BuyerOrderStatus === "Rejected" &&
              !PurchaseOrderLoading ? (
              <Box
                sx={{
                  ...ButtonStyleForAcceptAndReject,
                  border: "0.5px solid #EB5757",
                  bgcolor: "#FFEFEF",
                  color: "#EB5757",
                }}
              >
                Order Rejected
              </Box>
            ) : (
              <CircularProgress size={20} color="inherit" />
            )}

            {fetchPurchaseOrder?.BuyerOrderStatus === "Rejected" ? (
              <Box
                sx={{
                  ...ButtonStyleForAcceptAndReject,
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  bgcolor: "#fff",
                  color: "#2261A2",
                  border: "1px solid #2261A2",
                }}
                onClick={() => navigate("/home/cart")}
              >
                <MdKeyboardBackspace size={25} />
                Back to Cart
              </Box>
            ) : fetchPurchaseOrder?.BuyerOrderStatus === "Accepted" ? null : (
              <React.Fragment>
                <Box
                  sx={{
                    ...ButtonStyleForAcceptAndReject,
                    bgcolor: "#fff",
                    border: "1px solid #445FD2",
                    color: "#445FD2",
                  }}
                  onClick={() => mutatePurchaseOrderRejected()}
                >
                  Reject
                </Box>

                <Modal
                  open={open}
                  onClose={() => setOpen(false)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transform: "translate(0%, 0%)",
                  }}
                >
                  <Box
                    sx={{
                      background: "#fff",
                      width: "350px",
                      height: openTextarea === "" ? "400px" : "480px",
                      p: 3,
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "poppins",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "16px",
                            color: "#6B7A99",
                          }}
                        >
                          Please select the reason of Rejection
                        </Typography>
                        <Box
                          component="img"
                          src={CloseIcon}
                          onClick={() => setOpen(false)}
                          sx={{
                            cursor: "pointer",
                          }}
                        ></Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "15px",
                        width: "100%",
                      }}
                    >
                      <Box sx={ButtonGroupstyle}>
                        <Box
                          sx={{
                            ...RejectReasonBtnStyle,
                            color:
                              openTextarea == "price" ? "#445FD2" : "#6B7A99",
                            border:
                              openTextarea == "price"
                                ? "1px solid #445FD2"
                                : "1px solid #E4E7EC",
                          }}
                          onClick={() => setOpenTextarea("price")}
                        >
                          <Box
                            sx={{
                              ...TextAndCheckBoxGroupStyle,
                              border: "none",
                            }}
                          >
                            {openTextarea == "price" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "price"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={CheckedBoxIcon}
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Price{" "}
                                  </Typography>
                                </Box>
                                <Box
                                  component="img"
                                  src={CheckedCheckboxIcon}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "price"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "20px",
                                }}
                              >
                                <Box component="img" src={CheckboxIcon} />
                                <Typography sx={ReasonTextStyle}>
                                  {" "}
                                  Price{" "}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <React.Fragment>
                          {openTextarea === "price" ? (
                            <TextField
                              sx={TextAreaStyle}
                              InputProps={InputPropsStyle}
                              placeholder="please explain your reason"
                              multiline
                              rows={4}
                            ></TextField>
                          ) : null}
                        </React.Fragment>
                      </Box>
                      <Box sx={ButtonGroupstyle}>
                        <Box
                          sx={{
                            ...RejectReasonBtnStyle,
                            color:
                              openTextarea == "deliverydate"
                                ? "#445FD2"
                                : "#6B7A99",
                            border:
                              openTextarea == "deliverydate"
                                ? "1px solid #445FD2"
                                : "1px solid #E4E7EC",
                          }}
                          onClick={() => setOpenTextarea("deliverydate")}
                        >
                          <Box sx={TextAndCheckBoxGroupStyle}>
                            {openTextarea == "deliverydate" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "deliverydate"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={CheckedBoxIcon}
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Delivery Date{" "}
                                  </Typography>
                                </Box>
                                <Box
                                  component="img"
                                  src={CheckedCheckboxIcon}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "deliverydate"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "20px",
                                }}
                              >
                                <Box component="img" src={CheckboxIcon} />
                                <Typography sx={ReasonTextStyle}>
                                  {" "}
                                  Delivery Date{" "}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <React.Fragment>
                          {openTextarea === "deliverydate" ? (
                            <TextField
                              sx={TextAreaStyle}
                              InputProps={InputPropsStyle}
                              placeholder="please explain your reason"
                              multiline
                              rows={4}
                            ></TextField>
                          ) : null}
                        </React.Fragment>
                      </Box>
                      <Box sx={ButtonGroupstyle}>
                        <Box
                          sx={{
                            ...RejectReasonBtnStyle,
                            color:
                              openTextarea == "quantity"
                                ? "#445FD2"
                                : "#6B7A99",
                            border:
                              openTextarea == "quantity"
                                ? "1px solid #445FD2"
                                : "1px solid #E4E7EC",
                          }}
                          onClick={() => setOpenTextarea("quantity")}
                        >
                          <Box sx={TextAndCheckBoxGroupStyle}>
                            {openTextarea == "quantity" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "quantity"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={CheckedBoxIcon}
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Quantity{" "}
                                  </Typography>
                                </Box>
                                <Box
                                  component="img"
                                  src={CheckedCheckboxIcon}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "quantity"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "20px",
                                }}
                              >
                                <Box component="img" src={CheckboxIcon} />
                                <Typography sx={ReasonTextStyle}>
                                  {" "}
                                  Quantity{" "}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <React.Fragment>
                          {openTextarea === "quantity" ? (
                            <TextField
                              sx={TextAreaStyle}
                              InputProps={InputPropsStyle}
                              placeholder="please explain your reason"
                              multiline
                              rows={4}
                            ></TextField>
                          ) : null}
                        </React.Fragment>
                      </Box>
                      <Box sx={ButtonGroupstyle}>
                        <Box
                          sx={{
                            ...RejectReasonBtnStyle,
                            color:
                              openTextarea == "other" ? "#445FD2" : "#6B7A99",
                            border:
                              openTextarea == "other"
                                ? "1px solid #445FD2"
                                : "1px solid #E4E7EC",
                          }}
                          onClick={() => setOpenTextarea("other")}
                        >
                          <Box sx={TextAndCheckBoxGroupStyle}>
                            {openTextarea == "other" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "other"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={CheckedBoxIcon}
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <Typography sx={ReasonTextStyle}>
                                    {" "}
                                    Other{" "}
                                  </Typography>
                                </Box>
                                <Box
                                  component="img"
                                  src={CheckedCheckboxIcon}
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    openTextarea === "other"
                                      ? "space-between"
                                      : "flex-start",
                                  gap: "20px",
                                }}
                              >
                                <Box component="img" src={CheckboxIcon} />
                                <Typography sx={ReasonTextStyle}>
                                  {" "}
                                  Other{" "}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <React.Fragment>
                          {openTextarea === "other" ? (
                            <TextField
                              sx={TextAreaStyle}
                              InputProps={InputPropsStyle}
                              placeholder="please explain your reason"
                              multiline
                              rows={4}
                            ></TextField>
                          ) : null}
                        </React.Fragment>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Button
                        onClick={() => setOpen(false)}
                        sx={CancelButtonStyle}
                      >
                        Cancel
                      </Button>
                      <Button sx={SubmitButtonStyle}>Confirm</Button>
                    </Box>
                  </Box>
                </Modal>
                <Dialog
                  open={ProductsOpen}
                  fullWidth
                  maxWidth="lg"
                  sx={{
                    backdropFilter: "blur(2px)",
                  }}
                  PaperProps={{
                    sx: {
                      width: "60%",
                      maxHeight: 600,
                      borderRadius: "20px",
                    },
                  }}
                >
                  <DialogTitle id="responsive-dialog-title">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          width: "80%",
                          mx: "auto",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "18px",
                            lineHeight: "28px",
                            color: "#6B7A99",
                            textAlign: "center",
                          }}
                        >
                          Please select the product for which you wish to put in
                          Ready Stock
                        </Typography>
                      </Box>
                    </Box>
                  </DialogTitle>

                  <DialogContent
                    sx={{
                      overflow: "auto",
                      mr: 2,
                      "::-webkit-scrollbar": {
                        display: "flex",
                      },
                      "::-webkit-scrollbar-thumb": {
                        dynamic: "#8d8e90",
                        minHeight: "10px",
                        borderRadius: "3px",
                      },
                      "::-webkit-scrollbar-thumb:vertical": {
                        miaxHeight: "10px",
                      },
                    }}
                  >
                    {CompaniesProducts && CompaniesProducts.length > 0 ? (
                      CompaniesProducts.slice()
                        .reverse()
                        // .filter((el) => el.IsReadyStock === false)
                        .map((el, idx) => {
                          const sums = calculateSums(el?.ProductsVariantions);
                          return (
                            <Box key={idx}>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "80px",
                                  maxHeight: "122px",
                                  background: "#FFFFFF",
                                  border: "1px solid #EDEFF2",
                                  borderRadius: "10px 10px 10px 10px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  alignContent: "center",
                                  mt: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "100%",
                                    maxWidth: {
                                      xl: "800px",
                                      lg: "800px",
                                      md: "800px",
                                      sm: "350px",
                                      xs: "350px",
                                    },
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: "2rem",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      ml: "1%",
                                      height: "80px",
                                      width: "150px",
                                      maxHeight: "122px",
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                      borderRadius: "25px 25px 25px 25px",
                                      backgroundImage:
                                        el?.ListingType === "Voucher"
                                          ? `url(${
                                              el?.VoucherImages?.at(0)?.url
                                            })`
                                          : `url(${
                                              el?.ProductImages?.at(0)?.url
                                            })`,
                                      backgroundSize: "contain",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  ></Box>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "start",
                                      alignContent: "start",
                                      flexDirection: "column",
                                      gap: "0.5rem",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "50%",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...ProductNameTextStyle,
                                          display: "-webkit-box",
                                          WebkitLineClamp: 1,
                                          WebkitBoxOrient: "vertical",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {el?.ProductName}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        width: "50%",
                                      }}
                                    >
                                      <Typography sx={ProductMetaTextStyle}>
                                        {el?.ProductDescription}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        alignContent: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...ProductPriceTextStyle,
                                          marginTop: "-03px",
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "2px",
                                          width: "100%",
                                        }}
                                      >
                                        &nbsp;
                                        <span
                                          style={{
                                            ...ProductNameTextStyle,
                                            fontSize: "15px",
                                          }}
                                        >
                                          Max Order Quantity :
                                        </span>{" "}
                                        &nbsp;
                                        <CommaSeprator
                                          Price={sums?.maxOrderQtySum}
                                        />
                                        &nbsp;
                                        <span
                                          style={{
                                            ...ProductNameTextStyle,
                                            fontSize: "15px",
                                          }}
                                        >
                                          Total Product Value :
                                        </span>{" "}
                                        &nbsp;
                                        <CommaSeprator
                                          Price={sums?.totalValueSum}
                                        />
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    width: "100%",
                                    maxWidth: "60px",
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  {ProductId === el._id ? (
                                    <Box
                                      onClick={() => {
                                        setProductId(null);
                                        sendEvent("Click on radio check");
                                      }}
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img src={radiocheck} size={30} />
                                    </Box>
                                  ) : (
                                    <Box
                                      onClick={() => {
                                        setProductId(el?._id);
                                        setProductData(el);
                                      }}
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img src={radiouncheck} size={30} />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          );
                        })
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "18px",
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                            color: "#8A8A8A",
                            lineHeight: 20,
                          }}
                        >
                          No Data Found
                        </Typography>
                      </Box>
                    )}
                  </DialogContent>
                  <DialogTitle id="responsive-dialog-title">
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{ ...reqbtn, width: "auto" }}
                        disabled={ProductId ? false : true}
                        onClick={() => {
                          setProductsOpenSec(true);
                          setProductsOpen(false);
                        }}
                      >
                        Put In Ready Stock
                      </Button>
                    </Box>
                  </DialogTitle>
                </Dialog>
                <Dialog
                  open={ProductsOpenSec}
                  sx={{
                    backdropFilter: "blur(2px)",
                  }}
                  fullWidth
                  maxWidth="lg"
                  PaperProps={{
                    sx: {
                      width: "60%",
                      height: "auto",
                      minHeight: "80%",
                      maxHeight: "80%",
                      borderRadius: "20px",
                      justifyContent: "center",
                    },
                  }}
                >
                  <DialogContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DialogContentText id="alert-dialog-description">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <Typography
                            sx={{
                              width: "100%",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "20px",
                              color: "#7D8BA6",
                              textAlign: "center",
                            }}
                          >
                            <b>
                              <u>CREDIT LINE AGREEMENT FOR THE BARTER COINS</u>
                            </b>
                          </Typography>
                          <PrivateDealAgreement
                            OrderData={StoreOrderSummaryData}
                            ProductData={ProductData}
                            CouponCodeData={CouponCodeData}
                          />
                          <Typography sx={credittermstext}>
                            <Checkbox
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: "20px",
                                },
                                padding: "1px",
                              }}
                              onClick={() => {
                                if (agreecheck === false) {
                                  setagreeCheck(true);
                                } else {
                                  setagreeCheck(false);
                                }
                              }}
                            />
                            I agree to all the clauses of this Credit Line
                            Agreement for Barter Coins as set forth above.
                          </Typography>
                        </Box>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                  <DialogTitle id="responsive-dialog-title">
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          ...reqbtn,
                          fontWeight: 700,
                          background: "none",
                          border: "0.7px solid #EBEDEE",
                          color: "#445FD2",
                        }}
                        onClick={() => {
                          if (open === false) {
                            setProductsOpen(true);
                            setProductsOpenSec(false);
                          } else {
                            setProductsOpen(false);
                            setProductsOpenSec(false);
                          }
                        }}
                      >
                        Decline
                      </Button>
                      <Button
                        variant="contained"
                        sx={reqbtn}
                        disabled={agreecheck ? false : true}
                        onClick={() => {
                          setProductsOpenSec(false);
                        }}
                      >
                        I Agree
                      </Button>
                    </Box>
                  </DialogTitle>
                </Dialog>
              </React.Fragment>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PurchaseOrderDetails;

const SubmitButtonStyle = {
  width: "50%",
  color: "#fff",
  background: "#445FD2",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  "&:hover": {
    background: "#445FD2",
  },
};

const CancelButtonStyle = {
  width: "50%",
  color: "#000",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  border: "1px solid #D0D5DD",
};

const AddressInputStyle = {
  width: "186px",
  height: "35px",
  background: "#F9F9F9",
  borderRadius: "6px",
  border: "none",
  paddingLeft: "10px",
};

const AddressInputTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  marginBottom: "10px",
  color: "#6B7A99",
};

const ButtonStyleForAcceptAndReject = {
  width: "100%",
  height: "40px",
  maxWidth: "200px",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const CommongTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const TextStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "left",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const TextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
  width: "120px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#FFFFFF",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",

  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const TableTotaltextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#050505",
  opacity: 1,
};

const RejectReasonBtnStyle = {
  width: "100%",
  mx: "auto",
  textTransform: "none",
  height: "40px",
  borderRadius: "6px",
  border: "1px solid #E4E7EC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ButtonGroupstyle = {
  width: "100%",
  border: "none",
};

const TextAndCheckBoxGroupStyle = {
  width: "100%",
  px: "10px",
  cursor: "pointer",
};

const TextAreaStyle = {
  width: "100%",
  mx: "auto",
  background: "#ECEFF1",
  color: "#C7C7CC",
  border: "1px solid lighgray",
  height: "100px",
  "& fieldset": { border: "none" },
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  my: 1,
};

const InputPropsStyle = {
  sx: {
    background: "#ECEFF1",
    fontFamily: "Poppins",
    color: "#445FD2",
    borderRadius: "10px",
    fontSize: "14px",
    width: "100%",
  },
};

const ReasonTextStyle = {
  fontFamily: "poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#6B7A99",
};

const ProductNameTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "18px",
    lg: "18px",
    md: "17px",
    sm: "13px",
    xs: "13px",
  },
  lineHeight: "25px",
  color: "#6B7A99",
  textAlign: "left",
};

const ProductMetaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: {
    xl: "12px",
    lg: "12px",
    md: "11px",
    sm: "9px",
    xs: "9px",
  },
  lineHeight: {
    xl: "18px",
    lg: "18px",
    md: "15px",
    sm: "14px",
    xs: "14px",
  },
  color: "#858585",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const reqbtn = {
  width: "100px",
  height: "40px",
  boxShadow: "none",
  background: "#445FD2",
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "24px",
  textTransform: "none",
};

const ProductPriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  color: "rgba(107, 122, 153, 0.5)",
  fontSize: {
    xl: "15px",
    lg: "15px",
    md: "15px",
    sm: "15px",
    xs: "15px",
  },
  lineHeight: {
    xl: "21px",
    lg: "21px",
    md: "19px",
    sm: "18px",
    xs: "17px",
  },
  alignContent: "center",
  alignItems: "center",
};

const credittermstext = {
  width: "100%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "13px",
    lg: "13px",
    md: "10px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "left",
  color: "#7D8BA6",
};
