import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BXICoin from "../../assets/BXITokanSVG.svg";
import { useNavigate } from "react-router-dom";
import CommaSeprator from "../../components/CommaSeprator";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import Nodatafound from "../../components/NoDataFound/NoDataFound";
import PageLoader from "../../components/LoadingButton/PageLoader";

const TransactionsPage = () => {
  const navigate = useNavigate();

  const [CreditData, setCreditData] = useState();
  const [ProductCreditData, setProductCreditData] = useState();
  const [CheckCompanyType, setCheckCompanyType] = useState("");
  const [Loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchCreditData = async () => {
      setLoader(true);
      try {
        const response = await axios.get(
          "wallet_funds/get-companies-credit-requests",
          {
            withCredentials: true,
          }
        );
        setLoader(false);
        setProductCreditData(response?.data);
      } catch (error) {
        return error.message;
      }
    };

    fetchCreditData();

    const storedValue = localStorage.getItem("companyType");
    setCheckCompanyType(storedValue);
  }, []);

  useEffect(() => {
    try {
      axios
        .get(`wallet/get_all_credit_line_onhold_transactions`)
        .then((res) => {
          setCreditData(res?.data?.body);
        });
    } catch (error) {
      return error;
    }
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust this based on how many items you want per page

  const totalItems = ProductCreditData?.filter(
    (product) => product?.requestType === "CreditRequest"
  ).length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginatedData = () => {
    const reversedData = ProductCreditData?.filter(
      (product) => product?.requestType === "CreditRequest"
    )
      .slice()
      .reverse();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return reversedData?.slice(startIndex, endIndex);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleViewButtonClick = (productData, item, status) => {
    navigate("/home/creditterms", {
      state: {
        CreditedAmount: productData?.at(0)?._balance,
        TransactionsId: productData?.at(0)?._transaction_id,
        ProductData: item,
        Itemdata: productData,
        Status: status,
      },
    });
  };

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  return (
    <Paper elevation={0} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
      <BreadCrumbHeader MainText={"Credit Line Status"} />
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "17px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            padding: "2rem",
            height: "100%",
          }}
          gap={2}
        >
          <Box>
            <Grid container>
              {Loader ? (
                <Box
                  sx={{
                    minHeight: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PageLoader />
                </Box>
              ) : (
                <>
                  {ProductCreditData?.filter(
                    (product) => product?.requestType === "CreditRequest"
                  )?.length > 0 ? (
                    <>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          p: 2,
                          borderBottom: "1px solid #F5F5F5",
                        }}
                      >
                        <Grid
                          item
                          xl={0.3}
                          lg={0.3}
                          md={0.3}
                          sm={0.3}
                          xs={0.3}
                          align="left"
                          sx={{ ...tablehead, textAlign: "left" }}
                        >
                          No.
                        </Grid>
                        <Grid
                          item
                          xl={2.2}
                          lg={2.2}
                          md={2.2}
                          sm={2.2}
                          xs={2.2}
                          sx={{
                            ...tablehead,
                            textAlign: "left",
                            width: "20px",
                          }}
                        >
                          Product Name
                        </Grid>
                        <Grid
                          item
                          xl={0.7}
                          lg={0.7}
                          md={0.7}
                          sm={0.7}
                          xs={0.7}
                          align="center"
                          sx={tablehead}
                        >
                          Type
                        </Grid>

                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          align="center"
                          sx={{ ...tablehead, textAlign: "center" }}
                        >
                          Requested Amount
                        </Grid>

                        {CheckCompanyType === "Media" ? null : (
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            align="center"
                            sx={{ ...tablehead, textAlign: "center" }}
                          >
                            Eligible Amount
                          </Grid>
                        )}

                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          align="center"
                          sx={{ ...tablehead, textAlign: "center" }}
                        >
                          Credited Amount
                        </Grid>

                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          align="center"
                          sx={{ ...tablehead, textAlign: "left" }}
                        >
                          Requested Date
                        </Grid>

                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          align="center"
                          sx={{ ...tablehead, textAlign: "center" }}
                        >
                          Issued Date
                        </Grid>

                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          align="center"
                          sx={tablehead}
                        >
                          Admin Status
                        </Grid>
                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          align="center"
                          sx={tablehead}
                        >
                          User Status
                        </Grid>
                        <Grid
                          item
                          xl={0.8}
                          lg={0.8}
                          md={0.8}
                          sm={0.8}
                          xs={0.8}
                          align="center"
                          sx={tablehead}
                        >
                          Agreement
                        </Grid>
                      </Grid>

                      {getPaginatedData()?.map((item, idx) => {
                        const continuousIdx =
                          (currentPage - 1) * itemsPerPage + idx + 1;

                        const productData = CreditData?.filter(
                          (product) =>
                            product?._request_id?.toString() === item?._id
                        );

                        const credittoken =
                          (Number(
                            item?.totalPoints + item?.productId?.ManualPoint
                          ) /
                            120) *
                          100;

                        const calculateSums = (productVariations) => {
                          let totalValueSum = 0;
                          let maxOrderQtySum = 0;

                          productVariations?.forEach((item) => {
                            const currentValue =
                              item?.MaxOrderQuantity * item?.DiscountedPrice;

                            totalValueSum += currentValue;
                            maxOrderQtySum += item?.MaxOrderQuantity;
                          });

                          return {
                            totalValueSum,
                            maxOrderQtySum,
                          };
                        };

                        const sums = calculateSums(
                          item?.productId?.ProductsVariantions
                        );

                        const highestValue = sums?.totalValueSum;

                        const totalValue = (
                          (highestValue * credittoken) /
                          100
                        ).toFixed();

                        return (
                          <Grid
                            key={idx}
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                              p: 2,
                              flexDirection: "row",
                              borderBottom: "1px solid #F5F5F5",
                              mt: 2,
                            }}
                          >
                            <Grid
                              item
                              xl={0.3}
                              lg={0.3}
                              md={0.3}
                              sm={0.3}
                              xs={0.3}
                              align="left"
                            >
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                                gap={2}
                              >
                                <Box>
                                  <Typography
                                    sx={{
                                      fontFamily: "Kumbh Sans",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "#929EAE",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex",
                                      gap: "10px",
                                    }}
                                  >
                                    {continuousIdx}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid
                              xl={2.2}
                              lg={2.2}
                              md={2.2}
                              sm={2.2}
                              xs={2.2}
                              align="left"
                            >
                              <Box
                                sx={{
                                  width: "auto",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                  alignItems: "center",
                                  alignContent: "center",
                                }}
                              >
                                {" "}
                                {item?.productId?.ProductImages?.at(0)?.url ? (
                                  <>
                                    <img
                                      src={
                                        item?.productId?.ProductImages?.at(0)
                                          ?.url
                                      }
                                      alt="company-logo"
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "30px",
                                      }}
                                    />{" "}
                                    <Typography
                                      sx={{
                                        fontFamily: "Kumbh Sans",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "#929EAE",
                                      }}
                                    >
                                      {item?.productId?.ProductName
                                        ? item?.productId?.ProductName
                                        : "--"}
                                      <br />
                                      {item?.clnumber ? (
                                        <span
                                          style={{
                                            fontSize: "9px",
                                          }}
                                        >
                                          Credit_Id: {item?.clnumber}
                                        </span>
                                      ) : (
                                        "--"
                                      )}
                                    </Typography>
                                  </>
                                ) : item?.productId?.VoucherImages?.at(0)
                                    ?.url ? (
                                  <>
                                    <img
                                      src={
                                        item?.productId?.VoucherImages?.at(0)
                                          ?.url
                                      }
                                      alt="company-logo"
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "30px",
                                      }}
                                    />{" "}
                                    <Typography
                                      sx={{
                                        fontFamily: "Kumbh Sans",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "#929EAE",
                                      }}
                                    >
                                      {item?.productId?.ProductName
                                        ? item?.productId?.ProductName
                                        : "--"}
                                      <br />
                                      {item?.clnumber ? (
                                        <span
                                          style={{
                                            fontSize: "9px",
                                          }}
                                        >
                                          Credit_Id: {item?.clnumber}
                                        </span>
                                      ) : (
                                        "--"
                                      )}
                                    </Typography>
                                  </>
                                ) : (
                                  "--"
                                )}
                                <br />
                              </Box>
                            </Grid>

                            <Grid
                              item
                              xl={0.7}
                              lg={0.7}
                              md={0.7}
                              sm={0.7}
                              xs={0.7}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Kumbh Sans",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  color: "#929EAE",
                                }}
                              >
                                <span>
                                  {item?.productId?.ListingType
                                    ? item?.productId?.ListingType
                                    : "--"}
                                </span>
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xl={1.5}
                              lg={1.5}
                              md={1.5}
                              sm={1.5}
                              xs={1.5}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Kumbh Sans",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "#929EAE",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  gap: "5px",
                                }}
                              >
                                {item?.requestedUserAmount ? (
                                  <>
                                    <Box
                                      component="img"
                                      src={BXICoin}
                                      alt="currency"
                                      style={{ width: "20px", height: "20px" }}
                                    />
                                    <CommaSeprator
                                      Price={item?.requestedUserAmount}
                                    />
                                  </>
                                ) : (
                                  "--"
                                )}
                              </Typography>
                            </Grid>

                            {CheckCompanyType === "Media" ? null : (
                              <Grid
                                item
                                xl={1.5}
                                lg={1.5}
                                md={1.5}
                                sm={1.5}
                                xs={1.5}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                  gap={2}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontFamily: "Kumbh Sans",
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "#929EAE",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        gap: "5px",
                                      }}
                                    >
                                      <Box
                                        component="img"
                                        src={BXICoin}
                                        alt="currency"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                      <CommaSeprator Price={totalValue} />
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            )}

                            <Grid
                              item
                              xl={1.5}
                              lg={1.5}
                              md={1.5}
                              sm={1.5}
                              xs={1.5}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                                gap={2}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "#929EAE",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    gap: "5px",
                                  }}
                                >
                                  {item?.approvedAmount ? (
                                    <>
                                      <Box
                                        component="img"
                                        src={BXICoin}
                                        alt="currency"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                      <CommaSeprator
                                        Price={item?.approvedAmount}
                                      />
                                    </>
                                  ) : (
                                    "--"
                                  )}
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                                gap={2}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "#929EAE",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  {formatDate(item?.createdAt)}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                                gap={2}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "#929EAE",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  {productData?.length > 0
                                    ? productData?.map((item, idx) => {
                                        return item?._document_type ===
                                          "onhold" ? (
                                          <Box key={idx}>
                                            {item?._status === true ? (
                                              <span>
                                                {item?.createdAt ===
                                                item?.updatedAt
                                                  ? "--"
                                                  : formatDate(item?.updatedAt)}
                                              </span>
                                            ) : (
                                              "--"
                                            )}
                                          </Box>
                                        ) : (
                                          ""
                                        );
                                      })
                                    : "--"}
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid
                              item
                              xl={1.5}
                              lg={1.5}
                              md={1.5}
                              sm={1.5}
                              xs={1.5}
                              align="center"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Kumbh Sans",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "11px",
                                  color: "#445fd2",
                                  background: "#445fd221",
                                  width: "100px",
                                  height: "30px",
                                  borderRadius: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span>
                                  {item?.requestStatus === "accepted"
                                    ? "BXI Approved"
                                    : item?.requestStatus === "pending"
                                    ? "Request Raised"
                                    : item?.requestStatus === "rejected"
                                    ? "BXI Rejected"
                                    : null}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xl={1.5}
                              lg={1.5}
                              md={1.5}
                              sm={1.5}
                              xs={1.5}
                              align="center"
                              sx={{
                                ...tablehead,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {productData?.length > 0
                                ? productData?.map((el, idx) => {
                                    return el?._document_type === "onhold" ? (
                                      <Box key={idx}>
                                        {el?._acceptorreject === "reject" ? (
                                          <Typography
                                            sx={{
                                              fontFamily: "Kumbh Sans",
                                              fontStyle: "normal",
                                              fontWeight: 400,
                                              fontSize: "11px",
                                              color: "#445fd2",
                                              background: "#445fd221",
                                              width: "110px",
                                              height: "30px",
                                              borderRadius: 1,
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span>Member Declined</span>
                                          </Typography>
                                        ) : el?._acceptorreject === "accept" ? (
                                          <Typography
                                            sx={{
                                              fontFamily: "Kumbh Sans",
                                              fontStyle: "normal",
                                              fontWeight: 400,
                                              fontSize: "11px",
                                              color: "#445fd2",
                                              background: "#445fd221",
                                              width: "110px",
                                              height: "30px",
                                              borderRadius: 1,
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span>Credit Issued</span>
                                          </Typography>
                                        ) : (
                                          "--"
                                        )}
                                      </Box>
                                    ) : (
                                      ""
                                    );
                                  })
                                : "--"}
                            </Grid>
                            <Grid
                              item
                              xl={0.8}
                              lg={0.8}
                              md={0.8}
                              sm={0.8}
                              xs={0.8}
                              align="center"
                              sx={{
                                ...tablehead,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {productData?.length > 0
                                ? productData?.map((el, idx) => {
                                    return el?._document_type === "onhold" ? (
                                      <Box key={idx}>
                                        {el?._acceptorreject === "reject" ? (
                                          "--"
                                        ) : el?._acceptorreject === "accept" ? (
                                          <Button
                                            variant="contained"
                                            sx={{
                                              boxShadow: "none",
                                              background: "#445fd2",
                                              textTransform: "none",
                                            }}
                                            onClick={() =>
                                              handleViewButtonClick(
                                                productData,
                                                item,
                                                el?._acceptorreject
                                              )
                                            }
                                          >
                                            <span>View</span>
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="contained"
                                            sx={{
                                              boxShadow: "none",
                                              background: "#445fd2",
                                              textTransform: "none",
                                            }}
                                            onClick={() =>
                                              handleViewButtonClick(
                                                productData,
                                                item
                                              )
                                            }
                                          >
                                            View
                                          </Button>
                                        )}
                                      </Box>
                                    ) : (
                                      ""
                                    );
                                  })
                                : "--"}
                            </Grid>
                          </Grid>
                        );
                      })}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          alignItems: "center",
                        }}
                        mt={2}
                      >
                        <Pagination
                          count={totalPages}
                          color="primary"
                          page={currentPage}
                          onChange={handlePageChange}
                          showFirstButton
                          showLastButton
                        />
                      </Box>
                    </>
                  ) : (
                    <Nodatafound />
                  )}
                </>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TransactionsPage;

const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  textAlign: "center",
  color: "#929EAE",
};
