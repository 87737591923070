import React from "react";
import { Card, CardContent, Grid, Typography, Paper } from "@mui/material";

import { Box } from "@mui/system";
import MailIcon from "../../assets/Images/MembersPage/email.png";
import PhoneIcon from "../../assets/Images/MembersPage/phone.png";
import CloseIcon from "@mui/icons-material/Close";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";

import { useGetAllDeletedMembers } from "../../Hooks/CompanyMember/CompanyMembersHooks";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import Avatargenerator from "../../components/AvatarGenerator";
import axios from "axios";

export default function DeletedMembers() {
  const { data: companyMembers } = useGetAllDeletedMembers();
  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
  } = useGetLoggedInUser();

  async function handelDeleteMember(id) {
    const confirmed = window.confirm(
      "Are you sure you want to delete this member?"
    );
    if (confirmed === true) {
      axios
        .delete("assign/permanentdeleteassign/" + id)
        .then((res) => {
          if (res.data === "Unauthorized") {
            window.alert("Unauthorized");
            return;
          }
          window.alert("Member deleted successfully.");
        })
        .catch((err) => {});
    } else {
      return;
    }
  }

  return (
    <Paper
      sx={{ bgcolor: "transparent", width: "100%", boxShadow: "none" }}
      elevation={0}
    >
      <BreadCrumbHeader MainText="Deleted Company Members" />
      <Paper sx={{ bgcolor: "transparent", mt: 3 }} elevation={0}>
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
          {companyMembers === "Users doesnt exist" ? (
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "12px",
                textAlign: "center",
                color: "#1B212D",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxHeight: "2.5em",
                lineHeight: "1.5em",
                maxWidth: "100%",
                marginTop: "5px",
                ml: "20px",
              }}
            >
              No Data Found
            </Typography>
          ) : (
            companyMembers?.map((card, idx) => {
              return (
                <Grid key={idx} item xl={2.4} lg={2.4} md={3} sm={6} xs={12}>
                  <Card
                    variant="outlined"
                    sx={{
                      background: "#FFFFFF",
                      borderRadius: "10px",
                      width: "204px",
                      height: "232px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    key={card.id}
                  >
                    <CardContent
                      sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                          // bgcolor: "red",
                        }}
                      >
                        <CloseIcon
                          sx={{
                            color: "#929EAE",
                            fontSize: "17px",
                            cursor: "pointer",
                          }}
                          onClick={() => handelDeleteMember(card._id)}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // marginTop: "1.5rem",
                        }}
                      >
                        <Avatargenerator
                          companyname={userData?.data?.companyName}
                          AvatarUrl={userData?.data?.CompanyAvatar?.url}
                        />
                      </Box>
                      <Box
                        sx={{
                          borderBottom: "1px solid #F5F5F5",
                          width: "90%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "12px",
                            textAlign: "center",
                            color: "#1B212D",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxHeight: "2.5em",
                            lineHeight: "1.5em",
                            maxWidth: "100%",
                            marginTop: "5px",
                          }}
                        >
                          {card?.name}
                        </Typography>
                        <Typography
                          sx={{ ...rolestyle, mb: "10px", marginTop: "3px" }}
                        >
                          {card?.roleName === null ||
                          card?.roleName === undefined
                            ? "No Permission"
                            : card?.roleName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignContent: "center",
                          alignItems: "flex-start",
                          marginTop: "10px",
                          mb: "10px",
                          width: "90%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "90%",
                          }}
                        >
                          <Typography
                            sx={{
                              ...rolestyle,
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <Box
                              component="img"
                              src={PhoneIcon}
                              sx={{
                                color: "#929EAE",
                                cursor: "pointer",
                                height: "15px",
                                width: "15px",
                              }}
                            ></Box>
                            {card?.phone}
                          </Typography>
                          <Typography
                            sx={{
                              ...rolestyle,
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <Box
                              component="img"
                              src={MailIcon}
                              sx={{
                                color: "#929EAE",
                                cursor: "pointer",
                                height: "15px",
                                width: "15px",
                              }}
                            />
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {card?.email}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          )}
        </Grid>
      </Paper>
    </Paper>
  );
}

const rolestyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textAlign: "center",
  color: "#929EAE",
};
