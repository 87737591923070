import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ToastContainer, toast } from "react-toastify";
import print from "../../../src/assets/Images/CommonImages/PrintInvoice.svg";
import download from "../../../src/assets/Images/CommonImages/downloadinvoice.svg";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import NumberToWord from "../../components/NumberToWord";
import BXIStamp from "../../../src/assets/BXIStamp.png";
import axios from "axios";
import CommaSeprator from "../../components/CommaSeprator";
import useGetCompanyFullData from "../../Hooks/CompanyData/useGetCompanyFullData";

const Commission = () => {
  const { id } = useParams();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownload = () => {
    setTimeout(() => {
      html2canvas(componentRef.current)
        .then((canvas) => {
          const pdf = new jsPDF();
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight
          );
          pdf.save("print.pdf");
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    }, 1000);
  };

  const [CommissionData, setCommissionData] = useState();
  const [OrderData, setOrderData] = useState();
  const [MemberShipData, setMemberShipData] = useState();

  let TotalAdditionalCostWtihoutGSTInBXI = 0;
  let TotalPriceWithoutGST = 0;
  let GrandTotal = 0;
  let TotalQuantity = 0;

  OrderData?.PurchaseOrderData?.ProductData?.map((el, idx) => {
    TotalQuantity += el?.ProductQuantity;
    if (OrderData?.IsVoucher) {
      TotalAdditionalCostWtihoutGSTInBXI +=
        el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
    } else {
      TotalAdditionalCostWtihoutGSTInBXI +=
        el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
    }

    TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
    if (el?.ProductTypeName === "Media") {
      GrandTotal += el?.TotalPriceWithoutGST;
    } else if (OrderData?.IsVoucher) {
      GrandTotal +=
        el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI +
        el?.TotalPriceWithoutGST
          ? el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI +
            el?.TotalPriceWithoutGST
          : el?.TotalPriceInBXI;
    } else {
      GrandTotal +=
        el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI +
        el?.TotalPriceWithoutGST
          ? el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI +
            el?.TotalPriceWithoutGST
          : el?.TotalPriceInBXI;
    }
  });

  const { data: companyData } = useGetCompanyFullData();

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  const GetCommission = async () => {
    await axios
      .get(`commission/get_bxi_commision_by_orderId/${id}`)
      .then((res) => {
        setCommissionData(res?.data?.at(0));
        setOrderData(res?.data?.at(0)?.OrderData);
      })
      .catch((err) => {});
  };

  let BuyerId =
    OrderData?.BuyerDetails?.BuyerCompanyId || OrderData?.BuyerCompanyId;

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(`/company/get_company/${BuyerId}`, {
        withCredentials: true,
      });
      const data =
        response.data.memberships[response?.data?.memberships?.length - 1]
          .MembershipPlan;
      setMemberShipData(data);
    } catch (error) {}
  };

  useEffect(() => {
    GetCompanyByID();
  }, [OrderData]);

  useEffect(() => {
    GetCommission();
  }, []);

  return (
    <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
      <BreadCrumbHeader
        MainText="BXI Commission"
        LinkText1="{splitedurl[1]}"
        LinkText2="{splitedurl[2]}"
        link1="Link1"
        link2="link2"
      />
      <Paper
        sx={{
          bgcolor: "#fff",
          boxShadow: "none",
          p: 3,
          borderRadius: "20px",
          height: "auto",
          minHeight: "520px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",

          gap: "30px",
        }}
        elevation={0}
      >
        <Box
          sx={{
            bgcolor: "transparent",
            width: "100%",
            height: "100%",
          }}
          elevation={0}
        >
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <Box
            sx={{
              width: "100%",
              bgcolor: "white",
              mx: "auto",
              borderRadius: "17px",
              py: "40px",
            }}
            elevation={1}
            ref={componentRef}
          >
            <Box
              sx={{
                width: "95%",
                mx: "auto",
                borderLeft: "1px solid #cdcdcd",
                borderRight: "1px solid #cdcdcd",
                borderBottom: "1px solid #cdcdcd",
                borderTop: "1px solid #cdcdcd",
                px: "0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  height: "auto",
                  mx: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "130px",
                    width: "30%",
                  }}
                >
                  <img
                    src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/Commissionbarterlogo.png"
                    style={{ height: "85px", width: "auto" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid #cdcdcd",
                    height: "130px",
                    width: "40%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      width: "98%",
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    BXI WORLD LLP
                  </Typography>
                  <Typography
                    sx={{
                      ...TextLastStyle,
                      color: "#6B7A99",
                      fontSize: "14px",
                      fontWeight: 600,
                      width: "98%",
                      textAlign: "center",
                    }}
                  >
                    501 , 5th Floor Meadows Tower , Sahar Plaza Complex,
                  </Typography>
                  <Typography
                    sx={{
                      ...TextLastStyle,
                      color: "#6B7A99",
                      fontSize: "14px",
                      fontWeight: 600,
                      width: "98%",
                      textAlign: "center",
                    }}
                  >
                    Andheri East , Mumbai - 400059
                  </Typography>
                  <Typography
                    sx={{
                      ...TextLastStyle,
                      width: "98%",
                      color: "#6B7A99",
                      fontSize: "14px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Tel : 022 - 49646776
                  </Typography>
                  <Typography
                    sx={{
                      ...TextLastStyle,
                      width: "98%",
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: 600,
                      mt: 0.5,
                      textAlign: "center",
                    }}
                  >
                    GSTIN : 27AAXFB2929C1ZA
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // borderRight: "1px solid #cdcdcd",
                    borderLeft: "1px solid #cdcdcd",
                    height: "130px",
                    width: "30%",
                  }}
                >
                  <Typography
                    sx={{
                      ...TextLastStyle,
                      width: "98%",
                      color: "#445FD2",
                      fontSize: "14px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    www.bxiworld.com
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "42px",
                  mx: "auto",
                  background: "#156DB6",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  Commission Tax Invoice
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            Invoice No :{CommissionData?.CommitionId}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            Invoice Date :{" "}
                            {convertDate(CommissionData?.InvoiceDate)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {" "}
                            State :{" "}
                            {
                              OrderData?.PurchaseOrderData?.SellerDetails
                                ?.Address?.State
                            }
                          </Typography>
                          <Typography sx={TextLastStyle}>
                            {" "}
                            Code :{" "}
                            {
                              OrderData?.PurchaseOrderData?.SellerDetails
                                ?.StateCode
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            Place of Supply :{" "}
                            {OrderData?.InvoiceData?.SellerDetails?.Address
                              ?.State ||
                              OrderData?.PurchaseOrderData?.SellerDetails
                                ?.Address?.State}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {" "}
                            Purchase order no :{" "}
                            {OrderData?.IsVoucher ? "N/A" : OrderData?.PoNumber}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.2,
                          }}
                        >
                          <Typography sx={TextLastStyle}>
                            {" "}
                            Tax Invoice No: {OrderData?.TaxInvoice?.FINumber}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  mx: "auto",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "21px",
                    background: "#156DB6",
                    borderRadius: "3px 0px 0px 3",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    borderRadius: "3px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: "100%",
                      borderRight: "1px solid #F3F2F3",
                    }}
                  >
                    <Typography
                      sx={{
                        ...CommongTextStyle,
                        fontSize: "10px",
                        color: "white",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      &nbsp; Bill to Party
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        ...CommongTextStyle,
                        color: "white",
                        fontSize: "10px",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      &nbsp; Ship to Party
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      mx: "auto",
                      borderRight: "1px solid #CDCDCD",
                    }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.2,
                              borderBottom: "none",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Typography
                              sx={{ ...TextLastStyle, whiteSpace: "nowrap" }}
                            >
                              &nbsp;&nbsp;Seller Name : {""}
                              {CommissionData?.SellerDetails
                                ?.SellerCompanyName ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  ?.SellerCompanyName}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.2,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              &nbsp;&nbsp;Address :{" "}
                              {CommissionData?.SellerDetails?.Address
                                ?.AddressLine ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  .Address?.AddressLine}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.2,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              {" "}
                              &nbsp;&nbsp;City :{" "}
                              {CommissionData?.SellerDetails?.Address
                                ?.District ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  .Address?.District}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.2,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              {" "}
                              &nbsp;&nbsp;GSTIN :{" "}
                              {CommissionData?.SellerDetails?.GSTIN ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  ?.GSTIN}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.2,
                              borderBottom: "none",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TextLastStyle,

                                display: "inline-block",
                              }}
                            >
                              &nbsp;&nbsp;State :{" "}
                              {CommissionData?.SellerDetails?.Address?.State ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  ?.Address?.State}
                            </Typography>
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                display: "inline-block",
                              }}
                            >
                              &nbsp;Code :{" "}
                              {CommissionData?.SellerDetails?.StateCode ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  ?.StateCode}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Table sx={{ p: 0 }}>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.2,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              {" "}
                              &nbsp;&nbsp;Seller Name : {""}
                              {CommissionData?.SellerDetails
                                ?.SellerCompanyName ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  ?.SellerCompanyName}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.2,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              &nbsp;&nbsp;Address :{" "}
                              {CommissionData?.SellerDetails?.Address
                                ?.AddressLine ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  .Address?.AddressLine}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.2,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              {" "}
                              &nbsp;&nbsp;City :{" "}
                              {CommissionData?.SellerDetails?.Address
                                ?.District ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  .Address?.District}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.2,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              {" "}
                              &nbsp;&nbsp;GSTIN :{" "}
                              {CommissionData?.SellerDetails?.GSTIN ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  ?.GSTIN}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.2,
                              display: "flex",
                              borderBottom: "none",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ ...TextLastStyle, display: "inline-block" }}
                            >
                              {" "}
                              &nbsp;&nbsp;State :{" "}
                              {CommissionData?.SellerDetails?.Address?.State ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  ?.Address?.State}
                            </Typography>{" "}
                            <Typography
                              sx={{ ...TextLastStyle, display: "inline-block" }}
                            >
                              {" "}
                              Code :{" "}
                              {CommissionData?.SellerDetails?.StateCode ||
                                OrderData?.PurchaseOrderData?.SellerDetails
                                  ?.StateCode}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Box>

              <TableContainer
                component={Paper}
                sx={{
                  maxWidth: "100%",
                  mx: "auto",
                  boxShadow: "none",
                  borderRadius: "0px",
                }}
              >
                <Table
                  sx={{
                    minWidth: "700px",
                    boxShadow: "none",
                    borderRight: "none",
                  }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow sx={{ height: "42px", bgcolor: "#2261A2" }}>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}> Sr. No.</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={2}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>
                          Product / Service Description
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>
                          HSN / SAC
                          <br />
                          Code
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>GST %</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>QTY</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>Rate</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>
                          Amount ( Taxable Value)
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        height: "42px",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableBottomtext}>1</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontSize: "14px",
                          }}
                        >
                          Commission Charges @{" "}
                          {OrderData?.PurchaseOrderData?.CouponData
                            ?.BXICommission
                            ? `${Number(
                                OrderData?.PurchaseOrderData?.CouponData
                                  ?.BXICommission
                              )}%`
                            : `${Number(MemberShipData?.GST)}%`}{" "}
                          (
                          {(
                            TotalAdditionalCostWtihoutGSTInBXI +
                            TotalPriceWithoutGST
                          ).toFixed(2)}
                          *
                          {OrderData?.PurchaseOrderData?.CouponData
                            ?.BXICommission
                            ? `${Number(
                                OrderData?.PurchaseOrderData?.CouponData
                                  ?.BXICommission
                              )}%`
                            : `${Number(MemberShipData?.GST)}%`}
                          )
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableBottomtext}>996211</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}>18% &nbsp;</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          {/* {TotalQuantity} &nbsp; */}1&nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator
                            Price={CommissionData?.Commission?.CommissionPrice}
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          {" "}
                          <CommaSeprator
                            Price={CommissionData?.Commission?.CommissionPrice}
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{
                        height: "42px",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableBottomtext}>2</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontSize: "14px",
                          }}
                        >
                          Reimbursement of TDS on Product and others charges
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableBottomtext}>999799</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator Price={OrderData?.INRDetails?.TDS} />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          {" "}
                          <CommaSeprator Price={OrderData?.INRDetails?.TDS} />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {[...Array(3)].map((data, idx) => {
                      return (
                        <TableRow
                          sx={{
                            height: "25px",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}></Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontSize: "13px",
                                fontWeight: 600,
                              }}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            ></Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                                textAlign: "right",
                              }}
                            >
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                                textAlign: "right",
                              }}
                            >
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                                textAlign: "right",
                              }}
                            >
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    <TableRow
                      sx={{
                        height: "25px",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          background: "#2261A2",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontSize: "13px",
                            align: "center",
                            color: "#fff",
                          }}
                        >
                          Total Amount Before Tax
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                            textAlign: "right",
                          }}
                        >
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            opacity: 1,
                            textAlign: "right",
                          }}
                        >
                          {(
                            CommissionData?.Commission?.CommissionPrice +
                            OrderData?.INRDetails?.TDS
                          ).toFixed(2)}
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            opacity: 1,
                            textAlign: "right",
                          }}
                        >
                          {(
                            CommissionData?.Commission?.CommissionPrice +
                            OrderData?.INRDetails?.TDS
                          ).toFixed(2)}
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderTop: "none",
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontStyle: "italic",
                            color: "#6B7A99",
                          }}
                        >
                          Terms & Conditions
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      >
                        {" "}
                        <Typography sx={TableBottomtext}>
                          {" "}
                          &nbsp;&nbsp;CGST
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          {CommissionData?.IsIGST ? null : "9%"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        {CommissionData?.IsIGST ? null : (
                          <Typography sx={TableBottomtext}>
                            <CommaSeprator
                              Price={
                                CommissionData?.Commission?.CommissionGST / 2
                              }
                            />
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderTop: "none",
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#6B7A99",
                          }}
                        >
                          The payment should be in favor of "BXI WORLD LLP"
                          -100% Advance
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          &nbsp;&nbsp;SGST
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          {CommissionData?.IsIGST ? null : "9%"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        {CommissionData?.IsIGST ? null : (
                          <Typography sx={TableBottomtext}>
                            <CommaSeprator
                              Price={
                                CommissionData?.Commission?.CommissionGST / 2
                              }
                            />
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderTop: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#6B7A99",
                          }}
                        >
                          All payments should be done through RTGS/NEFT only
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          &nbsp;&nbsp;IGST
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          {CommissionData?.IsIGST ? "18%" : null}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        {CommissionData?.IsIGST ? (
                          <Typography sx={TableBottomtext}>
                            <CommaSeprator
                              Price={CommissionData?.Commission?.CommissionGST}
                            />
                          </Typography>
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          // borderTop: "none",
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontStyle: "italic",
                            color: "#6B7A99",
                          }}
                        >
                          Total amount Payable (in words)
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={2}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography sx={TableBottomtext}>
                          Total GST Amount
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator
                            Price={CommissionData?.Commission?.CommissionGST}
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontWeight: 600,
                            fontStyle: "italic",
                            color: "#6B7A99",
                          }}
                        >
                          INR{" "}
                          <NumberToWord
                            number={(
                              CommissionData?.Commission?.CommissionPrice +
                              CommissionData?.Commission?.CommissionGST +
                              OrderData?.INRDetails?.TDS
                            ).toFixed(2)}
                          />
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={2}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          Invoice Total Amount
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator
                            Price={(
                              CommissionData?.Commission?.CommissionPrice +
                              CommissionData?.Commission?.CommissionGST +
                              OrderData?.INRDetails?.TDS
                            ).toFixed(2)}
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  width: "100%",
                  mx: "auto",
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              HSN / SAC
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              Taxable Value
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "200px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "220px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              CGST Tax
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                              width: "25%",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              Rate
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "220px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              SGST Tax
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                              width: "25%",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              Rate
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "220px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",

                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              Integrated TAX
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                              width: "25%",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              Rate
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "12px",
                              }}
                            >
                              Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "200px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "200px",
                              border: "none",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TextLastStyle,
                                color: "#6B7A99",
                                fontSize: "12px",
                                fontWeight: 500,
                              }}
                            >
                              Total TAX Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>996211</Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                            px: 0,
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                              textAlign: "right",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              <CommaSeprator
                                Price={
                                  CommissionData?.Commission?.CommissionPrice
                                }
                              />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              {CommissionData?.IsIGST ? null : "9%"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              borderBottom: "none",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            <Typography sx={TextLastStyle}>
                              {CommissionData?.IsIGST ? null : (
                                <CommaSeprator
                                  Price={
                                    CommissionData?.Commission?.CommissionGST /
                                    2
                                  }
                                />
                              )}
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              {CommissionData?.IsIGST ? null : "9%"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              borderBottom: "none",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            <Typography sx={TextLastStyle}>
                              {CommissionData?.IsIGST ? null : (
                                <CommaSeprator
                                  Price={
                                    CommissionData?.Commission?.CommissionGST /
                                    2
                                  }
                                />
                              )}
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                              borderBottom: "none",
                            }}
                          >
                            {" "}
                            <Typography sx={TextLastStyle}>
                              {CommissionData?.IsIGST ? "18%" : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              borderBottom: "none",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            <Typography sx={TextLastStyle}>
                              {CommissionData?.IsIGST ? (
                                <CommaSeprator
                                  Price={
                                    CommissionData?.Commission?.CommissionGST
                                  }
                                />
                              ) : null}
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>

                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "200px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                            px: 0,
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "200px",
                              border: "none",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              {" "}
                              <CommaSeprator
                                Price={
                                  CommissionData?.Commission?.CommissionGST
                                }
                              />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>Total</Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                            px: 0,
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                              textAlign: "right",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              <CommaSeprator
                                Price={
                                  CommissionData?.Commission?.CommissionPrice
                                }
                              />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}></Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              borderBottom: "none",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            <Typography sx={TextLastStyle}>
                              {CommissionData?.IsIGST ? null : (
                                <CommaSeprator
                                  Price={
                                    CommissionData?.Commission?.CommissionGST /
                                    2
                                  }
                                />
                              )}
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              {/* {CommissionData?.IsIGST ? null : "9%"} */}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              borderBottom: "none",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            <Typography sx={TextLastStyle}>
                              {CommissionData?.IsIGST ? null : (
                                <CommaSeprator
                                  Price={
                                    CommissionData?.Commission?.CommissionGST /
                                    2
                                  }
                                />
                              )}
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "220px",
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              width: "110px",
                              borderBottom: "none",
                            }}
                          >
                            {" "}
                            <Typography sx={TextLastStyle}>
                              {/* {CommissionData?.IsIGST ? "18%" : "0"} */}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "110px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                              borderBottom: "none",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            <Typography sx={TextLastStyle}>
                              {CommissionData?.IsIGST ? (
                                <CommaSeprator
                                  Price={
                                    CommissionData?.Commission?.CommissionGST
                                  }
                                />
                              ) : null}
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>

                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "200px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                            px: 0,
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "200px",
                              border: "none",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                            }}
                          >
                            <Typography sx={TextLastStyle}>
                              {" "}
                              <CommaSeprator
                                Price={
                                  CommissionData?.Commission?.CommissionGST
                                }
                              />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={7}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  mx: "auto",
                  borderRight: "1px solid #CDCDCD",
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          bgcolor: "#156DB6",
                          p: 0.2,
                        }}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, color: "#ffffff" }}
                        >
                          Bank Details:
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={4}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography
                          sx={{
                            ...TextLastStyle,
                            width: "380px",
                            textAlign: "end",
                            fontSize: "12px",
                          }}
                        >
                          For BXI WORLD LLP
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0.2,
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          Name : BXI WORLD LLP
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={4}
                        rowSpan={6}
                        sx={tablecell}
                      >
                        <img
                          src={BXIStamp}
                          style={{
                            maxHeight: "160px",
                            height: "auto",
                            width: "auto",
                          }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0.2,
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          Account No. : 007863300005298
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0.2,
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          {" "}
                          IFSC Code : YESB0000078
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0.2,
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          Branch Name : Andheri (East)
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0.2,
                        }}
                      >
                        <Typography sx={TextLastStyle}>
                          {" "}
                          Bank Name : YES BANK
                        </Typography>{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          borderBottom: "none",
                          p: 0.2,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TextLastStyle,
                            color: "#6B7A99",
                            fontWeight: 400,
                            fontSize: "12px",
                          }}
                        >
                          Ceritified that the particulars given above are true
                          and correct (E&OE)
                        </Typography>{" "}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Box
                  sx={{
                    borderTop: "1px solid #CDCDCD",
                    height: "22px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...TextLastStyle,
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: 400,
                    }}
                  >
                    This is a System Generated Invoice by BXI WORLD LLP
                  </Typography>{" "}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              mt: 3,
              pb: 3,
            }}
          >
            <Box
              sx={{
                maxWidth: "360px",
                mx: "auto",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                height: "100%",
              }}
            >
              <Button variant="outlined" sx={btnsx} onClick={handlePrint}>
                <Box component={"img"} src={print} sx={ButtonIconStyle} />{" "}
                &nbsp;Print
              </Button>

              <Button variant="outlined" sx={btnsx} onClick={handleDownload}>
                <Box component={"img"} src={download} sx={ButtonIconStyle} />{" "}
                &nbsp;Download
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Paper>
  );
};

export default Commission;
const CommongTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",

  color: "#6B7A99",
};

const ButtonIconStyle = {
  width: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "20px",
    xs: "18px",
  },
  height: "auto",
};

const TextStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "left",
  color: "#6B7A99",
  opacity: 0.7,
};

const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "20px",
  color: "#FFFFFF",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "20px",

  color: "#505050",
};

const TableTotaltextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#050505",
  opacity: 1,
};

const TextLastStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: "12px",
  lineHeight: "20px",
  color: "#6B7A99",
  fontWeight: 500,
  // fontSize: "12px",
  opacity: 1,
  // whiteSpace: "nowrap",
};

const BanktText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "11px",
  color: "#505050",
};

const BanktbottomText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "9px",
  lineHeight: "11px",
  color: "#505050",
};

const btnsx = {
  width: "100%",
  height: "51px",
  maxWidth: "168px",
  borderRadius: "10px",
  borderColor: "#156DB6",
  color: "#156DB6",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const tablecell = { borderRight: "1px solid #CDCDCD", p: 0.2 };
