/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CommaSeprator from "../../components/CommaSeprator";
import StacsOfCoinIcon from "../../assets/BXITokenIcon.png";
import useStyles from "./Styles";
import Purches from "../../assets/Images/CommonImages/checklist (1) 1.png";
import Perfoma from "../../assets/Images/CommonImages/invoice (4) 1.png";
import Tax from "../../assets/Images/CommonImages/tax 1.png";
import bxiLogo from "../../assets/Images/CommonImages/logo.png";
import Truck from "../../assets/Images/CommonImages/delivery-truck (2) 1.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeliveredIcon from "../../assets/Delivered.jpg";
import useGetOrderById from "../../Hooks/OrderActions/useGetOrderById";
import axios from "axios";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function DiscountedPrice({ regularPrice, discountPrice, percentage, type }) {
  const discount = regularPrice - discountPrice;
  const discountPercent = (discount / regularPrice) * 100;
  const gstPrice =
    type === "Media"
      ? (discountPrice * percentage) / 100
      : discountPrice / (1 + percentage / 100);
  const gstamount = discountPrice - gstPrice;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "space-between",
          flexDirection: "column",
          minWidth: "150px",
          width: "auto",
          gap: "8px",
        }}
      >
        <Typography
          sx={{
            ...ProductPriceTextStyle,
            fontWeight: 500,
            color: "#525252",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Amt :-
          <span
            style={{
              opacity: 0.8,
            }}
          >
            {type === "Media"
              ? (Number(regularPrice) + Number(gstPrice)).toLocaleString(
                  "en-IN",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )
              : regularPrice?.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </span>
        </Typography>
        <Typography
          sx={{
            ...ProductPriceTextStyle,
            fontWeight: 500,
            color: "#525252",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Coin :-
          <span
            style={{
              opacity: 0.8,
            }}
          >
            <img
              src={StacsOfCoinIcon}
              alt="rupieicon"
              style={{
                width: "13px",
                height: "auto",
              }}
            />
            &nbsp;
            {type === "Media"
              ? Number(regularPrice).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : gstPrice.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </span>
        </Typography>
        <Typography
          sx={{
            ...ProductPriceTextStyle,
            fontWeight: 500,
            color: "#525252",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          GST :-
          <span
            style={{
              opacity: 0.8,
            }}
          >
            ₹{" "}
            {type === "Media"
              ? gstPrice.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : gstamount.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </span>
        </Typography>
      </Box>
    </div>
  );
}

const ProductDetail = (props) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "10px",
        height: "150px",
        maxHeight: "150px",
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        p: 1,
      }}
    >
      {props?.data?.PurchaseOrderData?.ProductData?.map((res, idx) => {
        return (
          <Box
            key={idx}
            className={classes.rootbox}
            sx={{
              width: "99%",
            }}
          >
            <Box className={classes.rootboxChildOne}>
              <Box
                className={classes.cartProductStrip}
                sx={{
                  backgroundImage: `url(${res?.ProductImage})`,
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  alignContent: "start",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    ...ProductNameTextStyle,
                    width: "300px",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    color: "#525252",
                  }}
                >
                  {res?.ProductName}
                </Typography>
                <Typography
                  sx={{
                    ...ProductMetaTextStyle,
                    width: "300px",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                  }}
                >
                  {res?.ProductDescription}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...ProductPriceTextStyle,
                      marginTop: "-03px",
                      color: "#525252",
                    }}
                  >
                    Quantity:
                    <Typography
                      sx={{
                        ...ProductPriceTextStyle,
                        marginTop: "-03px",
                        color: "#888888",
                        display: "inline",
                        marginLeft: "4px",
                      }}
                    >
                      {res?.ProductQuantity}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.rootboxChildTwo}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  alignContent: "center",
                  width: "100%",
                  marginRight: "20px",
                }}
              >
                <Typography
                  sx={{
                    ...ProductPriceTextStyle,
                  }}
                >
                  &nbsp;
                  <DiscountedPrice
                    regularPrice={res?.PricePerUnit}
                    discountPrice={res?.DiscountedPrice}
                    percentage={res?.GST}
                    type={res?.ProductTypeName}
                  />
                </Typography>
                {/* )} */}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const BarterCoinDetails = (props) => {
  const OrderData = props?.OrderData;

  const [MemberShipData, setMemberShipData] = useState();

  let TotalAdditionalCostWtihoutGSTInBXI = 0;
  let TotalPriceWithoutGST = 0;
  let totaladditionalcostGstinrupee = 0;
  let totaladditionalcostGstinbxi = 0;

  let BuyerId = OrderData?.data?.BuyerCompanyId;

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(`/company/get_company/${BuyerId}`, {
        withCredentials: true,
      });
      const data =
        response.data.memberships[response?.data?.memberships?.length - 1]
          .MembershipPlan;
      setMemberShipData(data);
    } catch (error) {}
  };

  useEffect(() => {
    GetCompanyByID();
  }, [OrderData]);

  let BxiCommision = OrderData?.data?.PurchaseOrderData?.CouponData
    ?.BXICommission
    ? Number(OrderData?.data?.PurchaseOrderData?.CouponData?.BXICommission)
    : Number(MemberShipData?.GST);

  const ChargesArray = [
    {
      name: "Packaging charges",
      gst: Number(OrderData?.data?.PackagingData?.packagingcostgstfee),
      taxableValue: Number(OrderData?.data?.PackagingData?.packagingcosts),
      totalTaxAmount:
        (Number(OrderData?.data?.PackagingData?.packagingcosts) *
          Number(OrderData?.data?.PackagingData?.packagingcostgstfee)) /
        100,
    },
    {
      name: "Logistics Charges",
      gst:
        OrderData?.data?.BuyerChoosedTransportation?.TransportationType ===
        "notransportation"
          ? 0
          : Number(OrderData?.data?.Transportationdata?.gstFee || 0),
      taxableValue:
        OrderData?.data?.BuyerChoosedTransportation?.TransportationType ===
        "notransportation"
          ? 0
          : Number(OrderData?.data?.Transportationdata?.transportationfee || 0),
      totalTaxAmount:
        OrderData?.data?.BuyerChoosedTransportation?.TransportationType ===
        "notransportation"
          ? 0
          : (Number(
              OrderData?.data?.Transportationdata?.transportationfee || 0
            ) *
              Number(OrderData?.data?.Transportationdata?.gstFee || 0)) /
            100,
    },
    {
      name: "BXI Commission",
      gst: 18,
      commission: BxiCommision,
      taxableValue:
        (Number(OrderData?.data?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
        100,
      totalTaxAmount:
        (((Number(OrderData?.data?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
          100) *
          18) /
        100,
    },
  ];

  let ChargesTaxableAmount = 0;

  ChargesArray?.map((res) => {
    if (OrderData?.data?.IsMedia) {
      if (res.name === "BXI Commission") {
        ChargesTaxableAmount += Number(res?.totalTaxAmount);
      }
    } else {
      ChargesTaxableAmount += Number(res?.totalTaxAmount);
    }
  });

  let totalTaxAmount = OrderData?.data?.IsVoucher
    ? OrderData?.data?.INRDetails?.totalGstAmount +
      OrderData?.data?.INRDetails?.totalGstInrOfAdCost +
      OrderData?.data?.INRDetails?.bxiCommisionGST
    : OrderData?.data?.IsMedia
    ? OrderData?.data?.INRDetails?.TotalGstAmount +
      OrderData?.data?.INRDetails?.TotalGstInrOfAdCost +
      ChargesTaxableAmount
    : OrderData?.data?.PITotals?.TotalGstAmount +
      OrderData?.data?.PITotals?.TotalAdCostGstAmount +
      ChargesTaxableAmount +
      OrderData?.data?.INRDetails?.InsuranceCostGstValue;

  OrderData?.data?.PurchaseOrderData?.ProductData?.map((el, idx) => {
    if (OrderData?.data?.IsVoucher) {
      TotalAdditionalCostWtihoutGSTInBXI +=
        el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
    } else {
      TotalAdditionalCostWtihoutGSTInBXI +=
        el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
    }

    if (
      el &&
      (el?.AdditionCost?.AdditionCostArray?.length > 0 ||
        el?.AdditionalCost?.AdditionCostArrayData?.length > 0 ||
        el?.AdditionCost?.AdditionCostArrayData?.length)
    ) {
      // Choose the correct array to iterate based on availability
      const additionalCostArray =
        el?.AdditionCost?.AdditionCostArray?.length > 0
          ? el?.AdditionCost?.AdditionCostArray
          : el?.AdditionCost?.AdditionCostArrayData?.length > 0
          ? el?.AdditionCost?.AdditionCostArrayData
          : el?.AdditionalCost?.AdditionCostArrayData;

      // Iterate over the array
      additionalCostArray.forEach((item) => {
        // Check currency type and calculate total additional cost accordingly
        if (item.currencyType === "₹") {
          totaladditionalcostGstinrupee +=
            (item.TotalTaxableAmount * item.AdCostGST) / 100;
        } else if (item.currencyType === "BXITokens") {
          totaladditionalcostGstinbxi +=
            (item.TotalTaxableAmount * item.AdCostGST) / 100;
        }
      });
    }
    TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        mt: 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <br />
        <Table sx={{ minWidth: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow
              sx={{
                height: "24px",
                borderRadius: "5px",
                bgcolor: "red",
              }}
            >
              <TableCell
                sx={{
                  p: 0.3,
                  border: "2px solid #CDCDCD",
                  bgcolor: "#2261A2",
                }}
                align="center"
              >
                <Typography
                  sx={{
                    ...TableBottomtext,
                    fontWeight: 600,
                    textAlign: "left",
                    color: "white",
                  }}
                >
                  Particulars
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0.3,
                  border: "2px solid #CDCDCD",
                  bgcolor: "#2261A2",
                }}
                align="center"
              >
                <Typography
                  sx={{ ...TableBottomtext, fontWeight: 600, color: "white" }}
                >
                  Basic Value
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0.3,
                  border: "2px solid #CDCDCD",
                  bgcolor: "#2261A2",
                }}
                align="center"
              >
                <Typography
                  sx={{ ...TableBottomtext, fontWeight: 600, color: "white" }}
                >
                  GST (INR)
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0.3,
                  border: "2px solid #CDCDCD",
                  bgcolor: "#2261A2",
                }}
                align="center"
              >
                <Typography
                  sx={{ ...TableBottomtext, fontWeight: 600, color: "white" }}
                >
                  Total (Coins+INR)
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Product Cost */}
            <TableRow
              sx={{
                height: "24px",
              }}
            >
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                  borderLeft: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "left" }}>
                  Product Cost (Coins){" "}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  {" "}
                  <CommaSeprator
                    Price={TotalPriceWithoutGST ? TotalPriceWithoutGST : "0.00"}
                  />
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  {OrderData?.data?.IsMedia ? (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.TotalGstAmount
                          ? OrderData?.data?.INRDetails?.TotalGstAmount
                          : 0.0
                      }
                    />
                  ) : (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.totalGstAmount
                          ? OrderData?.data?.INRDetails?.totalGstAmount
                          : 0.0
                      }
                    />
                  )}
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                  borderRight: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  {OrderData?.data?.IsMedia ? (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.TotalGstAmount
                          ? OrderData?.data?.INRDetails?.TotalGstAmount +
                            TotalPriceWithoutGST
                          : 0.0
                      }
                    />
                  ) : (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.totalGstAmount
                          ? OrderData?.data?.INRDetails?.totalGstAmount +
                            TotalPriceWithoutGST
                          : 0.0
                      }
                    />
                  )}
                  &nbsp;
                </Typography>
              </TableCell>
            </TableRow>
            {/* Packaging Cost */}
            {OrderData?.data?.IsMedia || OrderData?.data?.IsVoucher ? null : (
              <>
                <TableRow
                  sx={{
                    height: "24px",
                  }}
                >
                  <TableCell
                    sx={{
                      p: 0,
                      border: "1px solid #CDCDCD",
                      borderLeft: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Typography sx={{ ...TableBottomtext, textAlign: "left" }}>
                      Packaging Cost
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                      {OrderData?.data?.INRDetails?.packagingCost ? (
                        <>
                          {OrderData?.data?.INRDetails?.packagingCost !== 0 && (
                            <CommaSeprator
                              Price={OrderData?.data?.INRDetails?.packagingCost}
                            />
                          )}
                        </>
                      ) : (
                        "0.00"
                      )}
                      &nbsp;
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "1px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                      {" "}
                      {OrderData?.data?.INRDetails?.packagingCostGST ? (
                        <>
                          {OrderData?.data?.INRDetails?.packagingCostGST !==
                            0 && (
                            <CommaSeprator
                              Price={
                                (OrderData?.data?.INRDetails?.packagingCost *
                                  OrderData?.data?.INRDetails
                                    ?.packagingCostGST) /
                                100
                              }
                            />
                          )}
                        </>
                      ) : (
                        "0.00"
                      )}
                      &nbsp;
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: 0,
                      border: "1px solid #CDCDCD",
                      borderRight: "2px solid #CDCDCD",
                    }}
                    align="center"
                  >
                    <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                      {OrderData?.data?.INRDetails?.packagingCost ? (
                        <>
                          {OrderData?.data?.INRDetails?.packagingCost !== 0 && (
                            <CommaSeprator
                              Price={
                                OrderData?.data?.INRDetails?.packagingCost +
                                (OrderData?.data?.INRDetails?.packagingCost *
                                  OrderData?.data?.INRDetails
                                    ?.packagingCostGST) /
                                  100
                              }
                            />
                          )}
                        </>
                      ) : (
                        "0.00"
                      )}
                      &nbsp;
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
            {/* Additional Cost (INR)*/}
            <TableRow
              sx={{
                height: "24px",
              }}
            >
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                  borderLeft: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "left" }}>
                  Additional Cost (INR)
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  {" "}
                  {OrderData?.data?.INRDetails?.totalValueInrOfAdCost ? (
                    <>
                      {OrderData?.data?.INRDetails?.totalValueInrOfAdCost !==
                        0 && (
                        <CommaSeprator
                          Price={
                            OrderData?.data?.INRDetails?.totalValueInrOfAdCost
                          }
                        />
                      )}
                    </>
                  ) : OrderData?.data?.INRDetails?.TotalValueInrOfAdCost ? (
                    <>
                      {OrderData?.data?.INRDetails?.TotalValueInrOfAdCost !==
                        0 && (
                        <CommaSeprator
                          Price={
                            OrderData?.data?.INRDetails?.TotalValueInrOfAdCost
                          }
                        />
                      )}
                    </>
                  ) : OrderData?.data?.POTotals?.TotalAdditionalCostInRupee ? (
                    <>
                      {OrderData?.data?.POTotals?.TotalAdditionalCostInRupee !==
                        0 && (
                        <CommaSeprator
                          Price={
                            OrderData?.data?.POTotals
                              ?.TotalAdditionalCostInRupee
                          }
                        />
                      )}
                    </>
                  ) : (
                    "0.00"
                  )}
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  <CommaSeprator
                    Price={
                      totaladditionalcostGstinrupee
                        ? totaladditionalcostGstinrupee
                        : "0.00"
                    }
                  />
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                  borderRight: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  {OrderData?.data?.INRDetails?.totalValueInrOfAdCost ? (
                    <>
                      {OrderData?.data?.INRDetails?.totalValueInrOfAdCost !==
                        0 && (
                        <CommaSeprator
                          Price={
                            OrderData?.data?.INRDetails?.totalValueInrOfAdCost +
                            totaladditionalcostGstinrupee
                          }
                        />
                      )}
                    </>
                  ) : OrderData?.data?.INRDetails?.TotalValueInrOfAdCost ? (
                    <>
                      {OrderData?.data?.INRDetails?.TotalValueInrOfAdCost !==
                        0 && (
                        <CommaSeprator
                          Price={
                            OrderData?.data?.INRDetails?.TotalValueInrOfAdCost +
                            totaladditionalcostGstinrupee
                          }
                        />
                      )}
                    </>
                  ) : OrderData?.data?.POTotals?.TotalAdditionalCostInRupee ? (
                    <>
                      {OrderData?.data?.POTotals?.TotalAdditionalCostInRupee !==
                        0 && (
                        <CommaSeprator
                          Price={
                            OrderData?.data?.POTotals
                              ?.TotalAdditionalCostInRupee +
                            totaladditionalcostGstinrupee
                          }
                        />
                      )}
                    </>
                  ) : (
                    "0.00"
                  )}
                  &nbsp;
                </Typography>
              </TableCell>
            </TableRow>
            {/* Additional Cost (Coins)*/}
            <TableRow
              sx={{
                height: "24px",
              }}
            >
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                  borderLeft: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "left" }}>
                  Additional Cost (Coins)
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  <CommaSeprator
                    Price={
                      TotalAdditionalCostWtihoutGSTInBXI
                        ? TotalAdditionalCostWtihoutGSTInBXI
                        : "0.00"
                    }
                  />
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  <CommaSeprator
                    Price={
                      totaladditionalcostGstinbxi
                        ? totaladditionalcostGstinbxi
                        : "0.00"
                    }
                  />
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                  borderRight: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  <CommaSeprator
                    Price={
                      TotalAdditionalCostWtihoutGSTInBXI +
                      totaladditionalcostGstinbxi
                        ? TotalAdditionalCostWtihoutGSTInBXI +
                          totaladditionalcostGstinbxi
                        : "0.00"
                    }
                  />
                  &nbsp;
                </Typography>
              </TableCell>
            </TableRow>
            {/* Logistic Cost */}
            {OrderData?.data?.BuyerChoosedTransportation?.TransportationType ===
              "notransportation" ||
            OrderData?.data?.IsMedia ||
            OrderData?.data?.IsVoucher ? null : (
              <TableRow
                sx={{
                  height: "24px",
                }}
              >
                <TableCell
                  sx={{
                    p: 0,
                    border: "1px solid #CDCDCD",
                    borderLeft: "2px solid #CDCDCD",
                  }}
                  align="center"
                >
                  <Typography sx={{ ...TableBottomtext, textAlign: "left" }}>
                    Logistic Cost
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0,
                    border: "1px solid #CDCDCD",
                  }}
                  align="center"
                >
                  <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                    {" "}
                    {OrderData?.data?.INRDetails?.totalTransportationCost ? (
                      <CommaSeprator
                        Price={
                          OrderData?.data?.INRDetails?.totalTransportationCost
                        }
                      />
                    ) : (
                      "0.00"
                    )}
                    &nbsp;
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0,
                    border: "1px solid #CDCDCD",
                  }}
                  align="center"
                >
                  <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                    {" "}
                    {OrderData?.data?.INRDetails?.totalTransportationCostGST ? (
                      <CommaSeprator
                        Price={
                          OrderData?.data?.INRDetails
                            ?.totalTransportationCostGST
                        }
                      />
                    ) : (
                      "0.00"
                    )}
                    &nbsp;
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0,
                    border: "1px solid #CDCDCD",
                    borderRight: "2px solid #CDCDCD",
                  }}
                  align="center"
                >
                  <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                    {OrderData?.data?.INRDetails?.totalTransportationCost ? (
                      <CommaSeprator
                        Price={
                          OrderData?.data?.INRDetails?.totalTransportationCost +
                          OrderData?.data?.INRDetails
                            ?.totalTransportationCostGST
                        }
                      />
                    ) : (
                      "0.00"
                    )}
                    &nbsp;
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {/* BXI Commission */}
            <TableRow
              sx={{
                height: "24px",
              }}
            >
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                  borderLeft: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "left" }}>
                  BXI Commission
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  {OrderData?.data?.IsMedia ? (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.TotalBxiCommission
                          ? OrderData?.data?.INRDetails?.TotalBxiCommission
                          : 0.0
                      }
                    />
                  ) : (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.totalBxiCommission
                          ? OrderData?.data?.INRDetails?.totalBxiCommission
                          : 0.0
                      }
                    />
                  )}
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  {" "}
                  {OrderData?.data?.IsMedia ? (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.BxiCommisionGST
                          ? OrderData?.data?.INRDetails?.BxiCommisionGST
                          : 0.0
                      }
                    />
                  ) : (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.bxiCommisionGST
                          ? OrderData?.data?.INRDetails?.bxiCommisionGST
                          : 0.0
                      }
                    />
                  )}
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "1px solid #CDCDCD",
                  borderRight: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                  {OrderData?.data?.IsMedia ? (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.TotalBxiCommission
                          ? OrderData?.data?.INRDetails?.TotalBxiCommission +
                            OrderData?.data?.INRDetails?.BxiCommisionGST
                          : 0.0
                      }
                    />
                  ) : (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.totalBxiCommission
                          ? OrderData?.data?.INRDetails?.totalBxiCommission +
                            OrderData?.data?.INRDetails?.bxiCommisionGST
                          : 0.0
                      }
                    />
                  )}
                  &nbsp;
                </Typography>
              </TableCell>
            </TableRow>
            {/* Insurance Cost */}
            {OrderData?.data?.BuyerChoosedTransportation?.TransportationType ===
              "notransportation" ||
            OrderData?.data?.IsMedia ||
            OrderData?.data?.IsVoucher ? null : (
              <TableRow
                sx={{
                  height: "24px",
                }}
              >
                <TableCell
                  sx={{
                    p: 0,
                    border: "1px solid #CDCDCD",
                    borderLeft: "2px solid #CDCDCD",
                  }}
                  align="center"
                >
                  <Typography sx={{ ...TableBottomtext, textAlign: "left" }}>
                    Insurance Cost
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0,
                    border: "1px solid #CDCDCD",
                  }}
                  align="center"
                >
                  <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                    {OrderData?.data?.INRDetails?.InsuranceCost ? (
                      <>
                        {OrderData?.data?.INRDetails?.InsuranceCost !== 0 && (
                          <CommaSeprator
                            Price={OrderData?.data?.INRDetails?.InsuranceCost}
                          />
                        )}
                      </>
                    ) : (
                      "0.00"
                    )}
                    &nbsp;
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0,
                    border: "1px solid #CDCDCD",
                  }}
                  align="center"
                >
                  <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                    {OrderData?.data?.INRDetails?.InsuranceCostGstValue ? (
                      <>
                        {OrderData?.data?.INRDetails?.InsuranceCostGstValue !==
                          0 && (
                          <CommaSeprator
                            Price={
                              OrderData?.data?.INRDetails?.InsuranceCostGstValue
                            }
                          />
                        )}
                      </>
                    ) : (
                      "0.00"
                    )}
                    &nbsp;
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    p: 0,
                    border: "1px solid #CDCDCD",
                    borderRight: "2px solid #CDCDCD",
                  }}
                  align="center"
                >
                  <Typography sx={{ ...TableBottomtext, textAlign: "right" }}>
                    {OrderData?.data?.INRDetails?.InsuranceCost ? (
                      <>
                        {OrderData?.data?.INRDetails?.InsuranceCost !== 0 && (
                          <CommaSeprator
                            Price={
                              OrderData?.data?.INRDetails?.InsuranceCost +
                              OrderData?.data?.INRDetails?.InsuranceCostGstValue
                            }
                          />
                        )}
                      </>
                    ) : (
                      "0.00"
                    )}
                    &nbsp;
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            <TableRow
              sx={{
                height: "24px",
                backgroundColor: "#F7F7F7",
              }}
            >
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  bgcolor: "#2261A2",
                }}
                align="center"
              >
                <Typography
                  sx={{
                    ...TableBottomtext,
                    fontWeight: 600,
                    textAlign: "left",
                    color: "white",
                  }}
                >
                  &nbsp;Total
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography
                  sx={{
                    ...TableBottomtext,
                    fontWeight: 600,
                    textAlign: "right",
                  }}
                >
                  <CommaSeprator
                    Price={
                      OrderData?.data?.INRDetails?.WithoutGSTValue
                        ? OrderData?.data?.INRDetails?.WithoutGSTValue
                        : 0.0
                    }
                  />
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography
                  sx={{
                    ...TableBottomtext,
                    fontWeight: 600,
                    textAlign: "right",
                  }}
                >
                  <CommaSeprator
                    Price={totalTaxAmount ? totalTaxAmount : 0.0}
                  />
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  borderRight: "2px solid #CDCDCD",
                }}
                align="center"
              >
                <Typography
                  sx={{
                    ...TableBottomtext,
                    fontWeight: 600,
                    textAlign: "right",
                  }}
                >
                  <CommaSeprator
                    Price={
                      OrderData?.data?.INRDetails?.WithoutGSTValue +
                      totalTaxAmount
                        ? OrderData?.data?.INRDetails?.WithoutGSTValue +
                          totalTaxAmount
                        : 0.0
                    }
                  />
                  &nbsp;
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
        <br />
        <Table sx={{ maxWidth: "37%" }} aria-label="customized table">
          <TableHead>
            <TableRow
              sx={{
                height: "24px",
                backgroundColor: "#F7F7F7",
              }}
            >
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  bgcolor: "#2261A2",
                  //   borderRight: "none",
                }}
                align="center"
              >
                <Typography
                  sx={{
                    ...TableBottomtext,
                    fontWeight: 600,
                    textAlign: "left",
                    color: "white",
                  }}
                >
                  &nbsp;TDS @1%
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  //   borderLeft: "none",
                }}
                align="right"
              >
                <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                  <CommaSeprator
                    Price={
                      OrderData?.data?.INRDetails?.TDS
                        ? OrderData?.data?.INRDetails?.TDS
                        : "0.00"
                    }
                  />
                  &nbsp;
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <br />
        <br />
        <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow
              sx={{
                height: "24px",
              }}
            >
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  bgcolor: "#2261A2",
                  //   borderRight: "none",
                }}
                align="center"
                colSpan={3}
                rowSpan={1}
              >
                <Typography
                  sx={{
                    ...TableBottomtext,
                    fontWeight: 600,
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Payment Details
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                height: "24px",
              }}
            >
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  //   borderRight: "none",
                }}
                align="center"
                colSpan={1}
                rowSpan={1}
              >
                <Typography
                  sx={{
                    ...TableBottomtext,
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  From{" "}
                  {OrderData?.data?.InvoiceData?.BuyerDetails
                    ?.BuyerCompanyName ||
                    OrderData?.data?.PurchaseOrderData?.BuyerDetails
                      ?.BuyerCompanyName}{" "}
                  to{" "}
                  {OrderData?.data?.InvoiceData?.SellerDetails
                    ?.SellerCompanyName ||
                    OrderData?.data?.PurchaseOrderData?.SellerDetails
                      ?.SellerCompanyName}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  backgroundColor: "#F7F7F7",
                  //   borderLeft: "none",
                }}
                align="right"
                colSpan={1}
                rowSpan={1}
              >
                <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                  <CommaSeprator
                    Price={
                      TotalAdditionalCostWtihoutGSTInBXI +
                      TotalPriceWithoutGST -
                      OrderData?.data?.INRDetails?.TDS
                        ? TotalAdditionalCostWtihoutGSTInBXI +
                          TotalPriceWithoutGST -
                          OrderData?.data?.INRDetails?.TDS
                        : "0.00"
                    }
                  />
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  backgroundColor: "#F7F7F7",
                  //   borderLeft: "none",
                }}
                align="left"
                colSpan={1}
                rowSpan={1}
              >
                <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                  &nbsp;Coins
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                height: "24px",
              }}
            >
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  //   borderRight: "none",
                }}
                align="center"
                colSpan={1}
                rowSpan={1}
              >
                <Typography
                  sx={{
                    ...TableBottomtext,
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  From{" "}
                  {OrderData?.data?.InvoiceData?.BuyerDetails
                    ?.BuyerCompanyName ||
                    OrderData?.data?.PurchaseOrderData?.BuyerDetails
                      ?.BuyerCompanyName}{" "}
                  to{" "}
                  {OrderData?.data?.InvoiceData?.SellerDetails
                    ?.SellerCompanyName ||
                    OrderData?.data?.PurchaseOrderData?.SellerDetails
                      ?.SellerCompanyName}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  backgroundColor: "#F7F7F7",
                  //   borderLeft: "none",
                }}
                align="right"
                colSpan={1}
                rowSpan={1}
              >
                <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                  <CommaSeprator
                    Price={
                      OrderData?.data?.INRDetails?.TotalInrToPay
                        ? OrderData?.data?.INRDetails?.TotalInrToPay
                        : 0.0
                    }
                  />
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  backgroundColor: "#F7F7F7",
                  //   borderLeft: "none",
                }}
                align="left"
                colSpan={1}
                rowSpan={1}
              >
                <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                  &nbsp;Inr
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                height: "24px",
              }}
            >
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  //   borderRight: "none",
                }}
                align="center"
                colSpan={1}
                rowSpan={1}
              >
                <Typography
                  sx={{
                    ...TableBottomtext,
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  From{" "}
                  {OrderData?.data?.InvoiceData?.SellerDetails
                    ?.SellerCompanyName ||
                    OrderData?.data?.PurchaseOrderData?.SellerDetails
                      ?.SellerCompanyName}{" "}
                  to BXI
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  backgroundColor: "#F7F7F7",
                  //   borderLeft: "none",
                }}
                align="right"
                colSpan={1}
                rowSpan={1}
              >
                <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                  {OrderData?.data?.IsMedia ? (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.TotalBxiCommission +
                        OrderData?.data?.INRDetails?.BxiCommisionGST +
                        OrderData?.data?.INRDetails?.TDS
                      }
                    />
                  ) : (
                    <CommaSeprator
                      Price={
                        OrderData?.data?.INRDetails?.totalBxiCommission +
                        OrderData?.data?.INRDetails?.bxiCommisionGST +
                        OrderData?.data?.INRDetails?.TDS
                      }
                    />
                  )}
                  &nbsp;
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  p: 0,
                  border: "2px solid #CDCDCD",
                  backgroundColor: "#F7F7F7",
                  //   borderLeft: "none",
                }}
                align="left"
                colSpan={1}
                rowSpan={1}
              >
                <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                  &nbsp;Inr
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <br />
        <Box
          sx={{
            width: "100%",
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              ...ProductPriceTextStyle,
              fontSize: "12px",
              color: "#28282B",
            }}
          >
            <b>NOTE :-</b>
            <br />
            BXI Commission is always charged on Barter Coins
            <br />
            1% TDS is collected in INR on Total Gross Value.
            <br />
            <img
              src={StacsOfCoinIcon}
              style={{
                width: "12px",
                height: "auto",
              }}
            />{" "}
            - This symbol denotes Barter Coin that can be used only for
            transactions within the BXI marketplace.
            <br />₹ - This symbol denotes the Indian Rupee involved in the
            Transaction.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const OrderStatus = (props) => {
  const OrderData = props?.OrderData;
  const {
    data: UpdatedOrderData,
    isLoading: OrderLoading,
    error: OrderDataError,
    refetch: OrderDataRefetch,
  } = useGetOrderById(OrderData?._id);

  const ConfirmOrderDelivery = async () => {
    window.confirm("Are you sure you want to confirm the order delivery?");
    await axios
      .put(`order/update_order_with_any_data/${OrderData?._id}`, {
        BuyerOrderStatus: "success",
      })
      .then((res) => {
        OrderDataRefetch();
        window.location.reload();
        toast.success("Order Delivery Confirmation Successfully");
      })
      .catch((err) => {
        OrderDataRefetch();
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          mt: 2,
        }}
      >
        <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
          Order Status
        </Typography>
        {OrderData?.OrderStatus === "Delivered" ||
        OrderData?.MediaStatus === "Completed" ? (
          <img src={DeliveredIcon} style={{ width: "100px", height: "auto" }} />
        ) : (
          <Box
            sx={{
              width: "auto",
              bgcolor:
                OrderData?.OrderStatus === "Out For delivery" ||
                OrderData?.MediaStatus === "Cancelled" ||
                OrderData?.VoucherStatus === "InActive"
                  ? "#FFEFEF"
                  : OrderData?.OrderStatus === "Ready To Ship" ||
                    OrderData?.OrderStatus === "Progress" ||
                    OrderData?.OrderStatus === "In Transit" ||
                    OrderData?.MediaStatus === "Executed" ||
                    OrderData?.OrderStatus === "Reached Nearest hub"
                  ? "#FFF1E5"
                  : OrderData?.OrderStatus === "Delivered" ||
                    OrderData?.MediaStatus === "Completed" ||
                    OrderData?.MediaStatus === "Creative Approved" ||
                    OrderData?.OrderStatus === "Shipped" ||
                    OrderData?.VoucherStatus === "Active"
                  ? "#D9FFE9"
                  : "#FFF380",
              px: "10px",
              py: "5px",
              borderRadius: "10px",
              fontWeight: 500,
              color:
                OrderData?.OrderStatus === "Out For delivery" ||
                OrderData?.MediaStatus === "Cancelled" ||
                OrderData?.VoucherStatus === "InActive"
                  ? "#EB5757"
                  : OrderData?.OrderStatus === "Ready To Ship" ||
                    OrderData?.OrderStatus === "Progress" ||
                    OrderData?.OrderStatus === "In Transit" ||
                    OrderData?.MediaStatus === "Executed" ||
                    OrderData?.OrderStatus === "Reached Nearest hub"
                  ? "#F2994A"
                  : OrderData?.OrderStatus === "Delivered" ||
                    OrderData?.MediaStatus === "Completed" ||
                    OrderData?.MediaStatus === "Creative Approved" ||
                    OrderData?.OrderStatus === "Shipped" ||
                    OrderData?.VoucherStatus === "Active"
                  ? "#27AE60"
                  : "black",
            }}
          >
            <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
              {OrderData?.OrderStatus
                ? OrderData?.OrderStatus
                : OrderData?.IsVoucher && !OrderData?.VoucherStatus
                ? "InActive"
                : OrderData?.MediaStatus ||
                  OrderData?.VoucherStatus ||
                  "Pending"}
            </Typography>
          </Box>
        )}
      </Box>
      <br />
      {OrderData?.IsMedia ? (
        <>
          {OrderData?.BuyerOrderStatus ? (
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                color: "green",
                background: "#E5F9E5",
                padding: "5px",
                width: "150px",
                borderRadius: "20px",
                textAlign: "center",
              }}
            >
              Confirmed
            </Typography>
          ) : (
            <>
              {OrderData?.MediaStatus &&
              OrderData?.MediaStatus === "Completed" ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      }}
                    >
                      Confirm Order Delivery
                    </Typography>

                    <Button
                      variant="contained"
                      sx={{
                        textDecoration: "none",
                        mt: "0px",
                        display: "flex",
                        justifyContent: "flex-end",
                        borderRadius: "15px",
                      }}
                      onClick={ConfirmOrderDelivery}
                    >
                      <Typography
                        style={{
                          fontWeight: 200,
                          width: "50px",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        Confirm
                      </Typography>
                    </Button>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "black",
                      padding: "5px",
                      borderRadius: "20px",
                      textAlign: "center",
                      mt: "20px",
                    }}
                  >
                    Important - Read Before Confirming: On Confirmation, we
                    consider your Media was executed and payments will be
                    released to Seller Refunds are not possible after release.
                    SOS - Connect with Seller/ Raise Triparty Chat
                  </Typography>
                </>
              ) : null}
            </>
          )}
        </>
      ) : OrderData?.IsVoucher ? (
        <>
          {OrderData?.VoucherStatus && OrderData?.VoucherStatus === "Active" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                  Confirm Order Delivery
                </Typography>
                {OrderData?.BuyerOrderStatus === "success" ? (
                  <img
                    src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/ConfirmedIcon.jpg`}
                    style={{
                      width: "100px",
                      height: "auto",
                    }}
                  />
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      textDecoration: "none",
                      mt: "0px",
                      width: "200px",
                      height: "35px",
                      borderRadius: "15px",
                    }}
                    onClick={ConfirmOrderDelivery}
                  >
                    <Typography
                      style={{
                        fontWeight: 200,
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      Confirm
                    </Typography>
                  </Button>
                )}
              </Box>
              {OrderData?.BuyerOrderStatus !== "success" && (
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    color: "black",
                    padding: "5px",
                    borderRadius: "20px",
                    textAlign: "center",
                    mt: "20px",
                  }}
                >
                  Important - Read Before Confirming: Carefully inspect and
                  ensure the voucher that you received is activated by seller.
                  On your confirmation, your payment held in Escrow account will
                  be immediately released to the Seller. NO refunds are possible
                  after your confirmation.
                </Typography>
              )}
            </>
          ) : null}
        </>
      ) : (
        <>
          {(OrderData?.OrderStatus && OrderData?.OrderStatus === "Delivered") ||
          (OrderData?.MediaStatus && OrderData?.MediaStatus === "Completed") ||
          OrderData?.SellerOrderStatus === "success" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontFamily: "Poppins", fontSize: "14px" }}>
                  Confirm Order Delivery
                </Typography>
                {OrderData?.BuyerOrderStatus === "success" ? (
                  <img
                    src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/ConfirmedIcon.jpg`}
                    style={{
                      width: "100px",
                      height: "auto",
                    }}
                  />
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      textDecoration: "none",
                      mt: "0px",
                      width: "200px",
                      height: "35px",
                      borderRadius: "15px",
                    }}
                    onClick={ConfirmOrderDelivery}
                  >
                    <Typography
                      style={{
                        fontWeight: 200,
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      Confirm
                    </Typography>
                  </Button>
                )}
              </Box>
              {OrderData?.BuyerOrderStatus !== "success" && (
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    color: "black",
                    padding: "5px",
                    borderRadius: "20px",
                    textAlign: "center",
                    mt: "20px",
                  }}
                >
                  Important - Read Before Confirming: Carefully inspect and
                  ensure the products received are in Perfect condition, with No
                  Damage or Missing items. On your confirmation, your payment
                  held in Escrow account will be immediately released to the
                  Seller. NO refunds are possible after your confirmation.
                </Typography>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

const InvoiceList = (props) => {
  const navigate = useNavigate();
  const OrderData = props?.OrderData;
  return (
    <>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "20px",
          mb: "20px",
        }}
      >
        Invoices:
      </Typography>

      {OrderData?.data?.PurchaseOrderData &&
      !OrderData?.data?.IsVoucher &&
      OrderData?.data?.PurchaseOrderData?.BuyerOrderStatus === "Accepted" ? (
        <>
          <Box
            sx={{
              width: "100px",
              mt: 1,
            }}
          >
            <span
              style={{
                display: "flex",
                whiteSpace: "nowrap",
                fontSize: "14px",
                fontWeight: 700,
                color: "#27272A",
                fontFamily: "Inter",
              }}
            >
              <img src={Purches} style={{ height: "32px", width: "32px" }} />
              &nbsp; Purchase Order{" "}
            </span>

            <Button
              variant="outlined"
              sx={{
                height: "21px",
                width: "15px",
                ml: 4,
              }}
              startIcon={<RemoveRedEyeIcon />}
              onClick={() => {
                navigate(
                  `/home/purchaseorder/${OrderData?.data?.PurchaseOrderData?._id}`
                );
              }}
            >
              view
            </Button>
          </Box>
          <hr />
        </>
      ) : null}

      <Box>
        {OrderData?.data?.InvoiceData && !OrderData?.data?.IsVoucher ? (
          <>
            <Box
              sx={{
                width: "100px",
                mt: 1,
              }}
            >
              <span
                style={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#27272A",
                  fontFamily: "Inter",
                }}
              >
                <img src={Perfoma} style={{ height: "32px", width: "32px" }} />
                &nbsp; Proforma Invoice{" "}
              </span>

              <Button
                variant="outlined"
                sx={{
                  height: "21px",
                  width: "21px",
                  ml: 4,
                }}
                startIcon={<RemoveRedEyeIcon />}
                onClick={() => {
                  navigate(
                    `/home/proformaInvoice/${OrderData?.data?.InvoiceData?.OrderSummeryId}`
                  );
                }}
              >
                view
              </Button>
            </Box>
            <hr />
          </>
        ) : null}

        {OrderData?.data?.TaxInvoice ? (
          <>
            <Box
              sx={{
                width: "100px",
                mt: 1,
              }}
            >
              <span
                style={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#27272A",
                  fontFamily: "Inter",
                }}
              >
                <img src={Tax} style={{ height: "32px", width: "32px" }} />
                &nbsp; Tax Invoice
              </span>

              <Button
                variant="outlined"
                sx={{
                  height: "21px",
                  width: "21px",
                  ml: 4,
                }}
                onClick={() => {
                  if (OrderData?.data?.IsVoucher) {
                    navigate(`/home/vouchertaxinvoice/${OrderData?.data?._id}`);
                  } else if (OrderData?.data?.IsMedia) {
                    navigate(`/home/mediataxinvoice/${OrderData?.data?._id}`);
                  } else {
                    navigate(`/home/taxinvoice/${OrderData?.data?._id}`);
                  }
                }}
                startIcon={<RemoveRedEyeIcon />}
              >
                view
              </Button>
            </Box>
            <hr />
          </>
        ) : null}
        {OrderData?.data?.shippingData ? (
          <>
            <Box
              sx={{
                width: "100px",
                mt: 1,
              }}
            >
              <span
                style={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#27272A",
                  fontFamily: "Inter",
                }}
              >
                <img src={Tax} style={{ height: "32px", width: "32px" }} />
                &nbsp; Shipping Invoice
              </span>

              {!OrderData?.data?.IsVoucher && !OrderData?.data?.IsMedia ? (
                <Button
                  variant="outlined"
                  sx={{
                    height: "21px",
                    width: "21px",
                    ml: 4,
                  }}
                  onClick={() => {
                    navigate(`/home/shippinginvoice/${OrderData?.data?._id}`);
                  }}
                  startIcon={<RemoveRedEyeIcon />}
                >
                  view
                </Button>
              ) : null}
            </Box>
            <hr />
          </>
        ) : null}

        {OrderData?.data?.IsCommisionInvoiceCreated ? (
          <>
            <Box
              sx={{
                width: "100px",
                mt: 1,
              }}
            >
              <span
                style={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#27272A",
                  fontFamily: "Inter",
                }}
              >
                <img src={bxiLogo} style={{ height: "32px", width: "32px" }} />
                &nbsp; BXI Commission{" "}
              </span>

              <Button
                variant="outlined"
                sx={{
                  height: "21px",
                  width: "21px",
                  ml: 4,
                }}
                onClick={() => {
                  navigate(
                    `/home/commissionByOrderId/${OrderData?.data?._id}`,
                    {
                      state: {
                        OrderId: OrderData?.data?.OrderId,
                        OrderData: OrderData?.data,
                      },
                    }
                  );
                }}
                startIcon={<RemoveRedEyeIcon />}
              >
                view
              </Button>
            </Box>
            <hr />
          </>
        ) : null}

        {OrderData?.data?.ShippingInvoice ? (
          <>
            <Box
              sx={{
                width: "100px",
                mt: 1,
              }}
            >
              <span
                style={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#27272A",
                  fontFamily: "Inter",
                }}
              >
                <img src={Truck} style={{ height: "32px", width: "32px" }} />
                &nbsp; Shipping Invoice{" "}
              </span>

              <Button
                variant="outlined"
                sx={{
                  height: "21px",
                  width: "21px",
                }}
                startIcon={<RemoveRedEyeIcon />}
              >
                view
              </Button>
            </Box>
            <hr />
          </>
        ) : null}
      </Box>
    </>
  );
};

const AddressInformation = (props) => {
  const OrderData = props?.OrderData;
  return OrderData?.data?.escrowPayment &&
    OrderData?.data?.escrowPayment?.success === "true" ? (
    props.ViewingType === "seller" ? (
      <Box
        sx={{
          backgroundColor: "white",
          mt: 3,
          height: "100%",
          width: "810px",
          borderRadius: "10px",
          display: "flex",
          marginRight: "150px",
          marginLeft: "-20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column ",
            ml: 2,
            bordrRadius: "10px",
          }}
        >
          <br />
          <Typography
            sx={{
              color: "#6B7A99",
              fontSize: "15px",
              fontWeight: 600,
              fontFamily: "Poppins",
              mt: 2,
              bordrRadius: "10px",
            }}
          >
            Buyer Details
          </Typography>
          <br />
          <br />

          <span
            style={{
              color: "#6B7A99",
              fontSize: "12px",
              fontWeight: 500,
              fontFamily: "Poppins",
              display: "flex",
            }}
          >
            <img
              src={
                OrderData?.data?.PurchaseOrderData?.BuyerDetails
                  ?.BuyerCompanyLogo
              }
              style={{ height: "30px", width: "30px" }}
            />
            &nbsp;{" "}
            {OrderData?.data?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyName}
          </span>
          <Typography
            sx={{
              color: "#6B7A99",
              fontSize: "10px",
              mt: 1,
              fontWeight: 400,
              fontFamily: "Poppins",
            }}
          >
            <PhoneIcon /> &nbsp;&nbsp;{" "}
            {
              OrderData?.data?.PurchaseOrderData?.BuyerDetails
                ?.BuyerCompanyContact
            }
          </Typography>

          <Typography
            sx={{
              color: "#6B7A99",
              fontSize: "10px",
              fontWeight: 400,
              fontFamily: "Poppins",
            }}
          >
            <EmailIcon />
            &nbsp;&nbsp;{" "}
            {
              OrderData?.data?.PurchaseOrderData?.BuyerDetails
                ?.BuyerCompanyEmail
            }
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            ml: 5,
            justifyContent: "flex-end",
          }}
        >
          <br />
          <Typography
            sx={{
              color: "#6B7A99",
              fontSize: "15px",
              fontWeight: 600,
              fontFamily: "Poppins",
              mt: 2,
            }}
          >
            Billing Address
          </Typography>
          <br />
          <br />
          <Typography
            sx={{
              color: "#6B7A99",
              fontSize: "10px",
              fontWeight: 400,
              fontFamily: "Poppins",
              mt: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "inline-block",
              maxWidth: "80%",
            }}
          >
            {
              OrderData?.data?.PurchaseOrderData?.BuyerDetails?.Address
                ?.AddressLine
            }
          </Typography>

          <br />
        </Box>
      </Box>
    ) : (
      <Box
        sx={{
          backgroundColor: "white",
          mt: 3,
          height: "100%",
          width: "810px",
          borderRadius: "10px",
          display: "flex",
          marginRight: "150px",
          marginLeft: "-20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column ",
            ml: 2,
            bordrRadius: "10px",
          }}
        >
          <br />
          <Typography
            sx={{
              color: "#6B7A99",
              fontSize: "15px",
              fontWeight: 600,
              fontFamily: "Poppins",
              mt: 2,
              bordrRadius: "10px",
            }}
          >
            Seller Details
          </Typography>
          <br />
          <br />

          <span
            style={{
              color: "#6B7A99",
              fontSize: "12px",
              fontWeight: 500,
              fontFamily: "Poppins",
              display: "flex",
            }}
          >
            <img
              src={
                OrderData?.data?.PurchaseOrderData?.SellerDetails
                  ?.SellerCompanyLogo
              }
              style={{ height: "30px", width: "30px" }}
            />
            &nbsp;{" "}
            {
              OrderData?.data?.PurchaseOrderData?.SellerDetails
                ?.SellerCompanyName
            }
          </span>
          <Typography
            sx={{
              color: "#6B7A99",
              fontSize: "10px",
              mt: 1,
              fontWeight: 400,
              fontFamily: "Poppins",
            }}
          >
            <PhoneIcon /> &nbsp;&nbsp;{" "}
            {
              OrderData?.data?.PurchaseOrderData?.SellerDetails
                ?.SellerCompanyContact
            }
          </Typography>

          <Typography
            sx={{
              color: "#6B7A99",
              fontSize: "10px",
              fontWeight: 400,
              fontFamily: "Poppins",
            }}
          >
            <EmailIcon />
            &nbsp;&nbsp;{" "}
            {
              OrderData?.data?.PurchaseOrderData?.SellerDetails
                ?.SellerCompanyEmail
            }
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            ml: 5,
            justifyContent: "flex-end",
          }}
        >
          <br />
          <Typography
            sx={{
              color: "#6B7A99",
              fontSize: "15px",
              fontWeight: 600,
              fontFamily: "Poppins",
              mt: 2,
            }}
          >
            Billing Address
          </Typography>
          <br />
          <br />
          <Typography
            sx={{
              color: "#6B7A99",
              fontSize: "10px",
              fontWeight: 400,
              fontFamily: "Poppins",
              mt: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "inline-block",
              maxWidth: "80%",
            }}
          >
            {
              OrderData?.data?.PurchaseOrderData?.SellerDetails?.Address
                ?.AddressLine
            }
          </Typography>

          <br />
        </Box>
      </Box>
    )
  ) : null;
};

export {
  BarterCoinDetails,
  ProductDetail,
  OrderStatus,
  InvoiceList,
  AddressInformation,
};

const ProductNameTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "18px",
    lg: "18px",
    md: "17px",
    sm: "13px",
    xs: "13px",
  },
  lineHeight: "25px",
  color: "#6B7A99",
  textAlign: "left",
};

const ProductPriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  color: "rgba(107, 122, 153, 0.5)",
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "14px",
    sm: "12px",
    xs: "12px",
  },
  lineHeight: {
    xl: "15px",
    lg: "15px",
    md: "19px",
    sm: "18px",
    xs: "17px",
  },
  alignContent: "center",
  alignItems: "center",
};

const ProductMetaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: {
    xl: "12px",
    lg: "12px",
    md: "11px",
    sm: "9px",
    xs: "9px",
  },
  lineHeight: {
    xl: "18px",
    lg: "18px",
    md: "15px",
    sm: "14px",
    xs: "14px",
  },
  color: "#858585",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const tabText = {
  fontSize: {
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1.1rem",
    sm: "0.8rem",
    xs: "0.8rem",
  },
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  textAlign: "center",
  color: "#6B7A99",
  textTransform: "none",
  width: { xl: "45%", lg: "50%", md: "50%", sm: "80%", xs: "95%" },
  mx: "auto",
  lineHeight: "2rem",
  mt: 2,
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#525252",
  opacity: 1,
};
