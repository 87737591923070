import React, { useState } from "react";
import { Box, Button, Collapse, Paper, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";

const BuyerSidebarRoutes = ({ sendDataToParent }) => {
  const [expanded, setExpanded] = useState(false);
  const [tradeManagementExpanded, setTradeManagementExpanded] = useState(false);

  const sendData = (props) => {
    sendDataToParent(props);
  };

  const RouteArray = [
    {
      id: 1,
      name: "Wallet Dashboard",
      linkUrl: "/home/dashboard",
    },
    {
      id: 2,
      name: "My Orders",
      linkUrl: "/home/myorderlist",
    },
    {
      id: 3,
      name: "Trade Management",
      linkUrl: "/home/myorderlist",
      tradeArray: [
        {
          id: 1,
          name: "Product PO",
          linkUrl: "/home/purchaseorderlist",
        },
        {
          id: 2,
          name: "Product PI",
          linkUrl: "/home/productpilist",
        },
        {
          id: 3,
          name: "Media PO",
          linkUrl: "/home/mediapurchaseorder",
        },
        {
          id: 4,
          name: "Media PI",
          linkUrl: "/home/mediapilist",
        },
        {
          id: 5,
          name: "Voucher Trade",
          linkUrl: "/home/voucherorderlist",
        },
      ],
    },
    {
      id: 4,
      name: "Wishlist & Wantlist",
      linkUrl: "/home/wishlist",
    },
    {
      id: 5,
      name: "Track Your Purchases",
      linkUrl: "/home/trackorders",
    },
  ];

  const currentPath = window.location.pathname;

  const clearLocalStorageForBreadcrumb = () => {
    localStorage.removeItem("myArray");
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        // maxHeight: "80vh",
        minHeight: "80vh",
        overflowY: "hidden",
        width: "100%",
        mt: "40px",
      }}
      elevation={0}
    >
      {RouteArray.map((res) => {
        return (
          <React.Fragment key={res.id}>
            {res?.name !== "Trade Management" ? (
              <Link
                to={res.linkUrl}
                onClick={() => {
                  sendData(false);
                  clearLocalStorageForBreadcrumb();
                }}
                style={{
                  textDecoration: "none",
                  width: "100%",
                  minWidth: "280px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ...SidebarButtonStyle,
                    background:
                      currentPath === res.linkUrl ? "#445FD2" : "transparent",
                    color: currentPath === res.linkUrl ? "#fff" : "#1D3163",
                  }}
                >
                  {res.name}
                </Box>
              </Link>
            ) : (
              <>
                <Button
                  sx={{
                    ...SidebarButtonStyle,
                    background: res.tradeArray.some(
                      (item) => item.linkUrl === currentPath
                    )
                      ? "#445FD2"
                      : "#fff",
                    color: res.tradeArray.some(
                      (item) => item.linkUrl === currentPath
                    )
                      ? "#fff"
                      : "#1D3163",
                    textTransform: "none",
                    width: "100%",
                  }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={() =>
                    setTradeManagementExpanded(!tradeManagementExpanded)
                  }
                >
                  Trade Management
                </Button>
                <Collapse
                  in={tradeManagementExpanded}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    margin: 0,
                    height: "100%",
                  }}
                >
                  <Box sx={{ height: "100%" }}>
                    {res.tradeArray.map((tradeItem) => (
                      <Link
                        key={tradeItem.id}
                        to={tradeItem.linkUrl}
                        onClick={() => {
                          sendData(false);
                          clearLocalStorageForBreadcrumb();
                        }}
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          minWidth: "280px",
                        }}
                      >
                        <Box
                          sx={{
                            ...SidebarButtonStyle,
                            background:
                              currentPath === tradeItem.linkUrl
                                ? "#445FD2"
                                : "transparent",
                            color:
                              currentPath === tradeItem.linkUrl
                                ? "#fff"
                                : "#1D3163",
                          }}
                        >
                          {tradeItem.name}
                        </Box>
                      </Link>
                    ))}
                  </Box>
                </Collapse>
              </>
            )}
          </React.Fragment>
        );
      })}
    </Paper>
  );
};

export default BuyerSidebarRoutes;

const SidebarButtonStyle = {
  ":hover": {
    background: "#fff",
    border: "1px solid #445FD2",
  },
  border: "1px solid #fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "15px",
    lg: "15px",
    md: "15px",
    sm: "14px",
    xs: "14px",
  },
  width: "95%",
  maxWidth: "250px",

  height: "42px",
  borderRadius: "7px",
  cursor: "pointer",
  "&:hover": {
    color: "#445FD2",
  },
  fontWeight: "500",
  display: "flex",
  justifyContent: "flex-start",
  gap: "17px",
  alignContent: "center",
  alignItems: "center",
  paddingLeft: "20px",
};
