import React, { useEffect, useRef, useState } from "react";

import { Box, Typography, Button } from "@mui/material";
import axios from "axios";
import UploadtoCloud from "../../assets/UploadtoCloud.svg";
import { toast } from "react-toastify";
import CameraIcon from "../../assets/Images/profile/CameraIcon.svg";
import DeleteIcon from "../../assets/Images/CommonImages/DeleteIcon.svg";

const MediaContentUpload = (props) => {
  const [AvatarIcon, setAvatarIcon] = useState(null);
  const [files, setFiles] = useState();

  // console.log("==>files", files);
  const inputRef = useRef(null);

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChangeFiles = (event) => {
    setFiles(event.target.files);
  };

  const uploadAvatar = async () => {
    if (files === null) {
      toast.error("Please Add File", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      try {
        const fileData = new FormData();
        fileData.append("file", files[0]);
        fileData.append("productId", props.productId);
        // console.log("fileData", files);

        const response = await axios.post(
          `purchase/update_media_ordersummary/${props.id}`,
          fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("File Uploaded", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        props.sendDataToParent(true);
        // console.log("Upload response:", response.data);
        // Do something with the response, e.g., update UI or state
      } catch (error) {
        console.log("mediaerror", error);
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        onClick={() => inputRef.current.click()}
        sx={{ ...ImageSelectBoxStyle, cursor: "pointer" }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <Box
          sx={{
            display: "grid",
            width: "60%",
            mx: " auto",
          }}
        >
          <Box sx={DisplayGridStyle}>
            <Box component="img" src={UploadtoCloud} sx={ImageBox} />
            <Typography
              sx={{
                ...TextStyle,
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                "&:hover": {
                  color: "#445FD2",
                },
                textalign: "center",
              }}
            >
              Drag & Drop upload or{" "}
              <span
                style={{
                  color: "#445FD2",
                  fontWeight: 500,
                  marginLeft: "3px",
                  marginRight: "3px ",
                }}
              >
                {"  "}
                browse{"  "}
              </span>{" "}
              to choose a file
            </Typography>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center", mt: "1%" }}>
          <Typography
            sx={{
              ...TextStyle,
              color: "#445FD2",
            }}
          >
            Mandatory Photos : Front View, Back View, Close Fabric View, Model
            Wearing View
          </Typography>
        </Box>
        <input
          type="file"
          onChange={handleChangeFiles}
          hidden
          accept=".png,.pdf,.mp4,.jpeg,.gif"
          ref={inputRef}
        />
      </Box>
      {files ? (
        <>
          <Box
            sx={{
              width: "40%",
              my: "1%",
            }}
          >
            {Array?.from(files)?.map((file, idx) => {
              return (
                <Box
                  key={idx}
                  sx={{
                    bgcolor: "white",
                  }}
                >
                  <Box sx={ImageMapBox}>
                    <Typography key={idx} sx={TextStyle}>
                      {file?.name}
                    </Typography>
                    <Box
                      component="img"
                      src={DeleteIcon}
                      sx={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFiles();
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Button onClick={uploadAvatar} sx={ButtonStyleForUpload}>
            Upload
          </Button>
        </>
      ) : (
        " "
      )}
    </Box>
  );
};

export default MediaContentUpload;

const ImageSelectBoxStyle = {
  border: "2px dashed #445FD2",
  background: "#fff",
  width: "40%",
  maxWidth: "670px",
  p: "1%",
  mx: "auto",
  position: "relative",
  mt: "1%",
};

const DisplayGridStyle = {
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  tetxAlign: "center",
};

const ImageBox = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  left: "5%",
  textalign: "center",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textTransform: "none",
  color: "#6B7A99",
};

const MulishFontTextStyle = {
  fontFamily: "Mulish",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "8px",
  color: "#676767",
  textAlign: "center",
};

const ImageMapBox = {
  background: "#fff",
  border: "1px solid green",
  borderRadius: "9px",
  height: "42px",
  width: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  my: "10px",
  px: "8px",
};

const ButtonStyleForUpload = {
  width: "100%",
  height: "30px",
  maxWidth: "100px",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  curso0r: "pointer",
  my: 1,
  border: "1px solid #fff",
  "&:hover": {
    color: "#2261A2",
    border: "1px solid #2261A2",
  },
};
