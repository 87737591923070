import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useGetSellerSidePendingOrder } from "../../../Hooks/OrderActions/useGetSellerSidePendingOrder";
import AcceptedIcon from "../../../assets/Images/CommonImages/Accepted.png";
import DownIcon from "../../../assets/Images/CommonImages/Down.png";
import GoLeft from "../../../assets/Images/CommonImages/GoLeft.png";
import PendingIcon from "../../../assets/Images/CommonImages/Pending.png";
import RejectedIcon from "../../../assets/Images/CommonImages/Rejected.png";
import stackofcoins from "../../../assets/Stack of Coins.svg";
import CommaSeprator from "../../../components/CommaSeprator";
import CompanyName from "../../../components/CompanyName";
import BreadCrumbHeader from "../../../components/Header/BreadCrumbHeader";
import Nodatafound from "../../../components/NoDataFound/NoDataFound";
import sendEvents from "../../../utils/sendEvents";
import ImageAndProductCarousel from "../../../components/Carousel/ImageAndProductCarousel";

function PurchaseOrderList() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "9px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "9px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(null);
  const [AllOrders, setAllOrder] = useState();

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const topElementRef = useRef(null);

  useEffect(() => {
    if (topElementRef.current) {
      topElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  const {
    data: purchaseOrderDataSeller,
    isLoading: purchaseOrderLoadingSellerPending,
    error: purchaseOrderErrorSellerPending,
    refetch: refetchSellerPending,
  } = useGetSellerSidePendingOrder(currentPage);

  const fetchPoListData = async () => {
    try {
      await axios
        .get(`order/get_all_purchase_order_by_seller?page=${currentPage}`, {
          withCredentials: true,
        })
        .then((res) => {
          setAllOrder(res?.data);
          setInitialPaginationCount(res?.data?.finalCount);
        });
    } catch (error) {}
  };
  useEffect(() => {
    setCurrentPage(1);
    refetchSellerPending({ page: 1 });
    fetchPoListData();
  }, []);

  useEffect(() => {
    fetchPoListData();
  }, [currentPage]);

  useEffect(() => {
    setInitialPaginationCount(AllOrders?.finalCount);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Paper
      ref={topElementRef}
      sx={{
        boxShadow: "none",
        background: "transparent",
        width: "100%",
        mx: "auto",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"PO Received against Sales"} />

      <Grid
        container
        sx={{
          background: "#FFFFFF",
          padding: "1rem",
          borderRadius: "20px",
          minHeight: "70vh",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: 1,
          position: "relative",
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            height: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "160px",
            }}
          >
            <Button
              onClick={() => {
                navigate("/home");
                sendEvents("Navigate to home");
              }}
              sx={{
                background: "transparent",
                boxShadow: "none",
              }}
            >
              <img src={GoLeft} width="22px" />
            </Button>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            sx={{
              height: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "99%",
            }}
          >
            <Grid
              item
              xl={0.5}
              lg={0.5}
              md={0.5}
              sm={0.5}
              xs={0.5}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography sx={tableheading}>No</Typography>
            </Grid>
            <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
              <Typography sx={tableheading}>Product Name</Typography>
            </Grid>

            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography sx={tableheading}>Order Number</Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Total Products
              </Typography>
            </Grid>
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
              <Typography align="center" sx={tableheading}>
                Barter Coins
              </Typography>
            </Grid>
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
              <Typography align="center" sx={tableheading}>
                Order date
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Seller Status
              </Typography>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
              <Typography align="center" sx={tableheading}>
                Action
              </Typography>
            </Grid>
          </Grid>

          {AllOrders?.OrderData?.length > 0 ? (
            AllOrders?.OrderData?.map((data, idx) => {
              let totalPrice = 0;
              data?.ProductData?.map((data) => {
                let timesec = data?.BoughtSeconds;
                totalPrice +=
                  data?.ProductTypeName === "Media"
                    ? data.DiscountedPrice *
                      data.ProductQuantity *
                      timesec *
                      data?.TimelineToBought
                    : data.DiscountedPrice * data.ProductQuantity;

                return totalPrice;
              });

              function convertDate(inputFormat) {
                function pad(s) {
                  return s < 10 ? "0" + s : s;
                }
                var d = new Date(inputFormat);
                return [
                  pad(d.getDate()),
                  pad(d.getMonth() + 1),
                  d.getFullYear(),
                ].join("/");
              }

              if (purchaseOrderErrorSellerPending) {
                return (
                  <Skeleton variant="rectangular" width={"100%"} height={35} />
                );
              } else {
                return (
                  <Accordion
                    sx={{
                      p: 0,
                      boxShadow: "none",
                      border: "none",
                      borderBottom: "1px solid #F5F5F5",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <img src={DownIcon} style={{ width: "9px" }} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ p: 0 }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xl={0.5}
                          lg={0.5}
                          md={0.5}
                          sm={0.5}
                          xs={0.5}
                          sx={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Typography sx={TableBodyTextStyling}>
                            {" "}
                            {idx + 1}
                          </Typography>
                        </Grid>
                        <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                          <ImageAndProductCarousel
                            carouselData={data?.ProductData}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Typography sx={TableBodyTextStyling}>
                            {data?.OrderDetails?.OrderId}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Typography sx={TableBodyTextStyling}>
                            {data?.ProductData?.length}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBodyTextStyling,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <img
                              src={stackofcoins}
                              alt="rupieicon"
                              style={{
                                width: "15px",
                                height: "auto",
                              }}
                            />
                            <CommaSeprator Price={totalPrice} />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={1.5}
                          lg={1.5}
                          md={1.5}
                          sm={1.5}
                          xs={1.5}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Typography sx={TableBodyTextStyling}>
                            {convertDate(data?.PoDate)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            sx={{
                              ...TableBodyTextStyling,
                              fontSize: "12px",
                              color:
                                data?.SellerOrderStatus === "Pending"
                                  ? "#FFB600"
                                  : data?.SellerOrderStatus === "Accepted"
                                  ? "#118A2C"
                                  : "#FF0000",

                              mx: "auto",
                            }}
                          >
                            {data?.SellerOrderStatus}
                          </Typography>
                          {data?.SellerOrderStatus === "Pending" ? (
                            <Box
                              component="img"
                              src={PendingIcon}
                              sx={{ width: "13px", height: "13px" }}
                            />
                          ) : data?.SellerOrderStatus === "Accepted" ? (
                            <Box
                              component="img"
                              src={AcceptedIcon}
                              sx={{ width: "13px", height: "13px" }}
                            />
                          ) : (
                            <Box
                              component="img"
                              src={RejectedIcon}
                              sx={{ width: "13px", height: "13px" }}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xl={3}
                          lg={3}
                          md={3}
                          sm={3}
                          xs={3}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Box
                            onClick={() => {
                              navigate(
                                `/home/sellerdetailedordersummary/${data?._id}`
                              );
                              sendEvents("Click view");
                            }}
                            sx={{
                              cursor: "pointer",
                              width: "100px",
                              mx: "auto",
                              height: "30px",
                              background: "#B64CA0",
                              borderRadius: "4.39877px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                color: "#fff",
                              }}
                            >
                              View
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          p: 0,
                          m: 0,
                          width: "100%",
                          maxWidth: "500px",
                          mb: 1,
                        }}
                      >
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <CompanyName
                            CompanyId={data.SellerCompanyId}
                            CompanyName={data?.SellerDetails?.SellerCompanyName}
                            CompanyTypeName={
                              data?.SellerDetails?.CompanyTypeName
                            }
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              }
            })
          ) : (
            <Nodatafound />
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
        <Stack spacing={2}>
          <StyledPagination
            count={initialPaginationCount}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
}

export default PurchaseOrderList;

const tableheading = {
  fontFamily: "Poppins",
  color: "#7E8BA6",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  textTransform: "Capitalize",
};

const TableBodyTextStyling = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#1B212D",
};
