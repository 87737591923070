import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import Radio from "@mui/material/Radio";
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as Styles from "../../components/common/Styled/Styles.js";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";

// Get All Roles
const useGetAllRoles = () =>
  useQuery("all-roles", async () => {
    return (await axios.get("/api/v1/roles/all-roles")).data;
  });

export const AddMember = () => {
  let navigate = useNavigate();
  const [passField, setPassField] = useState(true);
  const [rightsarray, setRightsarray] = useState([]);
  const [tokenlimit, setTokenLimit] = useState("custom");
  const [showPassword, setShowPassword] = useState(false);
  const [confrimPassField, setConfrimPassField] = useState(true);
  const [productRights, setProductRights] = useState("viewonly");
  const [roleAndPermission, setRoleAndPermission] = useState("Yes");
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  // Switch password see or hide
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const handleShowPasswordClickConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const ProductRightsChange = (event) => {
    setProductRights(event.target.value);
  };

  const RoleAndPermissionChange = (event) => {
    setRoleAndPermission(event.target.value);
  };
  const TokenLimitChange = (event) => {
    if (event.target.value === "custom") {
      setTokenLimit("custom");
    } else {
      setTokenLimit(event.target.value);
    }
  };

  async function handleRightsArray(rights) {
    if (rightsarray.includes(rights)) {
      const filteredArray = rightsarray.filter((item) => item !== rights);
      setRightsarray(filteredArray);
      return;
    }
    setRightsarray([...rightsarray, rights]);
  }

  // useForm
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  // For PAssword Strong
  const isStrongPassword = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);

    return (
      password.length >= minLength && hasUppercase && hasLowercase && hasNumber
    );
  };

  // check Phone Number
  const isValidPhoneNumber = (value) => {
    return /^\d{10}$/.test(value);
  };

  // Check Email
  const isValidEmailNumber = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value);
  };

  // Get Roles
  const { data: roledata } = useGetAllRoles();

  // Role of the User
  const addUserFormSubmitHandler = handleSubmit(async (formData) => {
    if (formData.roleId === "") {
      toast.error("Please Select Role", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    } else if (!formData.tokenlimit) {
      toast.error("Please Select Token Limit", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    } else if (!formData.tokenlimit) {
      toast.error("Please Add CustomToken Limit", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    } else if (rightsarray?.length <= 0) {
      toast.error("Please Select Rights", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    } else {
      await axios
        .post(
          "assign/add_iamUser",
          {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            roleId: formData.roleId.toString(),
            password: formData.password,
            confirmpassword: formData.confirmpassword,
            tokens: formData.tokens,
            tokenlimit: formData.tokenlimit,
            productRights: productRights,
            rightsarray: rightsarray,
            roleAndPermission: roleAndPermission,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res?.data?.status === 400) {
            toast.error(res?.data?.message, {
              position: "top-center",
              autoClose: 3000,
            });
          } else if (res?.data?.status === 200) {
            toast.success("Member Added Successfully", {
              position: "top-center",
              autoClose: 3000,
            });
            setTimeout(() => {
              navigate("/home/dashboard");
            }, 2000);
          }
        });
    }
  });

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: "transparent",
        boxShadow: "none",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"Add User Details"} />
      <form onSubmit={addUserFormSubmitHandler}>
        <Paper
          sx={{
            width: "100%",
            mx: "auto",
            height: "100%",
            borderRadius: "17px",
          }}
          elevation={0}
        >
          <Grid container>
            <Box sx={Styles.AdjAddmember}>
              <Grid item xl={12} lg={12} sd={12} xs={12} md={12}>
                <Typography
                  sx={{
                    ...Styles.TitletextStyle,
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#6B7A99",
                  }}
                >
                  Name & Email Address of the User
                </Typography>
                <Typography sx={Styles.TitleDescriptionStyle}>
                  Type a name & Email for login in to the account.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 3,
                  }}
                >
                  <Box sx={adjInput}>
                    <Typography sx={Styles.TitletextStyle}>
                      Member Name{" "}
                    </Typography>
                    <input
                      style={Styles.InputStyle}
                      fullWidth
                      placeholder="Name"
                      {...register("name", {
                        required: "Name is required",
                      })}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.name && (
                      <p style={{ color: "red" }}>{errors.name.message}</p>
                    )}
                  </Box>
                  <Box sx={adjInput}>
                    <Typography sx={Styles.TitletextStyle}>
                      Member Email{" "}
                    </Typography>
                    <input
                      style={Styles.InputStyle}
                      fullWidth
                      placeholder="E-Mail"
                      {...register("email", {
                        required: "Email is required",
                        validate: (value) =>
                          isValidEmailNumber(value) ||
                          "Please enter a valid Email",
                      })}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.email && (
                      <p style={{ color: "red" }}>{errors.email.message}</p>
                    )}
                  </Box>
                  <Box sx={adjInput}>
                    <Typography sx={Styles.TitletextStyle}>
                      Member Phone no.{" "}
                    </Typography>
                    <input
                      style={Styles.InputStyle}
                      fullWidth
                      placeholder="Phone no."
                      {...register("phone", {
                        required: "Phone is required",

                        validate: (value) =>
                          isValidPhoneNumber(value) ||
                          "Please enter a valid Phone number",
                      })}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.phone && (
                      <p style={{ color: "red" }}>{errors.phone.message}</p>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>

          <Grid container>
            <Box sx={Styles.AdjAddmember}>
              <Grid item xl={12} lg={12} sd={12} xs={12} md={12}>
                <Typography
                  sx={{
                    ...Styles.TitletextStyle,
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#6B7A99",
                  }}
                >
                  Role & Rights of the User
                </Typography>

                <Typography
                  sx={{ ...Styles.TitletextStyle, lineHeight: "40px" }}
                >
                  Role of the User
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    width: "100%",
                    height: "255px",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    mt: 2,
                  }}
                >
                  <select
                    style={{
                      ...Styles.InputStyle,
                      width: "50%",
                      borderRedius: "10px",
                      minWidth: "366px",
                      marginTop: "40px",
                    }}
                    fullWidth
                    placeholder="Select"
                    {...register("roleId")}
                  >
                    {" "}
                    {roledata?.roles?.map((role) => (
                      <option key={role._id} value={role._id}>
                        {role.RoleName}
                      </option>
                    ))}
                  </select>
                  <Grid container sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "auto",
                        mt: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          ...Styles.TitletextStyle,
                          fontWeight: 400,
                          lineHeight: "30px",
                        }}
                      >
                        Rights of the User
                      </Typography>
                    </Box>
                    <Grid item xl={6} lg={6} sd={6} xs={6} md={6}>
                      <Box sx={Styles.AdjCheckBox}>
                        <input
                          type="checkbox"
                          {...register("productRights")}
                          style={{
                            marginBottom: "26px",
                          }}
                          value={"purchase"}
                          onChange={(e) => {
                            handleRightsArray(e.target.value);
                          }}
                        />
                        <Typography sx={Styles.CheckboxtextStyle}>
                          Purchase<br></br>
                          <span style={Styles.check}>
                            ( Can burn the tokens )
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          ...Styles.AdjCheckBox,
                          mt: 2,
                        }}
                      >
                        <input
                          type="checkbox"
                          {...register("purchaseRights")}
                          style={{
                            marginBottom: "26px",
                          }}
                          value={"sales"}
                          onChange={(e) => {
                            handleRightsArray(e.target.value);
                          }}
                        />
                        <Typography sx={Styles.CheckboxtextStyle}>
                          Sales<br></br>{" "}
                          <span style={Styles.check}>
                            ( Can earn the tokens ){" "}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} sd={6} xs={6} md={6}>
                      <Box sx={Styles.AdjCheckBox}>
                        <input
                          type="checkbox"
                          {...register("salesRights")}
                          style={{
                            marginBottom: "26px",
                          }}
                          value={"finance"}
                          onChange={(e) => {
                            handleRightsArray(e.target.value);
                          }}
                        />
                        <Typography
                          sx={{ ...Styles.CheckboxtextStyle, width: "auto" }}
                        >
                          Finance<br></br>
                          <span style={Styles.check}>
                            ( Can see the summary and statements )
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          ...Styles.AdjCheckBox,
                          mt: 2,
                        }}
                      >
                        <input
                          type="checkbox"
                          {...register("financeRights")}
                          style={{
                            marginBottom: "26px",
                          }}
                          value={"custom"}
                          onChange={(e) => {
                            handleRightsArray(e.target.value);
                          }}
                        />
                        <Typography
                          sx={{ ...Styles.CheckboxtextStyle, width: "auto" }}
                        >
                          Custom permissions
                          <br></br>
                          <span style={Styles.check}>
                            ( Super admin can select the permissions to be given
                            )
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      display: "grid",
                      height: "100px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      bgcolor: "transparent",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={ProductRightsChange}
                      value={productRights}
                    >
                      <Typography
                        sx={{ ...Styles.TitletextStyle, fontWeight: 400 }}
                      >
                        Editing rights of the User
                      </Typography>
                      <FormControlLabel
                        value="viewonly"
                        onChange={ProductRightsChange}
                        control={<Radio sx={RadioDes} />}
                        label={<StyledLabel>View Only</StyledLabel>}
                      />
                      <FormControlLabel
                        value="view&edit"
                        sx={{
                          ml: 5,
                        }}
                        control={<Radio sx={RadioDes} />}
                        label={<StyledLabel>View & Edit</StyledLabel>}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>

          <Box sx={{ ...Styles.AdjAddmember, py: "0px" }}>
            <Box sx={BarterCoin}>
              <Box mt={5}>
                <Typography sx={{ ...Styles.TitletextStyle }}>
                  Barter Coin Details
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    mt: 1,
                  }}
                >
                  <Typography
                    sx={{
                      ...Styles.TitleDescriptionStyle,
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#ADB8CC",
                      mt: 1,
                    }}
                  >
                    Add Coin Limit
                  </Typography>
                  &nbsp;&nbsp;&nbsp;
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={tokenlimit}
                    onChange={TokenLimitChange}
                  >
                    <FormControlLabel
                      value="select"
                      control={<Radio sx={RadioDes} />}
                      label={<StyledLabel>Select Token Limit</StyledLabel>}
                    />

                    <FormControlLabel
                      value="custom"
                      control={<Radio sx={RadioDes} />}
                      label={<StyledLabel>Custom Token Limit</StyledLabel>}
                    />
                  </RadioGroup>
                  {tokenlimit !== "custom" ? (
                    <select
                      style={{
                        ...Styles.InputStyle,
                        width: "30%",
                        color: "#445FD2",
                        borderRedius: "10px",
                      }}
                      fullWidth
                      placeholder="Select"
                      {...register("tokenlimit")}
                      disabled={tokenlimit === "custom"}
                    >
                      <option value="50000">
                        Burn Upton 50000 Barter Coins
                      </option>
                      <option value="100000">
                        Burn Upton 100000 Barter Coins{" "}
                      </option>
                      <option value="250000">
                        Burn Upton 250000 Barter Coins
                      </option>
                      <option value="500000">
                        Burn Upton 500000 Barter Coins
                      </option>
                    </select>
                  ) : (
                    <input
                      type="number"
                      style={Styles.InputStyle}
                      fullWidth
                      placeholder="customTokenLimit"
                      {...register("tokenlimit", {
                        required: "customTokenLimit is required",
                        validate: (value) =>
                          parseInt(value) < 500001 ||
                          "Value must be less than 500000",
                      })}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                      disabled={tokenlimit !== "custom"}
                    />
                  )}
                  {errors.tokenlimit && (
                    <p style={{ color: "red" }}>{errors.tokenlimit.message}</p>
                  )}
                  {errors.customTokenLimit && (
                    <p style={{ color: "red" }}>
                      {errors.customTokenLimit.message}
                    </p>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={BarterCoin}>
            <Box
              sx={{
                width: "80%",
                ml: 8,
                mt: 3,
              }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  <Typography
                    sx={{ ...Styles.TitletextStyle, fontWeight: 400 }}
                  >
                    Can user edit the member role & permissions of other user?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={roleAndPermission}
                  onChange={RoleAndPermissionChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio sx={RadioDes} />}
                    label={<StyledLabel>Yes</StyledLabel>}
                  />

                  <FormControlLabel
                    value="No"
                    sx={{
                      ml: 5,
                    }}
                    control={<Radio sx={RadioDes} />}
                    label={<StyledLabel>No</StyledLabel>}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          <Grid container>
            <Box sx={Styles.AdjAddmember}>
              <Box sx={BarterCoin}>
                <Box
                  sx={{
                    width: "80%",
                    ml: 0,
                    mr: "auto",
                  }}
                >
                  <Typography sx={Styles.TitletextStyle}>
                    Password Details
                  </Typography>

                  <Grid item xl={12} lg={12} sd={12} xs={12} md={12}>
                    <Box
                      sx={{
                        display: "grid",
                        minWidth: "425px",
                        height: "100px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "auto",
                          justifyContent: "space-between",
                          alignItems: "center",
                          bgcolor: "transparent",
                          mt: "10px",
                        }}
                      >
                        <Box sx={adjInput}>
                          <Typography sx={Styles.TitletextStyle}>
                            Enter Password{" "}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <input
                              style={Styles.InputStyle}
                              fullWidth
                              type={passField ? "password" : "text"}
                              placeholder="Enter Password"
                              {...register("password", {
                                required: true,
                                validate: isStrongPassword,
                              })}
                              onKeyDown={(e) => {
                                if (
                                  e.key === " " &&
                                  e.target.selectionStart === 0
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />{" "}
                            <Typography
                              sx={{
                                fontSize: "12px",
                                cursor: "pointer",
                                mt: "0px",
                                cursor: "pointer",
                                position: "absolute",
                                right: "2%",
                              }}
                              onClick={() => {
                                setPassField(!passField);
                              }}
                            >
                              <IconButton onClick={handleShowPasswordClick}>
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </Typography>
                          </Box>
                          {errors.password && (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              Password should be at least 8 characters long and
                              contain uppercase letters, lowercase letters, and
                              numbers.
                            </p>
                          )}
                        </Box>
                        <Box
                          sx={{
                            ...adjInput,
                            ml: 10,
                          }}
                        >
                          <Typography sx={Styles.TitletextStyle}>
                            Confirm Password{" "}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <input
                              style={Styles.InputStyle}
                              fullWidth
                              type={confrimPassField ? "password" : "text"}
                              placeholder="Confirm Password"
                              {...register("confirmPassword", {
                                required: "Confirm Password is required",
                                validate: (value) =>
                                  value === watch("password") ||
                                  "Passwords do not match",
                              })}
                              onKeyDown={(e) => {
                                if (
                                  e.key === " " &&
                                  e.target.selectionStart === 0
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "12px",
                                mt: "0px",
                                cursor: "pointer",
                                position: "absolute",
                                right: "2%",
                              }}
                              onClick={() => {
                                setConfrimPassField(!confrimPassField);
                              }}
                            >
                              <IconButton
                                onClick={handleShowPasswordClickConfirm}
                              >
                                {showPasswordConfirm ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </Typography>
                          </Box>
                          {errors.confirmPassword && (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {errors.confirmPassword.message}
                            </p>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Box sx={Styles.AddmemberBorder}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                maxWidth: "450px",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: "1rem",
                alignContent: "center",
              }}
            >
              <Button
                sx={Styles.AddMemberBtn}
                onClick={() => {
                  navigate("/home/company_members");
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  ...Styles.AddMemberBtn,
                  background: "#445FD2",
                  color: "#fff",
                  "&:hover": {
                    border: "1px solid #445FD2",
                    color: "#445FD2",
                  },
                }}
                type="submit"
              >
                Add User
              </Button>
              <Button
                sx={Styles.AddMemberBtn}
                onClick={() => {
                  reset({
                    password: "",
                    name: "",
                    email: "",
                    phone: "",
                    roleId: "",
                    productRights: false,
                    purchaseRights: false,
                    salesRights: false,
                    financeRights: false,

                    tokenlimit: "",
                    confirmPassword: "",
                  });
                  setRightsarray([]);
                  setProductRights(null);
                  setRoleAndPermission(null);
                }}
              >
                Reset
              </Button>
            </Box>
          </Box>
        </Paper>
      </form>
    </Paper>
  );
};

const StyledLabel = styled("span")({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#6B7A99",
});

const adjInput = {
  height: "90px",
  display: "grid",
};

const BarterCoin = {
  width: "100%",
  maxWidth: "1500px",
  height: "auto",
};

const RadioDes = {
  color: "#445FD2",
  "& .MuiSvgIcon-root": {
    fontSize: 22,
  },
};
