import { Box, Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";

const ImagePoster = () => {
  const [BannerImage, SetBannerImage] = useState();
  const [buyNowButton, setBuyNowButton] = useState([]);

  const GetBanner = async () => {
    try {
      const response = await axios.get("/AdminCompanyDetails/GetBannerImage", {
        withCredentials: true,
      });
      SetBannerImage(response?.data?.ImageUrl?.at(0)?.imageUrl);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBuyNow = async () => {
    try {
      const response = await axios.get(
        "/AdminCompanyDetails/getProductImageInBanner",
        {
          withCredentials: true,
        }
      );
      const buyNowData = response?.data.image[0];
      setBuyNowButton(buyNowData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetBanner();
    fetchBuyNow();
  }, []);

  return (
    <Paper sx={PosterStyle} elevation={0}>
      <Box
        component={"img"}
        src={BannerImage}
        sx={{
          height: "100%",
          width: "100%",
          mx: "auto",
          borderRadius: "12px",
        }}
      />

      {buyNowButton?.togal ? (
        <a href={buyNowButton?.imageUrl} rel="noopener noreferrer">
          <Button sx={ButtonStyle}>Buy Now</Button>
        </a>
      ) : null}
    </Paper>
  );
};

export default ImagePoster;

const PosterStyle = {
  width: "97%",
  mx: "auto",
  height: {
    xl: "270px",
    lg: "270px",
    md: "260px",
    sm: "140px",
    xs: "120px",
  },
  boxShadow: "0px",

  borderRadius: "12px",
  position: "relative",
  background: "transparent",
  my: 1.5,
  zIndex: 0,
};

const ButtonStyle = {
  width: {
    xl: "200px",
    lg: "200px",
    md: "200px",
    sm: "150px",
    xs: "150px",
  },
  height: { xl: "50px", lg: "50px", md: "50px", sm: "35px", xs: "35px" },
  background: "#fff",
  borderRadius: "15px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "20px",
    lg: "18px",
    md: "18px",
    sm: "16px",
    xs: "15px",
  },
  "&:hover": {
    background: "#C3CAD9",
  },
  lineHeight: "36px",
  color: "#303030",
  bottom: {
    xl: "40%",
    lg: "40%",
    md: "40%",
    sm: "40%",
    xs: "40%",
  },
  left: { xl: "75%", lg: "65%", md: "65%", sm: "45%", xs: "45%" },
  zIndex: 0,
  textTransform: "none",
};
