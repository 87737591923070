import { Box, Paper, Typography } from "@mui/material";
import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";
// import { fontStyle } from "html2canvas/dist/types/css/property-descriptors/font-style";

const PrivacyPolicy = () => {
  function getDate() {
    const date = new Date();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();

    const year = date.getFullYear();
    return `${day} of ${month}, ${year}`;
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | BXI</title>
        <meta property="og:title" content="Privacy Policy | BXI" />
        <meta
          name="og:description"
          content="Discover how BXI safeguards your privacy with our transparent Privacy Policy. Learn about our data protection measures and your rights as a user."
        />
        <meta
          name="description"
          content="Discover how BXI safeguards your privacy with our transparent Privacy Policy. Learn about our data protection measures and your rights as a user."
        />
      </Helmet>
      <Box>
        <Navbar />
      </Box>
      <ToastContainer style={{ fontSize: "16px" }} />

      <Paper
        sx={{ mt: "100px", width: "100%", border: "none", boxShadow: "none" }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "36px",
            color: "#6B7A99",
            textAlign: "center",
            mx: "auto",
          }}
        >
          Privacy Policy{" "}
        </Typography>
      </Paper>
      <Paper elevation={0}>
        <Box sx={{ ...ContactBoxOneStyle, mt: "50px" }}>
          <Box
            sx={{
              width: "70%",
              mx: "auto",
              mb: 5,
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "20px",
              fontFamily: "Poppins",
            }}
          >
            {" "}
            <p style={InputLableText}>
              This Privacy Policy identifies and describes the way BXI World LLP
              (“BXI”), uses and protects the information we collect about our
              Members. All uses of BXI’s services and the Platform
              www.bxiworld.com/are subject to this Privacy Policy. The Privacy
              policy is effective from{" "}
              <b style={{ borderBottom: "dotted" }}>{getDate()} </b>.
              <br />
              <br /> We know that you care how information about you is used and
              shared, and we appreciate your trust that we will do so carefully
              and sensibly. This Privacy Policy describes how BXI Seller
              Services and its affiliates including www.bxiworld.com/ “the
              Platform”, collect and process your personal information through
              our website, devices, products, services, online
              marketplace/Platform, and applications that refers to this Privacy
              Policy.
              <br />
              <br /> This Privacy Policy is an electronic record in the form of
              an electronic contract formed under the Information Technology
              Act, 2000 and the rules made thereunder and the amended provisions
              pertaining to electronic documents / records in various statutes
              as amended by the Information Technology Act, 2000. This privacy
              policy does not require any physical, electronic or digital
              signature.
              <br />
              <br /> This document is published and shall be construed in
              accordance with the provisions of the Information Technology
              (reasonable security practices and procedures and sensitive
              personal data of information) rules, 2011 under Information
              Technology Act, 2000; that require publishing of the Privacy
              Policy for collection, use, storage and transfer of sensitive
              personal data or information.
              <br />
              <br />
              This Privacy Policy is a legally binding document between you and
              BXI. The terms of this Privacy policy will be effective upon your
              acceptance of the same (directly or indirectly in electronic form,
              by clicking on the "I accept" tab or by use of the Platform or by
              other means) and will govern the relationship between you and BXI
              for your use of the Platform.
              <br />
              <br /> Please read this Privacy policy carefully. By using the
              Platform, you indicate that you understand, agree and consent to
              this Privacy Policy. If you do not agree with the terms of this
              Privacy Policy, please do not use this Platform. You hereby
              provide your unconditional consent or agreements to BXI as
              provided under section 43a and section 72a of Information
              Technology Act, 2000.
              <br />
              <br /> By using the Services and the Platform you agree to our use
              of your personal information (including sensitive personal
              information) in accordance with this Privacy Policy, as may be
              amended from time to time by us at our discretion. You also agree
              and consent to us collecting, storing, processing, transferring,
              and sharing your personal information (including sensitive
              personal information) with third parties or service providers for
              the purposes set out in this Privacy Policy
              <br />
              <br />
              <span style={underlineStyle}>
                WHAT PERSONAL INFORMATION ABOUT CUSTOMERS DOES BXI COLLECT?
              </span>
              <br />
              <br />
              <span style={underlineStylee}>
                “We collect your personal information in order to provide and
                continually improve our products and services”.
              </span>
              <br />
              <br />
              Here are the types of personal information we collect:
              <br />
              <br />
              <span style={underlineStyle}>Information You Give Us:</span> We
              receive and store any information you provide in relation to the
              Platform. You can choose not to provide certain information, but
              then you might not be able to take advantage of many things on our
              Platform. The following information is collected on our end from
              you:
              <br /> <br />
              a. Identifying information such as your Company name
              <br /> <br />
              b. Company Mail Id
              <br />
              <br />
              c. Contact details of the Company i.e., Mobile Number to receive
              OTP
              <br />
              <br />
              d. Banking Details
              <br />
              <br />
              e. GSTIN Number of the Company
              <br />
              <br />
              f. Images, videos, and other content collected or stored in
              connection with the Platform.
              <br />
              <br />
              g. BXI Platform Data collected by us for better user experience.
              <br />
              <br />
              <span style={underlineStyle}>Automatic Information: </span> We
              automatically collect and store certain type of information about
              your use of the Platform, including information about your
              interaction with content and services available through the
              Platform. Like many websites, we use cookies and other unique
              identifiers, and we obtain certain types of information when your
              web browser or device accesses the Platform on behalf of BXI on
              other websites.
              <br /> <br />
              a. The internet protocol (IP) address used to connect your
              computer to the internet
              <br /> <br />
              b. Login & e-mail address.
              <br />
              <br />
              c. The geographical location of your device or computer
              <br />
              <br />
              d. Device metrics such as when a device is in use, application
              usage, connectivity data, and any errors or event failures,
              <br />
              <br />
              e. Version and time zone settings
              <br />
              <br />
              f. Transaction and content use history
              <br />
              <br />
              g. The full Uniform Resource Locator (URL) clickstream to, through
              and from our website including date and time, products, and
              content you viewed or searched for.
              <br />
              <br />
              h. Phone numbers used to call our customer service number and
              <br />
              <br />
              i. Images or videos which you shop in our marketplace using the
              Platform.
              <br />
              <br />
              <span style={underlineStyle}>
                Information from Other Sources:{" "}
              </span>{" "}
              We might receive information about you from other sources, such as
              updated delivery and address information from our carriers, which
              we use to correct our records and deliver your next purchase more
              easily via BXI Logistics.
              <br />
              <br />
              <span style={underlineStyle}>
                FOR WHAT PURPOSE DOES BXI WORLD LLP USE YOUR PERSONAL
                INFORMATION?
              </span>
              <br />
              <br />
              We use your personal information to operate, provide, develop, and
              improve the products and services that we offer our customers via
              Platform. These purposes include:
              <br />
              <br />
              a.{" "}
              <span style={{ fontWeight: 600, marginLeft: "8px" }}>
                Purchase and delivery of products and services-
              </span>{" "}
              We use your personal information to take and fulfil orders,
              deliver products and services, process payments, and communicate
              with you about orders, products and services, and promotional
              offers.
              <br />
              <br />
              b.{" "}
              <span style={{ fontWeight: 600, marginLeft: "8px" }}>
                Provide, troubleshoot, and improve the Platform-
              </span>{" "}
              We use your personal information to provide functionality, analyse
              performance, fix errors, and improve the usability and
              effectiveness of the Platform.
              <br />
              <br />
              c.{" "}
              <span style={{ fontWeight: 600, marginLeft: "8px" }}>
                Recommendations and personalization-
              </span>{" "}
              We use your personal information to recommend features, products,
              and services that might be of interest to you, identify your
              preferences, and personalize your experience with the Platform.
              <br />
              <br />
              d.{" "}
              <span style={{ fontWeight: 600, marginLeft: "8px" }}>
                Comply with legal obligations-{" "}
              </span>{" "}
              In certain cases, we collect and use your personal information to
              comply with laws. For instance, we collect information regarding
              place of establishment and bank account information for identity
              verification and other purposes.
              <br />
              <br />
              e.{" "}
              <span style={{ fontWeight: 600, marginLeft: "8px" }}>
                Communicate with you-{" "}
              </span>{" "}
              We use your personal information to provide functionality, analyse
              performance, fix errors, and improve the usability and
              effectiveness of the Platform.
              <br />
              <br />
              f.{" "}
              <span style={{ fontWeight: 600, marginLeft: "8px" }}>
                Advertising-{" "}
              </span>{" "}
              We use your personal information to display interest-based ads for
              features, products, and services that might be of interest to you.
              <br />
              <br />
              g.{" "}
              <span style={{ fontWeight: 600, marginLeft: "8px" }}>
                Use of your Brand and Logo-
              </span>{" "}
              We use your Brand and Logo to spread awareness that you’re a part
              of the Platform.
              <br />
              <br />
              h.{" "}
              <span style={{ fontWeight: 600, marginLeft: "8px" }}>
                Fraud Prevention-
              </span>{" "}
              We use personal information to prevent and detect fraud and abuse
              in order to protect the security of our customers, BXI, and
              others.
              <br />
              <br />
              <span style={underlineStyle}>DISCLOSURE:</span>
              We do not sell your Personal Information to anyone for any
              purpose, and we maintain information about you in our business
              records while you are a Member, or until it is no longer needed
              for business, tax, or legal purposes. We have implemented
              encryption or other appropriate security controls to protect
              Personal Information when stored or transmitted by BXI. Subject to
              applicable legal restrictions, under the Information Technology
              Act 2000 and its applicable rules, the BXI group of companies may
              share your Personal Information with each other to make sure your
              experience is as seamless as possible, and you have the full
              benefit of what BXI and its subsidiaries have to offer and we do
              not provide Personal Information to non- BXI group of companies
              for the marketing of their own products and services without your
              consent. We may provide Personal Information to non - BXI
              companies or other third parties for purposes such as pursuant to
              court orders, identity verification to prevent fraud and identity
              theft, enforcing of intellectual property rights or any disclosure
              required under any applicable law.
              <br />
              <br />
              <span style={underlineStyle}>SECURITY:</span>
              We have established electronic and administrative safeguards
              designed to secure the information we collect, to prevent
              unauthorized access to or disclosure of that information and to
              ensure it is used appropriately. Although we strive to keep your
              Personal Information secure, no security measures are absolute,
              and we cannot guarantee that your Personal Information will never
              be disclosed in a manner inconsistent with this Policy (for
              example, as the result of unauthorized acts by third parties that
              violate the law or this Policy). BXI uses industry - standard
              technologies when transferring and receiving Customer's and Users
              data exchanged between BXI and third parties to help ensure its
              security.
              <br />
              <br />
              <span style={underlineStyle}>CHANGES TO POLICY:</span>
              BXI may reserve the right to update the Privacy Policy. We will
              notify you about significant changes in the way we treat personal
              information by sending a notice to the address we have on file or
              by placing a prominent notice on our site at least 30 days prior
              to any changes taking effect. You may contact us with any
              questions or concerns at: Sahar Plaza, BXI (Barter Exchange of
              India) 501-5th Floor, Meadows Tower, Complex, Sir Mathuradas
              Vasantji Road, Andheri East,Mumbai, Maharashtra 400-059 or at
              support@bxiworld.com
              <br />
              <br />
              <span style={underlineStyle}>
                A LEGAL OR POTENTIAL LEGAL DISPUTE OR PROCEEDINGS:
              </span>
              We may need to use your information if we are involved in a
              dispute with you or a third party for example, either to resolve
              the dispute or as part of any mediation, arbitration or court
              resolution or similar process.
              <br />
              <br />
              Arbitration and Governing Law:
              <br />
              <br />
              a. In an event of any dispute, controversy, claims arising out of
              or relating to this Agreement, termination or invalidity thereof
              shall be referred to and finally resolved by arbitration under the
              provisions of the Arbitration and Conciliation Act, 1996. The
              Arbitration proceedings shall be conducted in English.
              <br />
              <br />
              b. The Arbitral Tribunal shall be composed of a sole Arbitrator,
              appointed by the Parties to the Agreement jointly. Cost of
              Arbitration shall be equally be borne.
              <br />
              <br />
              c. The principles of natural justice must be observed in the
              arbitration proceedings. The seat and venue of Arbitration shall
              be at Mumbai. The Arbitration award shall be final and binding
              upon both Parties hereto.
              <br />
              <br />
              d. The said Agreement shall be governed and construed in
              accordance with the laws of India. Subject to Clause (a)
              hereinabove, the Courts at Mumbai shall have exclusive
              jurisdiction to try and entertain any dispute arising out of or in
              connection with this Agreement.
            </p>
          </Box>
        </Box>
      </Paper>

      <Footer />
    </React.Fragment>
  );
};

export default PrivacyPolicy;

const errorMessageStyle = {
  color: "red",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,

  lineHeight: "10px",
};
const underlineStyle = {
  textDecoration: "underline",
  fontFamily: "Poppins",
};

const underlineStylee = {
  textDecoration: "underline",
  fontFamily: "Poppins",
  fontStyle: "italic",
};
const TextAreaStyle = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  height: "auto",
  width: "100%",
  minHeight: "90px",
  background: "transparent",
  border: "none",
  marginLeft: "auto",
  marginRight: "auto",
  outline: "none",
  outlineColor: "transparent",
  "&:focus": {
    outline: "none !important",
    border: "none !important",
  },
};

const ContactBoxOneStyle = {
  width: "90%",
  height: "auto",
  background: "#FFFFFF",
  fontFamily: "Poppins",
  borderRadius: "10px",
  zIndex: 10,
  display: "block",
  justifyContent: "space-evenly",
  mx: "auto",
};

const InputBox = {
  width: "auto",
  height: "40px",
  background: "rgba(243, 246, 249, 0.5)",
  borderRadius: "3px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
  pl: 3,
  pr: 1,
  mt: 1,
};

const PlaceHolderColor = {
  width: "100%",
  maxWidth: "380px",
  height: "100%",
  bgcolor: "transparent",
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "21px",
  display: "flex",
  alignItems: "center",
  // color: " rgba(107, 122, 153, 0.2)",
  // color: "cadetblue"
};

const InputLableText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "21px",
  display: "block",
  alignItems: "center",
  color: "#6B7A99",
};

const TitleBottomtext = {
  pt: "3%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  color: "#8A8A8A",
  mx: "auto",
};

const ButtonStyle = {
  width: "100%",
  height: "36px",
  // maxWidth: "380px",
  background: "#445FD2",
  borderRadius: "3px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  mx: "auto",
  "&:hover": {
    background: "#C3CAD9",
  },
};

const ContactInfoBoxStyle = {
  py: 5,
  mt: 2,
  width: "100%",
};

const ContactInformationBox = {
  display: "flex",
  gap: {
    xl: "2%",
    lg: "3%",
    md: "4%",
    sm: "5%",
    xs: "6%",
  },
};
const ContactInfoInnerText = {
  width: "100%",
  flexdirection: "row",
  py: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  display: "flex",
  alignItems: "center",
  color: "#778DA9",
  mr: {
    xl: "4rem",
    lg: "4rem",
    md: "3rem",
    sm: "1rem",
    xs: "0",
  },
};

const InformationTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18px",
  display: "flex",
  alignItems: "Left",
  alignContent: "Left",
  color: "#6B7A99",
};
