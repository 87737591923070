const GeneralInfo = "General Voucher Information";
const VoucherSubCategory = "Voucher SubCategory";
const VoucherName = "Voucher Name";
const VoucherSubtitle = "Voucher Subtitle";
const VoucherDescription = "Voucher Description";

const LINKName = "Link of your website";

const TagName =
  "Tags (Keywords that can improve your seach visibility on marketplace)";

module.exports = {
  GeneralInfo,
  VoucherSubCategory,
  VoucherName,
  VoucherSubtitle,
  VoucherDescription,
  TagName,
  LINKName,
};
