import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import MainLogo from "./MainLogo.jsx";
import { useForm } from "react-hook-form";
import Stepper from "../../components/Stepper";
import TostMessagesTop from "../../Component/OTPToast";
import { ToastContainer, toast } from "react-toastify";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import mainImg from "../../assets/Images/register/gsttax.svg";
import UnderDraw from "../../assets/undraw_completed_re_cisp 1.svg";
import { useCompanyStepDetails, useGetCompanyDetails } from "../../Hooks/Auth";
import * as StlyesOnBoarding from "../../components/common/Styled/StylesOnBoarding.js";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSentNotification } from "../../Hooks/Auth.js";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser.js";

const Gst = () => {
  const location = useLocation();
  const companyName = location?.state?.CompanyName;
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutate, isLoading } = useCompanyStepDetails();
  const { data: CompanyData } = useGetCompanyDetails();
  let [trueresponse, setTrueRespones] = useState(false);

  // useForm
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(
      z.object({
        gst: z.string().length(15),
      })
    ),
  });

  const { data: AuthUserData } = useGetAuthUser();
  const { mutate: SendNotification } = useSentNotification();

  const handleSendNotification = () => {
    SendNotification({
      id: AuthUserData?.data?.companyId,
      status: "GST",
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      handleSendNotification();
      e.returnValue =
        "Please click 'Stay on this Page' and we will give you candy";
      return "Please click 'Stay on this Page' and we will give you candy";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  //  post the gst here Api
  const submitGst = handleSubmit((data) => {
    var reggst = /^([0-2][0-9]|[3][0-7])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/;
    if (!reggst?.test(data?.gst) && data?.gst !== "") {
      toast.error(
        "GST Identification Number is not valid. It should be in this 11AAAAA1111Z1A1 format"
      );
    } else {
      const newData = { gst: data.gst, id };
      mutate(newData, {
        onSuccess: (Response) => {
          if (Response?.data?.success === false) {
            toast.error(Response?.data?.message, {
              position: "top-center",
            });
          }
          if (Response?.data?.success) {
            setTrueRespones(true);
          }
          setTimeout(() => {
            navigate(`/beingverified`);
          }, 3000);
        },
        onError: (err) => {
          alert(err);
        },
      });
    }
  });

  // check company Onboarding Status here
  useEffect(() => {
    if (CompanyData?.data?.companyOnboardingStatus === "FORWARD_DROP") {
      setTimeout(() => {
        navigate(`/home/terms`);
      }, [3000]);
    } else if (CompanyData?.data?.companyOnboardingStatus === "BANK_DETAILS") {
      setTimeout(() => {
        navigate(`/bank`);
      }, [3000]);
    } else if (CompanyData?.data?.companyOnboardingStatus === "UNDER_REVIEW") {
      setTimeout(() => {
        navigate(`/under_review`);
      }, [3000]);
    }
  }, [CompanyData]);

  return (
    <>
      <form onSubmit={submitGst}>
        <ToastContainer
          style={{
            fontSize: {
              xl: "16px",
              lg: "16px",
              md: "16px",
              sm: "14px",
              xs: "12px",
            },
          }}
        />
        <TostMessagesTop
          PageContent="Your GST Is Being Verified, We Thank You for your Patience."
          PageCompanyName={companyName}
        />
        <Paper elevation={0} sx={StlyesOnBoarding.mainCommonPage}>
          <Stepper />
          <Grid
            container
            sx={{
              background: "#fff",
              height: "95vh",
              width: {
                xl: "110%",
                lg: "110%",
                md: "110%",
                sm: "100%",
                xs: "100%",
              },
            }}
          >
            {/* detail Grid */}
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <MainLogo />
              <Typography sx={StlyesOnBoarding.HeadText}>
                GST Detail{" "}
              </Typography>
              <Box
                mt={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  width: "75%",
                  maxWidth: "450px",
                }}
              >
                <ThemeProvider theme={outerTheme}>
                  <Box sx={{ ...StlyesOnBoarding.inputFieldDesign, ml: 1.5 }}>
                    <Box sx={StlyesOnBoarding.inputbox}>
                      <label
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 400,
                          color: "#6B7A99",
                        }}
                      >
                        GST Number (15 Characters)
                      </label>
                    </Box>
                    <input
                      type={"text"}
                      {...register("gst")}
                      label="GST"
                      placeholder="12345667888"
                      style={{
                        ...StlyesOnBoarding.inputStyles,
                        height: "5rem",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      ml: 1,
                      mt: 1,
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    {errors["gst"]?.message}
                  </Typography>
                </ThemeProvider>

                <Grid
                  container
                  mt={3}
                  sx={{
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={StlyesOnBoarding.CommonBtn}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size="20px"
                        sx={{ color: "white", width: "40%" }}
                      />
                    ) : (
                      "Next"
                    )}
                  </Button>
                  {trueresponse ? (
                    <>
                      <Box
                        sx={{
                          height: "auto",
                          width: "100%",
                          alignItems: "center",
                          padding: "50px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          textAlignLast: "center",
                        }}
                      >
                        <img src={UnderDraw} alt="UnderDraw" />

                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontFamily: "Poppins",
                            fontSize: "32px",
                            lineHeight: "48px",
                            color: "#6B7A99",
                            width: "90%",
                          }}
                        >
                          Congratulations !{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontFamily: "Poppins",
                            color: "#6B7A99",
                            LineHeight: "21px",
                            textAlign: "center",
                            width: "95%",
                            fontSize: "14px",
                          }}
                        >
                          You have successfully completed the second step of
                          your registration process.
                        </Typography>
                      </Box>
                    </>
                  ) : null}
                </Grid>
              </Box>
            </Grid>
            {/* image Grid */}
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: {
                  xl: "flex",
                  lg: "flex",
                  md: "flex",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <img
                src={mainImg}
                alt="img"
                style={{
                  ...StlyesOnBoarding.CommonImgStyle,
                  marginRight: "150px",
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
};

export default Gst;

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#6B7A99",
    },
  },
  typography: {
    fontSize: 20,
  },
});
