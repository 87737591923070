// use query for get justpay payment methods
import { useQuery } from "react-query";
import axios from "axios";

const useGetJustPayPaymentMethds = () => {
  let { data, isLoading, error } = useQuery(
    ["getJustPayPaymentMethods"],
    async () => {
      return await axios.get(`just-pay/get-payment-methods`, {
        id: "bxiworld",
      });
    }
  );
  return { data, isLoading, error };
};
export default useGetJustPayPaymentMethds;
