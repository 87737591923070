import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import TostMessagesTop from "../../Component/Toast";
import LeftArrow from "../../assets/GoLeft.png";
import StacsOfCoinIcon from "../../assets/Images/CommonImages/BXIToken.svg";
import DownloadIcon from "../../assets/Images/CommonImages/downloadicon.svg";
import PrintIcon from "../../assets/Images/CommonImages/printicon.svg";
import CommaSeprator from "../../components/CommaSeprator";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import PageLoader from "../../components/LoadingButton/PageLoader";
import useGetCompanyFullData from "../../Hooks/CompanyData/useGetCompanyFullData";
import { socket } from "../../pages/Message/Message";

import { toast } from "react-toastify";

import {
  useBuyerUploadPaymentDocs,
  useCreateDeal,
  useCreateVoucherDeal,
} from "../../pages/PurchaseOrderList/Hooks";

const VoucherOrderSummary = (props) => {
  const { id } = useParams();
  const componentRef = useRef();
  const [OrderData, setOrderData] = useState({});
  const [PageLoading, setPageLoading] = useState(false);
  const [StorePaymentStatus, setStorePaymentStatus] = useState({});

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const navigate = useNavigate();

  const [isIntervalRunning, setIsIntervalRunning] = useState(false);

  useEffect(() => {
    let Amount = OrderData?.BXITokenDetails?.totalInrToPay;
    let BatterCoin =
      OrderData?.BXITokenDetails?.totalProductTokens +
      OrderData?.BXITokenDetails?.totalAdCostTokens;
    let InvoiceId = OrderData?._id;

    let buyerCompanyName = "8554887187";
    let parameterName = "buyer_name";
    let parameterValue = buyerCompanyName;
    let parameterName1 = "dynamic_link4_here";
    let parameterValue1 = "https://bxi.unada.in/home/performainvoice";
  });

  useEffect(() => {
    if (StorePaymentStatus?.data?.success === "true") {
      navigate("/home/voucherorderlist");
    }
  }, [StorePaymentStatus]);

  async function getOrderDetailsById() {
    await axios
      .get(`order/get_order_by_id/${id}`)
      .then((res) => {
        setOrderData(res.data);
        GetEscrowDeal_Status(res.data?.escrowData);
        setPageLoading(false);
      })
      .catch((err) => {
        setPageLoading(true);
      });
  }

  useEffect(() => {
    getOrderDetailsById();
  }, []);

  const startInterval = () => {
    setIsIntervalRunning(true);
    const intervalId = setInterval(() => {
      GetEscrowDeal_Status(OrderData?.escrowData);
    }, 5000);
    setIsIntervalRunning(intervalId);
  };

  const stopInterval = () => {
    setIsIntervalRunning(false);
    clearInterval(isIntervalRunning);
  };

  const {
    mutate: createVoucherDealMutate,
    isLoading: createVoucherDealLoading,
    error: createVoucherDealError,
  } = useCreateVoucherDeal();

  const {
    mutate: createDealMutate,
    isLoading: createDealLoading,
    data: createDealData,
    error: createDealError,
  } = useCreateDeal();

  const {
    mutate: buyerUploadPaymentDocs,
    isLoading: buyerUploadPaymentDocsLoading,
    error: buyerUploadPaymentDocsError,
  } = useBuyerUploadPaymentDocs();

  const itemDetails = [
    {
      item: "Seller Name :",
      value: OrderData?.SellerDetails?.SellerCompanyName,
    },
    {
      item: "Address :",
      value: OrderData?.SellerDetails?.Address?.AddressLine,
    },
    {
      item: "GSTIN :",
      value: OrderData?.SellerDetails?.GSTIN,
    },
    {
      item: "State :",
      value: OrderData?.SellerDetails?.Address?.State,
    },
  ];
  const pror = [
    {
      item: "Purchase Order Date :",
      value: new Date(OrderData?.OrderDate).toDateString(),
    },
  ];

  const { data: companyData } = useGetCompanyFullData();

  const createEscrowDeal = async () => {
    await localStorage.setItem("OrderId", OrderData?._id);
    await createDealMutate(
      {
        OrderId: OrderData?._id,
        amount: 3,
      },
      {
        onSuccess: (data) => {
          // axios
          //   .put(`order/update_order/${id}`, {
          //     data: "Success",
          //     EscrowDealData: data.data,
          //   })
          //   .then((res) => {});
          GetPaymentUrl();
        },
        onError: (err) => {},
      }
    );
  };

  const GetPaymentUrl = async () => {
    buyerUploadPaymentDocs(
      {
        OrderId: OrderData?._id,
      },
      {
        onSuccess: (data) => {
          if (!data.data.payment_url) {
            return alert("Something went wrong please try again later");
          }
          if (data.data.payment_url) {
            window.open(data.data.payment_url, "_blank");
          }
        },
        onError: (err) => {},
      }
    );
  };

  async function GetEscrowDeal_Status(props) {
    await axios
      .post(`/escrow/escrow_deal_status/${props?.escrowId}`)
      .then((res) => {
        setStorePaymentStatus(res?.data);
      })
      .catch((err) => {});
  }

  if (PageLoading) {
    return <PageLoader />;
  }

  const Transfer_wallet_amount = async () => {
    try {
      let WalletResponse = await axios
        .post(`wallet/transfer_amount`, {
          walletAddressFrom: OrderData?.BuyerDetails?.BuyerCompanyId,
          walletAddressTo: OrderData?.SellerDetails?.SellerCompanyId,
          amount:
            OrderData?.BXITokenDetails?.TotalBXiCoins +
            OrderData?.BXITokenDetails?.TotalAdCostInBxiWithoutGST,
          OrderId: "Voucher1234",
          order_id: OrderData?._id,
        })
        .then((response) => {});
    } catch (err) {}
  };

  return (
    <>
      <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
        <BreadCrumbHeader
          MainText="Order Summary"
          LinkText1="{splitedurl[1]}"
          LinkText2="{splitedurl[2]}"
          link1="Link1"
          link2="link2"
        />
        <Paper
          sx={{
            bgcolor: "#fff",
            boxShadow: "none",
            p: 3,
            borderRadius: "20px",
            height: "auto",
            minHeight: "520px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          elevation={0}
          // ref={componentRef}
        >
          <Box
            sx={{
              width: "96%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
            onClick={() => navigate(-1)}
          >
            <img
              src={LeftArrow}
              alt="letf-arrow"
              style={{ width: "30px", cursor: "pointer" }}
            />
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "21px",
                color: "#6B7A99",
              }}
            >
              Order Summary
            </Typography>
          </Box>
          <Box ref={componentRef}>
            <Grid
              container
              gap={8}
              sx={{
                py: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                sx={{
                  ...gridstyle,
                  width: "830px",
                  height: "auto",
                  position: "relative",
                }}
              >
                <Box sx={{ ...mainbox }}>
                  <Typography sx={headbox}>Supplier Details</Typography>
                </Box>
                <Box sx={contentbox}>
                  {itemDetails?.map((el, idx) => {
                    return (
                      <Box sx={mapbox} key={idx}>
                        <Typography sx={elitem}>{el.item}</Typography>
                        <Typography
                          sx={{
                            ...elvalue,
                            width: "85%",
                            textAlign: "left",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {el.value}
                        </Typography>
                      </Box>
                    );
                  })}

                  <Box sx={{ mt: "20px", width: "90%" }}>
                    {pror?.map((el, idx) => {
                      return (
                        <Box sx={{ ...mapbox }} key={idx}>
                          <Typography
                            sx={{
                              ...elitem,
                              width: "250px",
                              textAlign: "left",
                            }}
                          >
                            {el.item}
                          </Typography>
                          <Typography
                            sx={{
                              ...elvalue,
                              width: "85%",
                              textAlign: "left",
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {el.value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  <Typography
                    sx={{
                      ...selername,
                      justifyContent: "flex-end",
                      position: "absolute",
                      mt: 6,
                    }}
                  >
                    Code : {OrderData?.SellerDetails?.StateCode}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                sx={{
                  ...gridstyle,
                  width: "399px",
                  height: "auto",
                  minHeight: "320px",
                }}
              >
                <Box sx={headbox2}>
                  <Typography sx={detailtext}>INR Details</Typography>
                  <Typography sx={detailtext2}>
                    Details with more info
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    height: "200px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <Box sx={mapbox}>
                    <Typography sx={inrvalue}>Product Purchase GST</Typography>
                    <Typography sx={inrvalue}>
                      <CommaSeprator
                        Price={OrderData?.INRDetails?.totalGstAmount}
                      />
                    </Typography>
                  </Box>
                  <Box sx={mapbox}>
                    <Typography sx={inrvalue}>Additional Cost</Typography>
                    <Typography sx={inrvalue}>
                      <CommaSeprator
                        Price={OrderData?.INRDetails?.totalGstInrOfAdCost}
                      />
                    </Typography>
                  </Box>
                  <Box sx={mapbox}>
                    <Typography sx={inrvalue}>
                      Bxi Commission(
                      {companyData?.ChoosedPlan === "advance" ? "8" : "12"}%)
                    </Typography>
                    <Typography sx={inrvalue}>
                      <CommaSeprator
                        Price={OrderData?.INRDetails?.totalBxiCommission}
                      />{" "}
                    </Typography>
                  </Box>
                  <Box sx={mapbox}>
                    <Typography sx={inrvalue}>
                      GST on Commision (18%)
                    </Typography>
                    <Typography sx={inrvalue}>
                      <CommaSeprator
                        Price={OrderData?.INRDetails?.bxiCommisionGST}
                      />
                    </Typography>
                  </Box>
                  <Box sx={totaltextsec}>
                    <Typography sx={totaltext}>
                      Total without TDS Deduction
                    </Typography>
                    <Typography sx={totaltext}>
                      ₹{" "}
                      <CommaSeprator
                        Price={
                          OrderData?.INRDetails?.TotalInrToPay +
                          OrderData?.INRDetails?.TDS
                        }
                      />
                    </Typography>
                  </Box>
                  <Box sx={{ ...mapbox, height: "auto" }}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "12px",
                        textAlign: "center",
                        color: "#6B7A99",
                      }}
                    >
                      Deducted TDS (5%) on BXI Commission
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        textAlign: "center",
                        color: "red",
                      }}
                    >
                      - <CommaSeprator Price={OrderData?.INRDetails?.TDS} />
                    </Typography>
                  </Box>
                </Box>

                <Box sx={totaltextbox}>
                  <Box sx={totaltextsec}>
                    <Typography sx={totaltext}>Total </Typography>
                    <Typography sx={totaltext}>
                      ₹{" "}
                      <CommaSeprator
                        Price={OrderData?.INRDetails?.TotalInrToPay}
                      />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              gap={8}
              sx={{
                py: "10px",
                display: "flex",
                justifyContent: "center",
                width: "97%",
                mx: "auto",
              }}
            >
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  ...gridstyle,
                  height: "100%",
                }}
              >
                <Box sx={headbox2}>
                  <Typography sx={detailtext}>Item Details</Typography>
                  <Typography sx={detailtext2}>
                    Details with more info
                  </Typography>
                </Box>

                <Grid
                  container
                  sx={{
                    width: "100%",
                    height: "20%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "2rem 0rem 0rem 4rem",
                    justifyContent: "center",
                  }}
                >
                  <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                    <Typography sx={{ ...headtext, textAlign: "left" }}>
                      No
                    </Typography>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                    <Typography sx={{ ...headtext, textAlign: "left" }}>
                      Item
                    </Typography>
                  </Grid>
                  <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                    <Typography sx={{ ...headtext, textAlign: "center" }}>
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                    <Typography sx={{ ...headtext, textAlign: "center" }}>
                      Rate / Unit
                    </Typography>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                    <Typography sx={{ ...headtext, textAlign: "center" }}>
                      Additional Cost
                    </Typography>
                  </Grid>
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                    <Typography sx={{ ...headtext, textAlign: "center" }}>
                      BXI Coin
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  sx={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    padding: "1rem 1rem 1rem 4rem",
                    justifyContent: "end",
                    overflow: "scroll",
                  }}
                >
                  {OrderData?.ProductData?.map((el, idx) => {
                    return (
                      <Grid container sx={{ mt: "10px", py: 2 }}>
                        <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,
                              textAlign: "left",
                              color: "#6B7A99",
                              opacity: 1,
                            }}
                          >
                            {idx + 1}
                          </Typography>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,
                              textAlign: "left",
                              color: "#6B7A99",
                              opacity: 1,
                            }}
                          >
                            {el.ProductName}
                          </Typography>
                        </Grid>
                        <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,
                              textAlign: "center",
                              color: "#6B7A99",
                              opacity: 1,
                            }}
                          >
                            {el.ProductQuantity}
                          </Typography>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,
                              textAlign: "center",
                              color: "#6B7A99",
                              opacity: 1,
                            }}
                          >
                            <CommaSeprator Price={el?.PriceWithoutGST} />
                          </Typography>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,
                              color: "#6B7A99",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              opacity: 1,
                              gap: "5px",
                            }}
                          >
                            <Box
                              component="img"
                              src={StacsOfCoinIcon}
                              sx={{
                                width: "13px",
                                height: "13px",
                              }}
                            />
                            <CommaSeprator
                              Price={
                                el?.AdditionalCost
                                  ?.TotalAdditionalCostWtihoutGSTInBXI
                              }
                            />
                          </Typography>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography
                            sx={{
                              ...headtext,
                              fontWeight: 500,
                              color: "#6B7A99",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              opacity: 1,
                              gap: "5px",
                              textAlign: "right",
                            }}
                          >
                            <Box
                              component="img"
                              src={StacsOfCoinIcon}
                              sx={{
                                width: "13px",
                                height: "13px",
                              }}
                            />
                            <CommaSeprator
                              Price={
                                props?.PageType === "Voucher"
                                  ? el?.PriceWithoutGST * el?.ProductQuantity +
                                    el?.AdditionalCost
                                      ?.TotalAdditionalCostWtihoutGSTInBXI
                                  : el?.TotalPriceWithoutGST +
                                    el?.AdditionalCost
                                      ?.TotalAdditionalCostWtihoutGSTInBXI
                              }
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>

                <Box sx={{ ...totaltextbox }}>
                  <Box sx={{ ...totaltextsec }}>
                    <Typography sx={{ ...totaltext, py: 2 }}>Total</Typography>
                    <Typography
                      sx={{
                        ...totaltext,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "5px",
                      }}
                    >
                      <Box
                        component="img"
                        src={StacsOfCoinIcon}
                        sx={{
                          width: "13px",
                          height: "13px",
                        }}
                      />
                      <CommaSeprator
                        Price={
                          OrderData?.BXITokenDetails
                            ?.TotalAdCostInBxiWithoutGST +
                          OrderData?.BXITokenDetails?.TotalBXiCoins
                        }
                      />
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              gap={8}
              sx={{
                py: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                sx={{
                  width: "100%",
                  height: "50px",
                }}
              >
                {createVoucherDealLoading ? (
                  <Button
                    variant="contained"
                    // onClick={createEscrowDeal}
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: "#445FD2",
                      borderRadius: "10px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "21px",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                  >
                    <CircularProgress />
                  </Button>
                ) : StorePaymentStatus?.success === false ? (
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: "#445FD2",
                      borderRadius: "10px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                  >
                    Already Purchased
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      createEscrowDeal();
                      startInterval();
                      Transfer_wallet_amount();
                    }}
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: "#445FD2",
                      borderRadius: "10px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "21px",
                      color: "#FFFFFF",
                      textTransform: "none",
                    }}
                  >
                    Buy Now
                  </Button>
                )}
              </Grid>
              {/* <Button onClick={Transfer_wallet_amount}>Wallet</Button> */}
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                sx={{
                  width: "100%",
                  height: "50px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Button variant="outlined" sx={btn} onClick={handlePrint}>
                  <Box
                    component={"img"}
                    src={PrintIcon}
                    sx={ButtonIconStyle}
                  ></Box>
                  <Typography sx={btntext}>Print</Typography>
                </Button>
                <Button variant="outlined" sx={btn} onClick={handlePrint}>
                  <Box
                    component={"img"}
                    src={DownloadIcon}
                    sx={ButtonIconStyle}
                  ></Box>
                  <Typography sx={btntext}>Download</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <TostMessagesTop PageLocation="Order Summary" />
      </Paper>
    </>
  );
};

export default VoucherOrderSummary;

const ButtonIconStyle = {
  width: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "20px",
    xs: "18px",
  },
  height: "auto",
};

const headtext = {
  width: "100%",
  textAlign: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "11px",
  lineHeight: "15px",
  color: "#6B7A99",
  opacity: 1,
};

const mainbox = {
  width: "100%",
  height: "48px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const headbox = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const contentbox = {
  width: "100%",
  height: "70%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};

const headbox2 = {
  width: "100%",
  height: "64px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const selername = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "10px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  opacity: 0.7,
};

const mapbox = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const elitem = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const totaltext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
};

const totaltextbox = {
  width: "97%",
  height: "auto",
  borderTop: "1px solid rgba(149, 144, 168, 0.1)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  mx: "auto",
};

const elvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const detailtext = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const detailtext2 = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "20px",
  color: "#6B7A99",
  opacity: 0.4,
};

const totaltextsec = {
  width: "90%",
  mt: "10px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const btntext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "21px",
  textAlign: "center",
  color: "#445FD2",
  textTransform: "none",
};

const btn = {
  width: "40%",
  height: "100%",
  border: "1px solid #445FD2",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "row",
  gap: "10px",
};

const inrvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "10px",
  textAlign: "center",
  color: "#6B7A99",
};

const gridstyle = {
  border: "1px solid rgba(24, 2, 12, 0.05)",
  borderRadius: "10px 10px 0px 0",
};
