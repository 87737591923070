import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import axios from "axios";
import Radio from "@mui/material/Radio";
import { FaHotel } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import { Link, useParams } from "react-router-dom";
import BXICoin from "../../assets/BXITokanSVG.svg";
import RightsImg from "../../assets/Dashboard/rights.png";
import CommaSeprator from "../../components/CommaSeprator";
import Avatargenerator from "../../components/AvatarGenerator";
import * as Styles from "../../components/common/Styled/Styles.js";
import GoLeftIcon from "../../assets/Images/CommonImages/GoLeft.png";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import useGetCompanyMemberById from "../../Hooks/CompanyMember/useGetCompanyMemberById";

const MemberDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = React.useState([]);
  const [balance, setBalance] = React.useState("");

  // pertcular member data
  const {
    data: MemberData,
    isLoading: MemberDataIsLoading,
    error: MemberDataError,
  } = useGetCompanyMemberById(id);

  // requested balace
  const reqBalance = async () => {
    await axios
      .get("wallet/mywallet", {
        withCredentials: true,
      })
      .then((res) => {
        setBalance(res?.data?.data?.balance);
      });
  };

  // Get product details
  const fetchProductDetails = async () => {
    return await axios
      .post("/member-details/product-details", {
        sellerCompanyId: MemberData?.companyId,
        postedBy: MemberData?._id,
      })
      .then((response) => response.data)
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    const getProductDetails = async () => {
      const productDetails = await fetchProductDetails();
      setProductDetails(productDetails);
    };
    getProductDetails();
    reqBalance();
  }, []);

  // logged user
  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
  } = useGetLoggedInUser();
  return (
    <Paper
      sx={{
        boxShadow: "none",
        width: "100%",
        bgcolor: "transparent",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"Member Details"} />
      <Grid
        container
        sx={{
          background: "white",
          mx: "auto",
          width: "100%",
          borderRadius: "10px",
          marginTop: "3rem",
        }}
        spacing={2}
        position="relative"
        padding="2rem 0rem"
      >
        <Link to="/home/company_members">
          <img
            src={GoLeftIcon}
            style={{
              width: "22px",
              position: "absolute",
              top: "2%",
              left: "1%",
              fontSize: "2.5rem",
              color: "rgba(68, 95, 210, 1)",
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "5px",
            }}
          />
        </Link>

        <Grid
          item
          xl={3.5}
          lg={3.5}
          md={3.5}
          sm={12}
          xs={12}
          sx={{ padding: "2rem" }}
        >
          <Box sx={Styles.BoxDesignMember}>
            <Typography sx={Styles.BoxHeaderText}>Member Details</Typography>
            <Box sx={Styles.AvtarNameDesign}>
              <Avatargenerator
                companyname={userData?.data?.companyName}
                AvatarUrl={userData?.data?.CompanyAvatar?.url}
              />
              <Box
                sx={{
                  width: "80%",
                }}
              >
                <Typography sx={Styles.nameTextStyle}>
                  {MemberData?.name ? MemberData?.name : "N/A"}
                </Typography>
                <Typography
                  sx={{ ...Styles.emailTextStyle, overflowWrap: "break-word" }}
                >
                  {MemberData?.email ? MemberData?.email : "N/A"}
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                ...Styles.BoxHeaderText,
                fontSize: "14px",
                display: "flex",
                justifyContent: "flex-start",
                py: 1,
              }}
            >
              Issued limit For Transaction :{"  "}
              {"  "}
              {MemberData?.superAdmin ? (
                <>
                  <Box
                    component="img"
                    src={BXICoin}
                    alt="currency"
                    sx={{ width: "18px", height: "18px" }}
                  />
                  <CommaSeprator Price={balance} />
                </>
              ) : (
                <>
                  <Box
                    component="img"
                    src={BXICoin}
                    alt="currency"
                    sx={{ width: "18px", height: "18px" }}
                  />
                  <CommaSeprator Price={MemberData?.tokenlimit} />
                </>
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xl={8.5}
          lg={8.5}
          md={8.5}
          sm={12}
          xs={12}
          sx={{ padding: "2rem", overflow: "scroll" }}
        >
          <Box sx={Styles.BoxDesignMember}>
            <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
              <Typography sx={Styles.BoxHeaderText}>Permissions</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xl: "column",
                    lg: "column",
                    md: "column",
                    sm: "row",
                    xs: "column",
                  },
                }}
              >
                <Box sx={Styles.permissionDesign}>
                  <Box sx={iconDesign}>
                    <FaHotel style={{ fontSize: "35px" }} />

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box>
                        <Typography sx={Styles.BoxHeaderText}>
                          Product Category
                        </Typography>
                        <Typography
                          sx={{ ...Styles.emailTextStyle, marginTop: "0.5rem" }}
                        >
                          {MemberData?.productRights === "viewonly"
                            ? "View Only"
                            : MemberData?.productRights === "view&edit"
                            ? "View & Edit"
                            : MemberData?.productRights}
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        width: "100%",
                        minWidth: "300px",
                      }}
                    >
                      <Grid container>
                        <Grid item xl={6} lg={6} md={6}>
                          <FormControlLabel
                            value="View Only"
                            control={
                              <Radio
                                checked={
                                  MemberData?.productRights === "viewonly"
                                    ? true
                                    : false
                                }
                                sx={{
                                  color: "#445FD2",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 22,
                                  },
                                }}
                              />
                            }
                            label={<StyledLabel>View Only</StyledLabel>}
                          />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                          <FormControlLabel
                            value="View & Edit"
                            control={
                              <Radio
                                checked={
                                  MemberData?.productRights === "View & Edit" ||
                                  MemberData?.productRights === "view&edit"
                                    ? true
                                    : false
                                }
                                sx={{
                                  color: "#445FD2",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 22,
                                    marginLeft: "4px",
                                  },
                                }}
                              />
                            }
                            label={<StyledLabel>View & Edit</StyledLabel>}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Box>
                </Box>
                <Box
                  sx={{
                    ...Styles.permissionDesign,
                    mt: 1,
                  }}
                >
                  <Box sx={iconDesign}>
                    <FaHotel style={{ fontSize: "35px" }} />
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box>
                        <Typography sx={Styles.BoxHeaderText}>
                          Member Edit Rights
                        </Typography>
                        <Typography
                          sx={{ ...Styles.emailTextStyle, marginTop: "0.5rem" }}
                        >
                          {MemberData?.roleAndPermission}
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{
                        display: "flex",
                        bgcolor: "transparent",
                        width: "100%",
                        minWidth: "320px",
                        justifyContent: "end",
                      }}
                    >
                      <Grid container>
                        <Grid item xl={6} lg={6} md={6}>
                          <FormControlLabel
                            value="View Only"
                            sx={{
                              marginRight: "26px",
                            }}
                            control={
                              <Radio
                                checked={
                                  MemberData?.roleAndPermission === "Yes"
                                    ? true
                                    : false
                                }
                                sx={{
                                  color: "#445FD2",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 22,
                                  },
                                }}
                              />
                            }
                            label={<StyledLabel>Yes</StyledLabel>}
                          />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6}>
                          <FormControlLabel
                            value="View & Edit"
                            sx={{
                              marginRight: "58px",
                            }}
                            control={
                              <Radio
                                checked={
                                  MemberData?.roleAndPermission === "No"
                                    ? true
                                    : false
                                }
                                sx={{
                                  color: "#445FD2",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 22,
                                  },
                                }}
                              />
                            }
                            label={<StyledLabel>No</StyledLabel>}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Box>
                </Box>
                {MemberData?.rightsarray?.length === 0 ? null : (
                  <>
                    <Box sx={{ display: "flex", ml: 3, gap: "10px" }}>
                      <img
                        src={RightsImg}
                        style={{ width: "100%", maxWidth: "20px" }}
                      />
                      <Typography sx={{ ...Styles.BoxHeaderText, ml: 3 }}>
                        Rights :
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          width: "auto",
                        }}
                      >
                        {MemberData?.rightsarray?.map((res, idx) => {
                          return (
                            <Typography sx={Styles.BoxHeaderText}>
                              {res}
                              {MemberData?.rightsarray?.length > 1
                                ? ","
                                : null}{" "}
                            </Typography>
                          );
                        })}
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Button
        sx={{ ...Styles.ViewProfileBtn, fontSize: "1.4rem" }}
        onClick={() => navigate(`/home/edit_member/${id}`)}
      >
        Edit
      </Button>
    </Paper>
  );
};
export default MemberDetailsPage;

const iconDesign = {
  textAlign: "start",
  display: "flex",
  alignItems: "center",
  gap: "3.5rem",
};

const StyledLabel = styled("span")({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#6B7A99",
});
