/* eslint-disable react/no-unknown-property */
import CloseIcon from "@mui/icons-material/Close";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import { MdOutlineExpandMore } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useCompanyStepDetails, useSentNotification } from "../Hooks/Auth";
import axios from "axios";
import TostMessagesTop from ".././Component/Toast";
import Legal_Policies from "./Legal_Policies";
import TermsAndConditions from "./TermsAndConditions";
import Membership_Agreement from "./Membership_Agreement";
import Transaction_Facilitation_Terms from "./Transaction_Facilitation_Terms";
import useGetLoggedInUser from "../Hooks/LoggedInUser/useGetLoggedInUser";
import useGetAuthUser from "../Hooks/LoggedInUser/useGetAuthUser.js";
import Status from "../utils/status.js";

const TermsCondition = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [mandateTokenId, setMandateTokenId] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [rData, setRData] = useState("");
  const [html, setHtml] = useState("");

  const handleTypographyClick = () => {
    setShowVideo(!showVideo);
  };
  const { mutate, isLoading } = useCompanyStepDetails();
  const { data: AuthUserData } = useGetAuthUser();
  const { mutate: SendNotification } = useSentNotification();

  const handleSendNotification = () => {
    SendNotification({
      id: AuthUserData?.data?.companyId,
      status: Status.TNC,
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      handleSendNotification();
      e.returnValue =
        "Please click 'Stay on this Page' and we will give you candy";
      return "Please click 'Stay on this Page' and we will give you candy";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  let navigate = useNavigate();

  async function CreateJustPayCustomer() {
    let datahere = await axios
      .post(`just-pay/create-customer`)
      .then((res) => {});
  }
  const AcceptTerms = async () => {
    mutate(
      { termsAcceptStatus: true },
      {
        onSuccess: async (res) => {
          if (res) {
            const justpaycustomer = await CreateJustPayCustomer();
          }
          navigate("/pricing");
        },
        onError: () => {},
      }
    );
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const declineTerms = () => {
    setOpenSecond(true);
  };

  const [check, setCheck] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);

  const fetchPaymentForm = async () => {
    try {
      const res = await axios.post("enach_mandate/generate_mandate_token");
      const mandate = res?.data?.mandateTokenParams;

      setHtml(res?.data?.formHTML);
      setMandateTokenId(mandate?.mandate_tokenid);
      setMerchantId(mandate?.mercid);
      setRData(mandate?.links?.at(1)?.parameters?.rdata);
    } catch (error) {
      console.error("Error fetching payment form:", error);
    }
  };

  const handleSubmit = () => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://uat1.billdesk.com/u2/web/v1_2/embeddedsdk";
    form.target = "_blank";

    const mandateTokenIdInput = document.createElement("input");
    mandateTokenIdInput.type = "hidden";
    mandateTokenIdInput.name = "mandatetokenid";
    mandateTokenIdInput.value = mandateTokenId;
    form.appendChild(mandateTokenIdInput);

    const merchantIdInput = document.createElement("input");
    merchantIdInput.type = "hidden";
    merchantIdInput.name = "merchantid";
    merchantIdInput.value = merchantId;
    form.appendChild(merchantIdInput);

    const rDataInput = document.createElement("input");
    rDataInput.type = "hidden";
    rDataInput.name = "rdata";
    rDataInput.value = rData;
    form.appendChild(rDataInput);

    document.body.appendChild(form);
    form.submit();
  };

  const {
    data: loggedInCompanyData,
    isLoading: companyLoading,
  } = useGetLoggedInUser();

  const currentDate = new Date().toDateString("dd/MM/yyyy");
  const currentTime = new Date().toLocaleTimeString();

  return (
    <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
      <Paper sx={ChildPaperStyle} elevation={0}>
        <Box sx={MainBoxStyle}>
          <Typography sx={{ ...termstext, mb: 2 }}>
            Please read these terms and conditions carefully before using the
            www.bxiworld.comwebsite. By using the www.bxiworld.com website, you
            signify your agreement to rebound by these conditions.In addition,
            when you use any current or further service of www.bxiworld.com,
            youwill be subject to the terms, conditions, rules and regulations
            applicable to the BXI service.CONDITIONS RELATING TO YOUR USE OF
            WWW.BXIWORLD.COM
          </Typography>
          <Accordion
            sx={accordionStyle}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <Typography sx={termstext}>
                1. Terms and Conditions <MdOutlineExpandMore />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TermsAndConditions
                Date={currentDate}
                Time={currentTime}
                Place={loggedInCompanyData?.data?.CompanyAddress?.City}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={accordionStyle}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary>
              <Typography sx={termstext}>
                2. Legal Policies <MdOutlineExpandMore />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Legal_Policies />
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={accordionStyle}
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary>
              <Typography sx={termstext}>
                3. Membership Agreement <MdOutlineExpandMore />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Membership_Agreement
                Date={currentDate}
                Time={currentTime}
                Place={loggedInCompanyData?.data?.CompanyAddress?.City}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={accordionStyle}
            // expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary>
              <Typography sx={termstext}>
                4. EAAA AGREEMENT <MdOutlineExpandMore />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {" "}
              <Typography
                sx={{ ...termstext, color: "blue", cursor: "pointer" }}
                onClick={() => {
                  var win = window.open(
                    "https://d1tq5769y0bfry.cloudfront.net/centraluat-documents/K6NNC6ZQh6Vaj6F.pdf",
                    "_blank"
                  );
                  win.focus();
                }}
              >
                click Here To view Escrow Pay Aggrement
              </Typography>
              <iframe
                src="https://d1tq5769y0bfry.cloudfront.net/centraluat-documents/K6NNC6ZQh6Vaj6F.pdf#toolbar=0"
                frameborder="0"
                style={{
                  width: 1250,
                  height: 300,
                  display: "hidden",
                }}
              />{" "}
            </AccordionDetails>
          </Accordion>
          <Accordion sx={accordionStyle} onChange={handleChange("panel4")}>
            <AccordionSummary>
              <Typography sx={termstext}>
                5. Transaction Facilitation Terms <MdOutlineExpandMore />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Transaction_Facilitation_Terms
                Date={currentDate}
                Time={currentTime}
                Place={loggedInCompanyData?.data?.CompanyAddress?.City}
              />
            </AccordionDetails>
          </Accordion>
          {mandateTokenId ? null : (
            <Typography sx={CommonTypoStyle}>
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: "20px" },
                  padding: "1px",
                }}
                onClick={() => {
                  if (check === false) {
                    setCheck(true);
                  } else {
                    setCheck(false);
                  }
                }}
              />
              Yes, I understand and agree to the Rules and Regulations of the
              Trading, Privacy Policy, BXI Policies, and EAAA Agreement (By
              agreeing to EAAA Agreement I agree to hold my funds in Escrowpay's
              account willingly and consent for the same). I hereby acknowledge
              and am fully aware that I am legally obligated and bound by the
              aforementioned legal documents.
            </Typography>
          )}
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          gap={2}
        >
          {mandateTokenId ? null : (
            <>
              <Button
                variant="outlined"
                size="large"
                sx={DeclineTypoStyle}
                onClick={declineTerms}
              >
                <Typography sx={CommonTypoStyle}>Decline</Typography>
              </Button>
              <Button
                variant="outlined"
                disabled={check ? false : true}
                size="large"
                sx={ProgressTypoStyle}
                onClick={AcceptTerms}
              >
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    sx={{
                      ...AgreeTypoStyle,
                      color: check ? "#EBEDEE" : "#445FD2",
                    }}
                  >
                    Agree
                  </Typography>
                )}
              </Button>
            </>
          )}
          {mandateTokenId ? (
            <Button
              onClick={handleSubmit}
              sx={{
                width: "200px",
                height: "50px",
                background: "#445FD2",
                borderRadius: "10px",
                textTransform: "none",
                boxShadow: "none",
              }}
            >
              {" "}
              <Typography sx={SignMandateStyle}>Sign Mandate</Typography>
            </Button>
          ) : null}
        </Box>
        <Dialog
          open={openSecond}
          onClose={() => {
            setOpenSecond(false);
          }}
          sx={{
            backdropFilter: "blur(2px)",
          }}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            sx: {
              width: "35%",
              height: "auto",
              minHeight: "200px",
              // maxHeight: "200px",
              borderRadius: "20px",
              justifyContent: "center",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                // bgcolor: "red",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "90%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "18px",
                      lineHeight: "28px",
                      textAlign: "center",
                      color: "#6B7A99",
                    }}
                  >
                    Are you Sure ?
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      textAlign: "center",
                      color: "#475467",
                    }}
                  >
                    Do You really want to decline our Terms & Conditions ?
                  </Typography>
                </Box>
                <CloseIcon
                  sx={{
                    color: "#667085",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (openSecond === false) {
                      setOpenSecond(true);
                    } else {
                      setOpenSecond(false);
                    }
                  }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // bgcolor: "green",
            }}
          >
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    sx={CancelButton}
                    onClick={() => {
                      if (openSecond === false) {
                        setOpenSecond(true);
                      } else {
                        setOpenSecond(false);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      mutate(
                        { termsAcceptStatus: false },
                        {
                          onSuccess: (res) => {
                            navigate("/createaccount");
                          },
                          onError: (err) => {},
                        }
                      );
                    }}
                    size="large"
                    sx={confirmButton}
                  >
                    Confirm
                  </Button>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Paper>
      <TostMessagesTop PageLocation="Bank Details" />
    </Paper>
  );
};

export default TermsCondition;

const termstext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "13px",
    lg: "13px",
    md: "10px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "justify",
  color: "#7D8BA6",
};

const accordionStyle = {
  boxShadow: "none",
  fontFamily: "Poppins",
  backgroundColor: "transparent",
  mt: -5,
  "&:before": {
    display: "none",
  },
  "&:after": {},
};

const CancelButton = {
  background: "transparent",
  borderRadius: "8px",
  textTransform: "none",
  width: {
    xl: "21rem",
    lg: "21rem",
    md: "15rem",
    sm: "10rem",
    xs: "10rem",
  },
  height: {
    xl: "4.4rem",
    lg: "4.4rem",
    md: "3.4rem",
    sm: "2rem",
    xs: "2rem",
  },
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#344054",
  border: "1px solid #D0D5DD",
};

const confirmButton = {
  background: "#445FD2",
  borderRadius: "8px",
  boxShadow: "none",
  textTransform: "none",
  width: {
    xl: "21rem",
    lg: "21rem",
    md: "15rem",
    sm: "10rem",
    xs: "10rem",
  },
  height: {
    xl: "4.4rem",
    lg: "4.4rem",
    md: "3.4rem",
    sm: "2rem",
    xs: "2rem",
  },
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#FFFFFF",
};

const SignMandateStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1rem",
    sm: "1rem",
    xs: "1rem",
  },
  textAlign: "center",
  textTransform: "none",
  color: "#fff",
};

const AgreeTypoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1rem",
    sm: "1rem",
    xs: "1rem",
  },
  textAlign: "center",
  textTransform: "none",
};

const ProgressTypoStyle = {
  color: "white",
  border: "1px solid #EBEDEE",
  background: "#445FD2",
  borderRadius: "10px",
  width: {
    xl: "15.7rem",
    lg: "15.7rem",
    md: "10rem",
    sm: "5rem",
    xs: "5rem",
  },
  height: {
    xl: "5.4rem",
    lg: "5.4rem",
    md: "5rem",
    sm: "3rem",
    xs: "3rem",
  },
  ":hover": {
    background: "#445FD2",
    color: "#fff",
  },
};

const ChildPaperStyle = {
  bgcolor: "#fff",
  boxShadow: "none",
  p: 3,
  borderRadius: "20px",
  height: "auto",
  minHeight: "520px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: "30px",
};

const MainBoxStyle = {
  width: "98%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  overflowY: "scroll",
  gap: "30px",
  p: 1,
};

const CommonTypoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "22px",
  color: "#7D8BA6",
};

const DeclineTypoStyle = {
  borderRadius: "10px",
  textTransform: "none",
  boxShadow: "none",
  width: {
    xl: "15.7rem",
    lg: "15.7rem",
    md: "10rem",
    sm: "5rem",
    xs: "5rem",
  },
  height: {
    xl: "5.4rem",
    lg: "5.4rem",
    md: "5rem",
    sm: "3rem",
    xs: "3rem",
  },
};
