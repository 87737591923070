import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Nodataimgur from "../../assets/Images/datanotfound/NoDataFoundWithLogo.png";
import {
  Box,
  Button,
  Grid,
  Menu,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

const Nodatafound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        mx: "auto",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
        mt: 2,
      }}
    >
      <Box
        component="img"
        src={Nodataimgur}
        sx={{
          width: "90%",
          maxWidth: "250px",
          height: "auto",
          mx: "auto",
        }}
      />
      <Typography
        sx={{
          ...EmptyWishlistTextOne,
          lineHeight: "20px",
        }}
      >
        No Data Found!
        <span style={{ fontWeight: 400, fontSize: "12px" }}>
          Sorry .. This information is not available for a moment.
        </span>
      </Typography>
      <Button sx={EmptyWishlistButton} onClick={() => navigate(-1)}>
        Go Back
      </Button>
    </Box>
  );
};

const EmptyWishlistTextOne = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18px",
  textAlign: "center",
  color: "#7E8BA6",
  display: "flex",
  flexDirection: "column",
};

const EmptyWishlistButton = {
  width: "231.32px",
  height: "36.67px",
  background: "#445FD2",
  borderRadius: "10px",
  color: "white",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  "&:hover": {
    bgcolor: "#445FD2",
  },
};

export default Nodatafound;
