import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Typography,
  Modal,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useGetWishlistData } from "../../Hooks/ProductActions/useGetWishlistData";
import { useRemoveWishlistProduct } from "../../Hooks/ProductActions/useRemoveWishlistProduct";
import BXITokenIcon from "../../assets/BXITokenIcon.png";
import EmptyWishlistImage from "../../assets/Images/CommonImages/EmptyWishlist.png";
import LeftArrow from "../../assets/Images/CommonImages/GoLeft.png";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import sendEvents from "../../utils/sendEvents.js";
import CommaSeprator from "../../components/CommaSeprator";
import Delete from "../../assets/CartPage/delete.png";
import pluscircle from "../../assets/CartPage/pluscircle.png";
import * as Styles from "../../components/common/Styled/Styles.js";
import { useForm } from "react-hook-form";
import {
  useGetWantList,
  useAddToWantList,
  useRemoveWantList,
  useGetSubCategory,
} from "../../Hooks/WantList/WantList.js";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "react-toastify";

const WishlistPage = () => {
  const [opens, setOpen] = useState(1);
  const navigate = useNavigate();
  const tab = location?.state?.tab;
  const [open, setopen] = useState(false);
  const [Switchtab, setSwitchtab] = useState(tab ? tab : "wishlist");
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const {
    data: wishlistData,
    isLoading: wishlistLoading,
    error: wishlistError,
    refetch: wishlistRefetch,
  } = useGetWishlistData();

  const { data: mutateRemoveWishlistData, mutate: removefromwishlist } =
    useRemoveWishlistProduct();

  async function handleRemoveWishlist(id) {
    removefromwishlist(id);
    wishlistRefetch();
  }
  useEffect(() => {
    wishlistRefetch();
  }, [mutateRemoveWishlistData]);

  const GetProductCategory = async (id, type, listingType) => {
    if (listingType === "Voucher") {
      navigate(`/home/voucherdetail/${id._id}`);
    } else {
      await axios
        .get(`product_type/get_productType/${type}`, {
          withCredentials: true,
        })
        .then((res) => {
          if (res?.data?.CompanyTypeName === "Textile") {
            navigate(`/home/productdetail/${id._id}`);
          } else if (res?.data?.CompanyTypeName === "Hotel") {
            navigate(`/home/voucherdetail/${id._id}`);
          } else if (res?.data?.CompanyTypeName === "Mobility") {
            navigate(`/home/productdetail/${id._id}`);
          } else if (res?.data?.CompanyTypeName === "Electronics") {
            navigate(`/home/productdetail/${id._id}`);
          } else if (res?.data?.CompanyTypeName === "FMCG") {
            navigate(`/home/productdetail/${id._id}`);
          } else if (res?.data?.CompanyTypeName === "Office Supply") {
            navigate(`/home/productdetail/${id._id}`);
          } else if (res?.data?.CompanyTypeName === "Others") {
            navigate(`/home/productdetail/${id._id}`);
          } else if (res?.data?.CompanyTypeName === "Lifestyle") {
            navigate(`/home/productdetail/${id._id}`);
          } else if (res?.data?.CompanyTypeName === "Media") {
            navigate(`/home/mediabuying/${id._id}`);
          } else {
            navigate(`/home/productdetail/${id._id}`);
          }
        });
    }
  };

  const {
    data: wantListData,
    refetch: wantListRefetch,
    isLoading: wantListDataLoading,
    isError: wantListDataError,
  } = useGetWantList();

  const {
    mutate: addWantListitem,
    isLoading: wantMutateLoading,
    isError: wantMutateError,
  } = useAddToWantList();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(
      z.object({
        productName: z.string().min(1),
        category: z.string().min(1),
        subCategory: z.string().min(1),
        location: z.string().min(1),
        consumptionPeriod: z.string().min(1),
        qty: z.string().min(1),
        natureofOreder: z.string().min(1),
        consumptionPeriodUnit: z.string().min(1),
        type: z.string().min(1),
        remarks: z.string().min(1),
      })
    ),
  });
  const addWantList = handleSubmit((data) => {
    addWantListitem(data, {
      onSuccess: (res) => {
        handleClose();
        wantListRefetch();
        reset();
      },
      onError: (err) => {},
    });
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleCategoryChange = (e) => {
    const selected = e.target.value;
    setSelectedCategory(selected);
  };

  const {
    data: subCategoryData,
    isLoading: subcategoryLoading,
    isError: subCategoryError,
    isRefetching: subCategoryRefetch,
  } = useGetSubCategory(selectedCategory);

  const { mutate: removeWantItem } = useRemoveWantList();
  const handleDeleteList = (index) => {
    removeWantItem(
      { id: index },
      {
        onSuccess: () => {
          toast.success("Delete Successfully");
          wantListRefetch();
        },
      }
    );
  };

  return (
    <Paper elevation={0} sx={{ boxShadow: "none", background: "transparent" }}>
      <BreadCrumbHeader MainText=" Wishlist  |  Wantlist" />
      {wishlistLoading ? (
        <div>
          <Box sx={{ display: "flex" }}>
            <Skeleton
              variant="round"
              width={"400px"}
              height={"300px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
              }}
            />

            <Skeleton
              variant="round"
              width={"400px"}
              height={"300px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "50px",
              }}
            />
            <Skeleton
              variant="round"
              width={"400px"}
              height={"300px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "50px",
              }}
            />
            <Skeleton
              variant="round"
              width={"400px"}
              height={"300px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "50px",
              }}
            />
            <Button
              onClick={() => {
                sendEvents("Click on Marketplace wishlist");
              }}
            >
              Marketplace Wishlist
            </Button>

            <Button
              onClick={() => {
                setopen(2);
                sendEvents("Click on want list");
              }}
              sx={{
                minWidth: "90px",
                width: "15%",
                height: "35px",
                background: "#FFFFFF",
                boxShadow:
                  open === 2
                    ? "inset 4px 5px 4px rgba(211, 211, 211, 0.25);"
                    : "0px 10px 20px #DCDCDD",

                color: open === 2 ? "#445FD2" : "#000",
                borderRadius: "5px",
                fontFamily: "Outfit",
                fontStyle: "normal",
                fontWeight: 950,
                fontSize: "12px",
                lineHeight: "15px",
                marginLeft: "30px",
                whiteSpace: "nowrap",
                textTransform: "capitalize", // Adjust the value to control the button's curvature
              }}
            >
              Want List
            </Button>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Skeleton
              variant="round"
              width={"400px"}
              height={"300px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
              }}
            />

            <Skeleton
              variant="round"
              width={"400px"}
              height={"300px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "50px",
              }}
            />
            <Skeleton
              variant="round"
              width={"400px"}
              height={"300px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "50px",
              }}
            />
            <Skeleton
              variant="round"
              width={"400px"}
              height={"300px"}
              animation="wave"
              sx={{
                borderRadius: "20px",
                mt: 3,
                width: "100%",
                bgcolor: "#edf1f5",
                mx: "auto",
                marginLeft: "50px",
              }}
            />
          </Box>
        </div>
      ) : (
        <form onSubmit={addWantList}>
          <Grid
            container
            sx={{
              width: "99%",
              mx: "auto",
              mt: "3rem",
              height: "auto",
              background: "transparent",
              borderRadius: "20px",
            }}
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Grid container sx={{ width: "100%" }}>
                  <Grid item xl={1} lg={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/home");
                        sendEvents("Click on left arrow");
                      }}
                    >
                      <img
                        src={LeftArrow}
                        alt="Icon"
                        style={{
                          width: "27px",
                          height: "auto",
                          marginLeft: "13px",
                          marginTop: "11px",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                    <Box
                      style={{
                        gap: "10",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setOpen(false);
                          sendEvents("Click on marketplace wish list");
                          setSwitchtab("wishlist");
                        }}
                        sx={{
                          minWidth: "90px",
                          width: "150px",
                          height: "35px",
                          background: "#FFFFFF",
                          boxShadow:
                            Switchtab === "wishlist"
                              ? "0px 10px 20px #DCDCDD"
                              : "inset 4px 5px 4px rgba(211, 211, 211, 0.25);",
                          borderRadius: "5px",
                          fontFamily: "Outfit",
                          fontStyle: "normal",
                          fontWeight: 950,
                          fontSize: "12px",
                          lineHeight: "15px",
                          color: Switchtab === "wishlist" ? "#445FD2" : "#000",
                          whiteSpace: "nowrap",
                          textTransform: "capitalize", // Adjust the value to control the button's curvature
                        }}
                      >
                        My Wishlist
                      </Button>
                      <Button
                        onClick={() => {
                          setSwitchtab("wantlist");
                        }}
                        sx={{
                          minWidth: "90px",
                          width: "150px",
                          height: "35px",
                          background: "#FFFFFF",
                          boxShadow:
                            Switchtab === "wantlist"
                              ? "0px 10px 20px #DCDCDD"
                              : "inset 4px 5px 4px rgba(211, 211, 211, 0.25);",
                          borderRadius: "5px",
                          fontFamily: "Outfit",
                          fontStyle: "normal",
                          fontWeight: 950,
                          fontSize: "12px",
                          lineHeight: "15px",
                          marginLeft: "30px",
                          color: Switchtab === "wantlist" ? "#445FD2" : "#000",
                          whiteSpace: "nowrap",
                          textTransform: "capitalize", // Adjust the value to control the button's curvature
                        }}
                      >
                        My Want List
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xl={1} lg={1}>
                    {Switchtab !== "wishlist" ? (
                      <Box
                        onClick={handleOpen}
                        component={"img"}
                        src={pluscircle}
                        sx={{
                          height: "25px",
                          width: "25px",
                          cursor: "pointer",
                          mt: "07px",
                        }}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
              <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={modalText}
                    >
                      Add : More Interest
                    </Typography>
                    <Box
                      sx={{ ...modalText, cursor: "pointer" }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      X
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "90%",
                      mx: "auto",
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography sx={modalText}>
                      Name of Product/Voucher/media
                    </Typography>
                    <input
                      fullWidth
                      style={InputStyle}
                      placeholder="Apple iphone 15(128GB storage , red)"
                      {...register("productName")}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 1,
                      }}
                    >
                      <Box sx={adjInput}>
                        <Typography sx={modalText}>Category</Typography>
                        <select
                          style={{
                            ...InputStyle,
                            width: "100%",
                            borderRedius: "10px",
                            minWidth: "300px",
                          }}
                          fullWidth
                          placeholder="Select"
                          {...register("category")}
                          onChange={handleCategoryChange}
                        >
                          <option value="">Select Category</option>
                          <option value={"textile"}> textile </option>
                          <option value={"lifestyle"}> lifestyle </option>
                          <option value={"electronics"}> electronics </option>
                          <option value={"mobility"}> mobility </option>
                          <option value={"officesupply"}> officesupply </option>
                          <option value={"other"}> other </option>
                          <option value={"fmcg"}> fmcg </option>
                          <option value={"mediaonline"}> mediaonline </option>
                          <option value={"mediaoffline"}> mediaoffline </option>
                          <option value={"hotel"}> hotel </option>
                          <option value={"qsr"}> QSR </option>
                        </select>
                      </Box>
                      <Box sx={adjInput}>
                        <Typography sx={modalText}> Subcategory</Typography>
                        <select
                          style={{
                            ...InputStyle,
                            width: "100%",
                            borderRedius: "10px",
                            minWidth: "300px",
                          }}
                          fullWidth
                          placeholder="Select"
                          {...register("subCategory")}
                        >
                          {subCategoryData?.data?.map((subdata) => {
                            let subcategoryName = subdata.SubcategoryType; // Default value to SubcategoryType

                            // Handle cases where SubcategoryType doesn't exist or has a different key name
                            if (!subcategoryName) {
                              // Check if other key names exist for SubcategoryType
                              if (subdata.HotelFeature) {
                                subcategoryName = subdata.HotelFeature;
                              } else if (subdata.SampleMobilityCategoryType) {
                                subcategoryName =
                                  subdata.SampleMobilityCategoryType;
                              } else if (subdata.OtherSub) {
                                subcategoryName = subdata.OtherSub;
                              } else if (subdata.SampleFmcgCategoryType) {
                                subcategoryName =
                                  subdata.SampleFmcgCategoryType;
                              } else if (subdata.Mediaonlinecategorysingle) {
                                subcategoryName =
                                  subdata.Mediaonlinecategorysingle;
                              } else if (subdata.Mediaofflinecategory) {
                                subcategoryName = subdata.Mediaofflinecategory;
                              } else if (subdata.SampleCategoryType) {
                                subcategoryName = subdata.SampleCategoryType;
                              } else if (subdata.RestuarantQsrCategoryType) {
                                subcategoryName =
                                  subdata?.RestuarantQsrCategoryType;
                              }
                              // Add more conditions as per your data structure
                            }
                            return (
                              <option key={subdata._id} value={subcategoryName}>
                                {subcategoryName}
                              </option>
                            );
                          })}
                        </select>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={adjInput}>
                        <Typography sx={modalText}>Location </Typography>
                        <input
                          style={{ ...InputStyle, minWidth: "280px" }}
                          fullWidth
                          placeholder="Maharashtra"
                          {...register("location")}
                        />
                      </Box>
                      <Box sx={adjInput}>
                        <Typography sx={modalText}>
                          Within how many days do you want this?{" "}
                        </Typography>
                        <Box
                          sx={{
                            width: "100%",
                            height: "42px",
                            mt: "1%",
                            borderRadius: "0px",
                            border: "1px solid #E6E9EE",
                          }}
                        >
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            placeholder="70"
                            InputProps={{
                              disableUnderline: "true",
                              style: {
                                color: "#445FD2",
                                fontSize: "14px",
                                padding: "7px",
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: "red",
                              },
                            }}
                            {...register("consumptionPeriod")}
                            sx={{
                              width: "80%",
                              height: "100%",
                              background: "#FFFFFF",
                              borderRadius: "10px 0px 0px 10px",
                            }}
                          />
                          <Select
                            sx={GW}
                            defaultValue={"Days"}
                            {...register("consumptionPeriodUnit")}
                          >
                            <MenuItem sx={MenuItems} value="Days">
                              Days
                            </MenuItem>
                            <MenuItem sx={MenuItems} value="Weeks">
                              Weeks
                            </MenuItem>
                            <MenuItem sx={MenuItems} value="months">
                              months
                            </MenuItem>
                            <MenuItem sx={MenuItems} value="Years">
                              Years
                            </MenuItem>
                          </Select>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 0,
                      }}
                    >
                      <Box sx={adjInput}>
                        <Typography sx={modalText}>Quanitity </Typography>
                        <input
                          style={InputStyle}
                          fullWidth
                          placeholder="500"
                          {...register("qty")}
                        />
                      </Box>
                      <Box sx={adjInput}>
                        <Typography sx={modalText}>Nature of order </Typography>
                        <select
                          style={{
                            ...InputStyle,
                            width: "100%",
                            borderRedius: "10px",
                            minWidth: "200px",
                          }}
                          fullWidth
                          placeholder="Select"
                          {...register("natureofOreder")}
                        >
                          <option value={"oneTime"}> One Time </option>
                          <option value={"repetition"}> repetition </option>
                        </select>
                      </Box>
                      <Box sx={adjInput}>
                        <Typography sx={modalText}>Type </Typography>
                        <select
                          style={{
                            ...InputStyle,
                            width: "100%",
                            borderRedius: "10px",
                            minWidth: "200px",
                          }}
                          fullWidth
                          placeholder="Select"
                          {...register("type")}
                        >
                          <option value={"product"}> Product </option>
                          <option value={"voucher"}>Voucher </option>
                        </select>
                      </Box>
                    </Box>
                    <Typography sx={modalText}>
                      Please share additional Remarks if any!
                    </Typography>
                    <input
                      fullWidth
                      style={InputStyle}
                      placeholder="If any..."
                      {...register("remarks")}
                    />
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        maxWidth: "450px",
                        mx: "auto",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        gap: "1rem",
                        alignContent: "center",
                      }}
                    >
                      <Button
                        sx={Styles.AddMemberBtn}
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Cancel
                      </Button>
                      {wantMutateLoading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <Button
                            sx={{
                              ...Styles.AddMemberBtn,
                              background: "#445FD2",
                              color: "#fff",
                              "&:hover": {
                                border: "1px solid #445FD2",
                                color: "#445FD2",
                              },
                            }}
                            onClick={() => {
                              addWantList();
                            }}
                          >
                            Save
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Grid>
            {Switchtab === "wishlist" ? (
              <Grid container sx={{ width: "100%" }}>
                {wishlistData?.length === 0 ? (
                  <EmptyWishlistPage />
                ) : (
                  wishlistData
                    ?.slice()
                    ?.reverse()
                    ?.map((res, idx) => {
                      return (
                        <Grid
                          key={idx}
                          item
                          xl={3}
                          lg={3}
                          md={4}
                          sm={6}
                          xs={12}
                          sx={{ mt: 3 }}
                        >
                          <Paper
                            elevation={0}
                            sx={{
                              bgcolor: "transparent",
                              borderRadius: "15px",
                              maxWidth: "280px",
                              // height: "auto",
                              // minHeight: "250px",
                              // mx: "auto",
                              mx: {
                                xl: "auto",
                                lg: 1,
                                md: 1,
                                sm: 1,
                                xs: "auto",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                height: "auto",
                                minHeight: "280px",
                                bgcolor: "#fff",
                                borderRadius: "13.6px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                p: 0.1,
                                cursor: "pointer",
                                // border: "0.8px solid",
                                backgroundImage:
                                  "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                                // borderImageSlice: 1,
                                transition: "box-shadow .1s",
                                "&:hover": {
                                  boxShadow: "0px 0px 17px #ff864547",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  mx: "auto",
                                  height: "auto",
                                  minHeight: "280px",
                                  bgcolor: "#fff",
                                  borderRadius: "13px",
                                  padding: "5px",
                                  // border: "0.8px solid",
                                  // borderImage:
                                  //   "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                                  // borderImageSlice: 1,
                                }}
                              >
                                <Box
                                  sx={
                                    {
                                      // width: "300px",
                                      // height: "25px",
                                    }
                                  }
                                >
                                  <button
                                    style={{
                                      width: "35px",
                                      float: "right",
                                      borderRadius: "20px",
                                      height: "30px",
                                      border: "none",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <AiFillHeart
                                      color={"red"}
                                      size={"25px"}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleRemoveWishlist(res?._id)
                                      }
                                    />
                                  </button>
                                </Box>
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "auto",
                                    minHeight: "160px",
                                    mx: "auto",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    GetProductCategory(
                                      res?.ProductId,
                                      res?.ProductType,
                                      res?.ProductId?.ListingType
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      res?.ProductId?.VoucherImages?.at(0)?.url
                                        ? res?.ProductId?.VoucherImages?.at(0)
                                            ?.url
                                        : res?.ProductId?.ProductImages[0]?.url
                                    }
                                    alt="img"
                                    style={{
                                      width: "auto",
                                      maxWidth: "230px",
                                      maxHeight: "130px",
                                      height: "auto",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    width: "100%",
                                    mx: "auto",
                                    height: "auto",
                                    display: "grid",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    pb: 2,
                                    cursor: "pointer",
                                    mt: 1,
                                  }}
                                  onClick={() => {
                                    GetProductCategory(
                                      res?.ProductId,
                                      res?.ProductType,
                                      res?.ProductId?.ListingType
                                    );
                                  }}
                                >
                                  <Paper
                                    sx={{
                                      width: "90%",
                                      mx: "auto",
                                      height: "auto",
                                      minWidth: "240px",
                                      maxWidth: "275px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignContent: "center",
                                    }}
                                    elevation={0}
                                  >
                                    <Typography
                                      sx={{
                                        ...MainTextStyle,
                                        display: "-webkit-box",
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {res?.ProductId?.ProductName}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        ...PriceTextStyle,
                                        display: "flex",
                                        gap: "5px",
                                        alignItems: "center",
                                        alignContent: "center",
                                      }}
                                    >
                                      <img
                                        src={BXITokenIcon}
                                        alt="Icon"
                                        style={{
                                          width: "18px",
                                          height: "18px",
                                        }}
                                      />

                                      <CommaSeprator
                                        Price={
                                          res?.ProductId?.ProductsVariantions[0]
                                            ?.DiscountedPrice
                                        }
                                      />
                                    </Typography>{" "}
                                  </Paper>
                                  <Paper
                                    sx={{
                                      width: "90%",
                                      height: "auto",
                                      mx: "auto",
                                      minWidth: "240px",
                                      maxWidth: "275px",
                                    }}
                                    elevation={0}
                                  >
                                    <Typography sx={BottomTextStyle}>
                                      {res?.ProductId?.ProductDescription}
                                    </Typography>
                                  </Paper>
                                  {/* <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  alignContent: "center",
                                  mt: 2,
                                }}
                              >
                               <Button
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "17px",
                            lineHeight: "26px",
                            color: "#6B7A99",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "#445FD2",
                            },
                          }}
                          onClick={() => handleAddToCart(res?.ProductId)}
                        >
                          Add to cart
                        </Button> 
                                 {cartItems?.find(
                          (item) =>
                            item?.ProductId?._id === res?.ProductId?._id
                        ) ? (
                          <Button
                            sx={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: "17px",
                              lineHeight: "26px",
                              color: "#6B7A99",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "transparent",
                                color: "#445FD2",
                              },
                            }}
                            onClick={() => {
                              navigate("/home/cart");
                            }}
                          >
                            Go To cart
                          </Button>
                        ) : (
                          <Button
                            sx={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: "17px",
                              lineHeight: "26px",
                              color: "#6B7A99",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "transparent",
                                color: "#445FD2",
                              },
                            }}
                            onClick={() => handleAddToCart(res?.ProductId)}
                          >
                            Add to cart
                          </Button>
                        )} 
                              </Box> */}
                                </Box>
                              </Box>
                            </Box>
                          </Paper>
                        </Grid>
                      );
                    })
                )}
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ background: "#fff", borderRadius: "10px", p: 2, mt: 2 }}
                >
                  <Grid
                    container
                    sx={{
                      height: "35px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "99%",
                      borderBottom: "1px solid #F5F5F5",
                    }}
                  >
                    <Grid
                      item
                      xl={0.5}
                      lg={0.5}
                      md={0.5}
                      sm={0.5}
                      xs={0.5}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={tableheading}>No</Typography>
                    </Grid>
                    <Grid
                      item
                      xl={1.5}
                      lg={1.5}
                      md={1.5}
                      sm={1.5}
                      xs={1.5}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={tableheading}>
                        Product / Service Name
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography sx={tableheading}>Type</Typography>
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography align="center" sx={tableheading}>
                        Category
                      </Typography>
                    </Grid>
                    <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                      <Typography align="center" sx={tableheading}>
                        Sub Category
                      </Typography>
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography align="center" sx={tableheading}>
                        Nature
                      </Typography>
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography align="center" sx={tableheading}>
                        QTY
                      </Typography>
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography align="center" sx={tableheading}>
                        Location
                      </Typography>
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography align="center" sx={tableheading}>
                        Time
                      </Typography>
                    </Grid>
                    <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                      <Typography align="center" sx={tableheading}>
                        Remark
                      </Typography>
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Typography align="center" sx={tableheading}>
                        Action
                      </Typography>
                    </Grid>
                  </Grid>
                  {wantListData?.data?.map((data, idx) => {
                    return (
                      <Box sx={{ borderBottom: "1px solid #F5F5F5" }}>
                        <Grid container mt={2}>
                          <Grid
                            item
                            xl={0.5}
                            lg={0.5}
                            md={0.5}
                            sm={0.5}
                            xs={0.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {idx + 1}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                fontSize: "12px",
                                textAlign: "center",
                                alignItems: "center",
                              }}
                            >
                              {/* <CompanyName
                                    CompanyId={data.SellerCompanyId}
                                    CompanyName={
                                      data?.SellerDetails?.SellerCompanyName
                                    }
                                    CompanyTypeName={
                                      data?.SellerDetails?.CompanyTypeName
                                    }
                                  /> */}
                              {data?.productName}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {data?.type}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {data?.category}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              {data?.subCategory}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {data?.natureofOreder}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                fontSize: "12px",

                                mx: "auto",
                              }}
                            >
                              {data?.qty}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                fontSize: "12px",

                                mx: "auto",
                              }}
                            >
                              {data?.location}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                              }}
                            >
                              {data?.consumptionPeriod}{" "}
                              {data?.consumptionPeriodUnit}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                              }}
                            >
                              {data?.remarks.slice(0, 10) +
                                (data?.remarks.length > 10 ? "..." : "")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              // justifyContent: 'flex-end', // Align to the right side
                              // display: 'flex',
                            }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                marginRight: "40px",
                                mb: "10px",
                                mx: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                              onClick={() => handleDeleteList(data?._id)}
                            >
                              <Box
                                component={"img"}
                                src={Delete}
                                sx={{
                                  width: "40px",
                                  height: "auto",
                                  mt: -1,
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Grid>
              </>
            )}
          </Grid>
        </form>
      )}
    </Paper>
  );
};

export default WishlistPage;

const PriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: { xl: "16px", lg: "16px", md: "15px", sm: "15px", xs: "15px" },
  lineHeight: "24px",
  color: "#717171",
};

const MainTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "18px", lg: "18px", md: "18px", sm: "16px", xs: "16px" },
  lineHeight: "30px",
  maxWidth: "250px",
  color: "#141414",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const BottomTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
  color: "#6B7A99",
  textAlign: "left",
  mt: 1,
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const EmptyWishlistPage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "400px",
        mx: "auto",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
        mt: 2,
      }}
    >
      <Box
        component="img"
        src={EmptyWishlistImage}
        sx={{
          width: "90%",
          maxWidth: "230px",
          height: "auto",
          mx: "auto",
        }}
      />
      <Typography sx={EmptyWishlistTextOne}>
        Your Wishlist is currently empty
      </Typography>
      <Typography
        sx={{ ...EmptyWishlistTextOne, fontSize: "12px", lineHeight: "0px" }}
      >
        Seems like you don’t have wishes here .{" "}
      </Typography>
      <Typography
        sx={{
          ...EmptyWishlistTextOne,
          fontSize: "12px",
          lineHeight: "0px",
        }}
      >
        Make a Wish !
      </Typography>
      <Button
        sx={EmptyWishlistButton}
        onClick={() => {
          navigate("/home");
          sendEvents("Click on start shopping");
        }}
      >
        Start Shopping
      </Button>
    </Box>
  );
};

const EmptyWishlistTextOne = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "27px",
  textAlign: "center",
  color: "#7E8BA6",
};

const EmptyWishlistButton = {
  width: "231.32px",
  height: "36.67px",
  background: "#445FD2",
  borderRadius: "10px",
  color: "white",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  "&:hover": {
    bgcolor: "#445FD2",
  },
};

const morphicButtonStyle = {
  width: "89px",
  height: "35px ",
  background: "#FFFFFF",
  boxShadow: "0px 10px 20px #DCDCDD",
  borderRadius: "5px",
  // hover effect
  "&:hover": {
    background: "#FFFFFF",
    boxShadow: "inset 4px 5px 4px rgba(211, 211, 211, 0.25)",
    borderRadius: "5px",
  },
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "11px",
  lineHeight: "15px",
  color: "#000000",
  textTransform: "none",
};

const tableheading = {
  fontFamily: "Poppins",
  color: "#7E8BA6",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 500,
  textTransform: "Capitalize",
};

const TableBodyTextStyling = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#1B212D",
};

const modalText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: 10.27,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: -0.031,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const InputStyle = {
  height: "34px",
  width: "100%",
  // maxWidth: '350px',
  // minWidth: '350px',
  borderRadius: "4px",
  padding: "11px 363px 11px 16",
  border: "1.5px solid #E6E9EE",
  paddingLeft: "16px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "22px",
  display: "flex",
  alignItems: "center",
  color: "#445FD2",
};

const adjInput = {
  height: "70px",
  display: "grid",
};
const GW = {
  width: "20%",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  background: "#FFFFFF",
  height: "100%",
  color: "#6B7A99",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  borderRadius: "0px 10px 10px 0px",
};
const MenuItems = {
  fontSize: "12px",
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
};
