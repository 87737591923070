import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Table,
  Paper,
  Button,
  Dialog,
  TableRow,
  TableCell,
  Accordion,
  TableHead,
  Typography,
  DialogTitle,
  DialogContent,
  TableContainer,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import useStyles from "../CartPage/Styles";
import Skeleton from "@mui/material/Skeleton";
import CallIcon from "@mui/icons-material/Call";
import Pagination from "@mui/material/Pagination";
import sendEvents from "../../utils/sendEvents.js";
import { useSelector, useDispatch } from "react-redux";
import CloseIconModel from "@mui/icons-material/Close";
import CompanyName from "../../components/CompanyName";
import CloseIconDialog from "@mui/icons-material/Close";
import { useParams, useNavigate } from "react-router-dom";
import { useGetLoggedInUser } from "./OrdersInvoicesHooks";
import stackofcoins from "../../assets/Stack of Coins.svg";
import CommaSeprator from "../../components/CommaSeprator";
import stackofcoin from "../../assets/CartPage/unnamed 1.svg";
import ChatIcon from "../../assets/HeaderIcon/companychat.png";
import DownIcon from "../../assets/Images/CommonImages/Down.png";
import * as Styles from "../../components/common/Styled/Styles.js";
import Nodatafound from "../../components/NoDataFound/NoDataFound";
import { sendMassage } from "../../redux/action/Chat/Send-Massages";
import Checkboxbase from "../../assets/HeaderIcon/Checkboxbase.svg";
import Featuredicon from "../../assets/HeaderIcon/Featuredicon.svg";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { notifications } from "../../redux/action/Notification/notification";
import { OrderTracking } from "../../redux/action/Order-Tracking/Order-Tracking-Status";
import StyledTypography from "../../components/common/StyledTypography.jsx";
import ImageAndProductCarousel from "../../components/Carousel/ImageAndProductCarousel";

// table Header Data
let SellerDataTableHeadArray = [
  "Sr No",
  "Date",
  "Buyer Name",
  "Order Id",
  "Total Products",
  "Barter Coins",
  "Inr Total",
  "Order Status",
  "Order Summary",
  "Buyer Contact",
];

const SellerOrderList = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { orderTracking } = useSelector((state) => state.orderTracking);
  const { data: loggedInUserData } = useGetLoggedInUser();

  const GetCompanyByID = async (id) => {
    try {
      const response = await axios.get(`/company/get_company/${id}`, {
        withCredentials: true,
      });
      const data = response.data;
      setSellarData(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (orderTracking?.invoiceId?.SellerDetails?.SellerCompanyId) {
      GetCompanyByID(orderTracking.invoiceId.SellerDetails.SellerCompanyId);
    }
  }, [orderTracking?.invoiceId?.SellerDetails?.SellerCompanyId]);

  useEffect(() => {
    dispatch(OrderTracking(id));
  }, [dispatch]);

  const classes = useStyles();
  let navigate = useNavigate();
  const [Databyid, setDatabyid] = useState();
  const [loading, setLoading] = useState(false);
  const [Infoopen, setInfoOpen] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [userOrders, setUserOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sellerOrders, setSellerOrders] = useState([]);
  const [SellerLoading, setSellerLoading] = useState(false);

  // Active pannel
  const [activePanel, setActivePanel] = useState(
    Location?.state ? Location?.state : 0
  );

  // off panel
  const switchPanel = (panelIndex) => {
    setActivePanel(panelIndex);
  };

  const handlePageChange = (event, page) => {
    GetSellerOrders(page);
    setCurrentPage(page);
  };

  const handleClose = () => {
    setOpenChat(false);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const handleClickInfoOpen = () => {
    setInfoOpen(true);
  };

  // GET Seller User Data
  const GetSellerOrders = async (props) => {
    setSellerLoading(true);
    return await axios
      .get(`order/get-seller-orders`, {
        params: {
          page: currentPage,
          limit: 10,
        },
      })
      .then((res) => {
        if (res?.data) {
          setSellerLoading(false);
        }
        setSellerOrders(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    GetSellerOrders();
  }, [currentPage]);

  const itemsPerPage = 10;
  const totalItems = sellerOrders.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
        boxShadow: "none",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText="Orders" />

      {SellerLoading ? (
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {Array.from(new Array(10)).map((item, index) => {
            return <SkeletonFun />;
          })}
        </Box>
      ) : (
        <React.Fragment>
          <TableContainer>
            <Table sx={{ borderBottom: "none" }}>
              <TableHead>
                <TableRow>
                  {SellerDataTableHeadArray.map((data, idx) => {
                    return (
                      <TableCell
                        key={idx}
                        sx={{
                          ...Styles.tableheading,
                          pl: 2,
                          textAlign: "left",
                          width: data?.width,
                          fontSize: "13px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          width: data === "Buyer Name" ? "250px" : "auto",
                        }}
                      >
                        {data}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>

          {sellerOrders?.data?.length <= 0 ? (
            <Nodatafound />
          ) : (
            sellerOrders?.data?.map((data, idx) => {
              let totalPrice = 0;

              totalPrice += data?.BXITokenDetails
                ? data?.BXITokenDetails?.TotalBXiCoins +
                    data?.BXITokenDetails?.TotalAdCostInBxiWithoutGST ||
                  data?.BXITokenDetails?.totalProductTokens +
                    data?.BXITokenDetails?.totalAdCostTokens
                : data?.POTotals?.TotalCoinsWithAdditionalCost;

              function convertDate(inputFormat) {
                function pad(s) {
                  return s < 10 ? "0" + s : s;
                }
                var d = new Date(inputFormat);
                return [
                  pad(d.getDate()),
                  pad(d.getMonth() + 1),
                  d.getFullYear(),
                ].join("/");
              }

              return (
                <Accordion
                  sx={{
                    boxShadow: "none",
                    border: "none",
                    borderBottom: "1px solid #F5F5F5",
                    "&::before": {
                      display: "none",
                    },
                    mt: 0.5,
                    // px: 1,
                    borderRadius: "5px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<img src={DownIcon} style={{ width: "9px" }} />}
                    sx={{
                      // p: 0,
                      m: 0,
                      width: "100%",
                      height: "70px",
                    }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid
                      container
                      sx={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Grid
                        item
                        xl={0.5}
                        lg={0.5}
                        md={0.5}
                        sm={0.5}
                        xs={0.5}
                        sx={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <StyledTypography
                          text={idx + 1}
                          style={Styles.TableBodyTextStyling}
                        />
                      </Grid>
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <StyledTypography
                          text={
                            data?.IsVoucher === false
                              ? convertDate(data?.PurchaseOrderData?.PoDate)
                              : convertDate(data?.PurchaseOrderData?.OrderDate)
                          }
                          style={Styles.TableBodyTextStyling}
                        />
                      </Grid>
                      <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                        <ImageAndProductCarousel
                          MaxWidth={true}
                          carouselData={data?.PurchaseOrderData?.ProductData}
                        />
                      </Grid>
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <StyledTypography
                          text={data?.OrderId}
                          style={Styles.TableBodyTextStyling}
                        />
                      </Grid>
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <StyledTypography
                          text={
                            <CommaSeprator
                              Price={
                                data?.PurchaseOrderData?.ProductData?.length
                              }
                            />
                          }
                          style={Styles.TableBodyTextStyling}
                        />
                      </Grid>
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <StyledTypography
                          text={
                            <>
                              <img
                                src={stackofcoin}
                                alt="rupieicon"
                                style={{
                                  width: "15px",
                                  height: "auto",
                                }}
                              />
                              <CommaSeprator Price={totalPrice} />
                            </>
                          }
                          style={Styles.TableBodyTextStyling}
                          textlength={2}
                        />
                      </Grid>

                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <StyledTypography
                          text={
                            (data?.INRDetails
                            ? data?.INRDetails?.TotalInrToPay
                            : data?.PurchaseOrderData?.POTotals
                                ?.TotalGstAmount +
                              data?.PurchaseOrderData?.POTotals
                                ?.TotalAdditionalCostWtihoutGSTInRupee)
                              ? (data?.INRDetails
                                  ? data?.INRDetails?.TotalInrToPay
                                  : data?.PurchaseOrderData?.POTotals
                                      ?.TotalGstAmount +
                                    data?.PurchaseOrderData?.POTotals
                                      ?.TotalAdditionalCostWtihoutGSTInRupee
                                )?.toFixed(2)
                              : "--"
                          }
                          style={Styles.TableBodyTextStyling}
                        />
                      </Grid>

                      <Grid
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1.5}
                        sm={1.5}
                        xs={1.5}
                        sx={{
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "start",
                          display: "flex",
                          pl: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            ...Styles.TableBodyTextStyling,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "2px",
                            width: "100px",
                            height: "30px",
                            bgcolor:
                              data?.OrderStatus === "Out For delivery" ||
                              data?.MediaStatus === "Cancelled" ||
                              data?.VoucherStatus === "InActive"
                                ? "#FFEFEF"
                                : data?.OrderStatus === "Ready To Ship" ||
                                  data?.OrderStatus === "Progress" ||
                                  data?.OrderStatus === "In Transit" ||
                                  data?.MediaStatus === "Executed" ||
                                  data?.OrderStatus === "Reached Nearest hub"
                                ? "#FFF1E5"
                                : data?.OrderStatus === "Delivered" ||
                                  data?.OrderStatus === "Shipped" ||
                                  data?.MediaStatus === "Completed" ||
                                  data?.VoucherStatus === "Active" ||
                                  data?.MediaStatus === "Creative Approved"
                                ? "#D9FFE9"
                                : "#FFF380",
                            borderRadius: "5px",
                            color:
                              data?.OrderStatus === "Out For delivery" ||
                              data?.MediaStatus === "Cancelled" ||
                              data?.VoucherStatus === "InActive"
                                ? "#EB5757"
                                : data?.OrderStatus === "Ready To Ship" ||
                                  data?.OrderStatus === "Progress" ||
                                  data?.MediaStatus === "Executed" ||
                                  data?.OrderStatus === "In Transit" ||
                                  data?.OrderStatus === "Reached Nearest hub"
                                ? "#F2994A"
                                : data?.OrderStatus === "Delivered" ||
                                  data?.OrderStatus === "Shipped" ||
                                  data?.MediaStatus === "Completed" ||
                                  data?.VoucherStatus === "Active" ||
                                  data?.MediaStatus === "Creative Approved"
                                ? "#27AE60"
                                : "black",
                          }}
                        >
                          {data.OrderStatus
                            ? data.OrderStatus
                            : data?.IsVoucher && !data?.VoucherStatus
                            ? "InActive"
                            : data?.MediaStatus ||
                              data?.VoucherStatus ||
                              "Pending"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1.5}
                        sm={1.5}
                        xs={1.5}
                        sx={{
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "start",
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            cursor: "pointer",
                            width: "100px",
                            height: "30px",
                            background: "#c64091",
                            borderRadius: "4.39877px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                          onClick={() => {
                            navigate(
                              `/home/orderdetails/${data?._id}/${data?.PurchaseOrderData?.SellerDetails?.SellerCompanyId}/seller`
                            );
                          }}
                        >
                          <Typography
                            sx={{
                              ...Styles.TableBodyTextStyling,
                              color: "#fff",
                            }}
                          >
                            View
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        {data?.WalletData === undefined ||
                        data.WalletData === null ? (
                          "--"
                        ) : (
                          <Button
                            sx={{
                              width: "50px",
                              height: "auto",
                              cursor: "pointer",
                              color: "#445FD2",
                            }}
                            onClick={() => {
                              handleClickInfoOpen();
                              setDatabyid(data);
                            }}
                          >
                            <CallIcon
                              sx={{
                                color: "primary.main",
                                fontSize: "20px",
                              }}
                            />
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0, pb: 2 }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        p: 0,
                        ml: 1,
                        width: "30%",
                      }}
                    >
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <StyledTypography
                          text={
                            <CompanyName
                              CompanyId={
                                data?.PurchaseOrderData?.SellerCompanyId
                              }
                              CompanyName={
                                data?.PurchaseOrderData?.SellerDetails
                                  ?.SellerCompanyName
                              }
                            />
                          }
                          style={Styles.TableBodyTextStyling}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })
          )}
        </React.Fragment>
      )}

      <Dialog
        open={Infoopen}
        onClose={handleInfoClose}
        fullWidth
        maxWidth="sm"
        sx={{
          backdropFilter: "blur(2px)",
        }}
        PaperProps={{
          sx: {
            width: "60%",
            maxHeight: 600,
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "80%",
                mx: "auto",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: "#6B7A99",
                  textAlign: "center",
                }}
              >
                More information
              </Typography>
            </Box>
            <Box>
              <CloseIconDialog
                sx={{
                  color: "#667085",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (open === false) {
                    setInfoOpen(true);
                  } else {
                    setInfoOpen(false);
                  }
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              // border: "1px solid #EDEFF2",
              // borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // p: 2,
            }}
          >
            <Box sx={infoboxStyle}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "20px",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    ...HeadText,
                    bgcolor: "#fff",
                    zIndex: "1",
                  }}
                >
                  Buyer Details
                </Typography>
                <span
                  style={{
                    borderTop: "0.5px solid #6B7A99",
                    width: "75%",
                    position: "absolute",
                    top: "50%",
                    left: "25%",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  ...SubDataHeadText,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Name :{" "}
                <span
                  style={{
                    ...SubDataText,
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Box
                    src={
                      Databyid?.PurchaseOrderData?.BuyerDetails
                        ?.BuyerCompanyLogo
                    }
                    component={"img"}
                    sx={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "30px",
                      fontSize: "14px",
                    }}
                  />
                  {Databyid?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyName}
                </span>
              </Typography>
              <Typography sx={SubDataHeadText}>
                Email :{" "}
                <span style={SubDataText}>
                  {Databyid?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyEmail}
                </span>
              </Typography>
              <Typography sx={SubDataHeadText}>
                Phone :{" "}
                <span style={SubDataText}>
                  {
                    Databyid?.PurchaseOrderData?.BuyerDetails
                      ?.BuyerCompanyContact
                  }
                </span>
              </Typography>
              <Typography sx={SubDataHeadText}>
                Address :{" "}
                <span style={SubDataText}>
                  {
                    Databyid?.PurchaseOrderData?.BuyerDetails?.Address
                      ?.AddressLine
                  }
                </span>
              </Typography>
              <Typography sx={SubDataHeadText}>
                Product Name :{" "}
                <span style={SubDataText}>
                  {Databyid?.PurchaseOrderData?.ProductData?.at(0)?.ProductName}
                </span>
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
        <Stack spacing={2}>
          <StyledPagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

export default SellerOrderList;

const HeaderIconStyle = {
  height: "auto",
  width: {
    xl: "25px",
    lg: "25px",
    md: "25px",
    sm: "20px",
    xs: "20px",
  },
  mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
};

const infoboxStyle = {
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "8px",
};

const HeadText = {
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 500,
  color: "#191919CC",
  textAlign: "left",
  py: 1,
  width: "auto",
  MinWidth: "25%",
  pr: 1,
};

const SubDataHeadText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
  textAlign: "left",
};
const SubDataText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "left",
};

const StyledPagination = styled(Pagination)({
  "& .MuiPagination-ul li:last-child": {
    marginLeft: "16px",
  },
  "& .MuiPagination-ul li:last-child button::before": {
    content: "'Last'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "9px",
    lineHeight: "14px",
    color: "#445FD2",
    marginRight: "8px",
  },
  "& .MuiPagination-ul li:first-child": {
    marginRight: "16px",
  },
  "& .MuiPagination-ul li:first-child button::after": {
    content: "'First'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "9px",
    lineHeight: "14px",
    color: "#445FD2",
    marginLeft: "8px",
  },
  "& .MuiPaginationItem-icon": {
    color: "#445FD2",
    fontWeight: 800,
  },
});

const SkeletonFun = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={"1300px"}
      height={"60px"}
      animation="wave"
      sx={{
        borderRadius: "5px",
        mt: 1,
        width: "100%",
        bgcolor: "#edf1f5",
        mx: "auto",
      }}
    />
  );
};
