import {
  Box,
  Button,
  Grid,
  Input,
  Modal,
  Paper,
  Typography,
} from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffectOnce } from "react-use";
import TostMessagesTop from "../../Component/Toast";
import { useGetCompanyDetails } from "../../Hooks/Auth";
import useGetCompanyTypeData from "../../Hooks/CompanyData/useGetCompanyTypeData";
import { useGetInvoiceById } from "../../Hooks/Invoices/useGetInvoiceById";
import GoLeft from "../../assets/GoLeft.png";
import BoxIcon from "../../assets/Transportation/BoxIcon.svg";
import BubbleWrap from "../../assets/Transportation/BubbleWrap.svg";
import sendEvents from "../../utils/sendEvents.js";
import ChecklistIcon from "../../assets/Transportation/ChecklistIcon.svg";
import CompliantIcon from "../../assets/Transportation/CompliantIcon.svg";
import EwayIcon from "../../assets/Transportation/E-wayIcon.svg";
import FragileItemIcon from "../../assets/Transportation/FragileItemIcon.svg";
import HandshakeIcon from "../../assets/Transportation/HandshakeIcon.svg";
import NotificationIcon from "../../assets/Transportation/NotificationIcon.svg";
import PickupConfirmation from "../../assets/Transportation/PickupConfirmation.svg";
import RecordingIcon from "../../assets/Transportation/RecordingIcon.svg";
import ShieldIcon from "../../assets/Transportation/ShieldIcon.svg";
import TransportationIcon from "../../assets/Transportation/TransportationIcon.svg";
import Circle from "../../assets/Transportation/circle.svg";
import ColorCircle from "../../assets/Transportation/colorcircle.png";
import Shiprocket from "../../assets/Transportation/pikkercolor.png";
import CompanyName from "../../components/CompanyName";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { TransportationStyle } from "./ChooseTransportationStyle.js";

import BoxIcon1 from "../../assets/Transportation/3d-truck 1.svg";
import damage1 from "../../assets/Transportation/damaged-package 1.svg";
import box from "../../assets/Transportation/package 2.svg";
import compailant from "../../assets/Transportation/compliant (2) 1.svg";
import check from "../../assets/Transportation/checklist 1.svg";
import introduction from "../../assets/Transportation/introduction 1.svg";
import OrderProgressBar from "../../components/ProgressBar/OrderProgressBar.jsx";

function ChooseTransportation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [boxone, setboxone] = useState(true);
  const [boxsecond, setboxsecond] = useState(false);
  const [shipRocketData, setShipRocketData] = useState([]);
  const [shiprocket, setshiprocket] = useState([]);
  const [shiprocketDown, setShiprocketdown] = useState(false);
  const [TransporterId, setTransporterId] = useState("");
  const [OrderData, setOrderData] = useState({});
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [TransportationCalculatedData, setTransportationCalculatedData] =
    useState({
      TransportationType: "",
      Transportationfee: 0,
      GSTFee: 0,
      TotalWithGST: 0,
      BXIShippingHandlingFee: 0,
      BXIShippingHandlingFeeGST: 0,
      TDS: 0,
      Total: 0,
    });

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const gridRef = useRef(null);

  const handleGridScroll = () => {
    const gridElement = gridRef.current;

    if (gridElement.scrollTop >= 10) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  async function GetOrderData() {
    await axios.get(`order/get_order_by_id/${id}`).then((res) => {
      setOrderData(res?.data);
    });
  }
  const { data: CompanyData } = useGetCompanyDetails();

  useEffectOnce(() => {
    GetOrderData();
  }, []);

  const NaviGateFunction = (InvoiceId) => {
    if (OrderData?.InvoiceData?.ProductData[0]?.ProductTypeName === "Media") {
      navigate(`/home/ordersummerydetails/${InvoiceId}`);
    } else {
      navigate(`/home/ordersummerydetails/${InvoiceId}`);
    }
  };

  function subtractPercentage(includedValue, percentage) {
    return includedValue / (1 + percentage / 100);
  }

  useEffect(() => {
    const FindObjectFromTransponsterId = Object.entries(shipRocketData).find(
      ([key, value]) => value.transporter_id === TransporterId
    );

    setTransportationCalculatedData({
      ...TransportationCalculatedData,
      TransportationType: FindObjectFromTransponsterId?.at(1)?.transporter_name,
      Transportationfee: subtractPercentage(
        FindObjectFromTransponsterId?.at(1)?.rates,
        18
      ),
      GSTFee:
        subtractPercentage(FindObjectFromTransponsterId?.at(1)?.rates, 18) *
        0.18,
      TotalWithGST: FindObjectFromTransponsterId?.at(1)?.rates,
      BXIShippingHandlingFee:
        subtractPercentage(FindObjectFromTransponsterId?.at(1)?.rates, 18) *
        0.05,

      BXIShippingHandlingFeeGST:
        subtractPercentage(FindObjectFromTransponsterId?.at(1)?.rates, 18) *
        0.05 *
        0.18,
      TDS:
        (subtractPercentage(FindObjectFromTransponsterId?.at(1)?.rates, 18) +
          subtractPercentage(FindObjectFromTransponsterId?.at(1)?.rates, 18) *
            0.05) *
        0.02,
      Total:
        subtractPercentage(FindObjectFromTransponsterId?.at(1)?.rates, 18) +
        subtractPercentage(FindObjectFromTransponsterId?.at(1)?.rates, 18) *
          0.05 +
        subtractPercentage(FindObjectFromTransponsterId?.at(1)?.rates, 18) *
          0.18 -
        (subtractPercentage(FindObjectFromTransponsterId?.at(1)?.rates, 18) +
          subtractPercentage(FindObjectFromTransponsterId?.at(1)?.rates, 18) *
            0.05) *
          0.05,
    });
  }, [TransporterId]);

  const AddChoosedTransportDataToInvoiceshiprocket = async (props) => {
    const FindObjectFromTransponsterId = Object.entries(shipRocketData).find(
      ([key, value]) => value.transporter_id === TransporterId
    );
    try {
      const { InvoiceData } = OrderData || {};

      const isSeller = props === "Seller";
      const transportationType = props;
      const transportationFee = isSeller
        ? Number(InvoiceData?.Transportationdata?.transportationfee)
        : TransportationCalculatedData?.Transportationfee;
      const GSTFee =
        (Number(InvoiceData?.Transportationdata?.transportationfee) *
          Number(InvoiceData?.Transportationdata?.gstFee)) /
        100;

      const buyerChoosedTransportation = {
        TransportationType: transportationType,

        Transportationfee: transportationFee,
        GSTFee: isSeller
          ? (OrderData?.Transportationdata?.transportationfee *
              OrderData?.Transportationdata?.gstFee) /
            100
          : TransportationCalculatedData?.GSTFee,
        TotalWithGST: isSeller
          ? transportationFee + GSTFee
          : TransportationCalculatedData?.Transportationfee +
            TransportationCalculatedData?.GSTFee,
        BXIShippingHandlingFee: isSeller
          ? 0
          : TransportationCalculatedData?.BXIShippingHandlingFee,
        BXIShippingHandlingFeeGST: isSeller
          ? 0
          : TransportationCalculatedData?.BXIShippingHandlingFeeGST,
        TDS: isSeller ? 0 : TransportationCalculatedData?.TDS,
        TransportersDetails: isSeller
          ? {}
          : FindObjectFromTransponsterId?.at(1),
      };

      const apiPayload = {
        BuyerChoosedTransportation: buyerChoosedTransportation,
      };

      const apiUrl = `order/update_seller_order/${id}`;

      const res = await axios.put(apiUrl, apiPayload);

      if (res?.data?.body?.BuyerChoosedTransportation !== null) {
        toast.success("Successfully Chosen Logistics", {
          position: "top-center",
          autoClose: 2000,
        });
        setTimeout(() => {
          NaviGateFunction(res?.data?.body?._id);
        }, 3000);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (!OrderData?.InvoiceData?.Transportationdata) {
      setboxsecond(false);
    }
  }, [OrderData]);
  const handleclick1 = () => {
    setboxone(true);
    setboxsecond(false);
    setshiprocket(false);
  };

  const handleclick2 = () => {
    setboxone(false);
    setboxsecond(true);
    setshiprocket(true);
  };

  let SelectedShipment = Object.entries(shipRocketData).find(
    ([key, value]) => value.transporter_id == TransporterId
  );

  const BoxesInsideModal = [
    {
      id: 1,
      image: BoxIcon1,
      text: "Transport liquid / volatile products at your own risk. In case of damage, the Seller is liable.",
    },
    {
      id: 2,
      image: damage1,
      text: "Received damaged goods? Do not accept without a note on the delivery challan. Or it becomes the Buyer's liability.",
    },
    {
      id: 3,
      image: box,
      text: " If the package is not damaged externally but the products inside are damaged, the Seller is liable. ",
    },
    {
      id: 4,
      image: damage1,
      text: "Received damaged goods? Notify the Seller by raising a triparty notification on the BXI platform within 24 hours, including video evidence.",
    },
    {
      id: 5,
      image: compailant,
      text: "If Members file an insurance claim, BXI can assist only with providing proof of facts from the logistics company.",
    },
    {
      id: 6,
      image: check,
      text: "Please note, BXI does not oversee logistics. You can select your preferred option.",
    },
    {
      id: 7,
      image: introduction,
      text: "BXI will connect the Buyer and Seller on payment confirmation, for direct coordination.",
    },
  ];

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    Array(BoxesInsideModal.length).fill(false)
  );
  const [isAgreeButtonEnabled, setIsAgreeButtonEnabled] = useState(false);

  useEffect(() => {
    // Check if all checkboxes are selected
    setIsAgreeButtonEnabled(selectedCheckboxes.every((value) => value));

    // Save checkbox state to local storage
    localStorage.setItem("checkboxState", JSON.stringify(selectedCheckboxes));
  }, [selectedCheckboxes]);

  const handleCheckboxChange = (index) => {
    const updatedSelectedCheckboxes = [...selectedCheckboxes];
    updatedSelectedCheckboxes[index] = !updatedSelectedCheckboxes[index];
    setSelectedCheckboxes(updatedSelectedCheckboxes);
  };

  const resetCheckboxes = () => {
    setSelectedCheckboxes(Array(BoxesInsideModal.length).fill(false));
  };

  const price = [
    {
      item: `GST ${OrderData?.Transportationdata?.gstFee} %`,
      value:
        (OrderData?.Transportationdata?.transportationfee *
          OrderData?.Transportationdata?.gstFee) /
        100,
    },
    {
      item: "Logistics fees",
      value: OrderData?.Transportationdata?.transportationfee,
    },
  ];

  return (
    <Paper
      elevation={0}
      sx={{
        boxShadow: "none",
        background: "transparent",
      }}
    >
      <BreadCrumbHeader MainText={"Choose Logistics"} />
      <OrderProgressBar type={"Logistics"} Data={OrderData} />
      {/* <Modal open={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",

              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: 600,
                letterSpacing: 0,
              }}
            >
              Important Shipping Guidelines for a Safe Transit
            </Typography>
            <Typography
              sx={{
                width: "90%",
                fontFamily: "Poppins",
                fontSize: "10px",
                fontWeight: 400,
                letterSpacing: 0,
                mt: 1,
              }}
            >
              Before shipping your package, make sure to read and follow the
              shipping Standard Operating Procedure (SOP). Also, please ensure
              the following:
            </Typography>
          </Box>
          <Box
            sx={{
              height: "100%",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                height: "auto",
                maxHeight: "380px",
                overflowY: "scroll",

                "::-webkit-scrollbar": {
                  display: "flex",
                },
                "::-webkit-scrollbar-thumb": {
                  dynamic: "#8d8e90",
                  minHeight: "10px",
                  borderRadius: "8px",
                },
                "::-webkit-scrollbar-thumb:vertical": {
                  maxHeight: "30px",
                },
                p: 1,
                mt: 1,
              }}
            >
              {BoxesInsideModal?.map((element, index) => {
                return (
                  <Paper
                    elevation={0}
                    ref={gridRef}
                    onScroll={handleGridScroll}
                    sx={{
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "10%",
                      }}
                    >
                      <Checkbox
                        // ... other props ...
                        checked={selectedCheckboxes[index] || false}
                        onChange={() => handleCheckboxChange(index)}
                        sx={{
                          "&:hover": {
                            background: "none",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "25px",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #848484",
                        borderRadius: 2,
                        p: 2,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        mt: 2,
                        gap: 1,
                        height: "50px",
                        width: "90%",
                      }}
                    >
                      <Box component="img" src={element.image} />
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "11px",
                          "&:hover": {
                            background: "none",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                          },
                        }}
                      >
                        {element.text}
                      </Typography>
                    </Box>
                  </Paper>
                );
              })}
            </Box>
            <Button
              onClick={() => {
                handleClose();
                sendEvents("Click on i agree");
                resetCheckboxes();
              }}
              disabled={!isAgreeButtonEnabled}
              sx={{
                width: "91%",
                background: !isAgreeButtonEnabled ? "#c6c6c7" : "#445FD2",
                textTransform: "none",
                position: "absolute",
                color: "#fff",
                ":disabled": {
                  color: "#fff",
                },
                ":hover": {
                  background: "#445FD2",
                },
                bottom: "2%",
                borderRadius: 1,
                fontSize: "12px",
                fontFamily: "Poppins",
                fontWeight: 600,
              }}
            >
              I Agree
            </Button>
          </Box>
        </Box>
      </Modal> */}
      <Box
        sx={{
          bgcolor: "#fff",
          position: "relative",
          py: 5,
          borderRadius: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box>
            <Button
              sx={{
                position: "absolute",
                left: "3%",
                top: "3%",
                cursor: "pointer",
                border: "none",
                outline: "none",
              }}
              onClick={() => {
                navigate(-1);
                sendEvents("Click");
              }}
            >
              <img
                src={GoLeft}
                style={{
                  width: "30px",
                }}
                alt="laft-arrow"
              />
            </Button>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                color: "#6B7A99",
                fontSize: "18px",
                fontWeight: "600",
                marginTop: "0rem",
                mt: -3,
                textAlign: "center",
              }}
            >
              Select the logistics mode of your choice below.
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#B5BCCC",
              fontFamily: "Poppins",
              fontWeight: 400,
              mt: 1,
              textAlign: "center",
            }}
          >
            This quotation below is for the given address and you may decide
            your mode of Logistics.
          </Typography>
        </Box>
        <Paper
          sx={{
            width: "65%",
            mx: "auto",
            boxShadow: "none",
            background: "transparent",
            mt: 5,
            // bgcolor: "red",
          }}
          elevation={0}
        >
          <Box
            sx={{
              width: "95%",
              mx: "auto",
            }}
          >
            <Box
              sx={{
                height: "auto",
                minHeight: "100px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Box sx={adressbox}>
                <Typography sx={CommonTextStyle}>
                  Buyer Location for Delivery / Shipping Address of the Goods /
                  Services.
                </Typography>
                <Typography>
                  <div>
                    {OrderData &&
                    OrderData?.InvoiceData &&
                    OrderData?.InvoiceData.BuyerRequestedAddress ? (
                      <div>
                        <Typography
                          sx={{
                            ...deliverytext,
                            fontWeight: 400,
                            fontSize: "14px",
                          }}
                        >
                          Address:{" "}
                          <span style={{ color: "#445FD2" }}>
                            {
                              OrderData?.InvoiceData?.BuyerRequestedAddress
                                ?.Address
                            }
                          </span>
                        </Typography>
                        <Typography
                          sx={{
                            ...deliverytext,
                            fontWeight: 400,
                            fontSize: "14px",
                          }}
                        >
                          City:{" "}
                          <span style={{ color: "#445FD2" }}>
                            {
                              OrderData?.InvoiceData?.BuyerRequestedAddress
                                ?.City
                            }
                          </span>
                        </Typography>
                        <Typography
                          sx={{
                            ...deliverytext,
                            fontWeight: 400,
                            fontSize: "14px",
                          }}
                        >
                          State:{" "}
                          <span style={{ color: "#445FD2" }}>
                            {
                              OrderData?.InvoiceData?.BuyerRequestedAddress
                                ?.State
                            }
                          </span>
                        </Typography>
                        <Typography
                          sx={{
                            ...deliverytext,
                            fontWeight: 400,
                            fontSize: "14px",
                          }}
                        >
                          PinCode:{" "}
                          <span style={{ color: "#445FD2" }}>
                            {
                              OrderData?.InvoiceData?.BuyerRequestedAddress
                                ?.PinCode
                            }
                          </span>
                        </Typography>
                      </div>
                    ) : (
                      <div>
                        <Typography
                          sx={{
                            ...deliverytext,
                            fontWeight: 400,
                            fontSize: "13px",
                          }}
                        >
                          Address:{" "}
                          <span style={{ color: "#445FD2" }}>
                            {
                              OrderData?.InvoiceData?.BuyerDetails?.Address
                                ?.AddressLine
                            }
                          </span>
                        </Typography>
                      </div>
                    )}
                  </div>
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            container
            sx={{
              height: "auto",
              display: "flex",
              marginTop: "3rem",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              width: "95%",
              mx: "auto",
              // bgcolor: "red",
            }}
          >
            {OrderData?.InvoiceData?.Transportationdata ? (
              <Box
                onClick={handleclick1}
                sx={{
                  border: boxone ? "1px solid #D9D9D9" : "1px solid #D9D9D9",
                  // border: "1px solid rgba(68, 95, 210, 1)",
                  borderRadius: "10px",
                  width: "100%",
                  maxWidth: "460px",
                  height: "100%",
                  minHeight: "220px",
                  display: "flex",
                  cursor: "pointer",
                  flexDirection: "row",
                  "&:hover": {
                    border: "1px solid rgba(68, 95, 210, 1)",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "17%",
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "16px",
                      width: "60px",
                      height: "60px",
                      mx: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={
                        OrderData?.InvoiceData?.SellerDetails?.SellerCompanyLogo
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        maxWidth: "60px",
                        maxHeight: "60px",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "83%",
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: boxone
                          ? " #6B7A99"
                          : " rgba(107, 122, 153, 0.5)",
                        fontSize: "22px",
                        fontFamily: "Poppins",
                        fontWeight: 400,
                      }}
                    >
                      Seller Logistics
                    </Typography>
                    {boxone ? (
                      <img
                        src={ColorCircle}
                        alt="MyImage"
                        style={{ height: " fit-content" }}
                      />
                    ) : (
                      <img
                        src={Circle}
                        alt="MyImage"
                        style={{ height: " fit-content" }}
                      />
                    )}
                  </Box>

                  <Box sx={{ width: "85%" }}>
                    <Typography
                      sx={{
                        color: boxone ? " #445FD2 " : "rgba(71, 84, 103, 0.5);",
                        fontSize: "16px",
                        textAlign: "left",
                        fontFamily: "Poppins",
                        mt: 2,
                      }}
                    >
                      This are the Delivery Logistic Charges Provided By the
                      Seller of Whose Products / Services You have Generated the
                      Purchase Order for.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box
              onClick={handleclick2}
              sx={{
                border: boxone ? "1px solid #D9D9D9" : "1px solid #D9D9D9",
                borderRadius: "10px",
                width: "100%",
                maxWidth: "460px",
                height: "100%",
                minHeight: "220px",
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                "&:hover": {
                  border: "1px solid rgba(68, 95, 210, 1)",
                },
              }}
            >
              <Box
                sx={{
                  width: "90%",
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: boxsecond
                        ? " #6B7A99"
                        : " rgba(107, 122, 153, 0.5)",
                      fontSize: "18px",
                      fontFamily: "Poppins",
                      fontWeight: 400,
                    }}
                  >
                    I Don't Want Transportaion
                  </Typography>
                  {boxsecond ? (
                    <img
                      src={ColorCircle}
                      alt="MyImage"
                      style={{ height: " fit-content" }}
                    />
                  ) : (
                    <img
                      src={Circle}
                      alt="MyImage"
                      style={{ height: " fit-content" }}
                    />
                  )}
                </Box>

                <Typography
                  sx={{
                    color: boxsecond ? " #445FD2 " : "rgba(71, 84, 103, 0.5);",
                    fontSize: "14px",
                    textAlign: "left",
                    fontFamily: "Poppins",
                    mt: 2,
                  }}
                >
                  You can choose self-pickup and delivery. You must co-ordinate
                  with the Seller to update the tracking status on the website.
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* {!shiprocketDown ? (
            <> */}
          {OrderData?.InvoiceData?.Transportationdata &&
          boxone &&
          !boxsecond ? (
            <Box
              sx={{
                width: "95%",
                mx: "auto",
                border: " 1px solid rgba(24, 2, 12, 0.05)",
                borderRadius: "10px",
                mt: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box sx={headbox2}>
                <Typography
                  sx={{
                    ...tableBottomDataStyle,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Price Details ({OrderData?.InvoiceData?.ProductData?.length}{" "}
                  items)
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "60%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                {price?.map((el, idx) => {
                  return (
                    <Box sx={mapbox} key={idx}>
                      <Typography sx={inrvalue}>
                        {price[price.length - idx - 1].item}
                      </Typography>
                      <Typography sx={inrvalue}>
                        {price[price.length - idx - 1].value}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box sx={totaltextbox}>
                <Box sx={totaltextsec}>
                  <Typography sx={totaltext}>Total</Typography>
                  <Typography sx={totaltext}>
                    {" "}
                    {Number(
                      OrderData?.InvoiceData?.Transportationdata
                        ?.transportationfee
                    ) +
                      (OrderData?.InvoiceData?.Transportationdata?.gstFee *
                        Number(
                          OrderData?.InvoiceData?.Transportationdata
                            ?.transportationfee
                        )) /
                        100}{" "}
                    ₹
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  py: 3,
                  width: "100%",
                  maxWidth: "500px",
                  mx: "auto",
                }}
              >
                <Button
                  sx={TransportationStyle.ConfirmButtonStyle}
                  onClick={() => {
                    AddChoosedTransportDataToInvoiceshiprocket("Seller");
                    sendEvents("Click on confirm");
                  }}
                >
                  Confirm
                </Button>

                <Button
                  onClick={() => {
                    navigate(-1);
                    sendEvents("Click on back");
                  }}
                  sx={TransportationStyle.CancelButtonStyle}
                >
                  Back
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                py: 3,
                width: "100%",
                maxWidth: "500px",
                mx: "auto",
              }}
            >
              <Button
                sx={TransportationStyle.ConfirmButtonStyle}
                onClick={() => {
                  AddChoosedTransportDataToInvoiceshiprocket(
                    "notransportation"
                  );
                  sendEvents("Click on confirm");
                }}
              >
                Confirm
              </Button>

              <Button
                onClick={() => {
                  navigate(-1);
                  sendEvents("Click on back");
                }}
                sx={TransportationStyle.CancelButtonStyle}
              >
                Back
              </Button>
            </Box>
          )}

          {/* ) : (
                <>
                  {boxone === true ? (
                    <Box
                      sx={{
                        width: "100%",
                        mx: "auto",
                        border: " 1px solid rgba(24, 2, 12, 0.05)",
                        mt: 4,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box sx={headbox2}>
                        <Typography
                          sx={{
                            ...tableBottomDataStyle,
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          Price Details (
                          {OrderData?.InvoiceData?.ProductIds?.length} items)
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          height: "60%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "20px",
                        }}
                      >
                        {price?.map((el, idx) => {
                          return (
                            <Box sx={mapbox} key={idx}>
                              <Typography sx={inrvalue}>{el.item}</Typography>
                              <Typography sx={inrvalue}>{el.value}</Typography>
                            </Box>
                          );
                        })}
                      </Box>
                      <Box sx={totaltextbox}>
                        <Box sx={totaltextsec}>
                          <Typography sx={totaltext}>Total</Typography>
                          <Typography sx={totaltext}>₹ </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <React.Fragment>
                      <Box
                        sx={{
                          width: "auto",
                          maxWidth: "975px",
                          height: "auto",
                          mx: "auto",
                          borderRadius: "10px",
                          border: "2px solid rgba(24, 2, 12, 0.05)",
                          mt: 4,
                          p: 3,
                        }}
                      >
                        <FormControl
                          sx={{
                            width: "100%",
                            mx: "auto",
                            position: "relative",
                          }}
                        >
                          <FormLabel id="demo-radio-buttons-group-label">
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "20px",
                                lineHeight: "30px",
                                color: "#6B7A99",
                              }}
                            >
                              Choose Your Delivery Partner
                            </Typography>
                          </FormLabel>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="female"
                              name="radio-buttons-group"
                              onChange={(e) => {
                                setTransporterId(e.target.value);
                              }}
                              sx={{
                                width: "100%",
                                mt: 2,
                              }}
                            >
                              {shipRocketData &&
                                Object.values(shipRocketData)?.map(
                                  (item, index) => {
                                    if (
                                      item.common_name === "air" ||
                                      item.common_name === "oxyzenexpress" ||
                                      item.common_name === "muditaexpress"
                                    ) {
                                      return null;
                                    } else {
                                      return (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "left",
                                            alignItems: "left",
                                            gap: "10px",
                                            width: "100%",
                                            mx: "auto",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              ...deliverytext,
                                              width: "40%",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Delivery Option &nbsp; :
                                            <span
                                              style={{
                                                ...deliverytext2,
                                                opacity: "0.5",
                                              }}
                                            >
                                              &nbsp; {item.common_name}
                                            </span>
                                          </Typography>
                                          <Typography
                                            sx={{
                                              ...deliverytext,
                                              width: "20%",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Price &nbsp; : &nbsp;
                                            <span style={deliverytext2}>
                                              Rs. {item.rates}
                                            </span>
                                          </Typography>

                                          <FormControlLabel
                                            value={item.transporter_id}
                                            control={<Radio />}
                                            sx={{
                                              width: "20%",
                                              display: "flex",
                                              justifyContent: "flex-start",
                                            }}
                                          />
                                        </Box>
                                      );
                                    }
                                  }
                                )}
                            </RadioGroup>
                            <Box
                              sx={{
                                position: "absolute",
                                right: "-10%",
                                top: "35%",
                              }}
                            >
                              <Box
                                sx={{
                                  borderRadius: "10px",
                                  width: "70%",
                                  height: "20%",
                                  cursor: "pointer",
                                  position: "relative",
                                  transition: "transform 0.5s",
                                  background: "#F5F6FA",
                                  border: "1px solid rgba(24, 2, 12, 0.05)",
                                  boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.25)",
                                  "&:hover": {
                                    background: "#F5F6FA",
                                    boxShadow:
                                      "0px 5px 5px rgba(0, 0, 0, 0.25)",
                                    transform: "translate(0, 0) scale(0.95)",
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#142155",
                                    textAlign: "justify",
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    m: 2,
                                    height: "auto",
                                    textAlign: "center",
                                    lineHeight: "10px",
                                  }}
                                >
                                  Important Note
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#445FD2",
                                    textAlign: "center",
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    m: 2,
                                    height: "auto",
                                  }}
                                >
                                  Pickkr will charge additional GST after the
                                  cost
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </FormControl>
                      </Box>
                      <Box>
                        {SelectedShipment ? (
                          <React.Fragment>
                            <TableContainer
                              mt={4}
                              component={Paper}
                              elevation={0}
                              sx={{
                                width: "95%",
                                overflow: "auto",
                                border: "2px solid rgba(24, 2, 12, 0.05)",
                                borderRadius: "10px",
                                mx: "auto",
                                mt: 4,
                              }}
                            >
                              <TableHead
                                fullWidth
                                sx={{
                                  width: "100%",
                                  overflow: "auto",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <TableCell>
                                    <Typography
                                      sx={{ ...totaltext, fontSize: "16px" }}
                                    >
                                      Price Details
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      sx={{ ...totaltext, fontSize: "16px" }}
                                    >
                                      Rate ( INR )
                                    </Typography>{" "}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  width: "100%",
                                  overflow: "auto",
                                }}
                              >
                                <TableRow
                                  sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    minWidth: "450px",
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      width: "100%",
                                      overflow: "hidden",
                                      minWidth: "450px",
                                    }}
                                  >
                                    <Typography sx={{ ...inrvalue }}>
                                      {SelectedShipment &&
                                        SelectedShipment?.at(1)
                                          ?.delivery_partner}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography sx={{ ...inrvalue }}>
                                      {TransportationCalculatedData?.Transportationfee.toFixed(
                                        2
                                      )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    minWidth: "450px",
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      width: "100%",
                                      overflow: "hidden",
                                      minWidth: "450px",
                                    }}
                                  >
                                    <Typography sx={{ ...inrvalue }}>
                                      GST ( 18% )
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography sx={{ ...inrvalue }}>
                                      {SelectedShipment &&
                                        TransportationCalculatedData?.GSTFee.toFixed(
                                          2
                                        )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  {" "}
                                  <TableCell>
                                    {" "}
                                    <Typography sx={{ ...inrvalue }}>
                                      Shipping Handling Charge ( 5% )
                                    </Typography>{" "}
                                  </TableCell>
                                  <TableCell>
                                    <Typography sx={{ ...inrvalue }}>
                                      {SelectedShipment &&
                                        (TransportationCalculatedData?.BXIShippingHandlingFee).toFixed(
                                          2
                                        )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  {" "}
                                  <TableCell>
                                    {" "}
                                    <Typography sx={{ ...inrvalue }}>
                                      Shipping Handling Charge GST ( 18% )
                                    </Typography>{" "}
                                  </TableCell>
                                  <TableCell>
                                    <Typography sx={{ ...inrvalue }}>
                                      {SelectedShipment &&
                                        (TransportationCalculatedData?.BXIShippingHandlingFeeGST).toFixed(
                                          2
                                        )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  {" "}
                                  <TableCell>
                                    {" "}
                                    <Typography sx={{ ...inrvalue }}>
                                      TDS (2%)
                                    </Typography>{" "}
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      sx={{ ...inrvalue, color: "red" }}
                                    >
                                      -
                                      {SelectedShipment &&
                                        TransportationCalculatedData?.TDS.toFixed(
                                          2
                                        )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell
                                    sx={{
                                      width: "100%",
                                      overflow: "hidden",
                                      minWidth: "400px",
                                    }}
                                  >
                                    <Typography sx={{ ...inrvalue }}>
                                      Total
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "100%",
                                      overflow: "hidden",
                                      minWidth: "100px",
                                    }}
                                  >
                                    <Typography sx={{ ...inrvalue }}>
                                      {SelectedShipment &&
                                        (
                                          TransportationCalculatedData?.Transportationfee +
                                          TransportationCalculatedData?.GSTFee +
                                          TransportationCalculatedData?.BXIShippingHandlingFee -
                                          TransportationCalculatedData?.TDS +
                                          TransportationCalculatedData?.BXIShippingHandlingFeeGST
                                        ).toFixed(2)}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </TableContainer>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                py: 3,
                                gap: "50px",
                                width: "100%",
                                mx: "auto",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                sx={TransportationStyle.ConfirmButtonStyle}
                                onClick={() => {
                                  if (boxone === true) {
                                    AddChoosedTransportDataToInvoiceshiprocket(
                                      "Seller"
                                    );
                                  } else if (boxsecond === true) {
                                    AddChoosedTransportDataToInvoiceshiprocket(
                                      "pickrr"
                                    );
                                  }
                                  sendEvents("Click on confirm");
                                }}
                              >
                                Confirm
                              </Button>
                              <Button
                                onClick={() => {
                                  navigate(-1);
                                  sendEvents("Click on back");
                                }}
                                sx={TransportationStyle.CancelButtonStyle}
                              >
                                Back
                              </Button>
                            </Box>
                          </React.Fragment>
                        ) : null}
                      </Box>
                    </React.Fragment>
                  )}
                </>
              )}
            </>
          ) 
          : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
              }}
            >
              <Typography sx={tableheading}>Pickkr is down</Typography>
              <Button
                variant="contained"
                sx={{
                  borderRedius: "6px",
                  fontFamily: "Poppins",
                  textTransform: "none",
                  border: "1px solid #445FD2",
                  backgroundColor: "#445FD2",
                  color: "white",
                  fontWeight: 500,
                  fontSize: "14px",
                  mx: "auto",
                }}
                onClick={() => {
                  window.location.reload();

                  sendEvents("Click on refresh");
                }}
              >
                Click to Refresh
              </Button>
            </Box>
          )} */}
          {/* {OrderData?.BuyerChoosedTransportation ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 1,
              }}
            >
              <Button
                sx={TransportationStyle.ConfirmButtonStyle}
                onClick={() => {
                  if (
                    OrderData?.InvoiceData?.ProductData[0]?.ProductTypeName ===
                    "Media"
                  ) {
                    navigate(`/home/ordersummerydetails/${id}`);
                  } else {
                    navigate(`/home/ordersummerydetails/${id}`);
                  }
                }}
              >
                Continue
              </Button>
            </Box>
          ) : null} */}
        </Paper>
      </Box>
      <TostMessagesTop PageLocation="Choose Transportation" />
    </Paper>
  );
}

export default ChooseTransportation;

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "674px",
  height: "500px",
  bgcolor: "background.paper",
  boxShadow: "24px",
  borderRadius: "2px",
  p: 4,
};

const tableheading = {
  color: "red",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "large",
  textAlign: "center",
  my: 3,
};

const tableBottomDataStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  color: "#6B7A99",
};

const CommonTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
};

const CommonTextStyleee = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "21px",
  color: "#6B7A99",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
};
const CommonnnTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const adressbox = {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  mt: 1,
  maxWidth: "500px",
};

const adressinput = {
  width: "500px",
  height: "42px",
  background: "#F9F9F9",
  borderRadius: "9px",
  px: 1,
  color: "#445fd2",
  fontSize: "12px",
};

const deliverytext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const deliverytext2 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const mapbox = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const inrvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "20px",
  // textAlign: "center",
  color: "#6B7A99",
};

const totaltextbox = {
  width: "100%",
  height: "15%",
  borderTop: "1px solid rgba(149, 144, 168, 0.1)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};

const totaltextsec = {
  width: "90%",
  height: "60px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
};

const totaltext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "20px",
  // textAlign: "center",
  color: "#6B7A99",
};

const headbox2 = {
  width: "100%",
  height: "30px",
  display: "flex",
  width: "90%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  mt: 2,
};
