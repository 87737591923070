// Profile
export const headText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  textTransform: "none",
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "1.9rem",
    sm: "1.6rem",
    xs: "1.4rem",
  },
  textAlign: "center",
  color: "#778DA9",
};

export const subText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.4rem",
    sm: "1rem",
    xs: "0.8rem",
  },
  textAlign: "center",
  color: "#ADB8CC",
  lineHeight: "14px",
  marginTop: "5px",
  width: { xl: "58%", lg: "58%", md: "48%", sm: "95%", xs: "100%" },
  mx: "auto",
};

export const liveBtn = {
  background: "#445FD2",
  borderRadius: "10px",
  padding: "7px",
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.2rem",
    sm: "1rem",
    xs: "1rem",
  },
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  textAlign: "center",
  color: "#fff",
  textTransform: "none",
};

export const draftBtn = {
  borderRadius: "10px",
  padding: "10px",
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.5rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  textAlign: "center",
  color: "#445FD2",
  textTransform: "none",
};

export const tabText = {
  fontSize: {
    xl: "1.2rem",
    lg: "1.2rem",
    md: "1.1rem",
    sm: "0.8rem",
    xs: "0.8rem",
  },
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  textAlign: "center",
  color: "#6B7A99",
  textTransform: "none",
  width: { xl: "45%", lg: "50%", md: "50%", sm: "80%", xs: "95%" },
  mx: "auto",
  lineHeight: "2rem",
  mt: 2,
};

export const TextFieldStyle = {
  width: "100%",
  height: "35px",
  background: "#F3F6F9",
  borderRadius: "9px",
  border: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#6B7A99",
  overflow: "auto",
  paddingLeft: "0px",
  "&:focus": {
    outline: "none",
  },
};

export const InputPropsStyle = {
  disableUnderline: true,
  style: {
    backgroundColor: "#F3F6F9",
    fontFamily: "Poppins",
    color: "#6B7A99",
    borderRadius: "9px",
    height: "100%",
    paddingLeft: "10px",
    fontSize: "14px",
  },
};

export const textBox = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  textAlign: "left",
  color: "#6B7A99",
  textTransform: "none",
};

export const boxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "row",
};

export const userName = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  color: "#6B7A99",
  textAlign: "left",
};

export const nameUser = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  color: "rgba(107, 122, 153, 0.5)",
  textAlign: "left",
};

export const textProfile = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  textAlign: "left",
  color: "#757575",
  textTransform: "none",
};

export const CommonTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "15px",
  color: "#6B7A99",
  paddingBottom: "5px",
  mt: 2,
  textAlign: "left",
  lineHeight: "16px",
};

export const TypographyStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#6B7A99",
};

export const scrollDes = {
  overflow: "auto",
  "::-webkit-scrollbar": {
    display: "block",
  },
  "::-webkit-scrollbar-thumb": {
    dynamic: "#8d8e90",
    height: "0px",
    borderRadius: "8px",
  },
  maxHeight: "310px",
  height: "300px",
};

export const insideLogo = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  overflow: "hidden",
  border: "3px solid white",
  width: "97%",
  height: "97%",
};

export const profileDeltailsGrid = {
  width: {
    xl: "85%",
    lg: "85%",
    md: "90%",
    sm: "90%",
    xs: "75%",
  },
  height: "515px",
  background: "#fff",
  border: "1px solid rgba(24, 2, 12, 0.05)",
  borderRadius: "1rem",
  mr: "auto",
  p: 4,
  position: "relative",
};

export const outsideLogo = {
  display: "inline-block",
  borderRadius: "50%",
  overflow: "hidden",
  width: "150px",
  height: "150px",
  border: "2px solid #6B7A99",
};

export const insideTextFeild = {
  background: "rgba(243, 246, 249, 0.64)",
  borderRadius: "8px",
  width: "auto",
  p: 1,
  maxWidth: "300px",
  overflow: "auto",
  whiteSpace: "nowrap",
};

export const CommonBtn = {
  height: "30px",
  width: "auto",
  textTransform: "none",
  boxShadow: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  background: "#445FD2",
  color: "#fff",
  ":hover": {
    bgcolor: "#445FD2",
  },
};

export const modalDes = {
  background: "#fff",
  width: "500px",
  height: "auto",
  p: 3,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  textAlign: "center",
  alignItems: "center",
};

export const profileCompleGrid = {
  width: {
    xl: "97%",
    lg: "97%",
    md: "95%",
    sm: "95%",
    xs: "75%",
  },
  height: "auto",
  minHeight: "280px",
  background: "#fff",
  border: "1px solid rgba(24, 2, 12, 0.05)",
  borderRadius: "10px",
  ml: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: "relative",
};

export const AccDetail = {
  width: "100%",
  mt: 2,
  textTransform: "none",
  color: "#778DA9",
  fontSize: "20px",
  fontFamily: "Poppins",
  fontWeight: 500,
  lineHeight: "25px",
  textAlign: "center",
  borderTop: "inherit",
  fontStyle: "normal",
};

export const profileTabs = {
  width: {
    xl: "82%",
    lg: "82%",
    md: "82%",
    sm: "90%",
    xs: "75%",
  },

  height: "100%",
  background: "#fff",
  border: "1px solid rgba(24, 2, 12, 0.05)",
  borderRadius: "10px",
  ml: "auto",
  p: 7.5,
};

export const TabsBody = {
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
  width: "90%",
  mx: "auto",
  py: 1,
  gap: 2,
  borderBottom: "1px solid rgba(236, 236, 236, 1)",
  mx: "auto",
};

export const tabContent = {
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  gap: 0.5,
};

// AddMember

export const CheckboxtextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "20px",
  color: "#6B7A99",
};

export const TitletextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
  width: {
    lg: "100%",
    xl: "100%",
    md: "100%",
    sm: "100%",
    xs: "75%",
  },
};

export const TitleDescriptionStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "24px",
  color: "#ADB8CC",
  maxWidth: "348px",
};

export const InputStyle = {
  height: "44px",
  width: "90%",
  maxWidth: "350px",
  minWidth: "350px",
  borderRadius: "4px",
  padding: "11px 363px 11px 16",
  border: "1.5px solid #E6E9EE",
  paddingLeft: "16px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "22px",
  display: "flex",
  alignItems: "center",
  color: "#445FD2",
};

export const check = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "24px",
  mt: 1,
  color: "#ADB8CC",
  maxWidth: "348px",
};

export const AdjAddmember = {
  width: "90%",
  height: "100%",
  mx: "auto",
  py: "20px",
  display: "flex",
  justifyContent: "space-between",
  gap: "1rem",
  bgcolor: "transparent",
  flexDirection: {
    xl: "row",
    lg: "row",
    md: "row",
    sm: "column",
    xs: "column ",
  },
};

export const AdjCheckBox = {
  display: "flex",
  justifyContent: "flex-start",
  gap: "10px",
};

export const AddMemberBtn = {
  width: "175px",
  height: "34.99px",
  background: "#fff",
  borderRadius: "5px",
  color: "#445FD2",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  "&:hover": {
    border: "1px solid #445FD2",
  },
  textTransform: "none",
};

export const AddmemberBorder = {
  width: "90%",
  minHeight: "100px",
  height: "100%",
  mx: "auto",
  borderTop: "0.8px solid #EAEAEA",
  my: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  alignContent: "center",
};

// company_members

export const CardDesign = {
  background: "#FFFFFF",
  borderRadius: "10px",
  width: "270px",
  height: "250px",
  marginLeft: "auto",
  marginRight: "auto",
  transition: "box-shadow .1s",
  "&:hover": {
    boxShadow: "0px 0px 17px #cdcdcd",
  },
};

export const CardContent = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  mt: -2,
};

export const CardMap = {
  background: "#FFFFFF",
  borderRadius: "10px",
  width: "270px",
  height: "250px",
  marginLeft: "auto",
  marginRight: "auto",
};

export const CardContentMap = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const CardName = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  textAlign: "center",
  color: "#1B212D",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxHeight: "2.5em",
  lineHeight: "1.5em",
  maxWidth: "100%",
  textAlign: "center",
  marginTop: "5px",
};

export const rolestyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  textAlign: "center",
  color: "#000",
};

export const rolestyle2 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textAlign: "center",
  color: "#929EAE",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "2px",
};

export const ViewProfileBtn = {
  background: "#445FD2",
  borderRadius: "5px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 10,
  color: "white",
  marginTop: "1rem",
  width: "100%",
  height: "50%",
  border: "1px solid transparent",
  textTransform: "none",
  "&:hover": {
    color: "#445FD2",
    border: "1px solid #445FD2",
  },
};

// member Details

export const BoxHeaderText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "16px",
    lg: "14px",
    md: "12px",
    sm: "12px",
    xs: "10px",
  },
  color: "#1B212D",
  textAlign: "start",
};

export const nameTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "16px",
    lg: "14px",
    md: "12px",
    sm: "12px",
    xs: "10px",
  },
  textAlign: "start",
  color: "#1B212D",
  overflowWrap: "break-word",
};

export const emailTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "16px",
    lg: "14px",
    md: "12px",
    sm: "12px",
    xs: "10px",
  },
  color: "#929EAE",
  textAlign: "start",
};

export const AvtarNameDesign = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: "10px",
  width: "100%",
  alignItems: "center",
  borderBottom: "1px solid rgba(245, 245, 245, 1)",
  overflowWrap: "break-word",
  py: 2,
};

export const BoxDesignMember = {
  border: "1px solid rgba(245, 245, 245, 1)",
  borderRadius: "10px",
  textAlign: "center",
  padding: "1.5rem",
  height: "auto",
  minHeight: "206px",
};

export const permissionDesign = {
  display: "flex",
  justifyContent: "space-between",

  flexDirection: {
    xl: "row",
    lg: "row",
    md: "row",
    sm: "column",
    xs: "column",
  },
  width: "90%",
  padding: {
    xl: "0rem 2rem",
    lg: "0rem 2rem",
    md: "0rem 2rem",
    sm: "0rem 0rem",
    xs: "0rem 0rem",
  },
  mb: "1rem",
};

// membership plan
export const mainPage = {
  backgroundColor: "#FFFFFF",
  boxShadow: "none",
  p: 3,
  borderRadius: "20px",
  height: "auto",
  minHeight: "520px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const TextStyleInsidePaper = {
  display: "flex",
  alignItems: "center",
  gap: "1.5rem",
  fontFamily: "Poppins",
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1.1rem",
    sm: "0.9rem",
    xs: "0.9rem",
  },
  fontWeight: 500,
  color: "rgba(132, 129, 153, 1)",
  lineHeight: "1.5",
  marginBottom: "2%",
};

export const introtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "16px",
    lg: "16px",
    md: "14px",
    sm: "14px",
    xs: "14px",
  },
  color: "#B5BCCC",
  textAlign: "center",
  width: "75%",
  mx: "auto",
};

export const plantext = {
  fontFamily: "Poppins",
  fontStyle: "SemiBold",
  fontWeight: 600,
  fontSize: {
    xl: "26px",
    lg: "26px",
    md: "24px",
    sm: "22px",
    xs: "22px",
  },
  color: "#202020",
};

export const membertext = {
  fontStyle: "normal",
  fontFamily: "Poppins",
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1rem",
    sm: "0.5rem",
    xs: "0.5rem",
  },
  fontWeight: 500,
  color: "#848199",
};

// Cart
// Product cart
// media cart
// voucher cart
export const CreateOrderButtonStyle = {
  width: "100%",
  height: "50px",
  background: "#2261A2",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Poppins",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "21px",
  color: "#E0F0FF",
  mt: 1,
  "&:hover": {
    background: "#2261A2",
    color: "#E0F0FF",
  },
};

export const GridStyle = {
  background: "#fff",
  padding: "2rem",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: {
    xl: "row",
    lg: "row",
    md: "row",
    sm: "row",
    xs: "column",
  },
  gap: "2rem",
  marginTop: "2rem",
};

export const ProductNameTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "15px",
    lg: "18px",
    md: "17px",
    sm: "13px",
    xs: "13px",
  },
  lineHeight: "17px",
  color: "#6B7A99",
  textAlign: "left",
  width: "300px",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontFamily: "Poppins",
  fontWeight: 500,
};

export const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "15px",
  color: "#FFFFFF",
};

export const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#505050",
};

export const ProductPriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  color: "rgba(107, 122, 153, 0.5)",
  fontSize: {
    xl: "15px",
    lg: "18px",
    md: "18px",
    sm: "15px",
    xs: "15px",
  },
  lineHeight: {
    xl: "21px",
    lg: "21px",
    md: "19px",
    sm: "18px",
    xs: "17px",
  },
  alignContent: "center",
  alignItems: "center",
};

export const cartSelectionTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "25px",
  color: "#6B7A99",
};

// DashBoard

export const StatBoxes = {
  width: "90%",
  mx: "auto",
  height: "85px",
  border: "1px solid #E6E9EE",
  borderRadius: "12px",
  maxWidth: "170px",
  borderLeft: "4px solid #445FD2",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  alignContent: "flex-start",
  alignItems: "flex-start",
  mt: 1,
  pl: 1,
  flexDirection: "column",
};

export const StatMainText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  color: "darkgray",
  textTransform: "none",
};

export const MoneyTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "18px", lg: "18px", md: "18px", sm: "16px", xs: "14px" },
  lineHeight: "30px",
  mt: 0.5,
  color: "#15223C",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "2px",
};

export const morphicButtonStyle = {
  width: "89px",
  height: "35px ",
  boxShadow: "0px 10px 20px #DCDCDD",
  borderRadius: "5px",
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "11px",
  lineHeight: "15px",
  color: "#000000",
  textTransform: "none",
  "&:hover": {
    background: "#FFFFFF",
    boxShadow: "inset 4px 5px 4px rgba(211, 211, 211, 0.25)",
    borderRadius: "5px",
  },
};

export const TokenText = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: { xl: "17px", lg: "17px", md: "17px", sm: "16px", xs: "14px" },
  lineHeight: "25px",
  color: "#393D5E",
};

export const ProductMetaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: {
    xl: "12px",
    lg: "12px",
    md: "11px",
    sm: "9px",
    xs: "9px",
  },
  lineHeight: {
    xl: "18px",
    lg: "18px",
    md: "15px",
    sm: "14px",
    xs: "14px",
  },
  color: "#858585",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const requestCreditButtonStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "12px",
    lg: "10px",
    md: "8px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: {
    xl: "15px",
    lg: "12px",
    md: "8px",
    sm: "8px",
    xs: "8px",
  },
  color: "#FFFFFF",
  background: "#445FD2",
  borderRadius: "10px",
  textTransform: "none",
  padding: {
    xl: "1rem 2rem",
    lg: "0.8rem 1.8rem",
    md: "0.7rem 1.7rem",
    sm: "0.7rem 1.7rem",
    xs: "0.7rem 1.7rem",
  },
  "&:hover": {
    background: "#C3CAD9",
    color: "#545454",
  },
};

export const reqbtn = {
  width: "114px",
  height: "42px",
  boxShadow: "none",
  background: "#445FD2",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  textTransform: "none",
};

export const TokenAmountStyle = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "28px",
  lineHeight: "40px",
  color: "#545454",
  display: "flex",
  alignItems: "center",
};

export const RecentSearchBox = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height: "auto",
  padding: "0px 1rem",
  maxWidth: "200px",
  gap: "5px",
  mt: {
    xl: "0px",
    lg: "0px",
    md: "0px",
    sm: "1rem",
    xs: "1rem",
  },
};

export const VerticalScroll = {
  overflow: "auto",
  mr: 2,
  "::-webkit-scrollbar": {
    display: "flex",
  },
  "::-webkit-scrollbar-thumb": {
    dynamic: "#8d8e90",
    minHeight: "10px",
    borderRadius: "3px",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    miaxHeight: "10px",
  },
};

// Wallet Transition
export const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

// Creditline Status
export const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  textAlign: "center",
  color: "#929EAE",
};

export const TableBodyText = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#929EAE",
};

// CreditTerms
// myorderlist

export const TableBodyTextStyling = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#1B212D",
};

export const tableheading = {
  fontFamily: "Poppins",
  color: "#7E8BA6",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: 500,
  textTransform: "Capitalize",
};
// Purchase Order list
export const SwitchButtonStyle = {
  borderRadius: "9px",
  boxShadow: "none",
  fontSize: "12px",
  fontWeight: "400",
  textTransform: "capitalize",
  height: "30px",
  width: "100px",
  fontFamily: "Poppins",
};

export const purchaseOrderGrid = {
  background: "#FFFFFF",
  padding: "1rem",
  borderRadius: "20px",
  minHeight: "70vh",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  gap: 1,
  position: "relative",
};

export const TableBodyText2 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#1B212D",
};

// media order Po
export const viewPoBtn = {
  cursor: "pointer",
  width: "100px",
  mx: "auto",
  height: "30px",
  background: "#445FD2",
  borderRadius: "4.39877px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  marginLeft: "auto", // Add this property to move the box to the right
};

// Product details

export const semi = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "2.2rem",
    lg: "2.2rem",
    md: "2.2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#4D4D4D",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 },
};

export const colorText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  color: "grey",
  mt: 2,
};

// trackyourorder
// ordertracking
export const filterbutton = {
  color: "#1B212D",
  border: "1px solid #F5F5F5",
  borderRadius: "10px",
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "1.4rem",
  textTransform: "none",
  "&:hover": {
    color: "#445FD2",
  },
};
