import TabPanel from "@mui/lab/TabPanel";
import {
  Accordion,
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Tabs,
  Typography,
  Input,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CartIcon from "../../assets/HeaderIcon/Cart.png";
import ChatIcon from "../../assets/HeaderIcon/Chat.png";
import NotifyIcon from "../../assets/HeaderIcon/Notification.png";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import TabScrollButton from "@mui/material/TabScrollButton";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "timeago.js";
import CloseIconForNotification from "../../assets/HeaderIcon/CloseIconForNotification.svg";
import FullScreenIcon from "../../assets/HeaderIcon/FullScreenIcon.svg";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { socket } from "../../pages/Message/Message";
import { GetLastSeens } from "../../redux/action/Chat/GetLastSeen";
import { LastSeenSet } from "../../redux/action/Chat/LastSeen";
import { getProduct } from "../../redux/action/Home-Filter/products";
import { allNotification } from "../../redux/action/Notification/getNotifications";
import Avatargenerator from "../AvatarGenerator";
import { ProfileCompletions } from "../../redux/action/Profile/Profile-Completion";
import sendEvents from "../../utils/sendEvents";
import ToastMessage from "../../Component/Toast";

import { toast, ToastContainer } from "react-toastify";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";
import useGetCompanyProfileCompletionPercentage from "../../Hooks/CompanyData/CompanyProfile";
import useGetCompanyTypeData from "../../Hooks/CompanyData/useGetCompanyTypeData";
import GlobalToast from "../Toasts/GlobalToast";
import useGetProductImageByOrderId from "../../Hooks/GetProducts/useGetProductImageByOrderId";

export let Search = "";
export let Tag = "";
export let ProductDescriptionSearch = "";
export let ProductCompanyNameSearch = "";
export let ProductSubtittleSearch = "";

const Header = () => {
  const [notification, setNotification] = useState([]);
  const [notificationDot, setNotificationDot] = useState(false);
  const [cartUpdateNotification, setCartUpdateNotification] = useState(false);
  const [messagenotificationDot, setMessageNotificationDot] = useState(false);
  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("");
  const [descriptionSearch, setDescriptionSearch] = useState("");
  const [productCompanyNameSearch, setProductCompanyNameSearch] = useState("");
  const [productSubtittleSearch, setProductSubtittleSearch] = useState("");
  const [totalCountBXIArray, SetTotalCountBXIArray] = useState([]);
  const [totalCountBuyerArray, setTotalCountBuyerArray] = useState([]);
  const [totalCountSellerArray, setTotalCountSellerArray] = useState([]);
  const [incrementAll, setIncrementAll] = useState(false);
  const [incrementBxi, setIncrementBxi] = useState(false);
  const [incrementBuy, setIncrementBuy] = useState(false);
  const [incrementSeller, setIncrementSeller] = useState(false);
  const [unReadCountOfAll, setUnReadCountOfAll] = useState(null);
  const [unReadCountOfBxi, setUnReadCountOfBxi] = useState(null);
  const [unReadCountOfBuyer, setUnReadCountOfBuyer] = useState(null);
  const [unReadCountOfSeller, setUnReadCountOfSeller] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();

  const [usehubType, setUserHubType] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: loggedInUserData } = useGetLoggedInUser();
  const { data: AuthUserData } = useGetAuthUser();
  const { data: companyTypeData } = useGetCompanyTypeData(
    loggedInUserData?.data?.companyType
  );

  const path = location.pathname.split("/").pop();
  const [UpdateNotiticationStatus, setUpdateNotiticationStatus] = useState(
    false
  );

  const inputRef = useRef(null);
  const { getNotification } = useSelector((state) => state.allNotifiactions);
  const handleStyleChange = () => {
    inputRef.current.style.color = "#C3CAD9";
    inputRef.current.style.fontSize = "14px";
    inputRef.current.style.fontFamily = "Poppins";
  };

  const handleFocus = () => {
    if (isExpanded === false) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  };

  const handleBlur = () => {
    setIsExpanded(false);
  };

  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
    refetch: userRefetch,
  } = useGetLoggedInUser();

  useEffect(() => {
    if (getNotification) {
      const messagesWithCountBXI =
        getNotification.notifications?.filter((item) =>
          item?.message?.includes("BXI")
        ) ?? [];
      SetTotalCountBXIArray(messagesWithCountBXI);

      const messagesWithCountBuyer =
        getNotification.notifications?.filter(
          (item) =>
            item?.message?.includes("confirmed") ||
            item?.message?.includes("rejected") ||
            item?.message?.includes("Order")
        ) ?? [];
      setTotalCountBuyerArray(messagesWithCountBuyer);

      const messagesWithCountSeller =
        getNotification.notifications?.filter(
          (item) =>
            item.message.includes("messages") ||
            item.message.includes("contacted") ||
            item.message.includes("product") ||
            item.message.includes("PI")
        ) ?? [];
      setTotalCountSellerArray(messagesWithCountSeller);
    }
  }, [getNotification]);

  const UpdateNotifications = async (data) => {
    let status = null;

    if (data?.read === false) {
      status = true;
    } else if (data?.read === true) {
      status = false;
    }

    await axios
      .put(
        `notification/updatenotification/${data._id}`,
        { read: status },
        { withCredentials: true }
      )
      .then(() => {
        dispatch(allNotification(login_User));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    // Listen for changes in the getNotification state
    if (getNotification) {
      // Update totalCountBXIArray with the updated data
      const messagesWithCountBXI = getNotification.notifications?.filter(
        (item) => item.message.includes("BXI")
      );
      SetTotalCountBXIArray(messagesWithCountBXI);

      // Update totalCountBuyerArray with the updated data
      const messagesWithCountBuyer = getNotification.notifications?.filter(
        (item) =>
          item.message.includes("confirmed") ||
          item.message.includes("rejected") ||
          item.message.includes("Order")
      );
      setTotalCountBuyerArray(messagesWithCountBuyer);

      // Update totalCountSellerArray with the updated data
      const messagesWithCountSeller = getNotification.notifications?.filter(
        (item) =>
          item.message.includes("messages") ||
          item.message.includes("contacted") ||
          item.message.includes("product") ||
          item.message.includes("PI")
      );
      setTotalCountSellerArray(messagesWithCountSeller);
    }
  }, [getNotification]);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (getNotification) {
      getNotification?.notifications?.map((item) => {
        if (item?.read === false) {
          setNotificationDot(true);
        }
      });
    }
  }, [getNotification]);

  const handleOpen = () => {
    setOpen(true);
    setMessageNotificationDot(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let login_User = loggedInUserData?.data?._id;
  const filterUniqueMessages = (
    messagesWithCount,
    totalCountArray,
    setTotalCountArray
  ) => {
    const uniqueMessages = messagesWithCount.filter(
      (message) => !totalCountArray?.some((m) => m._id === message._id)
    );

    setTotalCountArray((prevArray) => {
      const updatedArray = [...prevArray];

      uniqueMessages.forEach((message) => {
        if (!updatedArray.some((m) => m._id === message._id)) {
          updatedArray.push(message);
        }
      });

      return updatedArray;
    });
  };

  useEffect(() => {
    if (getNotification !== null) {
      setNotification(getNotification?.notifications);

      if (getNotification?.notifications) {
        const AllCount = getNotification.notifications.filter(
          (item) => item.read === false
        );
        setUnReadCountOfAll(AllCount.length);

        const messagesWithCountSeller = getNotification.notifications.filter(
          (item) => {
            return (
              item.message.includes("messages") ||
              item.message.includes("contacted") ||
              item.message.includes("product") ||
              item.message.includes("PI")
            );
          }
        );
        filterUniqueMessages(
          messagesWithCountSeller,
          totalCountSellerArray,
          setTotalCountSellerArray
        );
        const SellerCount = messagesWithCountSeller.filter(
          (item) => item.read === false
        );
        setUnReadCountOfSeller(SellerCount.length);

        const messagesWithCountBuyer = getNotification.notifications.filter(
          (item) => {
            return (
              item.message.includes("confirmed") ||
              item.message.includes("rejected") ||
              item.message.includes("Order")
            );
          }
        );
        filterUniqueMessages(
          messagesWithCountBuyer,
          totalCountBuyerArray,
          setTotalCountBuyerArray
        );
        const BuyerCount = messagesWithCountBuyer.filter(
          (item) => item.read === false
        );
        setUnReadCountOfBuyer(BuyerCount.length);

        const messagesWithCountBXI = getNotification.notifications.filter(
          (item) => item.message.includes("BXI")
        );
        filterUniqueMessages(
          messagesWithCountBXI,
          totalCountBXIArray,
          SetTotalCountBXIArray
        );
        const BxiCount = messagesWithCountBXI.filter(
          (item) => item.read === false
        );
        setUnReadCountOfBxi(BxiCount.length);
      }
    }
  }, [getNotification]);

  useEffect(() => {
    if (login_User) {
      dispatch(allNotification(login_User));
    }
  }, [login_User]);

  useEffect(() => {
    socket.on("notificationS", (data) => {
      if (data !== null) {
        setNotification((prevNotification) => [data, ...prevNotification]);
        setNotificationDot(true);
        setIncrementAll(true);
      }
    });

    socket.on("MessagesNotification", (data) => {
      if (data !== null) {
        // dispatch(
        //   notifications(data.senderId, data.ReceiverId, data.message, data.type)
        // );
        setNotification((prevNotification) => [data, ...prevNotification]);
        setMessageNotificationDot(true);
        setNotificationDot(true);
        setIncrementAll(true);
        // const audio = new Audio(notificationbell);
        // audio.play();
      }
    });

    socket.on("QueryNotificationR", (data) => {
      if (data !== null) {
        // dispatch(
        //   notifications(data.sellerId, data.buyerId, data.message, data.type)
        // );
        setNotification((prevNotification) => [data, ...prevNotification]);
        setNotificationDot(true);
        setIncrementAll(true);
        // const audio = new Audio(notificationbell);
        // audio.play();
      }
    });

    socket.on("notificationS", (data) => {
      if (data !== null) {
        // dispatch(
        //   notifications(data.sellerId, data.buyerId, data.message, data.type)
        // );
        setNotification((prevNotification) => [data, ...prevNotification]);
        setNotificationDot(true);
        setIncrementAll(true);

        // const audio = new Audio(notificationbell);
        // audio.play();
      }
    });

    socket.on("OrderTracking", (data) => {
      if (data !== null) {
        setNotification((prevNotification) => [data, ...prevNotification]);
        setNotificationDot(true);
        setIncrementAll(true);

        // const audio = new Audio(notificationbell);
        // audio.play();
      }
    });

    socket.on("CartUpdate", (data) => {
      setCartUpdateNotification(true);
    });

    // const audio = new Audio(notificationbell);
    // audio.play();
  }, []);

  function CustomTabScrollButton(props) {
    return <TabScrollButton {...props} style={{ display: "none" }} />;
  }
  let pathData = "";

  const pathname = location.pathname;

  // Define regular expressions for each pattern
  const patterns = [
    { regex: /\/home\/(physical|eephysical)/, value: "true" },
    { regex: /officesupplydetail/, value: "false" },
    { regex: /apprealpreview/, value: "true" },
    { regex: /electronicsdetail|lifestyleproductpreview/, value: "false" },
    { regex: /\/home\/(textile|others)/, value: "true" },
    // Add more patterns as needed
  ];

  // Iterate over patterns to find a match
  for (const pattern of patterns) {
    if (pattern.regex.test(pathname)) {
      pathData = pattern.value;
      break;
    }
  }

  const OffDot = () => {
    setMessageNotificationDot(false);
  };

  let socketId = socket.id;
  useEffect(() => {
    if (socketId !== undefined && login_User !== undefined) {
      socket.emit("newUser", { login_User, socketId });
    }
  });

  Search = search;
  Tag = tag;
  ProductDescriptionSearch = descriptionSearch;
  ProductCompanyNameSearch = productCompanyNameSearch;
  ProductSubtittleSearch = productSubtittleSearch;

  useEffect(() => {
    dispatch(
      getProduct(
        search,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        tag,
        descriptionSearch,
        productCompanyNameSearch,
        productSubtittleSearch
      )
    );
  }, [dispatch, search, tag, descriptionSearch, productSubtittleSearch]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getProduct(search));
    }, 100);
  }, [dispatch, search]);

  useEffect(() => {
    dispatch(GetLastSeens(login_User));
  }, [dispatch, login_User]);

  useEffect(() => {
    window.addEventListener("beforeunload", function(event) {
      dispatch(LastSeenSet(login_User));
      dispatch(GetLastSeens(login_User));
    });
  }, [login_User]);

  const clearLocaStorageForBreadCrumb = () => {
    localStorage.removeItem("myArray");
  };

  const handleClickRedirect = ({ message, userHubType }) => {
    if (message.includes("messages")) {
      navigate("/home/message");
    } else if (message.includes("contacted")) {
      navigate("/home/message");
    } else if (
      message?.includes("approved by our team") ||
      message?.includes("disapproved by our team")
    ) {
      const productIdRegex = /productId\s*:\s*(\w+)/;
      const match = message.match(productIdRegex);

      let productId = null;
      if (match && match[1]) {
        productId = match[1];
      }

      let productTypeMatch = message.match(/productType:(\w+)/);

      let productType;
      if (productTypeMatch) {
        productType = productTypeMatch[1];
      }

      if (productId && productType === "Product") {
        navigate(`/home/productdetail/${productId}`);
      } else if (productId && productType === "Media") {
        navigate(`/home/mediabuying/${productId}`);
      } else if (productId && productType === "Voucher") {
        navigate(`/home/voucherdetail/${productId}`);
      }
    } else if (message.includes("rejected")) {
      navigate("/home/purchaseorderlist", {
        state: {
          tab: "Sold",
        },
      });
    } else if (message.includes("has confirmed delivery")) {
      navigate("/home/order_tracking");
    } else if (
      message.includes("better benefits!") ||
      message.includes("Profile now to")
    ) {
      navigate("/home/planpricing");
    } else if (message.includes("PI generated by you has been confirmed")) {
      navigate("/home/sellerpilist");
    } else if (message.includes("successfully uploaded your product")) {
      navigate("/home/sellerhub");
    } else if (
      message.includes("has generated PI for Order Id") ||
      message.includes("Proforma Invoice") ||
      message.includes("Attention! PI for the order id") ||
      message.includes("Proforma Invoice (PI) for Order id")
    ) {
      const productIdRegex = /piid\s*:\s*(\w+)/;
      const match = message.match(productIdRegex);

      let piId = null;
      if (match && match[1]) {
        piId = match[1];
      }

      if (piId) {
        navigate(`/home/performainvoice/${piId}`);
      }
    } else if (
      message.includes("item") ||
      message.includes("shipment") ||
      message.includes("picked") ||
      message.includes("delivery") ||
      message.includes("AWB")
    ) {
      navigate("/home/trackorders");
    } else if (message.includes("Price And Quantity")) {
      navigate("/home/sellerhub");
    } else if (message.includes("Debited") || message.includes("recovered")) {
      navigate("/home/dashboard");
    } else if (message.includes("credit") || message.includes("Credit")) {
      navigate("/home/creditstatus");
    } else if (message.includes("uploaded")) {
      navigate("/home/sellerhub");
    } else if (message.includes("Credited") || message.includes("Balance")) {
      navigate("/home/dashboard");
    } else if (message.includes("delivered")) {
      navigate("/home/myorderlist");
    } else if (message.includes("disapproved")) {
      navigate("/home/sellerhub");
    } else if (message.includes("reviewed & approved by our team.")) {
      navigate("/home/sellerhub");
    } else if (
      message.includes("PO for your product has been generated") ||
      message.includes("Attention! PO for the order id") ||
      message.includes("Purchase Order (PO) for Order Id")
    ) {
      const productIdRegex = /poid\s*:\s*(\w+)/;
      const match = message.match(productIdRegex);

      let poId = null;
      if (match && match[1]) {
        poId = match[1];
      }

      if (poId) {
        navigate(`/home/sellerdetailedordersummary/${poId}`);
      }
    } else if (message.includes("delisted")) {
      navigate("/home/sellerhub");
    }
    handleClose();
    clearLocaStorageForBreadCrumb();
    if (userHubType === "buyer") {
      handleBuyerSellerHub("buyer");
    } else if (userHubType === "seller") {
      handleBuyerSellerHub("seller");
    }
  };

  useEffect(() => {
    const storedVariable = localStorage.getItem("userhubtype");
    setUserHubType(storedVariable);
  }, []);

  const handleBuyerSellerHub = (props) => {
    localStorage.setItem("userhubtype", props);
    setUserHubType(props);
  };

  const handleBuyerSellerHubWithMainButton = (props) => {
    localStorage.setItem("userhubtype", props);
    setUserHubType(props);
  };

  const handleBuyerSellerType = (props) => {
    localStorage.setItem("userhubtype", props);
  };

  useEffect(() => {
    if (
      pathname === "/home" ||
      pathname === "/home/profile" ||
      pathname === "/home/cart" ||
      pathname === "/home/message" ||
      pathname === "/home/dashboard" ||
      pathname === "/home/trackorders" ||
      pathname === "/home/purchaseorderlist"
    ) {
      handleBuyerSellerType("buyer");
      setUserHubType("buyer");
    } else if (
      pathname === "/home/sellerhub" ||
      pathname === "/home/trackorders" ||
      pathname === "/home/purchaseorderlist"
    ) {
      handleBuyerSellerType("seller");
      setUserHubType("seller");
    }
  }, [pathname]);

  const NavigateFunction = () => {
    navigate("/home/sellerhub");
  };

  if (userData?.data?.termsAcceptStatus === false) {
    return (
      <Box
        sx={{
          position: "relative",
          width: "95%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexDirection: "row",
          borderBottom: "2px solid #EDEFF2;",
          height: "70px",
          pl: "70px",
          background: "transparent",
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            width: "auto",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            gap: "5px",
            maxWidth: "220px",
          }}
        >
          <Avatargenerator companyname={userData?.data?.companyName} />
          <Typography
            sx={{
              ...UserNameTextStyle,
              width: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              mr: 1,
            }}
          >
            {userData?.data?.companyName}
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <React.Fragment>
      <ToastContainer style={{ fontSize: "16px" }} />
      <Paper
        sx={{
          position: "relative",
          borderBottom: "2px solid #EDEFF2;",
          height: {
            xl: "65px",
            lg: "65px",
            md: "65px",
            sm: "95px",
            xs: "95px",
          },
          pl: "70px",
          background: "transparent",
        }}
        elevation={0}
      >
        <Paper
          sx={{
            width: "100%",
            maxWidth: "100vw",
            overflowX: "hidden",
            borderRadius: "0px",
            boxShadow: "none",
            display: "flex",
            justifyContent: "space-evenly",
            background: "transparent",
            alignItems: "center",
            alignContent: "center",
            height: "100%",
          }}
          elevation={0}
        >
          <Grid container>
            <Grid item xs={3} sm={3} md={4.5} lg={4.5} xl={4.5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "end",
                  width: "100%",
                  display: {
                    xl: "flex",
                    lg: "flex",
                    md: "flex",
                    sm: "none",
                    xs: "none",
                  },
                }}
              >
                <a
                  href={"/home"}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "end",
                      flexDirection: "row",
                      gap: "8px",
                      textDecoration: "none",
                    }}
                    onClick={clearLocaStorageForBreadCrumb}
                  >
                    <img
                      src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXI_LOGO.png"
                      alt=""
                      width="40px"
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: {
                          xl: "11px",
                          lg: "11px",
                          md: "11px",
                          sm: "7px",
                          xs: "7px",
                        },
                        textAlign: "center",
                        color: "#111034",
                        // mb: '-4px',
                      }}
                    >
                      Barter Exchange of India
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Grid>
            <Grid
              item
              xs={9}
              sm={9}
              md={2}
              lg={2}
              xl={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                  // alignContent: "center",
                  gap: "15px",
                  width: {
                    xl: "400px",
                    lg: "400px",
                    md: "400px",
                    sm: "400px",
                    xs: "400px",
                  },
                  mx: "auto",
                }}
              >
                {AuthUserData?.data?.rightsarray?.includes("sales") ||
                AuthUserData?.data?.superAdmin ? (
                  <Button
                    // disabled={disableButton}
                    // disabled={true}
                    sx={{
                      ...UnActiveExploreButtonStyle,
                      bgcolor: usehubType === "seller" ? "#C64091" : "#fff",
                      color: usehubType === "seller" ? "white" : "#C64091",
                      ":hover": {
                        background: "#fff",
                        color: "#C64091",
                        border: "1.4px solid #C64091",
                      },
                    }}
                    onClick={() => {
                      NavigateFunction();
                      handleBuyerSellerHubWithMainButton("seller");
                      sendEvents("Click on sell button");
                      clearLocaStorageForBreadCrumb();
                    }}
                  >
                    {usehubType === "seller"
                      ? "You are in Seller Hub"
                      : "Seller Hub"}
                  </Button>
                ) : (
                  <Button
                    sx={{
                      ...UnActiveExploreButtonStyle,
                      bgcolor: pathData === "true" ? "white" : "#ADB8CC",
                      color: pathData === "true" ? "#ADB8CC" : "white",
                    }}
                    onClick={() => {
                      handleBuyerSellerHubWithMainButton("seller");
                      sendEvents("Click on sell button");
                      return GlobalToast(
                        "You are not authorized to sell",
                        "error"
                      );
                    }}
                  >
                    Sell
                  </Button>
                )}
                <Button
                  sx={{
                    ...ExploreButtonStyle,
                    bgcolor: usehubType === "seller" ? "white" : "#2261a2",
                    color: usehubType === "seller" ? "#2261a2" : "white",
                    ":hover": {
                      background: "#fff",
                      color: "#2261a2",
                      border: "1.4px solid #2261a2",
                    },
                  }}
                  onClick={() => {
                    navigate("/home");
                    sendEvents("Click on buy button");
                    clearLocaStorageForBreadCrumb();
                  }}
                >
                  {usehubType === "buyer"
                    ? "You are in Buyer Hub"
                    : "Buyer Hub"}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
              <Box
                sx={{
                  display: "flex",
                  width: {
                    xl: "98%",
                    lg: "98%",
                    md: "98%",
                    sm: "70%",
                    xs: "70%",
                  },
                  mr: "auto",
                  justifyContent: {
                    xl: "flex-end",
                    lg: "flex-end",
                    md: "flex-end",
                    sm: "flex-start",
                    xs: "flex-start",
                  },
                  alignContent: "center",
                  alignItems: "center",
                  gap: "16px",
                  mt: { xl: 0, lg: 0, md: 0, sm: 2, xs: 2 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    alignContent: "center",
                    gap: "05px",
                    width: "400px",
                  }}
                >
                  &nbsp;&nbsp;
                  <Box sx={{ ...ButtonsStyle, position: "relative" }}>
                    {notificationDot === true ? (
                      <Box
                        sx={{
                          display: "flex",
                          bgcolor: "red",
                          height: "16px",
                          width: "16px",
                          borderRadius: "07px",
                          position: "absolute",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          top: {
                            xl: "-5px",
                            lg: "-5px",
                            md: "-5px",
                            sm: "-5px",
                            xs: "-5px",
                          },
                          right: {
                            xl: "7px",
                            lg: "7px",
                            md: "7px",
                            sm: "2px",
                            xs: "2px",
                          },
                          zIndex: 1,
                          color: "white",
                        }}
                      >
                        <Typography
                          sx={{
                            // fontFamily: "Poppins",
                            // fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: "8.5px",
                            // color: "#6B7A99",
                          }}
                        >
                          {incrementAll === true
                            ? unReadCountOfAll + 1
                            : unReadCountOfAll}
                        </Typography>
                      </Box>
                    ) : null}

                    <img
                      src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HeaderIcon/Notification.png"
                      alt="CartIcon"
                      width="auto"
                      height={"20px"}
                      style={{
                        width: "auto",
                        cursor: "pointer",
                        padding: "12px",
                      }}
                      onClick={() => {
                        handleOpen();
                        sendEvents("Click on Notification button");
                        clearLocaStorageForBreadCrumb();
                      }}
                    />
                    <Modal
                      open={open}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mb: "1rem",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: 18,
                              color: "#6B7A99",
                            }}
                          >
                            Notifications
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "20px",
                            }}
                          >
                            <Link
                              to={"/home/fullscreennotification"}
                              style={{ textDecoration: "none" }}
                              onClick={() => {
                                handleClose();
                                sendEvents("Click on Notification open button");
                                clearLocaStorageForBreadCrumb();
                              }}
                            >
                              <Box
                                component="img"
                                src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HeaderIcon/FullScreenIcon.svg"
                                sx={HeaderIcons}
                              ></Box>
                            </Link>
                            <Box
                              component="img"
                              src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HeaderIcon/CloseIconForNotification.svg"
                              onClick={() => {
                                handleClose();
                                clearLocaStorageForBreadCrumb();
                                sendEvents(
                                  "Click on close Notification button"
                                );
                              }}
                              sx={{ ...HeaderIcons }}
                            ></Box>
                          </Box>
                        </Box>
                        <Box>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              variant={
                                window.innerWidth < 1400
                                  ? "scrollable"
                                  : "standard"
                              }
                              scrollButtons={{
                                start: <CustomTabScrollButton />,
                                end: <CustomTabScrollButton />,
                              }}
                              sx={{
                                width: "auto",
                                borderBottom: "1px solid #E5E5E5",
                                wordWrap: "break-word",
                                position: "sticky",
                              }}
                            >
                              <Tab label="All" value="1" sx={SubTabStyle} />
                              <Box sx={notificationcount}>
                                {incrementAll === true
                                  ? unReadCountOfAll + 1
                                  : unReadCountOfAll}
                              </Box>
                              <Tab label="BXI" value="2" sx={SubTabStyle} />
                              <Box sx={notificationcount}>
                                {incrementBxi === true
                                  ? unReadCountOfBxi + 1
                                  : unReadCountOfBxi}
                              </Box>
                              <Tab
                                label="Buy"
                                value="3"
                                sx={{ ...SubTabStyle, ml: "10px" }}
                              />
                              <Box sx={notificationcount}>
                                {incrementBuy === true
                                  ? unReadCountOfBuyer + 1
                                  : unReadCountOfBuyer}
                              </Box>
                              <Tab label="Sell" value="4" sx={SubTabStyle} />
                              <Box sx={notificationcount}>
                                {" "}
                                {incrementSeller === true
                                  ? unReadCountOfSeller + 1
                                  : unReadCountOfSeller}
                              </Box>
                            </Tabs>
                          </Grid>
                        </Box>
                        <Box
                          sx={{
                            overflow: "auto",
                            "::-webkit-scrollbar": {
                              display: "flex",
                            },
                            "::-webkit-scrollbar-thumb": {
                              dynamic: "#8d8e90",
                              minHeight: "10px",
                              borderRadius: "8px",
                            },
                            "::-webkit-scrollbar-thumb:vertical": {
                              maxHeight: "30px",
                            },
                            maxHeight: "300px",
                            height: "290px",
                            p: 1,
                          }}
                        >
                          <TabContext value={value}>
                            <TabPanel
                              value={"1"}
                              sx={{
                                padding: "0px",
                                boxShadow: 0,
                                "::before": {
                                  height: "0px",
                                  left: "0px",
                                },
                              }}
                            >
                              {notification?.map((notification, idx) => {
                                let messages = notification.message;
                                let maskedMessage = messages.replace(
                                  /productId:.*|poid:.*|piid:.*/g,
                                  ""
                                );
                                return (
                                  <Accordion
                                    key={idx}
                                    sx={{
                                      mt: 1,
                                      boxshadow: "0px 0px 0px 0px",
                                      border: "none",
                                      boxShadow: 0,
                                      "::before": {
                                        height: "0px",
                                        left: "0px",
                                      },
                                    }}
                                  >
                                    <Box
                                      // expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      sx={{
                                        mt: 1,
                                        position: "relative",
                                        "::before": {
                                          height: "0px",
                                          left: "0px",
                                        },
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display:
                                            notification.read === true
                                              ? "none"
                                              : "flex",
                                          bgcolor: "red",
                                          height: "10px",
                                          width: "10px",
                                          borderRadius: "20px",
                                          position: "absolute",
                                          zIndex: 1,
                                        }}
                                      ></Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: "10px",
                                        }}
                                      >
                                        <Avatargenerator
                                          companyname={
                                            userData?.data?.companyName
                                          }
                                          AvatarUrl={
                                            userData?.data?.CompanyAvatar?.url
                                          }
                                        />

                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexDirection: "column",
                                            width: "100%",
                                          }}
                                        >
                                          <Box>
                                            <Typography
                                              sx={NotificationMessage}
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                handleClickRedirect({
                                                  message: notification.message,
                                                  userHubType: "",
                                                });
                                                sendEvents("Click on redirect");
                                                handleClose();
                                                clearLocaStorageForBreadCrumb();
                                              }}
                                            >
                                              {maskedMessage}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "space-between",
                                              width: "100%",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontFamily: "Outfit",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                fontSize: "10px",
                                                color: "#6C6C6C",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                              }}
                                            >
                                              {format(notification?.createdAt)}
                                            </Typography>

                                            <Box
                                              sx={{
                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                fontSize: 10,
                                                color: "#445FD2",
                                                textAlign: "right",
                                                cursor: "pointer",
                                                display: "flex",
                                                flexDirection: "flex-end",
                                                ml: "auto",
                                              }}
                                              onClick={() => {
                                                UpdateNotifications(
                                                  notification
                                                );
                                                setUpdateNotiticationStatus(
                                                  !UpdateNotiticationStatus
                                                );

                                                sendEvents(
                                                  "Click on notification"
                                                );
                                                clearLocaStorageForBreadCrumb();
                                              }}
                                            >
                                              {notification.read === false
                                                ? "Mark as Read"
                                                : "Mark as Unread"}
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Accordion>
                                );
                              })}
                            </TabPanel>
                            <TabPanel
                              value={"2"}
                              sx={{
                                padding: "0px",
                                boxShadow: 0,
                                "::before": {
                                  height: "0px",
                                  left: "0px",
                                },
                              }}
                            >
                              {totalCountBXIArray?.map((notification, idx) => {
                                let messages = notification.message;
                                let maskedMessage = messages.replace(
                                  /productId:.*|poid:.*|piid:.*/g,
                                  ""
                                );
                                return (
                                  <Accordion
                                    key={idx}
                                    sx={{
                                      boxshadow: "0px 0px 0px 0px",
                                      border: "none",
                                      boxShadow: 0,
                                      "::before": {
                                        height: "0px",
                                        left: "0px",
                                      },
                                    }}
                                  >
                                    <Box
                                      // expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      sx={{
                                        mt: 1,
                                        position: "relative",
                                        "::before": {
                                          height: "0px",
                                          left: "0px",
                                        },
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display:
                                            notification.read === true
                                              ? "none"
                                              : "flex",
                                          bgcolor: "red",
                                          height: "12px",
                                          width: "12px",
                                          borderRadius: "20px",
                                          position: "absolute",
                                          zIndex: 1,
                                        }}
                                      ></Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: "10px",
                                        }}
                                      >
                                        <Avatargenerator
                                          companyname={
                                            userData?.data?.companyName
                                          }
                                          AvatarUrl={
                                            userData?.data?.CompanyAvatar?.url
                                          }
                                        />

                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                          }}
                                        >
                                          <Typography
                                            style={{ cursor: "pointer" }}
                                            sx={NotificationMessage}
                                            onClick={() => {
                                              handleClickRedirect({
                                                message: notification.message,
                                                userHubType: "",
                                              });
                                              clearLocaStorageForBreadCrumb();
                                              sendEvents(
                                                "Click on notification message"
                                              );
                                              handleClose();
                                            }}
                                          >
                                            {maskedMessage}
                                          </Typography>
                                          <Box
                                            sx={{
                                              fontFamily: "Poppins",
                                              fontStyle: "normal",
                                              fontWeight: 500,
                                              fontSize: 10,
                                              color: "#445FD2",
                                              textAlign: "right",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              UpdateNotifications(notification);
                                              setUpdateNotiticationStatus(
                                                !UpdateNotiticationStatus
                                              );
                                              clearLocaStorageForBreadCrumb();
                                              sendEvents(
                                                "Click on read notifications"
                                              );
                                            }}
                                          >
                                            {notification.read === false
                                              ? "Mark as Read"
                                              : "Mark as Unread"}
                                          </Box>

                                          <Typography
                                            sx={{
                                              fontFamily: "Outfit",
                                              fontStyle: "normal",
                                              fontWeight: 500,
                                              fontSize: "10px",
                                              color: "#6C6C6C",
                                            }}
                                          >
                                            {format(notification?.createdAt)}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Accordion>
                                );
                              })}
                            </TabPanel>
                            <TabPanel
                              value={"3"}
                              sx={{
                                padding: "0px",
                                boxShadow: 0,
                                "::before": {
                                  height: "0px",
                                  left: "0px",
                                },
                              }}
                            >
                              {totalCountBuyerArray?.map(
                                (notification, idx) => {
                                  let messages = notification.message;
                                  let maskedMessage = messages.replace(
                                    /productId:.*|poid:.*|piid:.*/g,
                                    ""
                                  );
                                  return (
                                    <Accordion
                                      key={idx}
                                      sx={{
                                        boxshadow: "0px 0px 0px 0px",
                                        border: "none",
                                        boxShadow: 0,
                                        "::before": {
                                          height: "0px",
                                          left: "0px",
                                        },
                                      }}
                                    >
                                      <Box
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                          mt: 1,
                                          position: "relative",
                                          "::before": {
                                            height: "0px",
                                            left: "0px",
                                          },
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display:
                                              notification.read === true
                                                ? "none"
                                                : "flex",
                                            bgcolor: "red",
                                            height: "12px",
                                            width: "12px",
                                            borderRadius: "20px",
                                            position: "absolute",
                                            zIndex: 1,
                                          }}
                                        ></Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            gap: "10px",
                                          }}
                                        >
                                          <Avatargenerator
                                            companyname={
                                              userData?.data?.companyName
                                            }
                                            AvatarUrl={
                                              userData?.data?.CompanyAvatar?.url
                                            }
                                          />

                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "100%",
                                            }}
                                          >
                                            <Typography
                                              style={{ cursor: "pointer" }}
                                              sx={NotificationMessage}
                                              onClick={() => {
                                                handleClickRedirect({
                                                  message: notification.message,
                                                  userHubType: "buyer",
                                                });
                                                handleClose();
                                                clearLocaStorageForBreadCrumb();
                                              }}
                                            >
                                              {maskedMessage}
                                            </Typography>

                                            <Box
                                              sx={{
                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                fontSize: 10,
                                                color: "#445FD2",
                                                textAlign: "right",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                UpdateNotifications(
                                                  notification
                                                );
                                                setUpdateNotiticationStatus(
                                                  !UpdateNotiticationStatus
                                                );
                                                clearLocaStorageForBreadCrumb();
                                              }}
                                            >
                                              {notification.read === false
                                                ? "Mark as Read"
                                                : "Mark as Unread"}
                                            </Box>

                                            <Typography
                                              sx={{
                                                fontFamily: "Outfit",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                fontSize: "10px",
                                                color: "#6C6C6C",
                                              }}
                                            >
                                              {format(notification?.createdAt)}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Accordion>
                                  );
                                }
                              )}
                            </TabPanel>
                            <TabPanel
                              value={"4"}
                              sx={{
                                padding: "0px",
                                boxShadow: 0,
                                "::before": {
                                  height: "0px",
                                  left: "0px",
                                },
                              }}
                            >
                              {totalCountSellerArray?.map(
                                (notification, idx) => {
                                  let messages = notification.message;
                                  let maskedMessage = messages.replace(
                                    /productId:.*|poid:.*|piid:.*/g,
                                    ""
                                  );
                                  return (
                                    <Accordion
                                      key={idx}
                                      sx={{
                                        boxshadow: "0px 0px 0px 0px",
                                        border: "none",
                                        boxShadow: 0,
                                        "::before": {
                                          height: "0px",
                                          left: "0px",
                                        },
                                      }}
                                    >
                                      <Box
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{
                                          mt: 1,
                                          position: "relative",
                                          "::before": {
                                            height: "0px",
                                            left: "0px",
                                          },
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display:
                                              notification.read === true
                                                ? "none"
                                                : "flex",
                                            bgcolor: "red",
                                            height: "12px",
                                            width: "12px",
                                            borderRadius: "20px",
                                            position: "absolute",
                                            zIndex: 1,
                                          }}
                                        ></Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            gap: "10px",
                                          }}
                                        >
                                          <Avatargenerator
                                            companyname={
                                              userData?.data?.companyName
                                            }
                                            AvatarUrl={
                                              userData?.data?.CompanyAvatar?.url
                                            }
                                          />

                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "100%",
                                            }}
                                          >
                                            <Typography
                                              style={{ cursor: "pointer" }}
                                              sx={NotificationMessage}
                                              onClick={() => {
                                                handleClickRedirect({
                                                  message: notification.message,
                                                  userHubType: "seller",
                                                });
                                                handleClose();
                                                clearLocaStorageForBreadCrumb();
                                              }}
                                            >
                                              {maskedMessage}
                                            </Typography>

                                            <Box
                                              sx={{
                                                fontFamily: "Poppins",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                fontSize: 10,
                                                color: "#445FD2",
                                                textAlign: "right",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                UpdateNotifications(
                                                  notification
                                                );
                                                setUpdateNotiticationStatus(
                                                  !UpdateNotiticationStatus
                                                );
                                                clearLocaStorageForBreadCrumb();
                                              }}
                                            >
                                              {notification.read === false
                                                ? "Mark as Read"
                                                : "Mark as Unread"}
                                            </Box>

                                            <Typography
                                              sx={{
                                                fontFamily: "Outfit",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                fontSize: "10px",
                                                color: "#6C6C6C",
                                              }}
                                            >
                                              {format(notification?.createdAt)}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Accordion>
                                  );
                                }
                              )}
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </Box>
                    </Modal>
                  </Box>
                  &nbsp;&nbsp;
                  <Link
                    to="/home/message"
                    onClick={() => handleBuyerSellerHub("buyer")}
                  >
                    <Box sx={{ ...ButtonsStyle, position: "relative" }}>
                      {messagenotificationDot === true ? (
                        <Box
                          sx={{
                            display: "flex",
                            bgcolor: "red",
                            height: "10px",
                            width: "10px",
                            borderRadius: "20px",
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            zIndex: 1,
                          }}
                        ></Box>
                      ) : null}
                      <img
                        onClick={() => {
                          OffDot();
                          sendEvents("Click on Chat");
                          clearLocaStorageForBreadCrumb();
                        }}
                        src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HeaderIcon/Chat.png"
                        alt="CartIcon"
                        width="auto"
                        height={"16px"}
                      />
                    </Box>
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    to="/home/cart"
                    onClick={() => {
                      setCartUpdateNotification(false);
                      sendEvents("Click on Cart");
                      clearLocaStorageForBreadCrumb();
                    }}
                  >
                    <Box sx={{ ...ButtonsStyle, position: "relative" }}>
                      <Box
                        sx={{
                          position: "absolute",
                          background: cartUpdateNotification
                            ? "rgba(230, 46, 123, 1)"
                            : "transparent",
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          top: "20%",
                          right: "20%",
                        }}
                      ></Box>
                      <img
                        src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/HeaderIcon/Cart.png"
                        alt="CartIcon"
                        width="auto"
                        height={"22px"}
                      />
                    </Box>
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    to={"/home/profile"}
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      clearLocaStorageForBreadCrumb();
                      handleBuyerSellerHub("buyer");
                    }}
                  >
                    <Box
                      sx={{
                        ...ButtonsStyle,
                        display: "flex",
                        width: "auto",
                        justifyContent: "flex-start",
                        alignContent: "center",
                        alignItems: "center",
                        gap: "5px",
                        maxWidth: "220px",
                      }}
                    >
                      <Avatargenerator
                        companyname={userData?.data?.companyName}
                        AvatarUrl={userData?.data?.CompanyAvatar?.url}
                      />
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    </React.Fragment>
  );
};

export default Header;

const ExploreButtonStyle = {
  width: "300px",
  height: { xl: "44px", lg: "44px", md: "44px", sm: "34px", xs: "34px" },
  background: "#FFFFFF",
  borderRadius: "6px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  /* identical to box height */
  textAlign: "center",
  color: "#6B7A99",
  textTransform: "none",
};

const ButtonsStyle = {
  width: { xl: "45px", lg: "45px", md: "45px", sm: "30px", xs: "30px" },
  height: { xl: "45px", lg: "45px", md: "45px", sm: "30px", xs: "30px" },
  background: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  boxShadow: "0px 2px 5px rgba(38, 51, 77, 0.03)",
  borderRadius: "100px",
  cursor: "pointer",
};

const UnActiveExploreButtonStyle = {
  width: "300px",
  height: { xl: "44px", lg: "44px", md: "44px", sm: "34px", xs: "34px" },
  background: "transparent",
  borderRadius: "6px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  /* identical to box height */
  textAlign: "center",
  color: "#ADB8CC",
  textTransform: "none",
};

const UserNameTextStyle = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
};

const style = {
  position: "absolute",
  top: "37%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  height: "370px",
  overflow: "hidden",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 4,
  p: "30px",
  borderRadiusTopRight: "10px",
  maxWidth: "500px",
  minWidth: "250px",
  borderRadius: "20px 20px 0px 0px",
};

const HeaderIcons = {
  width: "20px",
  height: "20px",
  cursor: "pointer",
};

const SubTabStyle = {
  width: "auto",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "15px",
  color: "#000000",
};

const NotificationMessage = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};

const notificationcount = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: "22px",
  height: "20px",
  background: "#F8E8EA",
  borderRadius: "2px",
  mt: 1.2,
  p: "3px",
  ml: "-3%",
};
