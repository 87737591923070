import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetInvoiceByOrderSummary } from "../../../Hooks/Invoices/useGetInvoiceByOrderSummary";
import { useGetOrderSummaryByIdForBuyer } from "../../../Hooks/OrderActions/useGetOrderSummaryByIdForBuyer";
import CheckboxIcon from "../../../assets/Images/CommonImages/CheckboxIcon.svg";
import CheckedBoxIcon from "../../../assets/Images/CommonImages/CheckedBoxIcon.svg";
import CheckedCheckboxIcon from "../../../assets/Images/CommonImages/CheckedCheckboxIcon.svg";
import CloseIcon from "../../../assets/Images/CommonImages/CloseIcon.svg";
import { getCompanyById } from "../../../redux/action/CompanyActions";
import { notifications } from "../../../redux/action/Notification/notification";
import { getOrderSummary } from "../../../redux/action/OrderSummaryActions";
import PI from "../../BuyingJourneyPages/PI";
import { MdKeyboardBackspace } from "react-icons/md";
import PageLoader from "../../../components/LoadingButton/PageLoader";
import Checkbox from "@mui/material/Checkbox";
import sendEvents from "../../../utils/sendEvents";
import { SendMessageOnWhatsApp } from "../../../redux/action/WhatApp/SendMessages";
import MediaPI from "../../BuyingJourneyPages/MediaPI";
import OrderProgressBar from "../../../components/ProgressBar/OrderProgressBar";

const PerformaInvoice = () => {
  let dispatch = useDispatch();
  let { id } = useParams();

  const [mutateResponse, setMutateResponse] = useState();
  const [mutateRespopnseLoading, setMutateResponseLoading] = useState(false);
  const [notificationOn, setNotificationOn] = useState("");
  const [open, setOpen] = useState(false);
  const [openTextarea, setOpenTextarea] = useState("price");
  const [Reason, setReason] = useState("");
  const [pdf, setPDF] = useState(false);
  const [StoreOrderId, setStoreOrderId] = useState("");
  const navigate = useNavigate();
  const [InsuranceCheck, setInsuranceCheck] = useState(false);
  const [WalletData, setWalletData] = useState();
  const [CouponData, setCouponData] = useState();
  const [userHubType, setUserHubType] = useState();
  const topElementRef = useRef(null);

  useEffect(() => {
    if (topElementRef.current) {
      topElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    axios
      .get("wallet/get_wallet", {
        withCredentials: true,
      })
      .then((res) => {
        setWalletData(res?.data?.body);
      });
  }, []);

  const {
    data: InvoiceData,
    isLoading: InvoiceDataLoading,
    error: InvoiceDataError,
    refetch: InvoiceRefetch,
  } = useGetInvoiceByOrderSummary(id);

  const { data: orderSummaryData, isLoading: orderSummaryLoading } =
    useGetOrderSummaryByIdForBuyer(id);

  const CouponMatch = WalletData?._instant_balance?.filter(
    (el) => el?._coupon_Id === orderSummaryData?.CouponData?._id
  );

  let orderData = [];

  for (let i = 0; i < orderSummaryData?.ProductData?.length; i++) {
    orderData.push(orderSummaryData.ProductData[i]);
  }

  let totalPrice = 0;
  orderData?.map((item) => {
    totalPrice += item.DiscountedPrice
      ? item.DiscountedPrice
      : item.PricePerUnit * item.ProductQuantity * item.ProductQuantity;
  });

  const GetallCoupon = async () => {
    await axios
      .get(`/private_deal/get_coupons`, {
        headers: {
          withCredentials: true,
        },
      })
      .then((res) => {
        setCouponData(res.data.data);
        // console.log("Sufsdfsdfccess", res.data);
      })
      .catch((err) => {});
  };

  const CouponActiveMatch = CouponData?.filter(
    (el) => el?._id?.toString() === orderSummaryData?.CouponData?._id
  );

  useEffect(() => {
    GetallCoupon();
    const userhubtypeData = localStorage.getItem("userhubtype");
    setUserHubType(userhubtypeData);
  }, []);

  async function UpdateInvoice() {
    if (orderSummaryData?.CouponData?._id && CouponMatch?.at(0)?._is_used) {
      toast.error("Coupon Is Already Used", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (CouponActiveMatch?.at(0)?.IsActive === false) {
      toast.error("Coupon Is Not Active", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setNotificationOn("Accepted");

      await axios
        .put(
          `invoices/update_invoice/` + InvoiceData?._id,
          {
            BuyerInvoiceAcceptanceStatus: "Accepted",
            InsuranceCheck: InsuranceCheck ? true : false,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          toast.success("Invoice Accepted", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setMutateResponse(res?.data);
          setTimeout(() => {
            InvoiceRefetch();
          }, 2000);
          if (res?.data === "Accepted") {
            if (
              InvoiceData?.ProductData?.at(0)?.ProductTypeName === "Media" ||
              InvoiceData?.IsMedia
            ) {
              setTimeout(() => {
                navigate(`/home/ordersummerydetails/${res?.data?._id}`);
              }, 2000);
            } else {
              setTimeout(() => {
                navigate("/home/choosetransport/" + InvoiceData?._id);
              }, 2000);
            }
          }
        })
        .catch((err) => {});
    }
  }
  async function UpdateInvoiceReject() {
    setNotificationOn("Rejected");
    await axios
      .put(
        `invoices/update_invoice/` + InvoiceData?._id,
        {
          BuyerInvoiceAcceptanceStatus: "Rejected",
          RejectionType: openTextarea,
          RejectionDescription: Reason,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toast.error("Invoice Rejected", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setMutateResponse(res?.data);
        InvoiceRefetch();
      })
      .catch((err) => {
        alert("Error");
      });
  }

  const { OrderSummary: OrderSummarydata, loading: OrderSummaryDataLoading } =
    useSelector((state) => state.OrderSummaryD);

  useEffect(() => {
    dispatch(getCompanyById(OrderSummarydata?.SellerCompanyId));
    dispatch(getOrderSummary(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (mutateResponse === "Accepted") {
      alert("Order Accepted");
      navigate("/home/productpilist");
    } else if (mutateResponse === "Rejected") {
      alert("Order Rejected");
      navigate("/home/productpilist");
    } else if (mutateResponse === "Already Accepted") {
      alert("Error");
      navigate("/home/productpilist");
    }
  }, [mutateResponse]);

  useEffect(() => {
    dispatch(
      getCompanyById(orderSummaryData?.OrderSummarydata?.BuyerCompanyId)
    );
  }, [orderSummaryData?.OrderSummarydata?.BuyerCompanyId]);

  async function GetOrderByInvoice() {
    await axios.get(`order/get_order_by_orderSummaryId/${id}`).then((res) => {
      setStoreOrderId(res?.data?._id);
    });
  }
  useEffect(() => {
    GetOrderByInvoice();
  }, []);
  useEffect(() => {
    if (!InvoiceData?._id) {
      InvoiceRefetch();
    }
  }, [InvoiceData]);

  useEffect(() => {
    if (notificationOn != "") {
      const sellerId = InvoiceData?.SellerDetails?.SellerCompanyId;
      const buyerId = InvoiceData?.BuyerDetails?.BuyerCompanyId;
      const type = "Order";

      let message = "";
      if (notificationOn === "Accepted") {
        message = `Congratulations, PI generated by you has been confirmed by ${InvoiceData?.BuyerDetails?.BuyerCompanyName}`;
      } else if (notificationOn === "Rejected") {
        message = `Sorry, PI generated by you has got rejected by ${InvoiceData?.BuyerDetails?.BuyerCompanyName}`;
      }

      let TempletNameForConfirm = "pi_confirmed";
      let TempletNameForReject = "pi_rejected";

      let sellerWhatsAppNo = InvoiceData?.SellerDetails?.SellerCompanyContact;
      let buyerWhatsAppNo = InvoiceData?.BuyerDetails?.BuyerCompanyContact;
      let buyerCompanyName = InvoiceData?.BuyerDetails?.BuyerCompanyName;
      let SellerCompanyName = InvoiceData?.SellerDetails?.SellerCompanyName;

      if (notificationOn === "Accepted") {
        let parameterName = "buyer_name";
        let parameterValue = buyerCompanyName;
        let parameterName1 = "dynamic_link4_here";
        let parameterValue1 = "https://bxi.unada.in/home/performainvoice";

        dispatch(
          SendMessageOnWhatsApp(
            sellerWhatsAppNo,
            TempletNameForConfirm,
            parameterName,
            parameterValue,
            parameterName1,
            parameterValue1,
            "parameterName2",
            "parameterValue2"
          )
        );
      } else if (notificationOn === "Rejected") {
        let parameterName = "buyer_name";
        let parameterValue = buyerCompanyName;
        let parameterName1 = "dynamic_link5_here";
        let parameterValue1 = "https://bxi.unada.in/home/performainvoice";

        dispatch(
          SendMessageOnWhatsApp(
            sellerWhatsAppNo,
            TempletNameForReject,
            parameterName,
            parameterValue,
            parameterName1,
            parameterValue1,
            "parameterName2",
            "parameterValue2"
          )
        );
      }

      dispatch(notifications(sellerId, buyerId, message, type));
    }
  }, [notificationOn]);

  return (
    <Paper
      ref={topElementRef}
      sx={{
        bgcolor: "transparent",
        boxShadow: "none",
        width: "100%",
      }}
      elevation={0}
    >
      {!InvoiceDataLoading ? (
        <>
          {InvoiceData?.ProductData?.at(0)?.ProductTypeName === "Media" ||
          InvoiceData?.IsMedia ? (
            <MediaPI
              OrderId={StoreOrderId}
              SellerPage={true}
              ProformaInvoice={true}
              IsMedia={true}
              Title={"Media Proforma Invoice"}
              PageName={"Proforma Invoice"}
              InvoiceId={InvoiceData?._id}
            />
          ) : (
            <PI
              OrderId={StoreOrderId}
              SellerPage={true}
              ProformaInvoice={true}
              Title={"Proforma Invoice"}
              PageName={"Proforma Invoice"}
              downloadpdf={pdf}
              InvoiceId={InvoiceData?._id}
            />
          )}
          {!InvoiceData?.IsMedia && Number(InvoiceData?.InsuranceCost) > 0 ? (
            <Box
              sx={{
                width: "400px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "start",
                marginLeft: "34px",
                whiteSpace: "nowrap",
              }}
            >
              <Box sx={{ display: "block", whiteSpace: "nowrap" }}>
                <Typography sx={{ fontSize: "16px" }}>
                  Add seller provided Insurance to this order ?{" "}
                  <span style={{ fontSize: "14px" }}>
                    {" "}
                    ({InvoiceData?.InsuranceCost} INR)
                  </span>
                </Typography>
              </Box>
              {InsuranceCheck ? (
                <Checkbox
                  onChange={(e) => setInsuranceCheck(false)}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, pd: 2 }}
                />
              ) : (
                <Checkbox
                  onChange={(e) => setInsuranceCheck(true)}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, pd: 2 }}
                />
              )}
            </Box>
          ) : null}
          {orderSummaryData?.CouponData ? (
            <Box
              sx={{
                width: "400px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "start",
                marginLeft: "34px",
                whiteSpace: "nowrap",
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>
                • Coupon Applied{" "}
              </Typography>
            </Box>
          ) : null}

          {InvoiceData?.Status === "expired" ? (
            <Box
              sx={{
                ...ButtonStyleForAcceptAndReject,
                border: "0.5px solid #EB5757",
                bgcolor: "#FFEFEF",
                color: "#EB5757",
                mx: "auto",
              }}
              onClick={() => {
                setOpen(true);
                sendEvents("Click on reject");
              }}
            >
              Order Expired
            </Box>
          ) : (
            <>
              {userHubType === "buyer" ? (
                <Box
                  sx={{
                    width: "100%",
                    mt: 3,
                    pb: 3,
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "500px",
                      mx: "auto",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-evenly",
                      height: "100%",
                    }}
                  >
                    {InvoiceData?.BuyerInvoiceAcceptanceStatus === "Pending" ? (
                      <Box
                        sx={ButtonStyleForAcceptAndReject}
                        onClick={() => {
                          UpdateInvoice();
                          setPDF(true);
                          sendEvents("Click on accept");
                        }}
                      >
                        Accept
                      </Box>
                    ) : InvoiceData?.BuyerInvoiceAcceptanceStatus ===
                      "Accepted" ? (
                      <Box
                        sx={ButtonStyleForAcceptAndReject}
                        onClick={() => {
                          navigate(
                            InvoiceData?.ProductData?.at(0)?.ProductTypeName ===
                              "Media" || InvoiceData?.IsMedia
                              ? `/home/ordersummerydetails/${StoreOrderId}`
                              : `/home/choosetransport/${StoreOrderId}`
                          );
                          sendEvents("Click on continue");
                        }}
                      >
                        Next Step {"->"}
                      </Box>
                    ) : InvoiceData?.BuyerInvoiceAcceptanceStatus ===
                        "Rejected" && !mutateRespopnseLoading ? (
                      <Box
                        sx={{
                          ...ButtonStyleForAcceptAndReject,
                          border: "0.5px solid #EB5757",
                          bgcolor: "#FFEFEF",
                          color: "#EB5757",
                        }}
                      >
                        Order Rejected
                      </Box>
                    ) : (
                      <CircularProgress size={20} color="inherit" />
                    )}

                    {InvoiceData?.BuyerInvoiceAcceptanceStatus ===
                    "Rejected" ? (
                      <Box
                        sx={{
                          ...ButtonStyleForAcceptAndReject,
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          bgcolor: "#fff",
                          color: "#2261A2",
                          border: "1px solid #2261A2",
                        }}
                        onClick={() => {
                          navigate("/home/cart");
                          sendEvents("Click on back yo cart");
                        }}
                      >
                        <MdKeyboardBackspace size={25} />
                        Back to Cart
                      </Box>
                    ) : InvoiceData?.BuyerInvoiceAcceptanceStatus ===
                        "Accepted" && !mutateRespopnseLoading ? null : (
                      <React.Fragment>
                        <Box
                          sx={{
                            ...ButtonStyleForAcceptAndReject,
                            bgcolor: "#fff",
                            border: "1px solid #445FD2",
                            color: "#445FD2",
                          }}
                          onClick={() => {
                            setOpen(true);
                            sendEvents("Click on reject");
                          }}
                        >
                          Reject
                        </Box>

                        <Modal
                          open={open}
                          onClose={() => setOpen(false)}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            transform: "translate(0%, 0%)",
                          }}
                        >
                          <Box
                            sx={{
                              background: "#fff",
                              width: "350px",
                              height: openTextarea === "" ? "400px" : "480px",
                              p: 3,
                              borderRadius: "10px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "poppins",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    color: "#6B7A99",
                                  }}
                                >
                                  Please select the reason of Rejection
                                </Typography>
                                <Box
                                  component="img"
                                  src={CloseIcon}
                                  onClick={() => {
                                    setOpen(false);
                                    sendEvents("Click");
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                ></Box>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "15px",
                                width: "100%",
                              }}
                            >
                              <Box sx={ButtonGroupstyle}>
                                <Box
                                  sx={{
                                    ...RejectReasonBtnStyle,
                                    color:
                                      openTextarea == "price"
                                        ? "#445FD2"
                                        : "#6B7A99",
                                    border:
                                      openTextarea == "price"
                                        ? "1px solid #445FD2"
                                        : "1px solid #E4E7EC",
                                  }}
                                  onClick={() => {
                                    setOpenTextarea("price");
                                    setReason("");
                                  }}
                                >
                                  <Box
                                    sx={{
                                      ...TextAndCheckBoxGroupStyle,
                                      border: "none",
                                    }}
                                  >
                                    {openTextarea == "price" ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent:
                                            openTextarea === "price"
                                              ? "space-between"
                                              : "flex-start",
                                          gap: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          <Box
                                            component="img"
                                            src={CheckedBoxIcon}
                                            sx={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                          <Typography sx={ReasonTextStyle}>
                                            {" "}
                                            Price{" "}
                                          </Typography>
                                        </Box>
                                        <Box
                                          component="img"
                                          src={CheckedCheckboxIcon}
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent:
                                            openTextarea === "price"
                                              ? "space-between"
                                              : "flex-start",
                                          gap: "20px",
                                        }}
                                      >
                                        <Box
                                          component="img"
                                          src={CheckboxIcon}
                                        />
                                        <Typography sx={ReasonTextStyle}>
                                          {" "}
                                          Price{" "}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                                <React.Fragment>
                                  {openTextarea === "price" ? (
                                    <TextField
                                      sx={TextAreaStyle}
                                      InputProps={InputPropsStyle}
                                      placeholder="please explain your reason"
                                      multiline
                                      rows={4}
                                      onChange={(e) => {
                                        setReason(e.target.value);
                                      }}
                                    ></TextField>
                                  ) : null}
                                </React.Fragment>
                              </Box>
                              <Box sx={ButtonGroupstyle}>
                                <Box
                                  sx={{
                                    ...RejectReasonBtnStyle,
                                    color:
                                      openTextarea == "deliverydate"
                                        ? "#445FD2"
                                        : "#6B7A99",
                                    border:
                                      openTextarea == "deliverydate"
                                        ? "1px solid #445FD2"
                                        : "1px solid #E4E7EC",
                                  }}
                                  onClick={() => {
                                    setOpenTextarea("deliverydate");
                                    setReason("");
                                  }}
                                >
                                  <Box sx={TextAndCheckBoxGroupStyle}>
                                    {openTextarea == "deliverydate" ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent:
                                            openTextarea === "deliverydate"
                                              ? "space-between"
                                              : "flex-start",
                                          gap: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          <Box
                                            component="img"
                                            src={CheckedBoxIcon}
                                            sx={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                          <Typography sx={ReasonTextStyle}>
                                            {" "}
                                            Delivery Date{" "}
                                          </Typography>
                                        </Box>
                                        <Box
                                          component="img"
                                          src={CheckedCheckboxIcon}
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent:
                                            openTextarea === "deliverydate"
                                              ? "space-between"
                                              : "flex-start",
                                          gap: "20px",
                                        }}
                                      >
                                        <Box
                                          component="img"
                                          src={CheckboxIcon}
                                        />
                                        <Typography sx={ReasonTextStyle}>
                                          {" "}
                                          Delivery Date{" "}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                                <React.Fragment>
                                  {openTextarea === "deliverydate" ? (
                                    <TextField
                                      sx={TextAreaStyle}
                                      InputProps={InputPropsStyle}
                                      placeholder="please explain your reason"
                                      multiline
                                      rows={4}
                                      onChange={(e) => {
                                        setReason(e.target.value);
                                      }}
                                    ></TextField>
                                  ) : null}
                                </React.Fragment>
                              </Box>
                              <Box sx={ButtonGroupstyle}>
                                <Box
                                  sx={{
                                    ...RejectReasonBtnStyle,
                                    color:
                                      openTextarea == "quantity"
                                        ? "#445FD2"
                                        : "#6B7A99",
                                    border:
                                      openTextarea == "quantity"
                                        ? "1px solid #445FD2"
                                        : "1px solid #E4E7EC",
                                  }}
                                  onClick={() => {
                                    setOpenTextarea("quantity");
                                    setReason("");
                                  }}
                                >
                                  <Box sx={TextAndCheckBoxGroupStyle}>
                                    {openTextarea == "quantity" ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent:
                                            openTextarea === "quantity"
                                              ? "space-between"
                                              : "flex-start",
                                          gap: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          <Box
                                            component="img"
                                            src={CheckedBoxIcon}
                                            sx={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                          <Typography sx={ReasonTextStyle}>
                                            {" "}
                                            Quantity{" "}
                                          </Typography>
                                        </Box>
                                        <Box
                                          component="img"
                                          src={CheckedCheckboxIcon}
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent:
                                            openTextarea === "quantity"
                                              ? "space-between"
                                              : "flex-start",
                                          gap: "20px",
                                        }}
                                      >
                                        <Box
                                          component="img"
                                          src={CheckboxIcon}
                                        />
                                        <Typography sx={ReasonTextStyle}>
                                          {" "}
                                          Quantity{" "}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                                <React.Fragment>
                                  {openTextarea === "quantity" ? (
                                    <TextField
                                      sx={TextAreaStyle}
                                      InputProps={InputPropsStyle}
                                      placeholder="please explain your reason"
                                      multiline
                                      rows={4}
                                      onChange={(e) => {
                                        setReason(e.target.value);
                                      }}
                                    ></TextField>
                                  ) : null}
                                </React.Fragment>
                              </Box>
                              <Box sx={ButtonGroupstyle}>
                                <Box
                                  sx={{
                                    ...RejectReasonBtnStyle,
                                    color:
                                      openTextarea == "other"
                                        ? "#445FD2"
                                        : "#6B7A99",
                                    border:
                                      openTextarea == "other"
                                        ? "1px solid #445FD2"
                                        : "1px solid #E4E7EC",
                                  }}
                                  onClick={() => {
                                    setOpenTextarea("other");
                                    setReason("");
                                  }}
                                >
                                  <Box sx={TextAndCheckBoxGroupStyle}>
                                    {openTextarea == "other" ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent:
                                            openTextarea === "other"
                                              ? "space-between"
                                              : "flex-start",
                                          gap: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          <Box
                                            component="img"
                                            src={CheckedBoxIcon}
                                            sx={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                          <Typography sx={ReasonTextStyle}>
                                            {" "}
                                            Other{" "}
                                          </Typography>
                                        </Box>
                                        <Box
                                          component="img"
                                          src={CheckedCheckboxIcon}
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent:
                                            openTextarea === "other"
                                              ? "space-between"
                                              : "flex-start",
                                          gap: "20px",
                                        }}
                                      >
                                        <Box
                                          component="img"
                                          src={CheckboxIcon}
                                        />
                                        <Typography sx={ReasonTextStyle}>
                                          {" "}
                                          Other{" "}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                                <React.Fragment>
                                  {openTextarea === "other" ? (
                                    <TextField
                                      sx={TextAreaStyle}
                                      InputProps={InputPropsStyle}
                                      placeholder="please explain your reason"
                                      multiline
                                      rows={4}
                                      onChange={(e) => {
                                        setReason(e.target.value);
                                      }}
                                      // InputProps={{ border: "none" }}
                                    ></TextField>
                                  ) : null}
                                </React.Fragment>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setOpen(false);
                                  sendEvents("Click on cancel");
                                }}
                                sx={{
                                  width: "50%",
                                  color: "#000",
                                  textTransform: "none",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  border: "1px solid #D0D5DD",
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                sx={{
                                  width: "50%",
                                  color: "#fff",
                                  background: "#445FD2",
                                  textTransform: "none",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  "&:hover": {
                                    background: "#445FD2",
                                  },
                                }}
                                onClick={() => {
                                  UpdateInvoiceReject();
                                  sendEvents("Click Confirm");
                                }}
                              >
                                Confirm
                              </Button>
                            </Box>
                          </Box>
                        </Modal>
                      </React.Fragment>
                    )}
                  </Box>
                </Box>
              ) : null}
            </>
          )}
        </>
      ) : (
        <PageLoader />
      )}
    </Paper>
  );
};

export default PerformaInvoice;

const ButtonStyleForAcceptAndReject = {
  width: "100%",
  height: "40px",
  maxWidth: "200px",
  borderRadius: "6px",
  bgcolor: "#156DB6",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const RejectReasonBtnStyle = {
  width: "100%",
  mx: "auto",
  textTransform: "none",
  height: "40px",
  borderRadius: "6px",
  border: "1px solid #E4E7EC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ButtonGroupstyle = {
  width: "100%",
  border: "none",
};

const TextAndCheckBoxGroupStyle = {
  width: "100%",
  px: "10px",
  cursor: "pointer",
};

const TextAreaStyle = {
  width: "100%",
  mx: "auto",
  background: "#ECEFF1",
  color: "#C7C7CC",
  border: "1px solid lighgray",
  height: "100px",
  "& fieldset": { border: "none" },
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  my: 1,
};

const InputPropsStyle = {
  // disableUnderline: true,
  sx: {
    background: "#ECEFF1",
    fontFamily: "Poppins",
    color: "#445FD2",
    borderRadius: "10px",
    fontSize: "14px",
    width: "100%",
  },
};

const ReasonTextStyle = {
  fontFamily: "poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#6B7A99",
};
