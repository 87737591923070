import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Modal, Paper } from "@mui/material";
import { Outlet } from "react-router-dom";
import BarterGinie from "./assets/Images/BarterGinie.svg";
import BarterGPTModal from "./components/Sidebar/BarterGPTModal";
import BarterGPTForNewbies from "./components/Sidebar/BarterGPTForNewbies";
import sendEvents from "./utils/sendEvents";

function BarterGptLandingPage(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <React.Fragment>
      <Box>
        {location?.pathname.includes("/home") ? (
          <Paper
            sx={{
              bgcolor: "transparent",
              borderRadius: "30px",
              overflowY: "scroll",
              background: "red",
            }}
            elevation={0}
          >
            <Box
              component="img"
              src={BarterGinie}
              sx={{
                position: "fixed",
                zIndex: 100,
                width: {
                  xl: "100px",
                  lg: "100px",
                  md: "100px",
                  sm: "55px",
                  xs: "55px",
                },
                height: {
                  xl: "100px",
                  lg: "100px",
                  md: "100px",
                  sm: "55px",
                  xs: "55px",
                },
                right: { xl: "2%", lg: "2%", md: "2%", sm: "0%", xs: "0%" },
                bottom: "2%",
                cursor: "pointer",
              }}
              onClick={() => {
                handleOpen();
                sendEvents("Click on ginie");
              }}
            />
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <BarterGPTModal modelopen={handleClose} />
              </Box>
            </Modal>
          </Paper>
        ) : (
          <Box sx={OutLetBoxStyle}>
            <Outlet />
            <Box
              component="img"
              src={BarterGinie}
              sx={{
                position: "fixed",
                zIndex: 100,
                width: {
                  xl: "100px",
                  lg: "100px",
                  md: "100px",
                  sm: "55px",
                  xs: "55px",
                },
                height: {
                  xl: "100px",
                  lg: "100px",
                  md: "100px",
                  sm: "55px",
                  xs: "55px",
                },
                right: { xl: "2%", lg: "2%", md: "2%", sm: "0%", xs: "0%" },
                bottom: "2%",
                cursor: "pointer",
              }}
              onClick={() => {
                handleOpen();
                sendEvents("Click on ginie");
              }}
            />
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <BarterGPTForNewbies onClose={handleClose} />
              </Box>
            </Modal>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}

export default BarterGptLandingPage;

const OutLetBoxStyle = {
  maxWidth: "2000px",
  width: "96%",
  mx: "auto",
  bgcolor: "transparent",
  position: "relative",
};

const style = {
  position: "fixed",
  top: "-20%",
  left: { xl: "60%", lg: "60%", md: "60%", sm: "-45%", xs: "-45%" },
  transform: "translate(50%, 50%)",
  width: { xl: "350px", lg: "350px", md: "350px", sm: "350px", xs: "350px" },
  height: { xl: "550px", lg: "550px", md: "550px", sm: "450px", xs: "450px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
};
