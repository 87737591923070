import React from "react";
import { Stack } from "@mui/material";
import BankDetails from "../pages/BankDetails/BankDetails";
const BankDetailsNew = () => {
  return (
    <Stack>
      <BankDetails />
    </Stack>
  );
};

export default BankDetailsNew;
