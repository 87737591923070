import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  Grid,
  Paper,
  Stack,
  Button,
  MenuItem,
  Pagination,
  Typography,
} from "@mui/material";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import BxiToken from "../../assets/BXITokanSVG.svg";
import DialogTitle from "@mui/material/DialogTitle";
import { alpha, styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useGetCompanyDetails } from "../../Hooks/Auth";
import CloseIconDialog from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import FilterListIcon from "@mui/icons-material/FilterList";
import CommaSeparator from "../../components/CommaSeprator";
import * as Styles from "../../components/common/Styled/Styles";
import Nodatafound from "../../components/NoDataFound/NoDataFound";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import useGetCompanyTypeData from "../../Hooks/CompanyData/useGetCompanyTypeData";
import { useGetAllOrdersForTracking } from "../../Hooks/OrderActions/useGetAllOrdersForTracking";

const TrackYourOrderPage = () => {
  // Style for pagination
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "9px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "9px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: "16px",
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  // lost of order type
  const orderType = [
    "All",
    "Progress",
    "Ready To Ship",
    "Shipped",
    "In Transit",
    "Reached Nearest hub",
    "Out For delivery",
    "Delivered",
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [MediaId, setMediaId] = useState();
  const [filter, setFilter] = useState("all");
  const [OrderData, setOrderData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setActiveType] = useState("Product");
  const [currentPage, setCurrentPage] = useState(1);
  const [UploadChange, setUploadChange] = useState("");
  const [supportingData, setSupportingData] = useState();
  const [paginationCount, setPaginationCount] = useState(0);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(null);
  const openfl = Boolean(anchorEl);

  // Get Company Data
  const { data: CompanyData } = useGetCompanyDetails();
  const { data: CompanyTypeData } = useGetCompanyTypeData(
    CompanyData?.data?.companyType
  );

  const handleClickfl = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosefl = () => {
    setAnchorEl(null);
  };

  // change page
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // calculate expiration
  const calculateEndDate = (startDate, durationString) => {
    // Extract the numeric value from the input string using a regular expression
    const numberOfMonthsToAdd = parseInt(durationString.match(/\d+/)[0], 10);
    if (isNaN(numberOfMonthsToAdd)) {
      console.error("Invalid input for duration:", durationString);
      return startDate;
    }
    const startDateObject = new Date(startDate);
    const endDate = new Date(
      startDateObject.setMonth(startDateObject.getMonth() + numberOfMonthsToAdd)
    );
    return new Date(endDate).toLocaleDateString("en-IN");
  };

  // Get TimeLine from Bought Date
  const GetTimeline = (timeline, boughtDates) => {
    let result = [];
    if (timeline === "Month") {
      const months = Math.ceil(boughtDates.length / 30);
      for (let i = 0; i < months; i++) {
        const startIndex = i * 30;
        const endIndex = Math.min((i + 1) * 30, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Week") {
      const weeks = Math.ceil(boughtDates.length / 7);
      for (let i = 0; i < weeks; i++) {
        const startIndex = i * 7;
        const endIndex = Math.min((i + 1) * 7, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Day") {
      result = boughtDates.map((date) => ({
        startDate: date,
        endDate: date,
      }));
    } else if (timeline === "Years") {
      const years = Math.ceil(boughtDates.length / 365);
      for (let i = 0; i < years; i++) {
        const startIndex = i * 365;
        const endIndex = Math.min((i + 1) * 365, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    }

    return result;
  };

  // modal Operations
  const handleClose = () => {
    setOpen(false);
    setOrderData();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // Get Track oreders
  const {
    data: getAllTrackingOrders,
    isLoading: getAllTrackingOrdersLoading,
    error: getAllTrackingOrdersError,
    refetch: refetchTrackingData,
  } = useGetAllOrdersForTracking(type, currentPage, filter);

  useEffect(() => {
    const fetchData = async () => {
      await refetchTrackingData();
    };
    fetchData();
  }, [type, currentPage, filter]);

  useEffect(() => {
    if (getAllTrackingOrders?.orders?.length > 0) {
      setPaginationCount(getAllTrackingOrders?.finalCount);
    }
  }, [getAllTrackingOrders?.finalCount]);

  return (
    <>
      <Paper elevation={0} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
        <BreadCrumbHeader MainText={"Order Tracking"} />
        <Grid container>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "17px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              padding: "2rem",
            }}
            gap={2}
          >
            <Grid
              container
              sx={{
                display: "flex",
                flexdirection: "row",
                justifyContent: "flex-end",
              }}
              gap={2}
            >
              <Button
                id="demo-customized-button"
                aria-controls={openfl ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openfl ? "true" : undefined}
                disableElevation
                onClick={handleClickfl}
                variant="outlined"
                sx={{
                  ...Styles.filterbutton,
                  "&:hover": {
                    color: "#445FD2",
                  },
                  color: openfl ? "#445FD2" : "#1B212D",
                  border: openfl ? "1px solid #445FD2" : "1px solid #F5F5F5",
                }}
                startIcon={<FilterListIcon />}
              >
                <Typography
                  sx={{
                    ...Styles.TableBodyText,
                    color: "#000",
                    m: 1,
                  }}
                >
                  Filters
                </Typography>
              </Button>

              <Box
                sx={{
                  width: "100%",
                  height: "35px",
                  bgcolor: "teransparent",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    width: "auto",
                    border: "1px solid #445FD2",
                    borderRadius: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      ...Styles.SwitchButtonStyle,
                      background:
                        type === "Product" ? "#445FD2" : "transparent",
                      color: type === "Product" ? "#FFFFFF" : "#445FD2",
                      "&:hover": {
                        background:
                          type === "Product" ? "#445FD2" : "transparent",
                        color: type === "Product" ? "#FFFFFF" : "#445FD2",
                      },
                    }}
                    onClick={() => {
                      setCurrentPage(1);
                      setActiveType("Product");
                    }}
                  >
                    Product
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      ...Styles.SwitchButtonStyle,
                      background:
                        type === "Voucher" ? "#445FD2" : "transparent",
                      color: type === "Voucher" ? "#FFFFFF" : "#445FD2",
                      "&:hover": {
                        background:
                          type === "Voucher" ? "#445FD2" : "transparent",
                        color: type === "Voucher" ? "#FFFFFF" : "#445FD2",
                      },
                    }}
                    onClick={() => {
                      setCurrentPage(1);
                      setActiveType("Voucher");
                    }}
                  >
                    Voucher
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      ...Styles.SwitchButtonStyle,
                      background: type === "Media" ? "#445FD2" : "transparent",
                      color: type === "Media" ? "#FFFFFF" : "#445FD2",
                      "&:hover": {
                        background:
                          type === "Media" ? "#445FD2" : "transparent",
                        color: type === "Media" ? "#FFFFFF" : "#445FD2",
                      },
                    }}
                    onClick={() => {
                      setCurrentPage(1);
                      setActiveType("Media");
                    }}
                  >
                    Media
                  </Button>
                </Box>
              </Box>

              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={openfl}
                onClose={handleClosefl}
              >
                {orderType?.map((res, idx) => {
                  return (
                    <MenuItem
                      value={res}
                      key={idx}
                      onClick={() => {
                        setFilter(res);
                        handleClosefl();
                      }}
                    >
                      {res}
                    </MenuItem>
                  );
                })}
              </StyledMenu>
            </Grid>

            <Box
              sx={{
                width: "100%",
              }}
            >
              {type === "Product" ? (
                <Grid container>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      p: 1,
                      borderBottom: "1px solid #F5F5F5",
                    }}
                  >
                    <Grid
                      item
                      xl={0.3}
                      lg={0.3}
                      md={0.3}
                      sm={0.3}
                      xs={0.3}
                      align="left"
                      sx={tablehead}
                    >
                      No
                    </Grid>
                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={tablehead}
                    >
                      Purchase Date
                    </Grid>

                    <Grid
                      item
                      xl={2.5}
                      lg={2.5}
                      md={2.5}
                      sm={2.5}
                      xs={2.5}
                      align="left"
                      sx={tablehead}
                    >
                      Seller Name
                    </Grid>

                    <Grid
                      item
                      xl={1.2}
                      lg={1.2}
                      md={1.2}
                      sm={1.2}
                      xs={1.2}
                      align="center"
                      sx={tablehead}
                    >
                      Order Id
                    </Grid>

                    <Grid
                      item
                      xl={0.5}
                      lg={0.5}
                      md={0.5}
                      sm={0.5}
                      xs={0.5}
                      align="center"
                      sx={tablehead}
                    >
                      Total Products
                    </Grid>

                    <Grid
                      item
                      xl={1.5}
                      lg={1.5}
                      md={1.5}
                      sm={1.5}
                      xs={1.5}
                      align="center"
                      sx={tablehead}
                    >
                      Barter Value
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={tablehead}
                    >
                      Waybill No
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={tablehead}
                    >
                      Delivery Partner
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={tablehead}
                    >
                      Tracking Id
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={tablehead}
                    >
                      Order Status
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={{ ...tablehead }}
                    >
                      Delivery Type
                    </Grid>
                  </Grid>
                  {getAllTrackingOrdersLoading ? (
                    [...Array(10)].map((data, idx) => {
                      return <SkeletonFun />;
                    })
                  ) : getAllTrackingOrders?.orders?.length <= 0 ? (
                    <Nodatafound />
                  ) : (
                    getAllTrackingOrders?.orders?.map((el, idx) => {
                      const styles = getStatusStyles(
                        el?.BuyerChoosedTransportation?.TransportationType ===
                          "pickrr"
                          ? el?.BuyerChoosedTransportation?.OrderStatus
                          : el?.OrderStatus
                      );

                      return (
                        <Grid
                          key={idx}
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{
                            display: "flex",
                            p: 1,
                            borderBottom: "1px solid #F5F5F5",
                          }}
                        >
                          <Grid
                            item
                            xl={0.3}
                            lg={0.3}
                            md={0.3}
                            sm={0.3}
                            xs={0.3}
                            align="left"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            {" "}
                            <Typography sx={Styles.TableBodyText}>
                              {idx + 1}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {new Date(el.createdAt).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={2.5}
                            lg={2.5}
                            md={2.5}
                            sm={2.5}
                            xs={2.5}
                            align="left"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              gap={2}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    ...Styles.TableBodyText,
                                    color: "#1B212D",
                                    width: "90%",
                                    minWidth: "200px",
                                  }}
                                >
                                  {
                                    el.PurchaseOrderData?.SellerDetails
                                      ?.SellerCompanyName
                                  }
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xl={1.2}
                            lg={1.2}
                            md={1.2}
                            sm={1.2}
                            xs={1.2}
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {el?.OrderId}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={0.5}
                            lg={0.5}
                            md={0.5}
                            sm={0.5}
                            xs={0.5}
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {el.PurchaseOrderData?.POTotals?.TotalQuantity
                                ? el.PurchaseOrderData?.POTotals?.TotalQuantity
                                : "--"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {el.PurchaseOrderData?.POTotals
                              ?.TotalProductCoins ? (
                              <Typography
                                sx={{
                                  ...Styles.TableBodyText,
                                  color: "#1B212D",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <Box
                                  component="img"
                                  src={BxiToken}
                                  sx={{
                                    width: "15px",
                                    height: "auto",
                                  }}
                                />
                                <CommaSeparator
                                  Price={
                                    el.PurchaseOrderData?.POTotals
                                      ?.TotalProductCoins
                                  }
                                />
                              </Typography>
                            ) : (
                              "--"
                            )}
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {el?.shippingData?.waybill_no
                                ? el?.shippingData?.waybill_no
                                : "--"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {el?.BuyerChoosedTransportation
                                ?.TransportationType === "pickrr"
                                ? el?.shippingData?.delivery_partner?.name
                                : "Seller"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {el?.trackingId && el?.trackingUrl ? (
                                <Button
                                  variant="contained"
                                  sx={{
                                    borderRadius: "10px",
                                    textTransform: "none",
                                  }}
                                  onClick={() => {
                                    handleClickOpen();
                                    setOrderData(el);
                                  }}
                                >
                                  View
                                </Button>
                              ) : (
                                "--"
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              sx={{
                                bgcolor: styles.bgColor,
                                boxShadow: "none",
                                textTransform: "none",
                                color: styles.textColor,
                                "&:hover": {
                                  bgcolor: styles.hoverColor,
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                }}
                              >
                                {el?.BuyerChoosedTransportation
                                  ?.TransportationType === "pickrr" ? (
                                  el?.BuyerChoosedTransportation?.OrderStatus
                                ) : el?.OrderStatus !== "Pending" ? (
                                  el?.OrderStatus
                                ) : (
                                  <Button
                                    sx={{
                                      boxShadow: "none",
                                      textTransform: "none",
                                      color: "#F2994A",
                                      bgcolor: "#FFF1E5",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      fontFamily: "poppins",
                                    }}
                                  >
                                    Pending
                                  </Button>
                                )}
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {el?.BuyerChoosedTransportation
                                ?.TransportationType
                                ? el?.BuyerChoosedTransportation
                                    ?.TransportationType
                                : "--"}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })
                  )}
                </Grid>
              ) : type === "Voucher" ? (
                <Grid container>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      p: 1,

                      borderBottom: "1px solid #F5F5F5",
                    }}
                  >
                    <Grid
                      item
                      xl={0.3}
                      lg={0.3}
                      md={0.3}
                      sm={0.3}
                      xs={0.3}
                      align="left"
                      sx={tablehead}
                    >
                      No.
                    </Grid>
                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="left"
                      sx={tablehead}
                    >
                      Purchase Date
                    </Grid>
                    <Grid
                      item
                      xl={2.5}
                      lg={2.5}
                      md={2.5}
                      sm={2.5}
                      xs={2.5}
                      align="left"
                      sx={tablehead}
                    >
                      Seller Name
                    </Grid>
                    <Grid
                      item
                      xl={1.5}
                      lg={1.5}
                      md={1.5}
                      sm={1.5}
                      xs={1.5}
                      align="center"
                      sx={tablehead}
                    >
                      Order Id
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="left"
                      sx={tablehead}
                    >
                      Type of <br /> Voucher
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="left"
                      sx={tablehead}
                    >
                      Barter Value
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="left"
                      sx={tablehead}
                    >
                      Codes <br /> Generated By
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="left"
                      sx={tablehead}
                    >
                      Download <br /> Codes
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="left"
                      sx={tablehead}
                    >
                      Activation Date
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="left"
                      sx={tablehead}
                    >
                      Expiry Date
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="left"
                      sx={tablehead}
                    >
                      Status of Codes
                    </Grid>
                  </Grid>
                  {getAllTrackingOrdersLoading ? (
                    [...Array(10)].map((data, idx) => {
                      return <SkeletonFun />;
                    })
                  ) : getAllTrackingOrders?.orders?.length <= 0 ? (
                    <Nodatafound />
                  ) : (
                    getAllTrackingOrders?.orders?.map((el, idx) => {
                      // pdf downloading
                      async function generatePDF(link) {
                        if (link) {
                          await axios
                            .post(
                              "voucher/get_voucher_pdf_files",
                              {
                                fileUrl: link,
                              },
                              { responseType: "blob" }
                            )
                            .then((response) => {
                              const blob = new Blob([response.data], {
                                type: "application/pdf",
                              });

                              // Create a download link
                              const downloadLink = document.createElement("a");
                              downloadLink.href = window.URL.createObjectURL(
                                blob
                              );
                              downloadLink.download = "VoucherCodes.pdf";

                              document.body.appendChild(downloadLink);

                              downloadLink.click();

                              document.body.removeChild(downloadLink);
                            })
                            .catch((error) => {
                              console.error(
                                "elIdxresponseError downloading file:",
                                error
                              );
                            });
                        }
                      }

                      const voucherCodeGenrationType =
                        el?.PurchaseOrderData?.ProductData[0]
                          ?.VoucherCodeGenrationType;

                      return (
                        <Grid
                          item
                          key={idx}
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{
                            display: "flex",
                            p: 1.5,
                            borderBottom: "1px solid #F5F5F5",
                          }}
                        >
                          <Grid
                            item
                            xl={0.3}
                            lg={0.3}
                            md={0.3}
                            sm={0.3}
                            xs={0.3}
                            align="left"
                            sx={tablehead}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                                color: "#929EAE",
                              }}
                            >
                              {idx + 1}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="left"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#1B212D",
                              }}
                            >
                              {new Date(el?.createdAt).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={2.5}
                            lg={2.5}
                            md={2.5}
                            sm={2.5}
                            xs={2.5}
                            align="left"
                            sx={tablehead}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                              gap={2}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    fontFamily: "Kumbh Sans",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "#1B212D",
                                    width: "90%",
                                    minWidth: "200px",
                                  }}
                                >
                                  {
                                    el.PurchaseOrderData?.SellerDetails
                                      ?.SellerCompanyName
                                  }
                                </Typography>
                              </Box>
                            </Box>{" "}
                          </Grid>

                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            align="center"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#1B212D",
                              }}
                            >
                              {`${el?.OrderId ? el?.OrderId : "--"}`}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="left"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#1B212D",
                              }}
                            >
                              {el?.PurchaseOrderData?.ProductData[0]
                                ?.VoucherType
                                ? el?.PurchaseOrderData?.ProductData[0]
                                    ?.VoucherType
                                : "--"}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="left"
                          >
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#1B212D",
                                display: "flex",
                                alignItems: "left",
                              }}
                            >
                              <Box
                                component="img"
                                src={BxiToken}
                                sx={{
                                  width: "15px",
                                  height: "auto",
                                }}
                              />
                              <CommaSeparator
                                Price={
                                  el.PurchaseOrderData?.BXITokenDetails
                                    ?.TotalBXiCoins
                                }
                              />
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="left"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#1B212D",
                              }}
                            >
                              {voucherCodeGenrationType
                                ? voucherCodeGenrationType === "bxi"
                                  ? "BXI"
                                  : voucherCodeGenrationType === "self"
                                  ? "Self"
                                  : voucherCodeGenrationType
                                : "--"}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="left"
                            sx={tablehead}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#1B212D",
                              }}
                            >
                              {el?.VoucherStatus === "InActive" ||
                              el?.VoucherStatus === undefined ||
                              el?.VoucherStatus === null ? (
                                "--"
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      generatePDF(el?.BuyerVoucherFile?.url);
                                    }}
                                    sx={{
                                      textTransform: "none",
                                      borderRadius: "10px",
                                      width: "35px",
                                    }}
                                  >
                                    Voucher Codes
                                  </Button>{" "}
                                </>
                              )}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="left"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#1B212D",
                              }}
                            >
                              {new Date(
                                el?.PurchaseOrderData?.OrderDate
                              ).toLocaleDateString("en-IN")}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="left"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#1B212D",
                              }}
                            >
                              {el?.PurchaseOrderData?.ProductData?.at(0)
                                ?.VoucherValidity
                                ? calculateEndDate(
                                    el?.PurchaseOrderData?.OrderDate,
                                    el?.PurchaseOrderData?.ProductData?.at(0)
                                      ?.VoucherValidity
                                  )
                                : "--"}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="left"
                            sx={tablehead}
                          >
                            {" "}
                            <Button
                              sx={{
                                bgcolor:
                                  el?.VoucherStatus === "InActive"
                                    ? "#FFEFEF"
                                    : el?.VoucherStatus === "Active"
                                    ? "#D9FFE9"
                                    : "#FFEFEF",
                                boxShadow: "none",
                                textTransform: "none",
                                color:
                                  el?.VoucherStatus === "InActive"
                                    ? "#EB5757"
                                    : el?.VoucherStatus === "Active"
                                    ? "#27AE60"
                                    : "#EB5757",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Kumbh Sans",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                }}
                              >
                                {el?.VoucherStatus
                                  ? el?.VoucherStatus
                                  : "InActive"}
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })
                  )}
                </Grid>
              ) : type === "Media" ? (
                <Grid container>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      p: 1,
                      borderBottom: "1px solid #F5F5F5",
                    }}
                  >
                    <Grid
                      item
                      xl={0.5}
                      lg={0.5}
                      md={0.5}
                      sm={0.5}
                      xs={0.5}
                      align="left"
                      sx={tablehead}
                    >
                      Sr No.
                    </Grid>
                    <Grid
                      item
                      xl={1.5}
                      lg={1.5}
                      md={1.5}
                      sm={1.5}
                      xs={1.5}
                      align="left"
                      sx={tablehead}
                    >
                      Purchase Date
                    </Grid>
                    <Grid
                      item
                      xl={2.2}
                      lg={2.2}
                      md={2.2}
                      sm={2.2}
                      xs={2.2}
                      align="left"
                      sx={tablehead}
                    >
                      Seller Name
                    </Grid>
                    <Grid
                      item
                      xl={1.3}
                      lg={1.3}
                      md={1.3}
                      sm={1.3}
                      xs={1.3}
                      align="center"
                      sx={tablehead}
                    >
                      Order Id
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={tablehead}
                    >
                      Start Date
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={tablehead}
                    >
                      End Date
                    </Grid>

                    <Grid
                      item
                      xl={1.5}
                      lg={1.5}
                      md={1.5}
                      sm={1.5}
                      xs={1.5}
                      align="center"
                      sx={tablehead}
                    >
                      Supportings
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={tablehead}
                    >
                      Proof of Execution
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={tablehead}
                    >
                      Media Type
                    </Grid>

                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      align="center"
                      sx={tablehead}
                    >
                      Branding Status
                    </Grid>
                  </Grid>
                  {getAllTrackingOrdersLoading ? (
                    [...Array(10)].map((data, idx) => {
                      return <SkeletonFun />;
                    })
                  ) : getAllTrackingOrders?.orders?.length <= 0 ? (
                    <Nodatafound />
                  ) : (
                    getAllTrackingOrders?.orders?.map((el, idx) => {
                      return (
                        <Grid
                          item
                          key={idx}
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{
                            display: "flex",
                            p: 1,
                            borderBottom: "1px solid #F5F5F5",
                          }}
                        >
                          <Grid
                            item
                            xl={0.5}
                            lg={0.5}
                            md={0.5}
                            sm={0.5}
                            xs={0.5}
                            align="left"
                            sx={tablehead}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#929EAE",
                              }}
                            >
                              {idx + 1}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            align="left"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {new Date(el?.createdAt).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={2.2}
                            lg={2.2}
                            md={2.2}
                            sm={2.2}
                            xs={2.2}
                            align="left"
                            sx={tablehead}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                              gap={2}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    ...Styles.TableBodyText,
                                    color: "#1B212D",
                                    width: "90%",
                                    minWidth: "200px",
                                  }}
                                >
                                  {
                                    el.PurchaseOrderData?.SellerDetails
                                      ?.SellerCompanyName
                                  }
                                </Typography>
                              </Box>
                            </Box>{" "}
                          </Grid>

                          <Grid
                            item
                            xl={1.3}
                            lg={1.3}
                            md={1.3}
                            sm={1.3}
                            xs={1.3}
                            align="center"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {el?.OrderId ? el?.OrderId : "--"}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {new Date(
                                el?.PurchaseOrderData?.ProductData?.at(
                                  0
                                )?.BoughtDates?.at(0)
                              ).toLocaleDateString("en-IN")}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={tablehead}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {new Date(
                                el?.PurchaseOrderData?.ProductData?.at(
                                  0
                                )?.BoughtDates?.at(
                                  el?.PurchaseOrderData?.ProductData?.at(0)
                                    ?.BoughtDates?.length - 1
                                )
                              ).toLocaleDateString("en-IN")}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            align="center"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {GetTimeline(
                                el?.PurchaseOrderData?.ProductData?.at(0)
                                  ?.Timeline,
                                el?.PurchaseOrderData?.ProductData?.at(0)
                                  ?.BoughtDates
                              )?.length <= el?.MediaSupporting?.length ? (
                                <Button
                                  variant="contained"
                                  sx={{
                                    borderRadius: "10px",
                                    textTransform: "none",
                                  }}
                                  onClick={() => {
                                    handleClickOpen();
                                    setUploadChange("UploadSupporting");
                                    setOrderData(el);
                                    setMediaId(el?._id);
                                    setSupportingData(
                                      GetTimeline(
                                        el?.PurchaseOrderData?.ProductData?.at(
                                          0
                                        )?.Timeline,
                                        el?.PurchaseOrderData?.ProductData?.at(
                                          0
                                        )?.BoughtDates
                                      )
                                    );
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...Styles.TableBodyText,
                                      color: "#fff",
                                    }}
                                  >
                                    Supportings
                                  </Typography>
                                </Button>
                              ) : (
                                "Nothing In Supporting"
                              )}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={tablehead}
                          >
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {GetTimeline(
                                el?.PurchaseOrderData?.ProductData?.at(0)
                                  ?.Timeline,
                                el?.PurchaseOrderData?.ProductData?.at(0)
                                  ?.BoughtDates
                              )?.length <= el?.MediaProofs?.length ? (
                                <Button
                                  variant="contained"
                                  sx={{
                                    borderRadius: "10px",
                                    textTransform: "none",
                                  }}
                                  onClick={() => {
                                    handleClickOpen();
                                    setUploadChange("UploadProof");
                                    setOrderData(el);
                                    setMediaId(el?._id);
                                    setSupportingData(
                                      GetTimeline(
                                        el?.PurchaseOrderData?.ProductData?.at(
                                          0
                                        )?.Timeline,
                                        el?.PurchaseOrderData?.ProductData?.at(
                                          0
                                        )?.BoughtDates
                                      )
                                    );
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...Styles.TableBodyText,
                                      color: "#fff",
                                    }}
                                  >
                                    Proofs
                                  </Typography>
                                </Button>
                              ) : (
                                "Nothing In Proof"
                              )}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={tablehead}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...Styles.TableBodyText,
                                color: "#1B212D",
                              }}
                            >
                              {" "}
                              {el?.PurchaseOrderData?.ProductData?.at(0)
                                ?.ProductTypeId === "MediaOffline" ||
                              el?.PurchaseOrderData?.ProductData?.at(0)
                                ?.ProductTypeId === "MediaOnline"
                                ? el?.PurchaseOrderData?.ProductData?.at(0)
                                    ?.ProductTypeId
                                : "--"}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            align="center"
                            sx={tablehead}
                          >
                            {" "}
                            <Button
                              sx={{
                                bgcolor:
                                  el?.MediaStatus === "Cancelled"
                                    ? "#FFEFEF"
                                    : el?.MediaStatus === "Executed"
                                    ? "#FFF1E5"
                                    : el?.MediaStatus === "Creative Approved" ||
                                      el?.MediaStatus === "Completed"
                                    ? "#D9FFE9"
                                    : "",
                                boxShadow: "none",
                                textTransform: "none",
                                color:
                                  el?.MediaStatus === "Cancelled"
                                    ? "#EB5757"
                                    : el?.MediaStatus === "Executed"
                                    ? "#F2994A"
                                    : el?.MediaStatus === "Creative Approved" ||
                                      el?.MediaStatus === "Completed"
                                    ? "#27AE60"
                                    : "",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...Styles.TableBodyText,
                                }}
                              >
                                {el?.MediaStatus}
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })
                  )}
                </Grid>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
        {getAllTrackingOrders?.orders?.length > 0 ? (
          <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
            <Stack spacing={2}>
              <StyledPagination
                count={paginationCount}
                color="primary"
                page={currentPage}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
              />
            </Stack>
          </Box>
        ) : null}
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth={"xs"}
          sx={{
            backdropFilter: "blur(2px)",
          }}
          PaperProps={{
            sx: {
              maxHeight: 600,
              borderRadius: "20px",
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  mx: "auto",
                }}
              >
                <Typography sx={DateText}>
                  {type == "Media" ? (
                    <Box>
                      {UploadChange === "UploadSupporting"
                        ? "Supporting"
                        : "Proof"}
                    </Box>
                  ) : (
                    <Box>
                      {OrderData?.trackingId && OrderData.trackingUrl
                        ? "Tracking website Url and ID"
                        : ""}
                    </Box>
                  )}
                </Typography>
              </Box>
              <Box>
                <CloseIconDialog
                  sx={{
                    color: "#667085",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "scroll",
            }}
          >
            {type == "Media" ? (
              <Box>
                {UploadChange === "UploadSupporting" ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {OrderData?.MediaSupporting?.map((item, idx) => {
                      return (
                        <Box
                          key={idx}
                          sx={{
                            mt: "5px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Box>
                            <Typography sx={DateText}>
                              StartDate:-{" "}
                              {new Date(item?.StartDate).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                            <Typography sx={DateText}>
                              End Date:-{" "}
                              {new Date(item?.EndDate).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography sx={DateText}>
                              <a
                                href={item?.MediaSupporting}
                                target="_blank"
                                download="fileName"
                                style={{
                                  textDecoration: "none",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    // window.open(OrderSummarydata?.UploadContantFileUrl, "_blank");
                                  }}
                                  variant="contained"
                                  sx={{
                                    borderRadius: "10px",
                                    textTransform: "none",
                                  }}
                                >
                                  View Content
                                </Button>
                              </a>
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {OrderData?.MediaProofs?.map((item, idx) => {
                      return (
                        <Box
                          key={idx}
                          sx={{
                            mt: "5px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Box>
                            <Typography sx={DateText}>
                              StartDate:-{" "}
                              {new Date(item?.StartDate).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                            <Typography sx={DateText}>
                              End Date:-{" "}
                              {new Date(item?.EndDate).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography sx={DateText}>
                              <a
                                href={item?.MediaProofs}
                                target="_blank"
                                download="fileName"
                                style={{
                                  textDecoration: "none",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    // window.open(OrderSummarydata?.UploadContantFileUrl, "_blank");
                                  }}
                                  variant="contained"
                                  sx={{
                                    borderRadius: "10px",
                                    textTransform: "none",
                                  }}
                                >
                                  View Content
                                </Button>
                              </a>
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {OrderData?.trackingId && OrderData.trackingUrl ? (
                  <>
                    <Typography sx={DateText}>
                      Tracking Id : {OrderData?.trackingId}
                    </Typography>
                    <Typography sx={DateText}>
                      Tracking Url :{" "}
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${OrderData.trackingUrl}`
                          );
                          toast.info("URL Copied", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                        }}
                      >
                        {OrderData.trackingUrl}
                      </span>
                    </Typography>
                  </>
                ) : (
                  "--"
                )}
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Paper>
    </>
  );
};

export default TrackYourOrderPage;

const SkeletonFun = () => {
  return (
    <Skeleton
      animation="wave"
      height={80}
      sx={{
        borderRadius: "10px",
        width: "100%",
        bgcolor: "#edf1f5",
        spacing: "10px",
        mx: "auto",
      }}
    />
  );
};
const getStatusStyles = (status) => {
  switch (status) {
    case "Out For delivery":
    case "Cancelled":
      return {
        bgColor: "#FFEFEF",
        textColor: "#EB5757",
        hoverColor: "#FFEFEF",
      };
    case "Pickup Exception":
      return {
        bgColor: "#FFEFEF",
        textColor: "#EB5757",
        hoverColor: "#FFEFEF",
      };
    case "Created":
    case "Mounted":
      // something different color than yellow
      return {
        bgColor: "#FFF1E5",
        textColor: "#F2994A",
        hoverColor: "#FFF1E5",
      };

    case "Ready To Ship":
    case "Progress":
    case "In Transit":
    case "Pending":
    case "In Progress":
    case "Reached Nearest hub":
      return {
        bgColor: "#FFF1E5",
        textColor: "#F2994A",
        hoverColor: "#FFF1E5",
      };
    case "Delivered":
    case "Shipped":
      return {
        bgColor: "#D9FFE9",
        textColor: "#27AE60",
        hoverColor: "#D9FFE9",
      };
    case "Pickup Pending":
      return {
        bgColor: "#f6fafd",
        textColor: "#1976d2",
        hoverColor: "#f6fafd",
      };
    default:
      return {
        bgColor: "",
        textColor: "#F2994A",
        hoverColor: "",
      };
  }
};

const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#929EAE",
};

const DateText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "15px",
  lineHeight: "28px",
  color: "#6B7A99",
  textAlign: "center",
};
