import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress, TextField } from "@mui/material";
import { Select, MenuItem, BottomNavigation, Button } from "@mui/material";
import { usePostProductQuery } from "./ProductHooksQuery";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ToolTip from "../../../../components/ToolTip";
import axios from "axios";
import {
  TitleHeadText,
  TextFieldStyle,
  SelectStyle,
  BoxStyle,
  CommonTextStyle,
  InputPropsStyle,
  MenuItemTextStyle,
  CancelButtonStyle,
  NextButtonStyle,
} from "../Styles";

export default function GeneralInformation() {
  const [SubCategory, setSubCategory] = useState("Select");
  const LocationData = useLocation();
  const [ProductData, setProductData] = useState();
  const [MediaSubCatArr, setMediaSubCatArr] = useState([]);
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = usePostProductQuery();
  const fetchMediaSubCategories = async () => {
    await axios
      .get("/mediaonlinesub/Get_media_onlinesingle", {
        withCredentials: true,
      })
      .then((res) => {
        setMediaSubCatArr(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchMediaSubCategories();
  }, []);

  const FetchProduct = async () => {
    await axios
      .get("/product/get_product_byId/" + LocationData?.state?.id)
      .then((response) => {
        console.log("shdfghufsdgybufgudsg", response);
        setProductData(response?.data);
      })
      .catch((error) => {
        console.log("errorsdhfdsug", error);
      });
  };
  useEffect(() => {
    FetchProduct();
  }, []);
  let DropName = "";
  function DropDownName(id) {
    MediaSubCatArr &&
      MediaSubCatArr?.map((item) => {
        if (item._id === id) {
          DropName = item.Mediaonlinecategorysingle;
        }
      });

    return DropName;
  }
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    values: {
      subcategory: ProductData?.ProductSubCategory,
      productname: ProductData?.ProductName,
      productsubtitle: ProductData?.ProductSubtitle,
      productdescription: ProductData?.ProductDescription,
    },
    resolver: zodResolver(
      z.object({
        subcategory: z.string().min(1),
        productname: z
          .string()
          .max(50)
          .min(5),
        productsubtitle: z
          .string()
          .max(75)
          .min(10),
        productdescription: z
          .string()
          .max(1000)
          .min(20),
      })
    ),
    defaultValues: {
      subcategory: ProductData?.ProductSubCategory,
      productname: ProductData?.ProductName,
      productsubtitle: ProductData?.ProductSubtitle,
      productdescription: ProductData?.ProductDescription,
    },
  });

  const AddProduct = handleSubmit((data) => {
    mutateAsync(
      {
        ProductName: data.productname,
        ProductSubtitle: data.productsubtitle,
        ProductDescription: data.productdescription,
        ProductSubCategory: data.subcategory,
        id: LocationData?.state?.id,
        ProductUploadStatus: "productinformation",
        ListingType: "Media",
        ProductCategoryName:
          DropDownName(data.subcategory) === "Multiplex ADs" ||
          data.subcategory === "643cda0c53068696706e3951"
            ? "Multiplex ADs"
            : "MediaOnline",
        ProductSubCategoryName: DropDownName(data.subcategory),
      },
      {
        onSuccess: (response) => {
          navigate(
            "/home/mediaonline/mediaonlineproductinfo/" + response.data._id
          );
        },
        onError: (error) => {
          console.log("error", error);
        },
      }
    );
  });

  return (
    <form onSubmit={AddProduct}>
      <Box sx={BoxStyle}>
        <Box
          sx={{
            width: "100%",
            mx: "auto",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            px: "25px",
            py: "15px",
          }}
        >
          <Typography sx={TitleHeadText}>General Information</Typography>
          <ToolTip
            info={
              "General Information refers to broad and fundamental knowledge or facts about a particular Product OR Vouchers. It includes Basic details, features, or descriptions that provide overview."
            }
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            width: "88%",
            bgcolor: "transparent",
            mx: "auto",
            mt: "10px",
            mb: "20px",
            pb: 4,
            height: "auto",
            gap: "20px",
            maxHeight: "100%",
            overflowY: "hidden",
          }}
        >
          <Box
            sx={{
              width: "100%",
              background: "transparent",
            }}
          >
            <Typography sx={CommonTextStyle}>
              SubCategory <span style={{ color: "red" }}> *</span>
            </Typography>

            {ProductData?.ProductSubCategory ? (
              <Typography sx={CommonTextStyle}>
                {" "}
                Your Selected Category:{" "}
                <span style={{ fontSize: "14px", color: "#c64091" }}>
                  {DropDownName(ProductData?.ProductSubCategory)}
                </span>
              </Typography>
            ) : null}

            <Select
              value={SubCategory}
              {...register("subcategory")}
              onChange={(e) => setSubCategory(e.target?.value)}
              sx={SelectStyle}
            >
              {MediaSubCatArr?.sort((a, b) =>
                a.Mediaonlinecategorysingle.toLowerCase().localeCompare(
                  b.Mediaonlinecategorysingle.toLowerCase()
                )
              ).map((item) => (
                <MenuItem
                  value={item?._id}
                  sx={{ ...MenuItemTextStyle, textTransform: "capitalize" }}
                >
                  {item?.Mediaonlinecategorysingle.toLowerCase()}
                </MenuItem>
              ))}
            </Select>
            <Typography sx={ErrorStyle}>
              {errors["subcategory"]?.message}
            </Typography>
          </Box>
          <Box>
            <Typography sx={CommonTextStyle}>
              Product Name <span style={{ color: "red" }}> *</span>
            </Typography>
            <TextField
              focused
              placeholder="Eg. Cafe coffee Day Juhu ( 8 keywords max ) "
              multiline
              variant="standard"
              sx={{
                ...TextFieldStyle,
                width: "99%",
                mx: "auto",
                border: errors["productname"] ? "1px solid red" : null,
              }}
              InputProps={InputPropsStyle}
              {...register("productname")}
              onKeyDown={(e) => {
                if (e.key === " " && e.target.selectionStart === 0) {
                  e.preventDefault();
                }
              }}
            />
            <Typography sx={ErrorStyle}>
              {errors["productname"]?.message}
            </Typography>
          </Box>
          <Box>
            <Typography sx={CommonTextStyle}>
              Subtitle <span style={{ color: "red" }}> *</span>
            </Typography>
            <TextField
              focused
              placeholder="Eg. Digital Ads inside cafe on 64 inch TV (24 keywords max ) "
              multiline
              variant="standard"
              sx={{
                ...TextFieldStyle,
                width: "99%",
                mx: "auto",
                border: errors["productsubtitle"] ? "1px solid red" : null,
              }}
              InputProps={InputPropsStyle}
              {...register("productsubtitle")}
              onKeyDown={(e) => {
                if (e.key === " " && e.target.selectionStart === 0) {
                  e.preventDefault();
                }
              }}
            />
            <Typography sx={ErrorStyle}>
              {errors["productsubtitle"]?.message}
            </Typography>
          </Box>

          <Box>
            <Typography sx={CommonTextStyle}>
              Description <span style={{ color: "red" }}> *</span>
            </Typography>
            <TextField
              focused
              placeholder="Eg. Big Brands Need Big digital 64 inch Screens, strategically placed inside cafeteria at a prominent location. Reach your target audience and potential customers by advertising on the screens. Run your 15 seconds video ads on the screens which play 200 times in a day.( 50 keywords max ) "
              multiline
              variant="standard"
              sx={{
                ...TextFieldStyle,
                height: "100%",
                width: "99%",
                mx: "auto",
                border: errors["productdescription"] ? "1px solid red" : null,
              }}
              minRows={3}
              InputProps={InputPropsStyle}
              {...register("productdescription")}
              onKeyDown={(e) => {
                if (e.key === " " && e.target.selectionStart === 0) {
                  e.preventDefault();
                }
              }}
            />
            <Typography sx={ErrorStyle}>
              {errors["productdescription"]?.message}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            mx: "auto",
            height: "100%",
            bgcolor: "transparent",
          }}
        >
          <BottomNavigation
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              bgcolor: "#EEF1F6",
              p: "10px",
              boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
            }}
            showLabels
          >
            <Box sx={{ display: "flex", gap: "10px", p: 1, width: "50%" }}>
              <Button sx={CancelButtonStyle} variant="contained">
                Cancel
              </Button>
              <Button type="submit" sx={NextButtonStyle} variant="contained">
                {isLoading ? <CircularProgress size={20} /> : "Next"}
              </Button>
            </Box>
          </BottomNavigation>
        </Box>
      </Box>
    </form>
  );
}

const ErrorStyle = {
  color: "red",
};
