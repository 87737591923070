import axios from "axios";

import {
  MEDIA_BULK_UPLOAD_TODB_REQUEST,
  MEDIA_BULK_UPLOAD_TODB_SUCCESS,
  MEDIA_BULK_UPLOAD_TODB_FAIL,
} from "../../reduser/Products/MediaBulkuploadproductToDB";

export const MediaBulkuploadproductToDB = () => async (dispatch) => {
  try {
    dispatch({ type: MEDIA_BULK_UPLOAD_TODB_REQUEST.toString() });

    let link = `product/mediaProductBulkUploadsToDB`;

    const { data } = await axios.post(link, { withCredentials: true });

    dispatch({
      type: MEDIA_BULK_UPLOAD_TODB_SUCCESS.toString(),
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEDIA_BULK_UPLOAD_TODB_FAIL.toString(),
      payload: error.response.data.message,
    });
  }
};
