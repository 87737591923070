import { Button, Paper } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

const PaymentPage = () => {
  const [mandateTokenId, setMandateTokenId] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [rData, setRData] = useState("");

  const fetchPaymentForm = async () => {
    try {
      const res = await axios.post("enach_mandate/generate_mandate_token");

      const mandate = res?.data?.mandateTokenParams;
      setMandateTokenId(mandate?.mandate_tokenid);
      setMerchantId(mandate?.mercid);
      setRData(mandate?.links?.at(1)?.parameters?.rdata);
    } catch (error) {
      console.error("Error fetching payment form:", error);
    }
  };

  const handleSubmit = () => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://uat1.billdesk.com/u2/web/v1_2/embeddedsdk";
    form.target = "_blank";

    const mandateTokenIdInput = document.createElement("input");
    mandateTokenIdInput.type = "hidden";
    mandateTokenIdInput.name = "mandatetokenid";
    mandateTokenIdInput.value = mandateTokenId;
    form.appendChild(mandateTokenIdInput);

    const merchantIdInput = document.createElement("input");
    merchantIdInput.type = "hidden";
    merchantIdInput.name = "merchantid";
    merchantIdInput.value = merchantId;
    form.appendChild(merchantIdInput);

    const rDataInput = document.createElement("input");
    rDataInput.type = "hidden";
    rDataInput.name = "rdata";
    rDataInput.value = rData;
    form.appendChild(rDataInput);

    document.body.appendChild(form);
    form.submit();
  };

  const invoice_create = async () => {
    try {
      await axios
        .post(`enach_mandate/create_recurring_transaction_invoice`)
        .then((res) => {
          console.log("response invoice", res);
        });
    } catch (error) {
      console.error("Error creating invoice:", error);
    }
  };

  const get_mandate_invoice = async () => {
    try {
      await axios.get(`enach_mandate/getInvoiceOf_mandate`).then((res) => {
        console.log("sdjguygsdyugfugsd", res.data);
      });
    } catch (error) {
      console.error("Error getting mandate invoice:", error);
    }
  };

  const makePaymentRequest = async () => {
    try {
      await axios
        .post(`enach_mandate/create_recurring_transaction`)
        .then((res) => {
          console.log("response sdfdsfdsfsdfsdfsdf", res);
        });
    } catch (error) {
      console.error("Error creating sdfsdfsdfdffsd:", error);
    }
  };

  const GenerateCommission = async () => {
    try {
      await axios
        .post(`commission/create_bxi_commision_invoice`, {
          OrderId: "666948bd16e7cf632d688e2b",
        })
        .then((response) => {
          console.log("response", response);
        });
    } catch (error) {
      console.error("Error creating commission:", error);
    }
  };

  return (
    <Paper sx={{ width: "100%", padding: 2 }}>
      <Button onClick={fetchPaymentForm}>Open Payment</Button>
      {mandateTokenId && merchantId && rData && (
        <Button onClick={handleSubmit}>Submit Payment</Button>
      )}
      <Button onClick={invoice_create}>Create Invoice</Button>
      <Button onClick={get_mandate_invoice}>Get Mandate Invoice</Button>
      <Button onClick={makePaymentRequest}>Make Payment Request</Button>
      <Button onClick={GenerateCommission}>Commission</Button>
    </Paper>
  );
};

export default PaymentPage;
