import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Skeleton,
  Paper,
  Button,
  Tooltip,
  Fade,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGetCompanyTypeData from "../Hooks/CompanyData/useGetCompanyTypeData";
import CommaSeprator from "../components/CommaSeprator";
import Nodatafound from "../components/NoDataFound/NoDataFound";
import sendEvents from "../utils/sendEvents";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIconDialog from "@mui/icons-material/Close";
import {
  AllListedProductByCompanyAction,
  ListedDraftProductsByCompanyAction,
  ListedProductsByCompanyAction,
  AllRejectedProductByCompanyAction,
  AllDelistProductByCompanyAction,
  AllPendingProductByCompanyAction,
} from "../redux/action/ProductActions/ListedProductByCompanyAction";
import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import GlobalToast from "../components/Toasts/GlobalToast";
import { GoPlus } from "react-icons/go";
import useGetLoggedInUser from "../Hooks/LoggedInUser/useGetLoggedInUser";
import useGetCompanyProfileCompletionPercentage from "../Hooks/CompanyData/CompanyProfile";

const MyListedProduct = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "9px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "9px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
          background: #445fd2;
          width:auto;
      `);

  const location = useLocation();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const [GetProductByIdData, setGetProductByIdData] = useState();

  const [FilterTab, setFilterTab] = useState("Live");
  const [categoryData, setCategoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [newStatus, setNewStatus] = useState("");
  const [isToggled, setToggled] = useState(false);
  const [initialPaginationCount, setInitialPaginationCount] = useState(null);

  const [UpdateRequestProduct, setUpdateRequestProduct] = useState();
  const [open, setOpen] = useState(false);
  const [StoreData, setStoreData] = useState();
  const [PreviousData, setPreviousData] = useState();
  const [ListingTypeofProduct, setListingTypeofProduct] = useState();

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleClose = () => {
    setOpen(false);
    setStoreData();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  async function GetProductByid() {
    await axios
      .get(`product/get_product_byId/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setGetProductByIdData(res?.data);
      });
  }
  useEffect(() => {
    GetProductByid();
  }, []);

  const { ListedProductsByCompanyData, loadingListedProduct } = useSelector(
    (state) => state.ListedProductsByCompany
  );

  const { ListedDraftProductsByCompanyData, loadingListedDraft } = useSelector(
    (state) => state.ListedDraftProductsByCompany
  );

  const { AllListedProductByCompanyData, loadingAllListedProduct } =
    useSelector((state) => state.AllListedProductByCompany);

  const { AllRejectedProductByCompanyData, loadingAllRejectedProduct } =
    useSelector((state) => state.AllRejectdProductByCompany);

  const { AllDelistProductByCompanyData, loadingAllDelistProduct } =
    useSelector((state) => state.AllDelistProductByCompany);

  const { AllPendingProductByCompanyData, loadingAllPendingProduct } =
    useSelector((state) => state.AllPendingProductByCompany);

  useEffect(() => {
    const { totalPages: allCount } = AllListedProductByCompanyData || {};
    const { totalPages: liveCount } = ListedProductsByCompanyData || {};
    const { totalPages: draftCount } = ListedDraftProductsByCompanyData || {};
    const { totalPages: rejectedCount } = AllRejectedProductByCompanyData || {};
    const { totalPages: delistCount } = AllDelistProductByCompanyData || {};
    const { totalPages: pendingCount } = AllPendingProductByCompanyData || {};

    if (allCount !== undefined && FilterTab === "All") {
      setInitialPaginationCount(allCount);
    } else if (liveCount !== undefined && FilterTab === "Live") {
      setInitialPaginationCount(liveCount);
    } else if (draftCount !== undefined && FilterTab === "In Draft") {
      setInitialPaginationCount(draftCount);
    } else if (rejectedCount !== undefined && FilterTab === "Rejected") {
      setInitialPaginationCount(rejectedCount);
    } else if (delistCount !== undefined && FilterTab === "Delist") {
      setInitialPaginationCount(delistCount);
    } else if (pendingCount !== undefined && FilterTab === "Admin Review") {
      setInitialPaginationCount(pendingCount);
    }
  }, [
    AllListedProductByCompanyData,
    AllRejectedProductByCompanyData,
    ListedProductsByCompanyData,
    ListedDraftProductsByCompanyData,
    AllDelistProductByCompanyData,
    AllPendingProductByCompanyData,
    FilterTab,
  ]);

  const [loading, setLoading] = useState(true);
  const { data: CompanyData } = useGetLoggedInUser();

  const { data: CompanyTypeData } = useGetCompanyTypeData(
    CompanyData?.data?.companyType
  );

  console.log("CompanyData", CompanyData);

  const getAllCetegory = async () => {
    await axios
      .get("/subcategory/getsubcategory")
      .then((res) => {
        setCategoryData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handelActiveOrInactive = async (id, IsActive) => {
    let confirm = window.confirm("Are you sure you want to Change the Status");
    if (confirm) {
      try {
        const response = await axios.put(
          `/AdminCompanyDetails/updatemediahoardingproduct/${id}`,
          {
            status: IsActive ? false : true,
          }
        );
        if (response) {
          dispatch(ListedProductsByCompanyAction(currentPage, newStatus));
          const message =
            response?.data?.updateProduct?.IsActive === false
              ? "Listing is now  Available."
              : "Listing is now Not Available.";
          return toast.success(message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  useEffect(() => {
    dispatch(ListedProductsByCompanyAction(currentPage, newStatus));
    dispatch(ListedDraftProductsByCompanyAction(currentPage, newStatus));
    dispatch(AllListedProductByCompanyAction(currentPage, newStatus));
    dispatch(AllRejectedProductByCompanyAction(currentPage, newStatus));
    dispatch(AllDelistProductByCompanyAction(currentPage, newStatus));
    dispatch(AllPendingProductByCompanyAction(currentPage, newStatus));
    getAllCetegory();
  }, [dispatch, currentPage, newStatus, isToggled]);

  let FilterTabArray = [
    {
      id: 1,
      name: "Live Products",
      tabName: "Live",
      image:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/SellerPageAssets/Live.png",
      count: ListedProductsByCompanyData?.totalProducts,
    },
    {
      id: 2,
      name: "In Draft Products",
      tabName: "In Draft",
      image:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/SellerPageAssets/Draft.png",
      count: ListedDraftProductsByCompanyData?.totalProducts,
    },
    {
      id: 3,
      name: "Admin Review",
      tabName: "Admin Review",
      image:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/SellerPageAssets/AdminReview.png",
      count: AllPendingProductByCompanyData?.totalProducts,
    },
    {
      id: 4,
      name: "Delisted Products",
      tabName: "Delist",
      image:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/DelistICons.png",
      count: AllDelistProductByCompanyData?.totalProducts,
    },
    {
      id: 5,
      name: "Rejected Products",
      tabName: "Rejected",
      image:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/SellerPageAssets/AllProducts.png",
      count: AllRejectedProductByCompanyData?.totalProducts,
    },
    {
      id: 6,
      name: "All Products",
      tabName: "All",
      image:
        "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/SellerPageAssets/Delist.png",
      count: AllListedProductByCompanyData?.totalProducts,
    },
  ];

  const NaviGateFunction = (product) => {
    console.log(
      "product.ListingType",
      product.ListingType,
      CompanyTypeData?.data?.CompanyTypeName
    );
    if (product.ListingType === "Voucher") {
      if (CompanyTypeData?.data?.CompanyTypeName === "Textile") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate(
            "/home/textileVoucher/textilegeneralinformation/" + product._id
          );
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/textileVoucher/textiletechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/textileVoucher/textilegolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Office Supply") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/officesupply/officesupplyproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/officesupply/officesupplytechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/officesupply/officesupplygolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Lifestyle") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate(
            "/home/lifestyleVoucher/lifestylegeneralinformation/" + product._id
          );
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/lifestyleVoucher/lifestyletechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/lifestyleVoucher/lifestylegolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Electronics") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate(
            "/home/electronicsVoucher/electronicsgeneralinformation" +
              product._id
          );
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate(
            "/home/electronicsVoucher/electronicstechinfo/" + product._id
          );
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/electronicsVoucher/electronicsgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "FMCG") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/fmcg/fmcgproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/fmcg/fmcgtechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/fmcg/fmcggolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Mobility") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/mobility/mobilityproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/mobility/mobilitytechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/mobility/mobilitygolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "QSR") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/restaurant/restaurantproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/restaurant/restauranttechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/restaurant/restaurantgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      }
      if (CompanyTypeData?.data?.CompanyTypeName === "Others") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/others/othersproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/others/otherstechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/others/othersgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Hotel") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/hotelsVoucher/hotelsproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/hotelsVoucher/hotelstechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/hotelsVoucher/hotelsgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Airlines Tickets"
      ) {
        console.log("here in airlines", product.ProductUploadStatus);
        if (product.ProductUploadStatus === "productinformation") {
          navigate(
            "/home/airlineVoucher/airlinegeneralinformation/" + product._id
          );
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/vouchers/voucherdesign/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Entertainment & Events"
      ) {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/eeVoucher/eegeneralinformation/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        }
      }
    } else {
      if (CompanyTypeData?.data?.CompanyTypeName === "Textile") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/textile/texttileproductInfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/textile/technicalinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/textile/golive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Office Supply") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/officesupply/officesupplyproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/officesupply/officesupplytechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/officesupply/officesupplygolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Lifestyle") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/lifestyle/lifestyleproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/lifestyle/lifestyletechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/lifestyle/lifestylegolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Electronics") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/electronics/electronicsproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/electronics/electronicstechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/electronics/electronicsgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "FMCG") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/fmcg/fmcgproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/fmcg/fmcgtechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/fmcg/fmcggolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Mobility") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/mobility/mobilityproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/mobility/mobilitytechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/mobility/mobilitygolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "QSR") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/restaurant/restaurantproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/restaurant/restauranttechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/restaurant/restaurantgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Media" &&
        product.ProductCategoryName === "MediaOnline"
      ) {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/mediaonline/mediaonlineproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/mediaonline/mediaonlinetechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/mediaonline/mediaonlinegolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/mediaonlineproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/mediaonlineproductpreview/" + product._id);
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Media" &&
        product.ProductCategoryName === "MediaOffline"
      ) {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/mediaoffline/mediaofflineproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/mediaoffline/mediaofflinetechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/mediaoffline/mediaofflinegolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/mediaonlineproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/mediaonlineproductpreview/" + product._id);
        }
      }
      if (CompanyTypeData?.data?.CompanyTypeName === "Others") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/others/othersproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/others/otherstechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/others/othersgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Hotel") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/hotelsVoucher/hotelsproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/hotelsVoucher/hotelstechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/hotelsVoucher/hotelsgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Airlines Tickets"
      ) {
        if (product.ProductUploadStatus === "productinformation") {
          navigate(
            "/home/airlineVoucher/airlinegeneralinformation/" + product._id
          );
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Entertainment & Events"
      ) {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/eeVoucher/eegeneralinformation/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        }
      }
    }
  };

  const MediaReviewNavigate = (product) => {
    try {
      if (
        CompanyTypeData?.data?.CompanyTypeName === "Media" &&
        product.ProductCategoryName === "MediaOnline"
      ) {
        navigate("/home/mediaonline/mediaonlineproductinfo/" + product._id);
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Media" &&
        product.ProductCategoryName === "MediaOffline"
      ) {
        navigate("/home/mediaonline/mediaonlineproductinfo/" + product._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const allProductByCompany = async () => {
    await axios
      .get(`product/get_product_bySellerCompanyId`, { withCredentials: true })
      .then((res) => {})
      .catch((err) => {});
  };

  const GetProductUpdateRequest = async () => {
    await axios
      .get(`product/AllUpdateProductVariantionRequest`, {
        withCredentials: true,
      })
      .then((res) => {
        setUpdateRequestProduct(res?.data);
      })
      .catch((err) => {});
  };

  const PostProductUpdateRequest = async (id, Status) => {
    await axios
      .post(
        `product/UserUpdateProductVariantion/${id}`,
        {
          Status: Status,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setOpen(false);
        GetProductUpdateRequest();
        if (Status === "Accpeted") {
          <GlobalToast ToastMessage={"Request Accepted"} type={"success"} />;
        } else if (Status === "Rejected") {
          <GlobalToast ToastMessage={"Request Rejected"} type={"error"} />;
        }
      })
      .catch((err) => {
        setOpen(false);
        GetProductUpdateRequest();
      });
  };

  useEffect(() => {
    allProductByCompany();
    GetProductUpdateRequest();
  }, []);

  const {
    data: profileCompletionData,
    isLoading: profileCompletionLoading,
    error: profileCompletionError,
    refetch: profileCompletionRefetching,
  } = useGetCompanyProfileCompletionPercentage();

  let findPercentage =
    typeof profileCompletionData?.data?.ProfileMessage === "string"
      ? profileCompletionData?.data?.ProfileMessage.split(" ")
      : null;

  let finalPercentage =
    findPercentage && findPercentage.length >= 4
      ? findPercentage[3]?.toString()?.split(".")
      : null;

  const NavigateFunction = () => {
    if (finalPercentage?.at(0) >= 100) {
      const companyType = localStorage.getItem("companyType");
      if (!companyType) {
        return;
      } else {
        if (companyType === "Media") {
          navigate("/home/MediaOnlinePhysical");
          clearLocaStorageForBreadCrumb();
        } else if (companyType === "Entertainment & Events") {
          navigate("/home/eephysical");
          clearLocaStorageForBreadCrumb();
        } else {
          navigate("/home/physical");
          clearLocaStorageForBreadCrumb();
        }
      }
    } else {
      GlobalToast("100% Profile Completion is required", "error");
      navigate("/home/profile");
      clearLocaStorageForBreadCrumb();
    }
  };

  const clearLocaStorageForBreadCrumb = () => {
    localStorage.removeItem("myArray");
  };

  const draftdummy = CompanyData?.data?.CompanyAvatar?.url;
  return (
    <Paper elevation={0} sx={{ boxShadow: "none", background: "transparent" }}>
      <Paper sx={{ background: "#fff", borderRadius: "17px" }}>
        <Box
          sx={{
            width: "100%",
            height: "340px",
            bgcolor: "transparent",
            position: "relative",
          }}
        >
          <img
            src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/sellerbg.png"
            style={{ width: "100%", height: "100%" }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              fontSize: "30px",
              color: "white",
              left: "15%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gap: "10px",
                bgcolor: "transparent",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "30px",
                  fontWeight: 600,
                }}
              >
                {AllListedProductByCompanyData?.totalProducts > 0
                  ? null
                  : "Start Your"}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "50px",
                  fontWeight: 600,
                }}
              >
                {" "}
                {AllListedProductByCompanyData?.totalProducts <= 0
                  ? "Seller Journey"
                  : "Sell with BXI"}
              </Typography>
            </Box>
            <Button
              onClick={() => NavigateFunction()}
              sx={{
                width: "210px",
                height: "56px",
                padding: "16px 32px 16px 32px",
                gap: "8px",
                borderRadius: "10px 0px 0px 0px",
                background: "#fff",
                borderRadius: "15px",
                mt: "50px",
                ":hover": {
                  background: "#f6f6f6",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;rgba(0, 0, 0, 0.35) 0px 5px 15px;",
                },
                textTransform: "none",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                alignContent: "center",
                fontSize: "18px",
                fontFamily: "poppins",
                fontWeight: 500,
                color: "#C64091",
              }}
            >
              <GoPlus fontSize="large" color="#C64091" />
              Add Product
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            alignContent: "center",
            padding: "40px 0px",
          }}
        >
          {FilterTabArray?.map((res, idx) => {
            return (
              <Box
                key={idx}
                onClick={() => setFilterTab(res.tabName)}
                sx={{
                  ...ProductcategoryButtonStyle,
                  boxShadow: "0px 2px 5px 0px rgba(38, 51, 77, 0.03)",
                  border:
                    FilterTab === res.tabName
                      ? "2px solid  #B0529C"
                      : "2px solid #F5F6F7",
                  ":hover": {
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  src={res.image}
                  style={{
                    width: "65px",
                    height: "65px",
                    marginTop: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: 600,
                    color: "#000",
                  }}
                >
                  {res.count}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#000",
                  }}
                >
                  {res?.name}
                </Typography>
                <Button
                  sx={TapToViewButtonStyle}
                  onClick={() => setFilterTab(res.tabName)}
                >
                  Tap to View
                </Button>
              </Box>
            );
          })}
        </Box>
      </Paper>
      <Typography
        sx={{
          color: "#C64091",
          textAlign: "center",
          fontFamily: "Poppins",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "30px",
          mt: "50px",
        }}
      >
        {FilterTab === "Live"
          ? "Live Products"
          : FilterTab === "In Draft"
          ? "In Draft Products"
          : FilterTab === "Admin Review"
          ? "Admin Review Products"
          : FilterTab === "Delist"
          ? "Delisted Products"
          : FilterTab === "Rejected"
          ? "Rejected Products"
          : "All Products"}
      </Typography>

      <Grid
        container
        sx={{
          mt: 2,
          height: "500px",
        }}
      >
        {FilterTab === "Live" ? (
          ListedProductsByCompanyData?.products?.length > 0 ? (
            ListedProductsByCompanyData?.products?.map((res, idx) => {
              const UpdateData = UpdateRequestProduct?.filter(
                (product) =>
                  product?.productId === res?._id && product?.status === false
              ).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
              return (
                <>
                  {loadingListedProduct ? (
                    <div>
                      <Box sx={{ display: "flex" }}>
                        {() => {
                          sendEvents("Click on Live");
                        }}
                        <Skeleton
                          variant="round"
                          width={"900px"}
                          height={"300px"}
                          animation="wave"
                          sx={{
                            borderRadius: "20px",
                            mt: 3,
                            width: "100%",
                            bgcolor: "#edf1f5",
                            mx: "auto",
                          }}
                        />
                        <Skeleton
                          variant="round"
                          width={"600px"}
                          height={"300px"}
                          animation="wave"
                          sx={{
                            borderRadius: "20px",
                            mt: 3,
                            width: "100%",
                            bgcolor: "#edf1f5",
                            mx: "auto",
                            marginLeft: "50px",
                          }}
                        />
                        <Skeleton
                          variant="round"
                          width={"600px"}
                          height={"300px"}
                          animation="wave"
                          sx={{
                            borderRadius: "20px",
                            mt: 3,
                            width: "100%",
                            bgcolor: "#edf1f5",
                            mx: "auto",
                            marginLeft: "50px",
                          }}
                        />
                      </Box>
                      <Box>
                        <Skeleton
                          variant="rectangular"
                          width={"1350px"}
                          height={"60px"}
                          animation="wave"
                          sx={{
                            borderRadius: "20px",
                            mt: 3,
                            width: "100%",
                            bgcolor: "#edf1f5",
                            mx: "auto",
                            marginLeft: "2px",
                          }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width={"1350px"}
                          height={"60px"}
                          animation="wave"
                          sx={{
                            borderRadius: "20px",
                            mt: 3,
                            width: "100%",
                            bgcolor: "#edf1f5",
                            mx: "auto",
                            marginLeft: "2px",
                          }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width={"1350px"}
                          height={"60px"}
                          animation="wave"
                          sx={{
                            borderRadius: "20px",
                            mt: 3,
                            width: "100%",
                            bgcolor: "#edf1f5",
                            mx: "auto",
                            marginLeft: "2px",
                          }}
                        />
                      </Box>
                    </div>
                  ) : (
                    <Grid
                      key={idx}
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      sx={{ mt: 3 }}
                      // backgroundColor="red"
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          bgcolor: "transparent",
                          borderRadius: "15px",
                          maxWidth: "280px",
                          mx: "auto",
                          // bgcolor: "red",
                        }}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            width: {
                              xl: "100%",
                              lg: "99%",
                              md: "98%",
                              sm: "97%",
                              xs: "96%",
                            },
                            height: "auto",
                            minHeight: "280px",
                            bgcolor: "#fff",
                            borderRadius: "13.6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // p: 0.1,
                            cursor: "pointer",
                            border: "0.1px solid transparent",
                            backgroundImage:
                              "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                            transition: "box-shadow .1s",
                            "&:hover": {
                              boxShadow: "0px 0px 17px #ff864547",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              mx: "auto",
                              height: "auto",
                              minHeight: "270px",
                              bgcolor: "#fff",
                              borderRadius: "13px",
                              padding: "5px",
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => {
                              sendEvents("Click on image");
                            }}
                          >
                            {UpdateData === null ||
                            UpdateData === undefined ||
                            UpdateData.length === 0 ? null : (
                              <Edit
                                sx={{
                                  position: "absolute",
                                  right: "2%",
                                  width: "25px",
                                  height: "auto",
                                  cursor: "pointer",
                                  color: "rgba(68, 95, 210, 1)",
                                }}
                                onClick={() => {
                                  handleClickOpen();
                                  setStoreData(UpdateData);
                                  setPreviousData(res?.ProductsVariantions);
                                  setListingTypeofProduct(res?.ListingType);
                                }}
                              />
                              // </Box>
                            )}

                            {res?.ListingType === "Media" ? (
                              <div>
                                <Button
                                  sx={{
                                    textTransform: "none",
                                    fontSize: "14px",
                                  }}
                                  onClick={() => {
                                    handelActiveOrInactive(
                                      res?._id,
                                      res?.IsActive
                                    );
                                  }}
                                >
                                  {res?.IsActive === false
                                    ? "Available"
                                    : "Not Available"}
                                </Button>
                              </div>
                            ) : null}

                            <Box
                              sx={{
                                width: "90%",
                                height: "auto",
                                minHeight: "160px",
                                maxHeight: "160px",
                                mx: "auto",
                                pt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  res?.ProductImages?.at(0)?.url === null ||
                                  res?.ProductImages?.at(0)?.url === undefined
                                    ? res?.VoucherImages?.at(0).url
                                    : res?.ProductImages?.at(0)?.url
                                }
                                alt="img"
                                style={{
                                  width: "auto",
                                  maxWidth: "230px",
                                  maxHeight: "130px",
                                  height: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  borderRadius: "10px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: "270px",
                                mx: "auto",
                                height: "auto",
                                display: "grid",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Paper
                                sx={{
                                  width: "90%",
                                  mx: "auto",
                                  height: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignContent: "center",
                                }}
                                elevation={0}
                              >
                                <Typography
                                  sx={{
                                    ...MainTextStyle,
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {res?.ProductName}
                                </Typography>
                                <Typography
                                  sx={{
                                    ...PriceTextStyle,
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <img
                                    src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXITokenIcon.png"
                                    alt="Icon"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      marginRight: "0.5rem",
                                    }}
                                  />
                                  <CommaSeprator
                                    Price={
                                      res?.ProductsVariantions?.at(0)
                                        ?.DiscountedPrice
                                        ? res?.ProductsVariantions?.at(0)
                                            ?.DiscountedPrice
                                        : res?.ProductsVariantions?.at(0)
                                            ?.PricePerUnit
                                    }
                                  />
                                </Typography>{" "}
                              </Paper>
                              <Paper
                                sx={{
                                  width: "90%",
                                  height: "auto",
                                  mx: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                }}
                                elevation={0}
                              >
                                <Typography sx={BottomTextStyle}>
                                  {res?.ProductDescription}
                                </Typography>

                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    sx={{
                                      width: "305px",
                                      textTransform: "none",
                                      mx: "auto",
                                      color: "#6B7A99",
                                      fontFamily: "Poppins",
                                      fontSize: "17px",
                                      fontWeight: 600,
                                    }}
                                    onClick={() => {
                                      NaviGateFunction(res);
                                    }}
                                  >
                                    View
                                  </Button>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  )}
                </>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Nodatafound />
            </div>
          )
        ) : FilterTab === "All" ? (
          AllListedProductByCompanyData?.products?.length > 0 ? (
            AllListedProductByCompanyData?.products?.map((res, idx) => {
              return (
                <>
                  {loadingAllListedProduct || loadingListedProduct ? (
                    <Grid
                      onClick={() => {
                        sendEvents("Click on All");
                      }}
                      sx={{
                        width: "98%",
                        mx: "auto",
                      }}
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          bgcolor: "transparent",
                          borderRadius: "20px",
                          maxWidth: "330px",
                          mx: "auto",
                        }}
                      >
                        <Skeleton
                          variant="rect"
                          height={280}
                          animation="wave"
                          sx={{
                            borderRadius: "10px",
                            mt: 3,
                            width: "100%",
                            bgcolor: "#edf1f5",
                            spacing: "10px",
                            mx: "auto",
                          }}
                        />
                      </Paper>
                    </Grid>
                  ) : (
                    <Grid
                      key={idx}
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      sx={{ mt: 3 }}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          bgcolor: "transparent",
                          borderRadius: "15px",
                          maxWidth: "280px",
                          mx: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            width: {
                              xl: "100%",
                              lg: "99%",
                              md: "98%",
                              sm: "97%",
                              xs: "96%",
                            },
                            height: "auto",
                            minHeight: "280px",
                            bgcolor: "#fff",
                            borderRadius: "13.6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // p: 0.1,
                            cursor: "pointer",
                            border: "0.1px solid transparent",
                            backgroundImage:
                              "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                            transition: "box-shadow .1s",
                            "&:hover": {
                              boxShadow: "0px 0px 17px #ff864547",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              mx: "auto",
                              height: "auto",
                              minHeight: "270px",
                              bgcolor: "#fff",
                              borderRadius: "13px",
                              padding: "5px",
                              // paddingTop: "0rem",
                              cursor: "pointer",
                            }}
                            // onClick={() => {
                            //   {
                            //     res.ListingType === "Voucher" ||
                            //     res.ListingType === "Media" ||
                            //     res.VoucherType ===
                            //       "Value Voucher / Gift Cards" ||
                            //     res.VoucherType === "Offer Specific"
                            //       ? null
                            //       : NaviGateFunction(res);
                            //     sendEvents("Click on image");
                            //   }
                            // }}
                          >
                            <Box
                              sx={{
                                width: "90%",
                                height: "auto",
                                minHeight: "160px",
                                maxHeight: "160px",
                                mx: "auto",
                                pt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  res?.ProductImages?.at(0)?.url === null ||
                                  res?.ProductImages?.at(0)?.url === undefined
                                    ? res?.VoucherImages?.at(0).url
                                    : res?.ProductImages?.at(0)?.url
                                }
                                alt="img"
                                style={{
                                  width: "auto",
                                  maxWidth: "230px",
                                  maxHeight: "130px",
                                  height: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  borderRadius: "10px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: "270px",
                                mx: "auto",
                                height: "auto",
                                display: "grid",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Paper
                                sx={{
                                  width: "90%",
                                  mx: "auto",
                                  height: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignContent: "center",
                                }}
                                elevation={0}
                              >
                                <Typography
                                  sx={{
                                    ...MainTextStyle,
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {res?.ProductName}
                                </Typography>
                                <Typography
                                  sx={{
                                    ...PriceTextStyle,
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <img
                                    src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXITokenIcon.png"
                                    alt="Icon"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      marginRight: "0.5rem",
                                    }}
                                  />
                                  {/*    {res?.ProductsVariantions[0]?.PricePerUnit
                                    ? res?.ProductsVariantions[0]?.PricePerUnit
                               : res?.ProductsVariantions[0]?.price} */}
                                  <CommaSeprator
                                    Price={
                                      res?.ProductsVariantions?.at(0)
                                        ?.DiscountedPrice
                                        ? res?.ProductsVariantions?.at(0)
                                            ?.DiscountedPrice
                                        : res?.ProductsVariantions?.at(0)
                                            ?.PricePerUnit
                                    }
                                  />
                                </Typography>{" "}
                              </Paper>
                              <Paper
                                sx={{
                                  width: "90%",
                                  height: "auto",
                                  mx: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                }}
                                elevation={0}
                              >
                                <Typography sx={BottomTextStyle}>
                                  {res?.ProductDescription}
                                </Typography>
                                {res.ListingType === "Voucher" ||
                                res.ListingType === "Media" ||
                                res.VoucherType ===
                                  "Value Voucher / Gift Cards" ||
                                res.VoucherType === "Offer Specific" ? null : (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      sx={{
                                        width: "305px",
                                        textTransform: "none",
                                        mx: "auto",
                                        color: "#6B7A99",
                                        fontFamily: "Poppins",
                                        fontSize: "17px",
                                        fontWeight: 600,
                                      }}
                                      onClick={() => {
                                        NaviGateFunction(res);
                                      }}
                                    >
                                      View
                                    </Button>
                                  </Box>
                                )}
                              </Paper>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  )}
                </>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Nodatafound />
            </div>
          )
        ) : FilterTab === "In Draft" || location?.state?.status === "draft" ? (
          ListedDraftProductsByCompanyData?.products?.length > 0 ? (
            ListedDraftProductsByCompanyData?.products?.map((res, idx) => {
              return (
                <>
                  {loadingListedDraft === true ? (
                    <Paper
                      onClick={() => {
                        sendEvents("Click on in draft");
                      }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "80vh",
                        position: "absolute",
                        top: 300,
                        left: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        zIndex: 1000,
                      }}
                      elevation={0}
                    >
                      <div className="triple-spinner"></div>
                    </Paper>
                  ) : (
                    <Grid
                      key={idx}
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      sx={{ mt: 3 }}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          bgcolor: "transparent",
                          borderRadius: "15px",
                          maxWidth: "280px",
                          mx: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            width: {
                              xl: "100%",
                              lg: "99%",
                              md: "98%",
                              sm: "97%",
                              xs: "96%",
                            },
                            height: "auto",
                            minHeight: "280px",
                            bgcolor: "#fff",
                            borderRadius: "13.6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // p: 0.1,
                            cursor: "pointer",
                            border: "0.1px solid transparent",
                            backgroundImage:
                              "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                            transition: "box-shadow .1s",
                            "&:hover": {
                              boxShadow: "0px 0px 17px #ff864547",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              mx: "auto",
                              height: "auto",
                              minHeight: "270px",
                              bgcolor: "#fff",
                              borderRadius: "13px",
                              padding: "5px",
                              // paddingTop: "0rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              {
                                res.ListingType === "Voucher" ||
                                res.ListingType === "Media" ||
                                res.VoucherType ===
                                  "Value Voucher / Gift Cards" ||
                                res.VoucherType === "Offer Specific"
                                  ? null
                                  : NaviGateFunction(res);
                              }
                            }}
                          >
                            <Box
                              sx={{
                                width: "90%",
                                height: "auto",
                                minHeight: "160px",
                                maxHeight: "160px",
                                mx: "auto",
                                pt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  res?.ProductImages?.at(0)?.url === null ||
                                  res?.ProductImages?.at(0)?.url === undefined
                                    ? draftdummy
                                    : res?.ProductImages?.at(0)?.url
                                }
                                alt="img"
                                style={{
                                  width: "auto",
                                  maxWidth: "230px",
                                  maxHeight: "130px",
                                  height: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  borderRadius: "10px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: "270px",
                                mx: "auto",
                                height: "auto",
                                display: "grid",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Paper
                                sx={{
                                  width: "90%",
                                  mx: "auto",
                                  height: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignContent: "center",
                                }}
                                elevation={0}
                              >
                                <Typography
                                  sx={{
                                    ...MainTextStyle,
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {res?.ProductName}
                                </Typography>
                                <Typography
                                  sx={{
                                    ...PriceTextStyle,
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <img
                                    src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXITokenIcon.png"
                                    alt="Icon"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      marginRight: "0.5rem",
                                    }}
                                  />
                                  {/*   {res?.ProductsVariantions[0]?.PricePerUnit
                                    ? res?.ProductsVariantions[0]?.PricePerUnit
                                : res?.ProductsVariantions[0]?.price} */}
                                  <CommaSeprator
                                    Price={
                                      res?.ProductsVariantions?.at(0)
                                        ?.DiscountedPrice
                                        ? res?.ProductsVariantions?.at(0)
                                            ?.DiscountedPrice
                                        : res?.ProductsVariantions?.at(0)
                                            ?.PricePerUnit
                                    }
                                  />
                                </Typography>{" "}
                              </Paper>
                              <Paper
                                sx={{
                                  width: "90%",
                                  height: "auto",
                                  mx: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                }}
                                elevation={0}
                              >
                                <Typography sx={BottomTextStyle}>
                                  {res?.ProductDescription}
                                </Typography>

                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    sx={{
                                      width: "305px",
                                      textTransform: "none",
                                      mx: "auto",
                                      color: "#6B7A99",
                                      fontFamily: "Poppins",
                                      fontSize: "17px",
                                      fontWeight: 600,
                                    }}
                                    onClick={() => {
                                      NaviGateFunction(res);
                                      sendEvents("Click on edit button");
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  )}
                </>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Nodatafound />
            </div>
          )
        ) : FilterTab === "Delist" ? (
          AllDelistProductByCompanyData?.products?.length > 0 ? (
            AllDelistProductByCompanyData?.products?.map((res, idx) => {
              return (
                <>
                  {loadingAllDelistProduct === true ? (
                    <Paper
                      onClick={() => {
                        sendEvents("Click on Delist");
                      }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "80vh",
                        position: "absolute",
                        top: 300,
                        left: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        zIndex: 1000,
                      }}
                      elevation={0}
                    >
                      <div className="triple-spinner"></div>
                    </Paper>
                  ) : (
                    <Grid
                      key={idx}
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      sx={{ mt: 3 }}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          bgcolor: "transparent",
                          borderRadius: "15px",
                          maxWidth: "280px",
                          mx: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            width: {
                              xl: "100%",
                              lg: "99%",
                              md: "98%",
                              sm: "97%",
                              xs: "96%",
                            },
                            height: "auto",
                            minHeight: "280px",
                            bgcolor: "#fff",
                            borderRadius: "13.6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // p: 0.1,
                            cursor: "pointer",
                            border: "0.1px solid transparent",
                            backgroundImage:
                              "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                            transition: "box-shadow .1s",
                            "&:hover": {
                              boxShadow: "0px 0px 17px #ff864547",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              mx: "auto",
                              height: "auto",
                              minHeight: "270px",
                              bgcolor: "#fff",
                              borderRadius: "13px",
                              padding: "5px",
                              // paddingTop: "0rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {}}
                          >
                            <Box
                              sx={{
                                width: "90%",
                                height: "auto",
                                minHeight: "160px",
                                maxHeight: "160px",
                                mx: "auto",
                                pt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  res?.ProductImages?.at(0)?.url === null ||
                                  res?.ProductImages?.at(0)?.url === undefined
                                    ? draftdummy
                                    : res?.ProductImages?.at(0)?.url
                                }
                                alt="img"
                                style={{
                                  width: "auto",
                                  maxWidth: "230px",
                                  maxHeight: "130px",
                                  height: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  borderRadius: "10px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: "270px",
                                mx: "auto",
                                height: "auto",
                                display: "grid",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Paper
                                sx={{
                                  width: "90%",
                                  mx: "auto",
                                  height: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignContent: "center",
                                }}
                                elevation={0}
                              >
                                <Typography
                                  sx={{
                                    ...MainTextStyle,
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {res?.ProductName}
                                </Typography>
                                <Typography
                                  sx={{
                                    ...PriceTextStyle,
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <img
                                    src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXITokenIcon.png"
                                    alt="Icon"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      marginRight: "0.5rem",
                                    }}
                                  />
                                  {/*    {res?.ProductsVariantions[0]?.PricePerUnit
                                    ? res?.ProductsVariantions[0]?.PricePerUnit
                               : res?.ProductsVariantions[0]?.price} */}
                                  <CommaSeprator
                                    Price={
                                      res?.ProductsVariantions?.at(0)
                                        ?.DiscountedPrice
                                        ? res?.ProductsVariantions?.at(0)
                                            ?.DiscountedPrice
                                        : res?.ProductsVariantions?.at(0)
                                            ?.PricePerUnit
                                    }
                                  />
                                </Typography>{" "}
                              </Paper>
                              <Paper
                                sx={{
                                  width: "90%",
                                  height: "auto",
                                  mx: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                }}
                                elevation={0}
                              >
                                <Typography sx={BottomTextStyle}>
                                  {res?.ProductDescription}
                                </Typography>
                              </Paper>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  )}
                </>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Nodatafound />
            </div>
          )
        ) : FilterTab === "Rejected" ? (
          AllRejectedProductByCompanyData?.products?.length > 0 ? (
            AllRejectedProductByCompanyData?.products?.map((res, idx) => {
              return (
                <>
                  {loadingAllRejectedProduct === true ? (
                    <Paper
                      onClick={() => {
                        sendEvents("Click on Rejected");
                      }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "80vh",
                        position: "absolute",
                        top: 300,
                        left: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        zIndex: 1000,
                      }}
                      elevation={0}
                    >
                      <div className="triple-spinner"></div>
                    </Paper>
                  ) : (
                    <Grid
                      key={idx}
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      sx={{ mt: 3 }}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          bgcolor: "transparent",
                          borderRadius: "15px",
                          maxWidth: "280px",
                          mx: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            width: {
                              xl: "100%",
                              lg: "99%",
                              md: "98%",
                              sm: "97%",
                              xs: "96%",
                            },
                            height: "auto",
                            minHeight: "280px",
                            bgcolor: "#fff",
                            borderRadius: "13.6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // p: 0.1,
                            cursor: "pointer",
                            border: "0.1px solid transparent",
                            backgroundImage:
                              "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                            transition: "box-shadow .1s",
                            "&:hover": {
                              boxShadow: "0px 0px 17px #ff864547",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              mx: "auto",
                              height: "auto",
                              minHeight: "270px",
                              bgcolor: "#fff",
                              borderRadius: "13px",
                              padding: "5px",
                              // paddingTop: "0rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {}}
                          >
                            {res?.rejectionReason === null ||
                            res?.rejectionReason === undefined ? null : (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <CustomTooltip
                                  sx={{ backgroundColor: "red" }}
                                  title={
                                    <Typography sx={ToolTextStyle}>
                                      {res?.rejectionReason}
                                    </Typography>
                                  }
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 400 }}
                                >
                                  <InfoIcon
                                    sx={{
                                      width: "28px",
                                      height: "auto",
                                      cursor: "pointer",
                                      color: "red",
                                    }}
                                  />
                                </CustomTooltip>
                              </Box>
                            )}

                            <Box
                              sx={{
                                width: "90%",
                                height: "auto",
                                minHeight: "160px",
                                maxHeight: "160px",
                                mx: "auto",
                                pt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  res?.ProductImages?.at(0)?.url === null ||
                                  res?.ProductImages?.at(0)?.url === undefined
                                    ? draftdummy
                                    : res?.ProductImages?.at(0)?.url
                                }
                                alt="img"
                                style={{
                                  width: "auto",
                                  maxWidth: "230px",
                                  maxHeight: "130px",
                                  height: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  borderRadius: "10px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: "270px",
                                mx: "auto",
                                height: "auto",
                                display: "grid",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Paper
                                sx={{
                                  width: "90%",
                                  mx: "auto",
                                  height: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignContent: "center",
                                }}
                                elevation={0}
                              >
                                <Typography
                                  sx={{
                                    ...MainTextStyle,
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {res?.ProductName}
                                </Typography>
                                <Typography
                                  sx={{
                                    ...PriceTextStyle,
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <img
                                    src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXITokenIcon.png"
                                    alt="Icon"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      marginRight: "0.5rem",
                                    }}
                                  />
                                  {/*    {res?.ProductsVariantions[0]?.PricePerUnit
                                    ? res?.ProductsVariantions[0]?.PricePerUnit
                               : res?.ProductsVariantions[0]?.price} */}
                                  <CommaSeprator
                                    Price={
                                      res?.ProductsVariantions?.at(0)
                                        ?.DiscountedPrice
                                        ? res?.ProductsVariantions?.at(0)
                                            ?.DiscountedPrice
                                        : res?.ProductsVariantions?.at(0)
                                            ?.PricePerUnit
                                    }
                                  />
                                </Typography>{" "}
                              </Paper>
                              <Paper
                                sx={{
                                  width: "90%",
                                  height: "auto",
                                  mx: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                }}
                                elevation={0}
                              >
                                <Typography sx={BottomTextStyle}>
                                  {res?.ProductDescription}
                                </Typography>
                              </Paper>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  )}
                </>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Nodatafound />
            </div>
          )
        ) : FilterTab === "Admin Review" ? (
          AllPendingProductByCompanyData?.products?.length > 0 ? (
            AllPendingProductByCompanyData?.products?.map((res, idx) => {
              return (
                <>
                  {loadingAllPendingProduct === true ? (
                    <Paper
                      onClick={() => {
                        sendEvents("Click on Review");
                      }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "80vh",
                        position: "absolute",
                        top: 300,
                        left: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        zIndex: 1000,
                      }}
                      elevation={0}
                    >
                      <div className="triple-spinner"></div>
                    </Paper>
                  ) : (
                    <Grid
                      key={idx}
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      sx={{ mt: 3 }}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          bgcolor: "transparent",
                          borderRadius: "15px",
                          maxWidth: "280px",
                          mx: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            boxSizing: "border-box",
                            width: {
                              xl: "100%",
                              lg: "99%",
                              md: "98%",
                              sm: "97%",
                              xs: "96%",
                            },
                            height: "auto",
                            minHeight: "280px",
                            bgcolor: "#fff",
                            borderRadius: "13.6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // p: 0.1,
                            cursor: "pointer",
                            border: "0.1px solid transparent",
                            backgroundImage:
                              "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                            transition: "box-shadow .1s",
                            "&:hover": {
                              boxShadow: "0px 0px 17px #ff864547",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              mx: "auto",
                              height: "auto",
                              minHeight: "270px",
                              bgcolor: "#fff",
                              borderRadius: "13px",
                              padding: "5px",
                              // paddingTop: "0rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {}}
                          >
                            {res?.reviewReason === null ||
                            res?.reviewReason === undefined ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  minWidth: "250px",
                                  backgroundColor: "white",
                                  pointerEvents: "none",
                                }}
                              >
                                <CustomTooltip
                                  sx={{ backgroundColor: "white" }}
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 400 }}
                                >
                                  <InfoIcon
                                    sx={{
                                      width: "28px",
                                      height: "auto",
                                      cursor: "pointer",
                                      color: "white",
                                    }}
                                  />
                                </CustomTooltip>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  minWidth: "250px",
                                }}
                              >
                                <CustomTooltip
                                  sx={{ backgroundColor: "red" }}
                                  title={
                                    <Typography sx={ToolTextStyle}>
                                      {res?.reviewReason}
                                    </Typography>
                                  }
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 400 }}
                                >
                                  <InfoIcon
                                    sx={{
                                      width: "28px",
                                      height: "auto",
                                      cursor: "pointer",
                                      color: "red",
                                    }}
                                  />
                                </CustomTooltip>
                              </Box>
                            )}

                            <Box
                              sx={{
                                width: "90%",
                                height: "auto",
                                minHeight: "160px",
                                maxHeight: "160px",
                                mx: "auto",
                                pt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  res?.ProductImages?.at(0)?.url === null ||
                                  res?.ProductImages?.at(0)?.url === undefined
                                    ? res?.VoucherImages?.at(0)?.url ||
                                      draftdummy
                                    : res?.ProductImages?.at(0)?.url ||
                                      draftdummy
                                }
                                alt="img"
                                style={{
                                  width: "auto",
                                  maxWidth: "230px",
                                  maxHeight: "130px",
                                  height: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  borderRadius: "10px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: "270px",
                                mx: "auto",
                                height: "auto",
                                display: "grid",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Paper
                                sx={{
                                  width: "90%",
                                  mx: "auto",
                                  height: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignContent: "center",
                                }}
                                elevation={0}
                              >
                                <Typography
                                  sx={{
                                    ...MainTextStyle,
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {res?.ProductName}
                                </Typography>
                                <Typography
                                  sx={{
                                    ...PriceTextStyle,
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <img
                                    src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXITokenIcon.png"
                                    alt="Icon"
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      marginRight: "0.5rem",
                                    }}
                                  />
                                  {/*    {res?.ProductsVariantions[0]?.PricePerUnit
                                    ? res?.ProductsVariantions[0]?.PricePerUnit
                               : res?.ProductsVariantions[0]?.price} */}
                                  <CommaSeprator
                                    Price={
                                      res?.ProductsVariantions?.at(0)
                                        ?.DiscountedPrice
                                        ? res?.ProductsVariantions?.at(0)
                                            ?.DiscountedPrice
                                        : res?.ProductsVariantions?.at(0)
                                            ?.PricePerUnit
                                    }
                                  />
                                </Typography>{" "}
                              </Paper>
                              <Paper
                                sx={{
                                  width: "90%",
                                  height: "auto",
                                  mx: "auto",
                                  minWidth: "240px",
                                  maxWidth: "275px",
                                }}
                                elevation={0}
                              >
                                <Typography sx={BottomTextStyle}>
                                  {res?.ProductDescription}
                                </Typography>

                                {res.ListingType === "Voucher" ||
                                res.VoucherType ===
                                  "Value Voucher / Gift Cards" ||
                                res.VoucherType === "Offer Specific" ? null : (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {res?.reviewReason ? (
                                      <Button
                                        sx={{
                                          width: "305px",
                                          textTransform: "none",
                                          mx: "auto",
                                          color: "#6B7A99",
                                          fontFamily: "Poppins",
                                          fontSize: "17px",
                                          fontWeight: 600,
                                        }}
                                        onClick={() => {
                                          MediaReviewNavigate(res);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    ) : (
                                      <Button
                                        sx={{
                                          width: "305px",
                                          textTransform: "none",
                                          mx: "auto",
                                          color: "#6B7A99",
                                          fontFamily: "Poppins",
                                          fontSize: "17px",
                                          fontWeight: 600,
                                        }}
                                        onClick={() => {
                                          NaviGateFunction(res);
                                        }}
                                      >
                                        View
                                      </Button>
                                    )}
                                  </Box>
                                )}
                              </Paper>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  )}
                </>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Nodatafound />
            </div>
          )
        ) : null}
        {ListedProductsByCompanyData?.products?.length > 0 ||
        ListedDraftProductsByCompanyData?.products?.length > 0 ||
        AllListedProductByCompanyData?.products?.length > 0 ||
        AllRejectedProductByCompanyData?.product?.length > 0 ||
        AllDelistProductByCompanyData?.product?.length > 0 ||
        AllPendingProductByCompanyData?.product?.length > 0 ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            mt={2}
          >
            <Stack spacing={2}>
              <StyledPagination
                count={initialPaginationCount}
                color="primary"
                page={currentPage}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
                strokeWidth={currentPage}
              />
            </Stack>
          </Box>
        ) : null}
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="lg"
          sx={{
            backdropFilter: "blur(2px)",
          }}
          PaperProps={{
            sx: {
              width: "80%",
              maxHeight: 600,
              borderRadius: "20px",
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  mx: "auto",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "15px",
                    lineHeight: "28px",
                    color: "#6B7A99",
                    textAlign: "center",
                  }}
                >
                  Admin has suggested to change - Price/Quanty for this product.
                </Typography>
              </Box>
              <Box>
                <CloseIconDialog
                  sx={{
                    color: "#667085",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (open === false) {
                      setOpen(true);
                    } else {
                      setOpen(false);
                    }
                  }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "scroll",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {PreviousData?.map((item, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      py: "10px",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "25px",
                        width: "45px",
                        borderRadius: "5px",
                        backgroundColor: item?.ProductColor,
                        cursor: "pointer",
                        border: "1px solid black",
                        "&:hover": {
                          boxShadow: "3px 3px 3px grey",
                          border: "1px solid blue",
                        },
                      }}
                    ></Box>

                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "13px",
                        width: "50%",
                        // lineHeight: "28px",
                        color: "#6B7A99",
                        textAlign: "center",
                      }}
                    >
                      {ListingTypeofProduct === "Voucher" ? (
                        <>
                          Existing Price Per Unit:{" "}
                          <span>{item?.PricePerUnit}</span>
                        </>
                      ) : (
                        <>
                          Existing Discounted Price:{" "}
                          <span>{item?.DiscountedPrice}</span>
                        </>
                      )}
                    </Typography>
                    {ListingTypeofProduct === "Voucher" ? null : (
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "13px",
                          width: "50%",
                          // lineHeight: "28px",
                          color: "#6B7A99",
                          textAlign: "center",
                        }}
                      >
                        Existing Price Per Unit:{" "}
                        <span>{item?.PricePerUnit}</span>
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "13px",
                        width: "50%",
                        // lineHeight: "28px",
                        color: "#6B7A99",
                        textAlign: "center",
                      }}
                    >
                      Existing Min Quantity:{" "}
                      <span>{item?.MinOrderQuantity}</span>
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "13px",
                        width: "50%",
                        // lineHeight: "28px",
                        color: "#6B7A99",
                        textAlign: "center",
                      }}
                    >
                      Existing Max Quantity:{" "}
                      <span>{item?.MaxOrderQuantity}</span>
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {StoreData?.at(0)?.productVariantion?.map((item, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      py: "10px",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "25px",
                        width: "45px",
                        borderRadius: "5px",
                        backgroundColor: item?.ProductColor,
                        cursor: "pointer",
                        border: "1px solid black",
                        "&:hover": {
                          boxShadow: "3px 3px 3px grey",
                          border: "1px solid blue",
                        },
                      }}
                    ></Box>

                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "13px",
                        width: "50%",
                        // lineHeight: "28px",
                        color: "#6B7A99",
                        textAlign: "center",
                      }}
                    >
                      {ListingTypeofProduct === "Voucher" ? (
                        <>
                          New Price Per Unit: <span>{item?.PricePerUnit}</span>
                        </>
                      ) : (
                        <>
                          New Discounted Price:{" "}
                          <span>{item?.DiscountedPrice}</span>
                        </>
                      )}
                    </Typography>

                    {ListingTypeofProduct === "Voucher" ? null : (
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "13px",
                          width: "50%",
                          // lineHeight: "28px",
                          color: "#6B7A99",
                          textAlign: "center",
                        }}
                      >
                        New Price Per Unit: <span>{item?.PricePerUnit}</span>
                      </Typography>
                    )}

                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "13px",
                        width: "50%",
                        // lineHeight: "28px",
                        color: "#6B7A99",
                        textAlign: "center",
                      }}
                    >
                      New Min Quantity: <span>{item?.MinOrderQuantity}</span>
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "13px",
                        width: "50%",
                        // lineHeight: "28px",
                        color: "#6B7A99",
                        textAlign: "center",
                      }}
                    >
                      New Max Quantity: <span>{item?.MaxOrderQuantity}</span>
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </DialogContent>
          <DialogTitle id="responsive-dialog-title">
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                sx={reqbtn}
                onClick={() => {
                  PostProductUpdateRequest(StoreData?.at(0)?._id, "Accpeted");
                }}
              >
                Accept
              </Button>
              <Button
                variant="outlined"
                sx={{
                  ...reqbtn,
                  fontWeight: 700,
                  background: "none",
                  border: "0.7px solid #EBEDEE",
                  color: "#445FD2",
                }}
                onClick={() => {
                  PostProductUpdateRequest(StoreData?.at(0)?._id, "Rejected");
                }}
              >
                Reject
              </Button>
            </Box>
          </DialogTitle>
        </Dialog>
      </Grid>
    </Paper>
  );
};

export default MyListedProduct;

const PriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: { xl: "14px", lg: "14px", md: "13px", sm: "13px", xs: "13px" },
  lineHeight: "24px",
  color: "#717171",
};

const MainTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "12px", xs: "12px" },
  lineHeight: "30px",
  width: "100%",
  color: "#141414",
  maxWidth: "160px",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const BottomTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "16px",
  color: "#6B7A99",
  textAlign: "left",
  mt: 1,
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const ToolTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "13px",
  color: "#fff",
  textAlign: "center",
  cursor: "pointer",
  bgcolor: "red",
};

const reqbtn = {
  width: "114px",
  height: "42px",
  boxShadow: "none",
  background: "#445FD2",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  textTransform: "none",
};

const ProductcategoryButtonStyle = {
  width: "200px",
  height: "253px",
  borderRadius: "10px",
  border: "2px solid #F5F6F7",
  background: "#FFF",
  boxShadow: "0px 2px 5px 0px rgba(38, 51, 77, 0.03)",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  gap: "20px",
};

const TapToViewButtonStyle = {
  borderRadius: "5px",
  border: "2px solid #F5F6F7",
  background: "#C64091",
  boxShadow: "0px 2px 5px 0px rgba(38, 51, 77, 0.03)",
  fontFamily: "Poppins",
  fontSize: "12px",
  textTransform: "none",
  color: "#fff",
  ":hover": {
    background: "#C64091",
  },
};
