import {
  BottomNavigation,
  Box,
  Button,
  CircularProgress,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

import { zodResolver } from "@hookform/resolvers/zod";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffectOnce } from "react-use";
import { z } from "zod";
import EditIcon from "../../../../assets/Images/CommonImages/EditIcon.svg";
import RemoveIcon from "../../../../assets/Images/CommonImages/RemoveIcon.svg";
import stackofcoins from "../../../../assets/Stack of Coins.svg";
import ElectronicsTextileProductInform from "./ElectronicsTextileProductInform.jsx";
import { useUpdateProductQuery } from "./ProductHooksQuery";
// import ElectronicsProductVariations from "./ElectronicsProductVariations.jsx";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import TostMessagesTop from "../../../../Component/OTPToast";

import bxitoken from "../../../../assets/Images/CommonImages/BXIToken.svg";
import CommaSeprator from "../../../../components/CommaSeprator";
import ToolTip from "../../../../components/ToolTip";
import StateData from "../../../../utils/StateCityArray.json";
import OthercostPortion from "../../Textile/ProductInfo/OthercostPortion.jsx";
import useGetLoggedInUser from "../../../../Hooks/LoggedInUser/useGetLoggedInUser";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ElectronicsProductInfo = () => {
  const [editId, SetEditId] = useState(null);
  const ProductId = useParams().id;
  const navigate = useNavigate();
  const [size, setSize] = useState("Length");
  const [isChecked, setIsChecked] = useState(false);

  const {
    data: datahere,
    isLoading: DataLoading,
    error: DataError,
    refetch: refetchData,
    isRefetching,
  } = useGetLoggedInUser();

  let skippedCount = 0;
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const [currency, setCurrency] = useState({
    currencyType: "",
    amount: "",
    reasonOfCost: "",
  });
  const [bestFeatures, setBestFeatures] = useState({
    selectedbestFeature: "",
    featureDescription: "",
  });
  const [bestFeaturesArr, setBestFeaturesArr] = useState([]);
  const [costsArr, setCostsArr] = useState([]);
  let ArrayForCurrencyData = [];
  const [HSNStore, setHSNStore] = useState();
  const [ProductData, setProductData] = useState("");
  const [traits, setTraits] = useState([]);
  const [ExpiryDate, setExpiryDate] = useState();
  const [ManufacturingData, setManufacturingData] = useState();
  const [pickup, setPickup] = useState({
    pickupLocation: "",
    pickupPinCode: "",
  });
  const [modelName, setModelName] = useState();
  const [paythru, setPaythru] = useState({
    bxitokens: "",
    inr: "",
  });

  const [OthercostEditId, SetOthercostEditId] = useState(null);

  const [Feature, setFeature] = useState();
  const [city, setCity] = useState("");
  const [CityArray, setCityArray] = useState();
  const [stateArray, setStateArray] = useState();
  const [state, setState] = useState("");

  useEffect(() => {
    if (stateArray) {
      let stateData = StateData?.filter((item) => item?.name === stateArray);
      setCityArray(stateData[0]?.data);
    }
  }, [stateArray]);

  const FetchProduct = async () => {
    await axios
      .get("/product/get_product_byId/" + ProductId)
      .then((response) => {
        setProductData(response?.data);
        setProductData(response?.data);
        if (response?.data?.ProductsVariantions?.length > 0) {
          setSize(response?.data?.ProductsVariantions);
          append(response?.data?.ProductsVariantions);
          OthercostAppend(response?.data?.OtherCost);
          setItems(response?.data?.ProductFeatures);
          setManufacturingData(response?.data?.ManufacturingData);
          setValue(
            "locationdetails.region",
            response?.data?.LocationDetails?.region
          );
          setValue(
            "locationdetails.state",
            response?.data?.LocationDetails?.state
          );
          setValue(
            "locationdetails.city",
            response?.data?.LocationDetails?.city
          );
          setValue(
            "locationdetails.landmark",
            response?.data?.LocationDetails?.landmark
          );
          setValue(
            "locationdetails.pincode",
            response?.data?.LocationDetails?.pincode
          );
          setValue(
            "packagerelateddates.expirydate",
            response?.data?.ExpiryDate
          );
          setValue(
            "packagerelateddates.manufacturingdate",
            response?.data?.ManufacturingData
          );

          setValue("modelname", response?.data?.ModelName);
          setExpiryDate(response?.data?.ExpiryDate);
        }
      })
      .catch((error) => {});
  };
  useEffectOnce(() => {
    FetchProduct();
  });

  const getAllFeature = async () => {
    await axios
      .get("electronicfeature/Get_electronics_feature")
      .then((res) => {
        const sortedData = res.data
          .slice()
          .sort((a, b) =>
            a.ElectronicsFeature.localeCompare(b.ElectronicsFeature)
          );
        setFeature(sortedData);
      })
      .catch((err) => console.log(err));
  };
  async function FetchAddedProduct() {
    await axios
      .get(`product/get_product_byId/${ProductId}`, {
        withCredentials: true,
      })
      .then((res) => {
        fetchHsnCode(res.data?.ProductSubCategory);
        return res.data;
      });
  }

  async function fetchHsnCode(props) {
    await axios
      .post(
        "hsn/Get_HSNCode",
        { SubCatId: props },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        setHSNStore(res.data);
      });
  }

  useEffect(() => {
    FetchAddedProduct();
  }, []);
  useEffect(() => {
    getAllFeature();
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(
      z.object({
        modelname: z.string().min(1),
        manufacturingdate: z.date(),
        expirydate: isChecked ? z.date() : z.any(),
        locationdetails: z.object({
          region: z.string().min(1),
          state: z.string().min(1),
          city: z.string().min(1),
          landmark: z.string().min(1),
          pincode: z
            .string()
            .min(6)
            .max(6),
        }),
      })
    ),
  });
  const {
    fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    update,
  } = useFieldArray({
    control,
    name: "ProductsVariantions",
  });

  if (fields.length === 0) {
    for (let i = fields.length - 1; i >= 0; i--) {
      const fieldValue = fields[i];
      remove(fieldValue);
    }
  }

  const {
    fields: OthercostFields,
    append: OthercostAppend,
    remove: OthercostRemove,
    update: OthercostUpdate,
  } = useFieldArray({
    control,
    name: "Othercost",
  });
  const {
    mutate: updateProduct,
    isLoading,
    isError,
    data: productData,
    variables,
    error: RegisterError,
  } = useUpdateProductQuery();

  useEffect(() => {
    setValue("traits", fields);
    setValue("othercost", OthercostFields);
  }, [fields, OthercostFields]);

  const [data, setData] = useState([]);
  const { id } = useParams();

  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleItemAdd = (e) => {
    // e.preventDefault();
    if (description === "") {
      return toast.error("Please fill the proper features and discription", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (description.length > 75) {
      return toast.error("description must contain max 75 character", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (name !== "Other" && items.some((res) => res.name === name)) {
      setName("");
      return toast.error("Please fill the unique key feature", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (name === "") {
      return toast.error(" Please add Unique features ", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const newItem = { name, description };
      if (name.trim() || description.trim() !== "") {
        setItems([...items, newItem]);
      }
    }
    // setName("");
    setDescription("");
  };

  const handleDelete = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const [additionalData, setAdditionalData] = useState([]);

  const [CostPrice, setCostPrice] = useState("");
  const [ReasonOfCost, setReasonOfCost] = useState("");

  useEffect(() => {
    setAdditionalData([...additionalData, { CostPrice, ReasonOfCost }]);
  }, [CostPrice, ReasonOfCost]);

  const secondSubmit = (e) => {
    ArrayForCurrencyData.push(getValues().CostPrice);
    e.preventDefault();
    const newitems = { CostPrice, ReasonOfCost };
    setAdditionalData([...additionalData, newitems]);
    setCostPrice("");
    setReasonOfCost("");
  };

  const updateProductTotextilestatus = handleSubmit((data) => {
    const manufacturingDate = new Date(ManufacturingData);
    const expiryDate = ExpiryDate ? new Date(ExpiryDate) : null;

    // Function to count days between two dates
    function countDaysBetweenDates(startDate, endDate) {
      if (startDate && endDate) {
        const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
        const diffInDays = Math.round((endDate - new Date()) / oneDay);
        return diffInDays;
      }
      return null;
    }

    const daysBetweenDates = countDaysBetweenDates(
      manufacturingDate,
      expiryDate
    );

    const ProductUpdatedata = {
      id: ProductId,
      ProductsVariantions: getValues()?.ProductsVariantions,
      OtherCost: OthercostFields,
      ProductFeatures: items,
      ProductPickupLocation: getValues()?.packagerelateddates
        ?.productpickuplocation,
      PickupLocationPinCode: getValues()?.packagerelateddates
        ?.pickuplocationpincode,
      ManufacturingDate: new Date(ManufacturingData),
      ExpiryDate: ExpiryDate ? new Date(ExpiryDate) : null,
      GapBetweenDays: daysBetweenDates,
      LocationDetails: getValues()?.locationdetails,
      ModelName: getValues()?.modelname,
      ProductUploadStatus: "technicalinformation",
    };
    if (ProductUpdatedata.ProductsVariantions.length === 0) {
      return toast.error("Please Fill All The Neccessary Fields", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (ProductUpdatedata.ProductFeatures.length < 1) {
      return toast.error("Please Select Best Feature ( Min 5 )", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (ProductUpdatedata.ProductFeatures.length > 20) {
      return toast.error("Please Select Best Feature ( max 20 )", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      updateProduct(ProductUpdatedata, {
        onSuccess: (response) => {
          if (response.status === 200) {
            navigate(`/home/electronics/electronicstechinfo/${id}`);
          }
        },
        onError: (error) => {},
      });
    }
  });

  const arrayOfFields = [
    "Product Color",
    "HSN",
    "GST",
    "Product Id Type",
    "Price Per Unit",
    "Discounted Price",
    "Min Order Quantity",
    "Max Order Quantity",

    "length",
    "Measurement Unit",
    "height",
    "width",
    "weight",
    "Sample Availability",
    "Price Of Sample",
    "battery Capacity",
    "power Consumption",
    "storage Capacity",
    "resolution",
    "frequency",
    "amplification",
    "Product Size",
    // "shoe Size",
  ];

  const OthercostFieldsarray = [
    "Applicable On",
    "Other cost ",
    "HSN",
    "GST",
    "Reason Of Cost",
  ];

  return (
    <React.Fragment>
      <TostMessagesTop
        PageContent="Product will be delisted 14 days before expiry."
        PageCompanyName={datahere?.data?.companyName}
      />
      <form onSubmit={updateProductTotextilestatus}>
        <Box
          sx={{
            width: "650px",
            height: "100%",
            minHeight: "100%",
            overflowY: "hidden",
            boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
            bgcolor: "transparent",
            mx: "auto",
            maxWidth: "716px",
            bgcolor: "#FAFBFD",
            overflowX: "hidden",
            px: 4,
            py: 3,
          }}
        >
          <Box
            sx={{
              backgroundColor: "transparent",
              width: "100%",
              mx: "auto",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: {
                  xs: "18px",
                  sm: "16px",
                  md: "16px",
                  lg: "14px",
                  xl: "14px",
                },
                color: "#6B7A99",
              }}
            >
              Product Information
            </Typography>
            <ToolTip
              info={
                "Product Information encompasses essential details and specifications about a specific product/vouchers, including its name, description, features, pricing, and other relevant data, facilitating informed purchasing decisions for potential buyers."
              }
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              mt: 2,
              height: "auto",
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                overflow: "auto",
                "::-webkit-scrollbar": {
                  display: "flex",
                },
                "::-webkit-scrollbar-thumb": {
                  dynamic: "#8d8e90",
                  minHeight: "10px",
                  borderRadius: "8px",
                },
                "::-webkit-scrollbar-thumb:vertical": {
                  miaxHeight: "10px",
                },
                maxHeight: "100%",
                height: "100%",
                p: 1,
              }}
            >
              <Stack>
                <Box
                  sx={{
                    height: "auto",
                    position: "relative",
                  }}
                >
                  <Typography sx={CommonTextStyle}>
                    What best suits your product?
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      width: "auto",
                      float: "left",
                      mt: 1,
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        height: "100%",
                        // padding: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "Weight"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("Weight");
                          } else return;
                        }}
                        // onClick={() => setSize("Weight")}
                      >
                        <Typography
                          sx={{
                            color: size === "Weight" ? "#C64091" : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Weight
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "BatteryCapacity"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("BatteryCapacity");
                          } else return;
                        }}
                        // onClick={() => setSize("BatteryCapacity")}
                      >
                        <Typography
                          sx={{
                            color:
                              size === "BatteryCapacity"
                                ? "#C64091"
                                : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Battery Capacity
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "PowerConsumption"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("PowerConsumption");
                          } else return;
                        }}
                        // onClick={() => setSize("PowerConsumption")}
                      >
                        <Typography
                          sx={{
                            color:
                              size === "PowerConsumption"
                                ? "#C64091"
                                : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Power
                          <br />
                          Consumption
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "StorageCapacity"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("StorageCapacity");
                          } else return;
                        }}
                        // onClick={() => setSize("StorageCapacity")}
                      >
                        <Typography
                          sx={{
                            color:
                              size === "StorageCapacity"
                                ? "#C64091"
                                : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Storage
                          <br />
                          Capacity
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "Resolution"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("Resolution");
                          } else return;
                        }}
                        // onClick={() => setSize("Resolution")}
                      >
                        <Typography
                          sx={{
                            color:
                              size === "Resolution" ? "#C64091" : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Resolution
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "Amplification"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("Amplification");
                          } else return;
                        }}
                        // onClick={() => setSize("Amplification")}
                      >
                        <Typography
                          sx={{
                            color:
                              size === "Amplification" ? "#C64091" : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Amplification
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "Frequency"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("Frequency");
                          } else return;
                        }}
                        // onClick={() => setSize("Frequency")}
                      >
                        <Typography
                          sx={{
                            color: size === "Frequency" ? "#C64091" : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Frequency
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "Length"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("Length");
                          } else return;
                        }}
                        // onClick={() => setSize("Length")}
                      >
                        <Typography
                          sx={{
                            color: size === "Length" ? "#C64091" : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Length
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "Length x Height"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("Length x Height");
                          } else return;
                        }}
                        // onClick={() => setSize("Length x Height")}
                      >
                        <Typography
                          sx={{
                            color:
                              size === "Length x Height"
                                ? "#C64091"
                                : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Length x Height
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "Length x Height x Width"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("Length x Height x Width");
                          } else return;
                        }}
                        // onClick={() => setSize("Length x Height x Width")}
                      >
                        <Typography
                          sx={{
                            color:
                              size === "Length x Height x Width"
                                ? "#C64091"
                                : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Length x Height x Width
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          ...GenderBoxStyle,
                          border:
                            size === "Custom Size"
                              ? "1px solid #C64091"
                              : "1px solid #f3f6f9",
                          height: "70px",
                          cursor:
                            fields.length === 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (fields.length === 0) {
                            setSize("Custom Size");
                          } else return;
                        }}
                        // onClick={() => setSize("Custom Size")}
                      >
                        <Typography
                          sx={{
                            color:
                              size === "Custom Size" ? "#C64091" : "#ADB8CC",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Custom Size
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* /////////////////////////////////////////////// */}
                <ElectronicsTextileProductInform
                  append={(data, index) => {
                    if (index !== null) {
                      update(index, data);
                    } else {
                      append(data);
                    }
                    SetEditId(null);
                  }}
                  defaultValue={editId !== null ? fields[editId] : null}
                  index={editId}
                  size={size}
                  HSNData={HSNStore}
                />

                {fields.length === 0 ? null : (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      flexDirection: "column",
                      width: "98%",
                      mx: "auto",
                    }}
                  >
                    <Typography sx={CommonTextStyle}>
                      Added Details (
                      {fields &&
                        fields.filter(
                          (item) => item && Object.keys(item).length !== 1
                        ).length}
                      )
                    </Typography>
                    <TableContainer
                      sx={{
                        width: "auto",
                        borderRadius: "10px",
                        background: "transparent",
                        border: "1px solid #e3e3e3",
                        ml: 1,
                        overflow: "auto",
                        "::-webkit-scrollbar": {
                          display: "flex",
                          height: "6px",
                        },
                      }}
                    >
                      <Table
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                          // border: "1px solid #e3e3e3",
                          borderRadius: "10px",
                          overflowX: "auto",
                          background: "transparent",
                        }}
                        size="small"
                        aria-label="a dense table"
                      >
                        {fields &&
                          Object.keys(fields).length > 0 &&
                          fields?.map((item, idx) => {
                            if (item && Object.keys(item).length === 1) {
                              skippedCount++;
                              return null;
                            }
                            return (
                              <>
                                <TableHead>
                                  <TableRow>
                                    {arrayOfFields?.map((data) => {
                                      if (
                                        data === "id" ||
                                        data === "listPeriod"
                                      )
                                        return null;
                                      return (
                                        <TableCell
                                          key={data}
                                          sx={{
                                            ...tableDataStyle,
                                            padding: "10px",
                                            textTransform: "capitalize",
                                          }}
                                          component="th"
                                          scope="row"
                                        >
                                          {data}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                </TableHead>
                                <TableBody
                                  sx={{
                                    borderBottom: "1px solid #EDEFF2",
                                  }}
                                >
                                  <TableRow
                                    key={item}
                                    style={{
                                      borderBottom: "1px solid #e3e3e3",
                                      padding: "10px",
                                    }}
                                  >
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {/* {item.ProductColor} */}

                                      <input
                                        // disableUnderline
                                        value={item.ProductColor}
                                        type="color"
                                        disabled
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          border: "1px",
                                        }}
                                      />
                                    </TableCell>
                                    {/* <TableCell align="center" sx={TableCellStyle}>
                                {item.HSN}
                              </TableCell> */}

                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.HSN}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.GST}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.ProductIdType}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.PricePerUnit}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.DiscountedPrice}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.MinOrderQuantity}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.MaxOrderQuantity}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.length ? item.length : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.MeasurementUnit
                                        ? item.MeasurementUnit
                                        : "N/A"}
                                    </TableCell>
                                    {/* <TableCell align="center" sx={TableCellStyle}>
                                {item.GST}
                              </TableCell> */}
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.height ? item.height : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.width ? item.width : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.weight ? item.weight : "N/A"}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.SampleAvailability
                                        ? item.SampleAvailability
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.PriceOfSample
                                        ? item.PriceOfSample
                                        : "N/A"}
                                      {/* {item.currencyType === "BXITokens" ? (
                                        <img
                                          src={bxitoken}
                                          style={{
                                            width: "13px",
                                            height: "13px",
                                          }}
                                          // alt="bxitoken"
                                        />
                                      ) : (
                                        item.currencyType
                                      )} */}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.batteryCapacity
                                        ? item.batteryCapacity
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.powerConsumption
                                        ? item.powerConsumption
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.storageCapacity
                                        ? item.storageCapacity
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.resolution
                                        ? item.resolution
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.frequency ? item.frequency : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.amplification
                                        ? item.amplification
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={TableCellStyle}
                                    >
                                      {item.ProductSize
                                        ? item.ProductSize
                                        : "N/A"}
                                    </TableCell>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Button
                                        onClick={() => {
                                          SetEditId(idx);
                                        }}
                                      >
                                        <Box component="img" src={EditIcon} />
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          remove(idx);
                                        }}
                                      >
                                        <Box component="img" src={RemoveIcon} />
                                      </Button>
                                    </Box>
                                  </TableRow>
                                </TableBody>
                              </>
                            );
                          })}
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                {/* <Box
          sx={{
            py: "20px",
            display: "flex",
            gap: "20px",
            position: "relative",
          }}
        > */}
                <OthercostPortion
                  append={(data, index) => {
                    if (index !== null) {
                      OthercostUpdate(index, data);
                    } else {
                      OthercostAppend(data);
                    }
                    SetOthercostEditId(null);
                  }}
                  defaultValue={
                    OthercostEditId !== null
                      ? OthercostFields[OthercostEditId]
                      : null
                  }
                  index={OthercostEditId}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    flexDirection: "column",
                    width: "98%",
                    mx: "auto",
                  }}
                >
                  <TableContainer
                    sx={{
                      width: "auto",
                      borderRadius: "10px",
                      background: "transparent",
                      border:
                        OthercostFields.length === 0
                          ? "none"
                          : "1px solid #e3e3e3",
                      ml: 1,
                      overflow: "auto",
                      "::-webkit-scrollbar": {
                        display: "flex",
                        height: "6px",
                      },
                    }}
                  >
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                        // border: "1px solid #e3e3e3",
                        borderRadius: "10px",
                        overflowX: "auto",
                        background: "transparent",
                      }}
                      size="small"
                      aria-label="a dense table"
                    >
                      {OthercostFields?.map((item, idx) => {
                        return (
                          <>
                            <TableHead>
                              <TableRow>
                                {OthercostFieldsarray?.map((data) => {
                                  if (data === "id" || data === "listPeriod")
                                    return null;
                                  return (
                                    <TableCell
                                      align="left"
                                      key={data}
                                      sx={{
                                        ...tableDataStyle,
                                        padding: "10px",
                                        textTransform: "capitalize",
                                        whiteSpace: "nowrap",
                                      }}
                                      component="th"
                                      scope="row"
                                    >
                                      {data}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                borderBottom: "1px solid #EDEFF2",
                              }}
                            >
                              <TableRow
                                key={item}
                                style={{
                                  borderBottom: "1px solid #e3e3e3",
                                  padding: "10px",
                                }}
                              >
                                <TableCell align="center" sx={TableCellStyle}>
                                  {item.AdCostApplicableOn}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    ...TableCellStyle,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <CommaSeprator Price={item.CostPrice} />
                                  {/* {item.CostPrice} */}
                                  {"  "}
                                  {item.currencyType === "BXITokens" ? (
                                    <img
                                      src={bxitoken}
                                      style={{ width: "15px", height: "15px" }}
                                      alt="bxitoken"
                                    />
                                  ) : (
                                    item.currencyType
                                  )}
                                </TableCell>
                                <TableCell align="left" sx={TableCellStyle}>
                                  {item.AdCostHSN}
                                </TableCell>
                                <TableCell align="left" sx={TableCellStyle}>
                                  {item.AdCostGST} %
                                </TableCell>
                                <TableCell align="left" sx={TableCellStyle}>
                                  {item.ReasonOfCost}
                                </TableCell>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "20px",
                                    px: "10px",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={EditIcon}
                                    sx={{
                                      height: "12px",
                                      width: "12px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      SetOthercostEditId(idx);
                                    }}
                                  />

                                  <Box
                                    component="img"
                                    src={RemoveIcon}
                                    sx={{
                                      height: "12px",
                                      width: "12px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      OthercostRemove(idx);
                                    }}
                                  />
                                </Box>
                              </TableRow>
                            </TableBody>
                          </>
                        );
                      })}
                    </Table>
                  </TableContainer>
                </Box>
                {costsArr?.map((items) => {
                  console.log("costsArr", costsArr);
                  return (
                    <Box
                      key={items}
                      sx={{
                        justifyContent: "space-between",
                        display: "flex",
                        mt: "30px",
                        width: "100%",
                        gap: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "40%",
                          height: "60px",
                          display: " grid",
                          placeItems: "center",
                          border: "1px solid #E3E3E3",
                          borderRedius: "10px",
                          color: "#C64091",
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          borderRadius: "10px",
                        }}
                      >
                        {items?.amount}
                        {paythru === "bxitokens" ? (
                          <Box component="img" src={stackofcoins} />
                        ) : (
                          "₹"
                        )}
                      </Box>

                      <Box
                        sx={{
                          width: "60%",
                          height: "60px",
                          display: " flex",
                          placeItems: "center",
                          border: "1px solid #E3E3E3",
                          borderRedius: "10px",
                          color: "#C64091",
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          borderRadius: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>{items?.reasonOfCost}</Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              const newCostsArr = costsArr.filter(
                                (item) => item !== items
                              );
                              setCostsArr(newCostsArr);
                              setCurrency(items);
                            }}
                          >
                            <Box component="img" src={EditIcon} />
                          </Button>
                          <Button
                            onClick={() => {
                              const newCostsArr = costsArr.filter(
                                (item) => item !== items
                              );
                              setCostsArr(newCostsArr);
                            }}
                          >
                            <Box component="img" src={RemoveIcon} />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                <Box
                  sx={{
                    py: "20px",
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: "Poppins",
                      color: "#6B7A99",
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                      Select the best features that describes your brand/product
                    </Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      {" "}
                      (The more features you write the more you are discovered){" "}
                    </Typography>
                  </Box>

                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography sx={CommonTextStyle}>
                        Select Best Feature ( Min 5 ){" "}
                        <span style={{ color: "red" }}> *</span>
                      </Typography>
                      {/* <Select
                multiple
                value={selectedNames}
                onChange={(e) => setSelectedNames(e.target.value)}
                input={<OutlinedInput label="Multiple Select" />}
                
                key={traits}
                renderValue={(selected) => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Stack>
                )}
              >
                {Feature?.map((el, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      value={el?._id}
                      sx={CommonTextStyle}
                    >
                      <Typography sx={{ color: "black" }}>
                        {el.OfficesupplyFeature}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select> */}
                      <Select
                        // value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={{
                          width: "100%",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          background: "#fff",
                          height: "100%",
                          borderRadius: "10px",
                          color: "#C64091",
                          fontSize: "14px",
                        }}
                        key={traits}
                      >
                        {Feature?.map((el, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              value={el?.ElectronicsFeature}
                              sx={{ ...CommonTextStyle, color: "#C64091" }}
                            >
                              <Typography sx={{ fontSize: "14px" }}>
                                {el.ElectronicsFeature}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Box>
                    <Box>
                      <Typography sx={{ ...CommonTextStyle, pt: "20px" }}>
                        Selected Feature Description:{" "}
                        <span style={{ color: "red" }}> *</span>
                      </Typography>

                      <TextField
                        focused
                        multiline
                        placeholder="Harmonics Twins S3 Bluetooth earbuds are powered with Bluetooth v5.2 for a fast connection to your smartphone. Just tap and connect via Bluetooth and from your next time, the bluetooth earphones connect automatically."
                        variant="standard"
                        sx={{ ...TextFieldStyle, height: "100%", p: 2 }}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.selectionStart === 0) {
                            e.preventDefault();
                          } else if (e.key === "Enter") {
                            e.preventDefault();
                            handleItemAdd();
                          }
                        }}
                        minRows={3}
                        maxLength={15}
                        // InputProps={InputPropsStyle}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                              }}
                            ></Typography>
                          ),
                          style: {
                            fontFamily: "Poppins",
                            color: " #C64091",
                            fontSize: "14px",
                          },
                        }}
                      />
                    </Box>

                    <Button
                      variant="contained"
                      onClick={handleItemAdd}
                      sx={{
                        width: "100%",
                        height: "41px",
                        background: "#C64091",
                        borderRadius: "10px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#FFFFFF",
                        textTransform: "none",
                        "&:hover": {
                          background: "#C64091",
                        },
                        my: 3,
                      }}
                    >
                      Proceed to Add
                    </Button>

                    <Typography
                      sx={{
                        color: "#6B7A99",
                        fontFamily: "Poppins",
                        fonmtSize: "20px",
                        marginRight: "75%",
                        marginTop: "1rem",
                      }}
                    >
                      Key Features({items.length})
                    </Typography>

                    <Box sx={{ width: "100%" }}>
                      {items.map((item, index) => (
                        <Box
                          sx={{
                            border: "1px solid #E3E3E3",
                            marginTop: "1rem",
                            mx: "auto",
                            height: "auto",
                            width: "99%",
                            display: " flex",
                            flexDirection: "column",
                            placeItems: "center",
                            borderRadius: "10px",
                          }}
                        >
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              width: "97%",
                              minHeight: "60px",
                              justifyContent: "space-between",
                              height: "auto",
                            }}
                          >
                            <Typography sx={{ mapdata }}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  marginTop: "15px",
                                  fontSize: "12px",
                                  height: "auto",
                                  color: " #6B7A99",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {item.name}
                              </Typography>

                              {item.description}
                            </Typography>

                            <Button
                              onClick={() => handleDelete(index)}
                              sx={{ textTransform: "none", fontSize: "15px" }}
                            >
                              X
                            </Button>
                          </Box>
                        </Box>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        pt: 3,
                      }}
                    >
                      <Typography sx={{ ...CommonTextStyle }}>
                        Product Pickup Location & Pincode
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: "auto",
                        minHeight: "100px",
                        position: "relative",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          mt: 1,
                          maxWidth: "140px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...CommonTextStyle,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          Region <span style={{ color: "red" }}> *</span>
                        </Typography>
                        {/*   <Input
                          disableUnderline
                          // value={data.color}
                          // onChange={(e) => {
                          //   setTextilesDetails({
                          //     ...textTileDetails,
                          //     color: e.target.value,
                          //   });
                          // }}
                          placeholder="Eg. East"
                          {...register("locationdetails.region")}
                          sx={{
                            width: "139px",
                            height: "42px",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            px: 1,
                            color: "#C64091",
                            fontSize: "12px",
                            border: errors?.locationdetails?.region?.message
                              ? "1px solid red"
                              : null,
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === " " &&
                              e.target.selectionStart === 0
                            ) {
                              e.preventDefault();
                            }
                          }}
                          // sx={inputStyles}
                        /> */}
                        <Select
                          disableUnderline
                          {...register("locationdetails.region")}
                          //  sx={{
                          //      width: "139px",
                          //           height: "42px",
                          //           background: "#FFFFFF",
                          //           borderRadius: "10px",
                          //           px: 1,
                          //           color: "#C64091",
                          //           fontSize: "12px",
                          //  }}
                          sx={{
                            ...inputStyles,
                            border: errors?.locationdetails?.region?.message
                              ? "1px solid red"
                              : null,
                            color: "#C64091",
                          }}
                        >
                          <MenuItem sx={{ color: "#C64091" }} value="East ">
                            East
                          </MenuItem>
                          <MenuItem sx={{ color: "#C64091" }} value="North">
                            North
                          </MenuItem>
                          <MenuItem sx={{ color: "#C64091" }} value="South">
                            South
                          </MenuItem>
                          <MenuItem sx={{ color: "#C64091" }} value="West">
                            West
                          </MenuItem>
                        </Select>
                        {ProductData?.LocationDetails ? (
                          <Typography
                            sx={{ ...CommonTextStyle, fontSize: "10px" }}
                          >
                            : {ProductData?.LocationDetails?.region}
                          </Typography>
                        ) : null}{" "}
                        <Typography
                          sx={{ color: "red", fontFamily: "Poppins" }}
                        >
                          {errors?.locationdetails?.region?.message}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          mt: 1,
                          maxWidth: "140px",
                        }}
                      >
                        <Typography sx={CommonTextStyle}>
                          State <span style={{ color: "red" }}> *</span>
                        </Typography>
                        <Select
                          disableUnderline
                          {...register("locationdetails.state")}
                          sx={{
                            ...inputStyles,
                            border: errors?.locationdetails?.state?.message
                              ? "1px solid red"
                              : null,
                            color: "#C64091",
                          }}
                          onChange={(e) => {
                            setStateArray(e.target.value);
                            setState(e.target.value);
                          }}
                        >
                          {StateData?.sort((a, b) =>
                            a.name.localeCompare(b.name)
                          ) // Sort the data alphabetically by 'name'
                            .map((res, index) => (
                              <MenuItem
                                key={index}
                                sx={{
                                  color: "#C64091",
                                }}
                                value={res?.name}
                              >
                                {res?.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {ProductData?.LocationDetails ? (
                          <Typography
                            sx={{ ...CommonTextStyle, fontSize: "10px" }}
                          >
                            : {ProductData?.LocationDetails?.state}
                          </Typography>
                        ) : null}{" "}
                        <Typography
                          sx={{ color: "red", fontFamily: "Poppins" }}
                        >
                          {errors?.locationdetails?.state?.message}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          mt: 1,
                          maxWidth: "140px",
                        }}
                      >
                        <Typography sx={CommonTextStyle}>
                          City <span style={{ color: "red" }}> *</span>
                        </Typography>
                        <Select
                          disableUnderline
                          {...register("locationdetails.city")}
                          sx={{
                            ...inputStyles,
                            border: errors?.locationdetails?.city?.message
                              ? "1px solid red"
                              : null,
                            color: "#C64091",
                          }}
                          onChange={(e) => setCity(e.target.value)}
                        >
                          {CityArray?.map((res, index) => (
                            <MenuItem
                              key={index}
                              sx={{
                                color: "#C64091",
                              }}
                              value={res}
                            >
                              {res}
                            </MenuItem>
                          ))}
                        </Select>
                        {ProductData?.LocationDetails ? (
                          <Typography
                            sx={{ ...CommonTextStyle, fontSize: "10px" }}
                          >
                            : {ProductData?.LocationDetails?.city}
                          </Typography>
                        ) : null}{" "}
                        <Typography
                          sx={{ color: "red", fontFamily: "Poppins" }}
                        >
                          {errors?.locationdetails?.city?.message}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          mt: 1,
                          maxWidth: "140px",
                        }}
                      >
                        <Typography sx={CommonTextStyle}>
                          Landmark <span style={{ color: "red" }}> *</span>
                        </Typography>
                        <Input
                          disableUnderline
                          // value={data.productIdType}
                          // onChange={(e) => {
                          //   setTextilesDetails({
                          //     ...textTileDetails,
                          //     productIdType: e.target.value,
                          //   });
                          // }}
                          placeholder="Eg. Gandhi Maidan"
                          {...register("locationdetails.landmark")}
                          sx={{
                            width: "139px",
                            height: "42px",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            px: 1,
                            color: "#C64091",
                            fontSize: "12px",
                            border: errors?.locationdetails?.landmark?.message
                              ? "1px solid red"
                              : null,
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === " " &&
                              e.target.selectionStart === 0
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />

                        <Typography
                          sx={{ color: "red", fontFamily: "Poppins" }}
                        >
                          {errors?.locationdetails?.landmark?.message}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          mt: 1,
                          maxWidth: "140px",
                        }}
                      >
                        <Typography sx={CommonTextStyle}>
                          Pincode <span style={{ color: "red" }}> *</span>
                        </Typography>
                        <Input
                          type="number"
                          disableUnderline
                          // value={data.productIdType}
                          // onChange={(e) => {
                          //   setTextilesDetails({
                          //     ...textTileDetails,
                          //     productIdType: e.target.value,
                          //   });
                          // }}
                          placeholder="Eg. 800001"
                          {...register("locationdetails.pincode")}
                          onKeyDown={(e) => {
                            if (
                              e.key === " " &&
                              e.target.selectionStart === 0
                            ) {
                              e.preventDefault();
                            }
                          }}
                          sx={{
                            width: "139px",
                            height: "42px",
                            background: "#FFFFFF",
                            borderRadius: "10px",
                            px: 1,
                            color: "#C64091",
                            fontSize: "12px",
                            border: errors?.locationdetails?.pincode?.message
                              ? "1px solid red"
                              : null,
                          }}
                        />
                        <Typography
                          sx={{ color: "red", fontFamily: "Poppins" }}
                        >
                          {errors?.locationdetails?.pincode?.message}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        py: "10px",
                      }}
                    >
                      {/* <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "45%",
                        gap: "10px",
                      }}
                    >
                      <Typography sx={{ ...CommonTextStyle, pt: "10px" }}>
                        Product Pick Up Location{" "}
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        placeholder="Ahmedabad"
                        // onChange={(e) =>
                        //   setPickup({
                        //     ...pickup,
                        //     pickupLocation: e.target.value,
                        //   })
                        // }
                        {...register(
                          "packagerelateddates.productpickuplocation"
                        )}
                        InputProps={{
                          disableUnderline: "true",
                          style: {
                            color: "rgba(107, 122, 153)",
                            fontSize: "14px",
                            padding: "10px",
                            background: "transparent",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "red",
                          },
                        }}
                        sx={{
                          width: "auto",
                          height: "100%",
                          background: "#fff",
                          borderRadius: "10px",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "45%",
                        gap: "10px",
                      }}
                    >
                      <Typography sx={{ ...CommonTextStyle, pt: "10px" }}>
                        Pick Up Location Pincode
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        placeholder="380015"
                        // onChange={(e) =>
                        //   setPickup({
                        //     ...pickup,
                        //     pickupPinCode: e.target.value,
                        //   })
                        // }
                        {...register(
                          "packagerelateddates.pickuplocationpincode"
                        )}
                        InputProps={{
                          disableUnderline: "true",
                          style: {
                            color: "rgba(107, 122, 153)",
                            fontSize: "14px",
                            padding: "10px",
                            background: "transparent",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "red",
                          },
                        }}
                        sx={{
                          width: "auto",
                          height: "100%",
                          background: "#fff",
                          borderRadius: "10px",
                        }}
                      />
                    </Box>
                  </Box> */}
                      <Box
                        sx={{ display: "flex" }}
                        // onClick={handleCheckboxChange}
                      >
                        <Checkbox
                          {...label}
                          checked={isChecked}
                          // defaultChecked
                          onChange={handleCheckboxChange}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: 12,
                            textAlign: "center",
                            color: "#7D8BA6",
                            mt: 1,
                          }}
                        >
                          Click here , To add Expiry Date
                        </Typography>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "12px",
                            textAlign: "center",
                            color: "#7D8BA6",
                          }}
                        >
                          (Adding expiry date is mandatory for FMCG, Cosmetics,
                          Medical Supplies and All Non Durables)
                        </span>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          width: "98%",
                          justifyContent: "space-between",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "45%",
                              gap: "5px",
                            }}
                          >
                            <Typography sx={{ ...CommonTextStyle, pt: "10px" }}>
                              Manufacturing Date{" "}
                              <span style={{ color: "red" }}> *</span>
                            </Typography>

                            <DatePicker
                              // {...register("packagerelateddates.manufacturingdate", {
                              //   onChange: (e) => console.log(e),
                              // })}
                              onChange={(e) => {
                                setManufacturingData(e);
                                setValue("manufacturingdate", new Date(e));
                              }}
                              // maxDate={new Date()}
                              disableFuture={true}
                            />
                            <Typography
                              sx={{ color: "red", fontFamily: "Poppins" }}
                            >
                              {errors?.manufacturingdate?.message}
                            </Typography>
                          </Box>
                          {isChecked && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "45%",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...CommonTextStyle,
                                  pt: "10px",
                                  height: "100%",
                                }}
                              >
                                Expiry Date{" "}
                                <span style={{ color: "red" }}> *</span>
                              </Typography>

                              <DatePicker
                                minDate={
                                  ManufacturingData ? ManufacturingData : null
                                }
                                disablePast={true}
                                shouldDisableDate={(day) => {
                                  const manufacturingDate = new Date(
                                    ManufacturingData
                                  );
                                  const fifteenDaysFromNow = new Date(
                                    manufacturingDate.setDate(
                                      manufacturingDate.getDate() + 14
                                    )
                                  );
                                  return day < fifteenDaysFromNow;
                                }}
                                // disabled={false}
                                // disabled={isChecked}
                                onChange={(e) => {
                                  setExpiryDate(e);
                                  setValue("expirydate", new Date(e));
                                }}
                              />
                              <Typography
                                sx={{ color: "red", fontFamily: "Poppins" }}
                              >
                                {errors?.expirydate?.message}
                              </Typography>
                            </Box>
                          )}
                        </LocalizationProvider>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            alignItems: "flex-start",
                            marginTop: "10px",
                          }}
                        >
                          <Typography sx={CommonTextStyle}>
                            {" "}
                            Model Name / Model Number{" "}
                            <span style={{ color: "red" }}> *</span>
                          </Typography>
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            placeholder="Eg. Harmonics Twins 27"
                            onChange={(e) => setModelName(e.target.value)}
                            // {...register("modelname")}
                            {...register("modelname")}
                            InputProps={{
                              disableUnderline: "true",
                              style: {
                                color: "rgba(107, 122, 153)",
                                fontSize: "14px",
                                padding: "10px",
                                background: "transparent",
                                color: "#C64091",
                                border: errors?.modelname?.message
                                  ? "1px solid red"
                                  : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: "red",
                              },
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === " " &&
                                e.target.selectionStart === 0
                              ) {
                                e.preventDefault();
                              }
                            }}
                            sx={{
                              width: "100%",
                              height: "100%",
                              background: "#fff",
                              borderRadius: "10px",
                              textAlign: "left",
                              color: "#C64091",
                            }}
                          />
                          <Typography
                            sx={{ color: "red", fontFamily: "Poppins" }}
                          >
                            {errors?.modelname?.message}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              mx: "auto",
              height: "auto",
              background: "transparent",
            }}
          >
            <BottomNavigation
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                background: "transparent",
              }}
              showLabels
            >
              {/* <Button
                sx={{
                  marginRight: "auto",
                  p: "2%",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  color: "#6B7A99",
                  fontSize: 14,
                  display: "flex",
                  gap: "10px",
                  cursor: "pointer",
                  textTransform: "none",
                }}
                onClick={() => {
                  reset();
                  setDescription("");
                }}
              >
                <Box
                  component="img"
                  sx={{ width: "23px", height: "23px" }}
                  src={RedoIcon}
                  alt=""
                />
                Reset to Default
              </Button> */}
              <Box sx={{ display: "flex", gap: "10px", p: 1, width: "50%" }}>
                <Button
                  sx={{
                    width: "100%",
                    height: "32px",
                    borderRadius: "10px",
                    background: "#fff",
                    color: "#636161",
                    fontSize: "14px",
                    textTransform: "none",
                    "&:hover": {
                      background: "#f3f6f9",
                      color: "#000",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    let confirm = window.confirm(
                      "Are you sure you want to cancel the product?"
                    );
                    if (confirm) {
                      navigate("/home/sellerhub");
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{
                    width: "100%",
                    height: "32px",
                    borderRadius: "10px",
                    background: "#C64091",
                    fontSize: "14px",
                    textTransform: "none",
                    "&:hover": {
                      background: "#C64091",
                    },
                  }}
                  variant="contained"
                >
                  {isLoading ? <CircularProgress size={20} /> : "Next"}
                </Button>
              </Box>
            </BottomNavigation>
          </Box>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default ElectronicsProductInfo;

const CommonTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const tableDataStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 12,
  color: "#6B7A99",
};

const TableCellStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 12,
  textAlign: "revert",
  color: "#C64091",
  overflow: "auto",
  whiteSpace: "nowrap",
};

const GenderBoxStyle = {
  // border: "1px solid #C64091",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  heigth: "97px",
  width: "75px",
  padding: "10px",
  gap: "20px",
  borderRadius: "10px",
  background: "#fff",
};

const mapdata = {
  color: " #6B7A99",
  fontFamily: "Poppins",
  width: "100%",
  fontSize: "12px",
  minHeight: "60px",
  height: "auto",
};

const TextFieldStyle = {
  width: "95%",
  height: "48px",
  background: "#fff",
  borderRadius: "9px",
  border: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#6B7A99",
  overflow: "auto",
  paddingLeft: "0px",
  "&:focus": {
    outline: "none",
  },
};

const inputStyles = {
  width: "139px",
  height: "42px",
  background: "#FFFFFF",
  borderRadius: "10px",
  padding: "0px 10px",
  fontSize: "12px",
  color: "#C64091",
};
