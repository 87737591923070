import { useMutation, useQuery } from "react-query";
import axios from "axios";

const useGetMemberships = () => {
  const { data, error, isLoading, refetch } = useQuery("products", () =>
    axios.get("/membership/getallmembership").then((res) => res.data)
  );

  return { data, error, isLoading, refetch };
};

export const useCancleMembershipPaymentMutation = () => {
  return useMutation({
    mutationFn: async (data) => {
      return await axios.post(`just-pay/cancle-membership-payment`, data, {
        withCredentials: true,
      });
    },
  });
};

export default useGetMemberships;
