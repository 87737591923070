import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Fade from "react-reveal/Fade";
import axios from "axios";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blog = () => {
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);
  const getAllBlog = async () => {
    try {
      const response = await axios.get(`blog/Get_Blog`);
      setCategoryData(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllBlog();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          BXI Blog - Insights, Tips, and News for Business Bartering
        </title>
        <meta
          property="og:title"
          content="BXI Blog - Insights, Tips, and News for Business Bartering"
        />
        <meta
          name="og:description"
          content="Our insightful blogs are packed with tips and tricks to help you master the art of bartering. See the benefits of bartering with BXI."
        />
        <meta
          name="description"
          content="Our insightful blogs are packed with tips and tricks to help you master the art of bartering. See the benefits of bartering with BXI."
        />
      </Helmet>
      <ToastContainer style={{ fontSize: "16px" }} />
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ marginTop: "70px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{ ...gradientText, lineHeight: "44px" }}
            >
              BLOGS
            </Typography>
          </Fade>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "15px" }}
        >
          <Typography sx={subNewsText}>
            Explore our Blogs to understand the meaning of Barter and gain
            valuable insights and tips on Barter.
          </Typography>
        </Box>
        <Box
          sx={{
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              display: "flex",
              height: "6px",
              width: "40",
            },
            display: "flex",
            width: "100%",
            height: "500px",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              height: "auto",
              mx: "auto",
              display: "flex",
            }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Link
                to="/blogpage"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    // bgcolor: "red",
                    // height: "1000px",
                    // width:"1000px",
                    justifyContent: "center",
                    marginTop: "60px",
                  }}
                >
                  <img
                    src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/BenefitsofBartering.png`}
                    alt="Benefits_Of_Bartering"
                    style={{
                      width: "30px",
                      maxWidth: "330px",
                      height: "270px",
                      width: "275px",
                      maxWidth: "330px",
                      // height: "auto",
                      transition: "transform 0.3s ease",
                      cursor: "pointer",
                      // filter: "sepia(0.5) saturate(1.5) hue-rotate(330deg)",
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = "scale(1)";
                    }}
                  />
                </Box>
              </Link>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={newsFeedTypoStyle}>
                  Benefits of Bartering
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <a
                href="https://barterexchangeofindia.blogspot.com/2023/05/future-of-barter.html"
                target="_blank"
                alt="wtsp"
                style={{
                  color: "inherit",
                }}
                rel="noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "60px",
                  }}
                >
                  <img
                    src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/FutureofBarter.png`}
                    alt="Future_Of_Barter"
                    style={{
                      height: "270px",
                      width: "275px",
                      maxWidth: "330px",
                      transition: "transform 0.3s ease",
                      cursor: "pointer",
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = "scale(1)";
                    }}
                  />
                </Box>
              </a>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={newsFeedTypoStyle}>Future of Barter</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <a
                href="https://barterexchangeofindia.blogspot.com/2023/05/barter-strategic-tool-for-new-economy.html"
                target="_blank"
                alt="wtsp"
                style={{
                  color: "inherit",
                }}
                rel="noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "60px",
                  }}
                >
                  <img
                    src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/LegalTax.png`}
                    alt="Implications_Of_Barter"
                    style={{
                      height: "270px",
                      width: "275px",
                      maxWidth: "330px",
                      transition: "transform 0.3s ease",
                      cursor: "pointer",
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = "scale(1)";
                    }}
                  />
                </Box>
              </a>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ ...newsFeedTypoStyle, width: "300px" }}>
                  A strategic tool for the new economy in india
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <a
                href="https://barterexchangeofindia.blogspot.com/2023/05/barter-strategic-tool-for-new-economy.html"
                target="_blank"
                alt="wtsp"
                style={{
                  color: "inherit",
                }}
                rel="noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "60px",
                  }}
                >
                  <img
                    src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/LegalTax.png`}
                    alt="Implications_Of_Barter"
                    style={{
                      maxWidth: "330px",
                      height: "270px",
                      width: "275px",
                      transition: "transform 0.3s ease",
                      cursor: "pointer",
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = "scale(1)";
                    }}
                  />
                </Box>
              </a>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ ...newsFeedTypoStyle, width: "300px" }}>
                  A strategic tool for the new economy in india
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <a
                href="https://barterexchangeofindia.blogspot.com/2023/05/barter-strategic-tool-for-new-economy.html"
                target="_blank"
                alt="wtsp"
                style={{
                  color: "inherit",
                }}
                rel="noreferrer"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "60px",
                  }}
                >
                  <img
                    src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/LegalTax.png`}
                    alt="Implications_Of_Barter"
                    style={{
                      maxWidth: "330px",
                      height: "270px",
                      width: "275px",
                      transition: "transform 0.3s ease",
                      cursor: "pointer",
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = "scale(1)";
                    }}
                  />
                </Box>
              </a>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ ...newsFeedTypoStyle, width: "300px" }}>
                  A strategic tool for the new economy in india
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Blog;

const newsFeedTypoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "17.6889px",
  lineHeight: "29px",
  alignItems: "center",
  textAlign: "center",
  textTransform: "capitalize",
  color: "rgba(73, 75, 122, 1)",
  marginTop: "20px",
  width: "317.99px",
  height: "58px",
};

const gradientText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xs: "35px",
    sm: "35px",
    md: "35px",
    lg: "35px",
    xl: "35px",
  },
  textTransform: "uppercase",
  lineHeight: "65px",
  display: "flex",
  alignItems: "center",
  background:
    "linear-gradient(90deg, rgba(55, 93, 187, 1), rgba(7, 167, 247, 1))",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
};

const subNewsText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xs: "15px",
    sm: "18px",
    md: "18px",
    lg: "18px",
    xl: "18px",
  },
  lineHeight: "14px",
  display: "flex",
  alignItems: "center",
  color: "rgba(73, 75, 122, 1)",
};
