import HorizontalScrollingList from "./HorizontalScrollingList";
import ClientBox from "./ClientBox";

const clients = [
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Cheesiano_12.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Cinepolis.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Telex+375X120.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/BW+Magzine.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Sugar+375X120.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Budwiser.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/BT+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/BR.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Bombay+Shaving+Company.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/375X120+AD.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Adonmo.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/BG.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/Nilons+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/My+FM.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Nexus+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Navabharat.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Mootovolt.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Miraj.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Mohanlal+Sons.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Mermaid.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/One+Cen.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/MacD+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Redission.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Saphaire.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/Regenta+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/ScreenOx.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/SHrigo+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/PVR+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/Premier+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Philiphs.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Parle+Agro.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Portronics.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/The+Orchid.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Spyker.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Spicejet.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/India+Circus.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/Raipur+City+centre_.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Boat+(2).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Esprit+(1).png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Eva.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Chelsea+Madison.png",
  },
  {
    image:
      "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Ekya+Schools.png",
  },
];

const RandomFirstList = [...clients].sort(() => Math.random() - 0.6);
const RandomSecondList = [...clients].sort(() => Math.random() - 0.6);
const RandomThirdList = [...clients].sort(() => Math.random() - 0.6);

const ClientMarquee = (props) => {
  return (
    <HorizontalScrollingList fromRight sx={{ mt: 1 }}>
      {clients.map((client, idx) => {
        return (
          <ClientBox
            key={idx}
            image={client.image}
            directions={props.directions}
          />
        );
      })}
    </HorizontalScrollingList>
  );
};

export default ClientMarquee;
