import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import MainLogo from "./MainLogo.jsx";
import OtpInput from "react-otp-input";
import { useSearchParam } from "react-use";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import Stepper from "../../components/Stepper";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import TostMessagesTop from "../../Component/OTPToast";
import { useCaptchaVerify } from "../../Hooks/Auth.js";
import { useOtp, useResendOtp, useSentNotification } from "../../Hooks/Auth";
import { RecapctchSiteKey } from "../../utils/captchApiKey";
import emailIcon from "../../assets/Images/OnBoardingPages/emaiIcon.svg";
import mainImg from "../../assets/Images/register/otpverfication.svg";
import * as StylesOnBoarding from "../../components/common/Styled/StylesOnBoarding.js";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser.js";
import Status from "../../utils/status.js";

const Otp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [timerActive, setTimerActive] = useState(true);
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newtost, setNewtost] = useState(false);
  const email = useSearchParam("email");
  const id = useSearchParam("id");
  const companyName = useSearchParam("companyName");
  const { mutate, isLoading } = useOtp();
  const { mutate: ResendOtp } = useResendOtp();

  const { data: AuthUserData } = useGetAuthUser();
  const { mutate: SendNotification } = useSentNotification();

  const handleSendNotification = () => {
    SendNotification({
      id: AuthUserData?.data?.companyId,
      status: Status.EMAIL_VERIFICATION,
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      handleSendNotification();
      e.returnValue =
        "Please click 'Stay on this Page' and we will give you candy";
      return "Please click 'Stay on this Page' and we will give you candy";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // open for edit email
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // here we edit email and post it
  const handleVerifyOTP = async () => {
    try {
      const data = {
        previceEmail: oldEmail,
        newEmail: newEmail,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      await axios.post("api/v1/auth/editemail", data, config);

      toast.success("OTP send Successfully", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setNewEmail("");
      setOldEmail("");
      resetTimer();
      handleClose();
    } catch (error) {
      toast.error(
        error?.response?.data?.issues?.at(0)?.message
          ? error?.response?.data?.issues?.at(0)?.message
          : "Company Not Found",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const handleChange = (enteredOtp) => {
    const combinedOtp = enteredOtp.split("").join("");
    setOtp(combinedOtp);
  };

  // use useForm
  const {
    mutate: CaptchaVerifyMutate,
    isLoading: CaptchaVerifyLoading,
  } = useCaptchaVerify();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: id,
    },
  });

  // resend otp click
  const ResendOtpClick = () => {
    toast.success("OTP send Successfully", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    ResendOtp(
      { email },
      {
        onSuccess: (res) => {
          resetTimer();
        },
        onError: (err) => {},
      }
    );
  };

  // otp time
  useEffect(() => {
    let interval = null;
    if (timerActive) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            setTimerActive(false);
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timerActive]);

  useEffect(() => {
    setNewtost(true);
  });

  // resend otp timer
  const resetTimer = () => {
    setSeconds(60);
    setTimerActive(true);
  };

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const queryClient = useQueryClient();
  const recaptcha = useRef();
  const [captchaValue, setCaptchValue] = useState("");

  const submitOtp = handleSubmit(async (data) => {
    CaptchaVerifyMutate(captchaValue, {
      onSuccess: (res) => {
        if (res.data.success) {
          mutate(
            { id, otp },
            {
              onSuccess: async (res) => {
                await queryClient.invalidateQueries("getLoggedInUser");
                if (res?.status === 200) {
                  navigate(`/gst`, {
                    state: {
                      CompanyName: companyName,
                    },
                  });
                }
              },
              onError: (err) => {
                setCaptchValue("");
                recaptcha.current.reset();

                toast.error(
                  err.response.data?.message ?? "Internal Server Error",
                  {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
              },
            }
          );
        } else {
          setCaptchValue("");
          recaptcha.current.reset();
          toast.error("Captcha Not Verified", {});
          return;
        }
      },
      onError: (err) => {
        setCaptchValue("");
        toast.error("Captcha Not Verified", {});
      },
    });
  });

  return (
    <>
      <form onSubmit={submitOtp}>
        <ToastContainer style={{ fontSize: "16px" }} />
        <TostMessagesTop
          PageContent="Your OTP Is being Verified"
          PageCompanyName={companyName}
        />

        <Paper elevation={0} sx={StylesOnBoarding.mainCommonPage}>
          <Box>
            <Stepper />
          </Box>
          <Grid
            container
            sx={{
              background: "#fff",
              height: "100vh",
              width: {
                xl: "110%",
                lg: "110%",
                md: "110%",
                sm: "100%",
                xs: "100%",
              },
            }}
          >
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={StylesOnBoarding.oneSideGrid}
            >
              <MainLogo />
              <Box component={"img"} src={emailIcon}></Box>
              <Typography sx={StylesOnBoarding.HeadText}>
                Verify your email
              </Typography>
              <Typography sx={metaTextStyle}>
                A One-Time password has been sent to{" "}
              </Typography>
              <Typography sx={metaEmailTextStyle}>{email}</Typography>
              {/* <Button onClick={handleClickOpen}>Edit Email</Button> */}
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  width: "75%",
                  gap: "5px",
                  maxWidth: "450px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: {
                      xl: "50%",
                      lg: "50%",
                      md: "50%",
                      sm: "80%",
                      xs: "80%",
                    },
                    mx: "auto",
                  }}
                >
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    isInputNum={true}
                    containerStyle={{
                      gap: "2rem",
                    }}
                    separateAfter={false}
                    inputStyle={StylesOnBoarding.otpInputStyle}
                  />
                </Box>
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "10px",
                    color: "red",
                  }}
                >
                  {errors["otp"]?.message}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    mt: 1,
                  }}
                >
                  <ReCAPTCHA
                    ref={recaptcha}
                    value={captchaValue}
                    onChange={(e) => {
                      setCaptchValue(recaptcha.current.getValue());
                    }}
                    sitekey={RecapctchSiteKey}
                  />
                </Box>
                <Grid
                  container
                  mt={3}
                  sx={{
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={StylesOnBoarding.CommonBtn}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={"20px"}
                        sx={{ color: "white", width: "50%" }}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "1rem",
                    }}
                  >
                    <Typography
                      sx={resendOtpTextStyle}
                      onClick={timerActive ? null : ResendOtpClick}
                      style={{
                        cursor: timerActive ? "not-allowed" : "pointer",
                        color: timerActive ? "red" : "green",
                      }}
                    >
                      Resend OTP ?
                    </Typography>
                    <Typography sx={resendOtpTextStyle}>
                      OTP Valid For {minutes}:{remainingSeconds < 10 ? "0" : ""}
                      {remainingSeconds} Min
                    </Typography>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: {
                  xl: "flex",
                  lg: "flex",
                  md: "flex",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <img
                src={mainImg}
                alt="img"
                style={StylesOnBoarding.CommonImgStyle2}
              />
            </Grid>
          </Grid>
        </Paper>
      </form>
      {/* edit email dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit email"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ display: "flex", gap: "20px", width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Old Email"
                variant="outlined"
                value={oldEmail}
                onChange={(e) => setOldEmail(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="New Email"
                variant="outlined"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleVerifyOTP} autoFocus>
            Send OTP
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Otp;

const metaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "22px",
  textAlign: "center",
  color: "#6B7A99",
  // marginx: "4rem",
  marginTop: "2rem",
  marginBottom: "0.5rem",
};
const metaEmailTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "15px",
  lineHeight: "22px",
  textAlign: "center",
  color: "#375DBB",
};
const resendOtpTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "22px",
  textAlign: "center",
  color: "#6B7A99",
  cursor: "pointer",
};
