import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { useDispatch, useSelector } from "react-redux";
import LineChatPageForProductAnalytics from "../../views/Dashboard/LineChatPageForProduct";
import { ProductAnalysiss } from "../../redux/action/Products/ProductAnalysis";
import {
  ProductAnalysisDataOfLastWeeks,
  ProductAnalysisDataOfLastMonths,
  ProductAnalysisDataOfThreeMonths,
  ProductAnalysisDataOfSixMonths,
  ProductAnalysisDataOfLastYears,
  ProductAnalysisDataOfLastFiveYears,
} from "../../redux/action/Products/ProductAnalysisData";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import bestseller from "../../assets/Images/MarketplaceImages/BestSellernew.svg";

const ProductAnalysis = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "0px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "0px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [ProductId, setProductId] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hover, setHover] = useState(false);
  const [initialPaginationCount, setInitialPaginationCount] = useState(1);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const dispatch = useDispatch();
  const { ProductAnalysis } = useSelector((state) => state.ProductAnalysis);

  useEffect(() => {
    dispatch(ProductAnalysiss(currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (ProductId) {
      dispatch(ProductAnalysisDataOfLastWeeks(ProductId));
      dispatch(ProductAnalysisDataOfLastMonths(ProductId));
      dispatch(ProductAnalysisDataOfThreeMonths(ProductId));
      dispatch(ProductAnalysisDataOfSixMonths(ProductId));
      dispatch(ProductAnalysisDataOfLastYears(ProductId));
      dispatch(ProductAnalysisDataOfLastFiveYears(ProductId));
    }
  }, [ProductId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (ProductAnalysis?.productData?.length > 0) {
      setProductId(ProductAnalysis?.productData[0]._id);
      setInitialPaginationCount(ProductAnalysis?.finalCount);
    }
  }, [ProductAnalysis?.productData]);

  return (
    <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
      <BreadCrumbHeader
        MainText="Product Analytics"
        LinkText1="{splitedurl[1]}"
        LinkText2="{splitedurl[2]}"
        link1="Link1"
        link2="link2"
      />
      <Paper
        sx={{ bgcolor: "#fff", boxShadow: "none", p: 2, borderRadius: "20px" }}
        elevation={0}
      >
        <Grid container>
          <Grid
            item
            xl={3.8}
            lg={3.8}
            md={3.8}
            sm={12}
            xs={12}
            sx={{ bgcolor: "transparent" }}
          >
            <>
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={440}
                    height={520}
                    animation="wave"
                    sx={{ borderRadius: "10px", mt: 3 }}
                  />
                </>
              ) : (
                <Box sx={{ width: "100%", height: "auto", mt: 1.2 }}>
                  <Box sx={RecenteUsersBox}>
                    <Box
                      sx={{
                        padding: "2rem 2rem",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ ...TokenText, lineHeight: "10px" }}>
                        Product Details
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: "0px 2rem",
                        display: "block",
                        justifyItems: "flex-start",
                        height: "100%",
                        maxHeight: "800px",
                        overflowY: "scroll",
                        bgcolor: "transparent",
                      }}
                    >
                      <Stack
                        sx={{
                          maxHeight: "420px",
                          height: "400px",
                          p: 1,
                        }}
                      >
                        {ProductAnalysis?.productData?.map((item, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "97%",
                                mt: index === 0 ? "0px" : "1rem",
                                borderRadius: "10px",
                                padding: "3px",
                                border:
                                  hover === item._id
                                    ? "0.5px solid #445FD2"
                                    : "0.5px solid #445fd236",
                                cursor: "pointer",
                              }}
                              onClick={() => setHover(item._id)}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  width: "100%",
                                  bgcolor: "transparent",
                                  position: "relative",
                                }}
                                onClick={() => setProductId(item._id)}
                              >
                                <Box key={index}>
                                  {item?.ListingType === "Voucher" ? (
                                    <>
                                      <img
                                        style={{
                                          width: "60px",
                                          height: "60px",
                                          borderRadius: "5px",
                                        }}
                                        src={item?.VoucherImages?.[0]?.url}
                                        alt="image"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        style={{
                                          width: "60px",
                                          height: "60px",
                                          borderRadius: "5px",
                                        }}
                                        src={item?.ProductImages[0]?.url}
                                        alt="image"
                                      />
                                    </>
                                  )}
                                </Box>
                                <Box sx={{ ml: "2rem" }}>
                                  <Typography
                                    sx={{
                                      ...ProfileNameStyle,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      width: "200px",
                                      display: "block",
                                    }}
                                  >
                                    {item.ProductName
                                      ? item.ProductName
                                      : "Product"}
                                  </Typography>

                                  <Typography
                                    sx={{
                                      ...StatMainText,
                                      fontSize: "12px",
                                      lineHeight: "26px",
                                      maxHeight: "40px",
                                      minHeight: "40px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      width: "200px",
                                      display: "block",
                                      // width:200,
                                      overflowWrap: "break-word",
                                      wordWrap: "break-word",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {item.ProductDescription
                                      ? item.ProductDescription
                                      : "Product Description"}
                                  </Typography>
                                  {item?.isTopProduct === true ? (
                                    <Box
                                      sx={{
                                        top: "-5%",
                                        right: "0%",
                                        position: "absolute",
                                      }}
                                    >
                                      <img
                                        src={bestseller}
                                        alt=""
                                        style={{
                                          height: "auto",
                                          width: "28px",
                                        }}
                                      />
                                    </Box>
                                  ) : null}
                                </Box>
                              </Box>
                              <Button
                                sx={{ ml: "auto" }}
                                onClick={() => setProductId(item._id)}
                              >
                                <Typography
                                  sx={{ ...StatMainText, color: "#445FD2" }}
                                >
                                  View
                                </Typography>
                              </Button>
                            </Box>
                          );
                        })}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          mt={0.8}
                        >
                          <Stack spacing={2}>
                            <StyledPagination
                              size="small"
                              count={initialPaginationCount}
                              color="primary"
                              page={currentPage}
                              onChange={handlePageChange}
                              showFirstButton
                              showLastButton
                              strokeWidth={currentPage}
                            />
                          </Stack>
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          </Grid>
          <Grid item xl={8.2} lg={8.2} md={8.2} sm={12} xs={12}>
            <>
              {loading ? (
                <>
                  <Box>
                    <Skeleton
                      variant="rectangular"
                      width={990}
                      height={520}
                      animation="wave"
                      sx={{ borderRadius: "10px", mt: 3 }}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      width: "98%",
                      ml: "auto",
                      mr: "0px",
                      height: "auto",
                    }}
                    // ref={componentRef1}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        width: "100%",
                        mx: "auto",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexWrap: "wrap",
                        bgcolor: "transparent",
                      }}
                    ></Paper>
                    <Paper sx={DashboardChartStyle} elevation={0}>
                      <LineChatPageForProductAnalytics />
                    </Paper>
                  </Box>
                </>
              )}
            </>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default ProductAnalysis;

const RecenteUsersBox = {
  width: "100%",
  height: "490px",
  background: "#ffffff",
  border: "1px solid #E6E9EE",
  borderRadius: "12px",
  mx: "auto",
  mt: 3,
};

const DashboardChartStyle = {
  width: "97%",
  mx: "auto",
  mt: 3,
  height: "494px",
  background: "#FFFFFF",
  border: "1px solid #E6E9EE",
  borderRadius: "12px",
  overflow: "scroll",
};

const StatMainText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#AFAFAF",
  textTransform: "none",
};

const TokenText = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: { xl: "20px", lg: "20px", md: "18px", sm: "16px", xs: "14px" },
  lineHeight: "25px",
  color: "#393D5E",
};

const ProfileNameStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  color: "#15223C",
};
