import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import HBM from '../../../assets/HomePageImages/becomememberimg.svg';
import Fade from 'react-reveal/Fade';
const BecomeMember = () => {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          height: {
            xl: '80vh',
            lg: '80vh',
            md: '80vh',
            sm: '100vh',
            xs: '100vh',
          },
          width: '100%',
          mt: { xl: 0, lg: 0, md: 0, sm: 15, xs: 15 },
        }}
      >
        {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <video
              controls
              autoPlay
              controlsList="nodownload"
              src={SampleVideo}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "0 17.449px 17.449px 0",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography variant="inherit" component="span" sx={headingStyle}>
                How
              </Typography>
              <Typography variant="inherit" component="span" sx={gradientText}>
                To Become a Member?
              </Typography>
            </Box>
            <Box
              sx={{
                width: "90%",
                maxWidth: "90%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                marginLeft: "40px",
                marginTop: "24px",
              }}
            >
              <img
                src={HBM}
                alt="HBM"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid> */}
        <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            sm={12}
            sx={{ mt: { xl: 4, lg: 4, md: 4, sm: 8, xs: 8 } }}
          >
            <iframe
              src="https://www.youtube.com/embed/SDVXjAgnZFQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              style={{
                width: '88%',
                height: '55vh',
                objectFit: 'cover',
                borderRadius: '17.449px',
                border: 'none',
                marginLeft: '20px',
              }}
              allowfullscreen
            ></iframe>
            {/* <iframe
              // controls
              autoPlay
              controlsList="nodownload"
              src={
                "https://www.youtube.com/embed/xztZxlg_Nkg?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0"
              }
              style={{
                width: "100%",
                height: "57.95vh",
                objectFit: "cover",
                borderRadius: "0 17.449px 17.449px 0",
                border: "none",
              }}
              allowFullScreen
            /> */}
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: { xl: 2, lg: 2, md: 2, sm: 0, xs: 0 },
                }}
              >
                <Fade top duration={1000}>
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={headingStyle}
                  >
                    HOW
                  </Typography>
                  <Typography
                    variant="inherit"
                    component="span"
                    sx={gradientText}
                  >
                    BXI WORKS?
                  </Typography>
                </Fade>
              </Box>
              <Box
                sx={{
                  width: '90%',
                  mx: 'auto',
                  maxWidth: '60%',
                  height: '80%',
                  maxHeight: '52vh',
                  display: 'flex',
                  justifyContent: 'center',
                  // marginLeft: "40px",
                  mt: 1,
                }}
              >
                <img
                  src={HBM}
                  alt="HBM"
                  style={{ width: '100%', height: 'auto' }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default BecomeMember;
const gradientText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xs: '22px',
    sm: '25px',
    md: '28px',
    lg: '28px',
    xl: '28px',
  },
  letterSpacing: '0.5px',
  lineHeight: '65px',
  display: 'flex',
  alignItems: 'center',
  background: 'linear-gradient(75deg, #375DBB 29.17%, #00B1FF 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
};
const headingStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: {
    xs: '22px',
    sm: '25px',
    md: '28px',
    lg: '28px',
    xl: '28px',
  },
  letterSpacing: '0.5px',
  lineHeight: '65px',
  display: 'flex',
  alignItems: 'center',
  color: '#0D0E0E',
  marginRight: '10px',
};
