import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Typography } from "@mui/material";
import "./ProgressBar.css";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const ProductProgressBar = (props) => {
  const [stepCount, setStepCount] = useState();
  const [CategoryType, setCategoryType] = useState();
  const ProductId = useParams().id;
  const [pathnameChanged, setPathnameChanged] = React.useState(false);

  useEffect(() => {
    setCategoryType(localStorage.getItem("digitalData"));
  }, []);

  const location = useLocation();

  const fetchProductProgress = async () => {
    try {
      await axios
        .post(
          `product/product_progress`,
          { id: ProductId },
          { withCredentials: true }
        )
        .then((res) => {
          setStepCount(res?.data);
        });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    setPathnameChanged(true);

    fetchProductProgress();
    const timer = setTimeout(() => {
      fetchProductProgress();
      setPathnameChanged(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  useEffect(() => {
    fetchProductProgress();
  }, [location?.pathname]);

  const steps =
    location.pathname?.includes("Voucher") ||
    location.pathname?.includes("voucher")
      ? [
          { num: 1, label: "General Information" },
          { num: 2, label: "Voucher Information" },
          { num: 3, label: "Technical Information" },
          { num: 4, label: "Template Created" },
          { num: 5, label: "Voucher Uploaded" },
          { num: 6, label: "Voucher Approved and Listed" },
        ]
      : [
          { num: 1, label: "General Information" },
          { num: 2, label: "Product Information" },
          { num: 3, label: "Technical Information" },
          { num: 4, label: "Images Uploaded" },
          { num: 5, label: "Product Uploaded" },
          { num: 6, label: "Product Approved and Listed" },
        ];

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={stepCount?.step} alternativeLabel>
        {steps?.map((data) => {
          if (data?.num) {
            return (
              <Step key={data?.num}>
                <StepLabel className="icon-wrapper">
                  <Typography sx={TextStyle}>{data?.label}</Typography>
                </StepLabel>
              </Step>
            );
          } else {
            return null;
          }
        })}
        <Box>
          <Typography
            sx={{
              ...TextStyle,
              fontWeight: 500,
              backgroundColor: "#DBEDFF",
              borderRadius: "8px",
              px: 1,
              py: 0.2,
            }}
          >
            {stepCount?.progress}%
          </Typography>
        </Box>
      </Stepper>
    </Box>
  );
};

export default ProductProgressBar;

const TextStyle = {
  color: "#2261A2",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 400,
};
