import { Paper, Box, Typography, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { useNavigate, useParams } from "react-router-dom";
import DocDownloadImg from "../../assets/Images/CommonImages/DocDownload.png";
import PrintPurchaseOrder from "../../assets/Images/CommonImages/Print.png";
import { useReactToPrint } from "react-to-print";
import CommaSeprator from "../../components/CommaSeprator";
import {
  AddressInformation,
  BarterCoinDetails,
  InvoiceList,
  OrderStatus,
  ProductDetail,
} from "./OrderDetailsChildComp";
import LeftArrow from "../../assets/Images/payment/LeftArrow.png";
import BXIReleasePage from "./EscrowPayment/BXIReleasePage.jsx";
import axios from "axios";
import ProgressBar from "../../components/ProgressBar/OrderProgressBar.jsx";

const OrderDetails = () => {
  let { id, type } = useParams();
  const [orderStatus, setOrderStatus] = useState("NEW");
  const [calculationData, setCalculationData] = useState({
    TotalAdditionalCostWtihoutGSTInBXI: 0,
    TotalPriceWithoutGST: 0,
    GrandTotal: 0,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [OrderData, setOrderData] = useState();
  const [bxiReleaseTrue, setBxiReleaseTrue] = useState(false);
  const [OrderLoading, setOrderLoading] = useState(false);
  const [confirmButton, setConfirmButton] = useState(false);
  const ViewingType = type;

  const navigate = useNavigate();

  const fetchData = async () => {
    setOrderLoading(true);
    try {
      await axios
        .get(`order/get_order_by_id/${id}`, {
          withCredentials: true,
        })
        .then((res) => {
          setOrderData(res);
          if (res) {
            GetOrderStatus(res?.data);
          }
          setOrderLoading(false);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let TotalAdditionalCostWtihoutGSTInBXI = 0;
    let TotalPriceWithoutGST = 0;
    let GrandTotal = 0;

    OrderData?.data?.PurchaseOrderData?.ProductData?.forEach((el) => {
      if (OrderData?.data?.IsVoucher) {
        TotalAdditionalCostWtihoutGSTInBXI +=
          el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI || 0;
      } else {
        TotalAdditionalCostWtihoutGSTInBXI +=
          el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI || 0;
      }

      TotalPriceWithoutGST += el?.TotalPriceWithoutGST || 0;

      if (el?.ProductTypeName === "Media") {
        GrandTotal += el?.TotalPriceWithoutGST || 0;
      } else if (OrderData?.data?.IsVoucher) {
        GrandTotal +=
          (el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI || 0) +
          (el?.TotalPriceWithoutGST || 0)
            ? (el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI || 0) +
              (el?.TotalPriceWithoutGST || 0)
            : el?.TotalPriceInBXI || 0;
      } else {
        GrandTotal +=
          (el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI || 0) +
          (el?.TotalPriceWithoutGST || 0)
            ? (el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI || 0) +
              (el?.TotalPriceWithoutGST || 0)
            : el?.TotalPriceInBXI || 0;
      }
    });

    setCalculationData({
      TotalAdditionalCostWtihoutGSTInBXI,
      TotalPriceWithoutGST,
      GrandTotal,
    });
  }, [OrderData]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleBXICommissionPayment = async (props) => {
    try {
      await axios
        .post(`just-pay/bxi_commision_payment`, {
          OrderId: props,
        })
        .then((res) => {
          if (res?.data) {
            window.open(res?.data?.paymentURL);
            setConfirmButton(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      alert("Error in Payment");
    }
  };

  useEffect(() => {
    if (OrderData) {
      GetOrderStatus(OrderData.data);
    }
  }, [OrderData]);

  const GetOrderStatus = async (data) => {
    try {
      let response = await axios.post(`just-pay/bxi_payment_status`, {
        order_id: data?._id,
      });
      if (response.data.status === "CHARGED" && type !== "buyer") {
        setOrderStatus(response.data.status);
      }

      if (
        type !== "buyer" &&
        response.data.status === "CHARGED" &&
        data?.escrowPayment?.success === "true"
      ) {
        if (!data?.escrowSellerReleaseResponse) {
          setBxiReleaseTrue(true);
        }
      }

      return response.data;
    } catch (error) {
      return error;
    }
  };

  const handleChildData = (dataFromChild) => {
    setBxiReleaseTrue(dataFromChild);
  };

  let BXICommissionFee = OrderData?.data?.IsMedia
    ? (
        OrderData?.data?.INRDetails?.TotalBxiCommission +
        OrderData?.data?.INRDetails?.BxiCommisionGST +
        OrderData?.data?.INRDetails?.TDS
      ).toFixed(2)
    : (
        OrderData?.data?.INRDetails?.totalBxiCommission +
        OrderData?.data?.INRDetails?.bxiCommisionGST +
        OrderData?.data?.INRDetails?.TDS
      ).toFixed(2);

  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"Order Details"} Margin={false} />
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          height: "120px",
          borderRadius: "20px",
          mb: "20px",
        }}
        elevation={0}
      >
        <ProgressBar
          type={
            OrderData?.data?.IsMedia
              ? "mediaordersummary"
              : OrderData?.data?.IsVoucher
              ? "voucherordersummary"
              : "productordersummary"
          }
          Data={OrderData?.data}
        />
      </Paper>

      <Paper sx={PaperStyle} elevation={0}>
        <Box sx={{ display: "grid" }}>
          <Typography
            sx={{
              marginLeft: "13px",
              color: "#6B7A99",
              fontSize: "15px",
              display: "flex",
            }}
          >
            Order ID:{" "}
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: 600,
                marginLeft: "5px",
                color: "#2261A2",
              }}
            >
              {OrderData?.data?.OrderId}{" "}
            </span>{" "}
          </Typography>
          <Typography
            sx={{
              marginLeft: "13px",
              color: "#6B7A99",
              fontSize: "15px",
              display: "flex",
            }}
          >
            Date
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: 600,
                marginLeft: "5px",
                color: "#2261A2",
              }}
            >
              {new Date(OrderData?.data?.createdAt).toDateString("en-IN")}
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            marginRight: "13px",
            width: "60px",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            src={PrintPurchaseOrder}
            sx={{ width: "22px", height: "auto", cursor: "pointer" }}
            onClick={handlePrint}
          />
          <Box
            component="img"
            src={DocDownloadImg}
            sx={{ width: "21px", height: "auto", cursor: "pointer" }}
            // onClick={handleDownloadClick}
            onClick={handlePrint}
          />
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: {
            xl: "row",
            lg: "row",
            md: "row",
          },
          justifyContent: "space-evenly",
        }}
        ref={componentRef}
      >
        <Box
          sx={{
            width: "60%",
            // mx: "auto",
            height: "100%",
            minHeight: "200px",
            p: "20px",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              background: "white",
              minHeight: "500px",
              height: "auto",
              borderRadius: "10px",
              p: "25px",
            }}
          >
            <Typography style={OrderSummaryTypo}>
              <Box
                component="img"
                src={LeftArrow}
                alt="LeftArrow"
                sx={{
                  width: {
                    xl: "22px",
                    lg: "22px",
                    md: "18px",
                    sm: "16px",
                    xs: "16px",
                  },
                  height: "auto",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              />{" "}
              Order Summary
            </Typography>
            <ProductDetail data={OrderData?.data} />
            <BarterCoinDetails data={calculationData} OrderData={OrderData} />
          </Box>
          <AddressInformation OrderData={OrderData} ViewingType={type} />
        </Box>
        <Box
          sx={{
            width: "30%",
            mx: "auto",
            height: "67rem",
            minHeight: "200px",
            p: "25px",
            backgroundColor: "white",
            borderRadius: "10px",
            mt: 2.8,
          }}
        >
          <InvoiceList OrderData={OrderData} />
          {type !== "buyer" &&
            OrderData?.data?.BuyerOrderStatus === "success" &&
            (orderStatus === "CHARGED" ? null : (
              <Box sx={{ width: "100%", height: "auto" }}>
                <Button
                  type="submit"
                  onClick={() => {
                    setModalOpen(true);
                    setConfirmButton(true);
                  }}
                  sx={{ width: "250px", height: "35px" }}
                  variant="contained"
                >
                  <Typography fontSize={15}>Pay BXI Commision</Typography>
                </Button>
              </Box>
            ))}

          {type !== "buyer" && orderStatus === "CHARGED" ? (
            <Box
              sx={{
                width: "100%",
                height: "auto",
                mt: 2,
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <Typography
                sx={ProductPriceTextStyle}
                fontWeight={400}
                color="#6B7A99"
              >
                BXI commission Payment
              </Typography>
              <Button
                type="submit"
                disabled
                sx={{
                  width: "80px",
                  height: "30px",
                  bgcolor: "green",
                  fontSize: "16px",
                  color: "#fff",
                }}
              >
                <span style={{ color: "white" }}>
                  {" "}
                  {orderStatus === "CHARGED" ? "Paid" : "Unpaid"}
                </span>
              </Button>
            </Box>
          ) : null}

          {type !== "buyer" &&
          OrderData?.data?.escrowSellerReleaseResponse?.success === true ? (
            <Box
              sx={{
                width: "100%",
                height: "auto",
                mt: 2,
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <Typography
                sx={ProductPriceTextStyle}
                fontWeight={400}
                color="#6B7A99"
              >
                Escrow Payment
              </Typography>
              <Button
                type="submit"
                disabled
                sx={{
                  width: "80px",
                  height: "30px",
                  bgcolor: "green",
                  fontSize: "16px",
                  color: "#fff",
                }}
              >
                <span style={{ color: "white" }}>
                  {" "}
                  {OrderData?.data?.escrowSellerReleaseResponse?.success ===
                  true
                    ? "Paid"
                    : "Not Paid"}
                </span>
              </Button>
            </Box>
          ) : null}
          {modalOpen ? (
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ModalStyle}>
                <Typography fontSize={20} fontWeight={600} color="#6B7A99">
                  Pay BXI Commision
                </Typography>
                <Box sx={{ display: "grid", gap: "10px" }}>
                  <Typography sx={TextStyle} fontWeight={400} color="#6B7A99">
                    You are about to pay the BXI commision for this order. Click
                    confirm to proceed.
                  </Typography>
                  <Typography sx={TextStyle} fontWeight={400} color="#6B7A99">
                    Total INR Amount:{" "}
                    <strong>
                      {" "}
                      <span
                        style={{
                          color: "#28282B",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        ₹{" "}
                        {OrderData?.data?.BuyerChoosedTransportation
                          ?.TransportationType === "notransportation" ||
                        OrderData?.data?.IsMedia ||
                        OrderData?.data?.IsVoucher ? (
                          <CommaSeprator
                            Price={
                              OrderData?.data?.INRDetails?.TotalInrToPay
                                ? OrderData?.data?.INRDetails?.TotalInrToPay
                                : 0.0
                            }
                          />
                        ) : (
                          <>
                            {OrderData?.data?.INRDetails?.packagingCost ||
                            OrderData?.data?.INRDetails?.InsuranceCost ? (
                              <CommaSeprator
                                Price={
                                  OrderData?.data?.INRDetails?.TotalInrToPay
                                    ? OrderData?.data?.INRDetails?.TotalInrToPay
                                    : 0.0
                                }
                              />
                            ) : (
                              <CommaSeprator
                                Price={
                                  OrderData?.data?.INRDetails?.TotalInrToPay
                                    ? OrderData?.data?.INRDetails?.TotalInrToPay
                                    : 0.0
                                }
                              />
                            )}
                          </>
                        )}
                      </span>
                    </strong>
                  </Typography>
                  <Typography sx={TextStyle} fontWeight={400} color="#6B7A99">
                    BXI Commision Fee: <strong>{BXICommissionFee}</strong>
                  </Typography>
                  <Typography sx={TextStyle} fontWeight={400} color="#6B7A99">
                    Amount to be paid: <strong>{BXICommissionFee}</strong>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                    mt: 2,
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {confirmButton ? (
                    <Button
                      type="submit"
                      onClick={() => {
                        handleBXICommissionPayment(OrderData?.data?._id);
                      }}
                      sx={{ width: "220px", height: "35px", mx: "auto" }}
                      variant="contained"
                    >
                      <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                        Confirm
                      </Typography>
                    </Button>
                  ) : null}
                  <Button
                    type="submit"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                    sx={{ width: "220px", height: "35px", mx: "auto" }}
                    variant="outlined"
                  >
                    <Typography sx={{ color: "#000", fontSize: "16px" }}>
                      Cancel
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Modal>
          ) : null}
          {ViewingType === "seller" ? null : (
            <OrderStatus OrderData={OrderData?.data} />
          )}
        </Box>
      </Box>
      <BXIReleasePage
        OrderData={OrderData?.data}
        modalOpen={bxiReleaseTrue}
        modalState={handleChildData}
        ReleaseEscrowFund={orderStatus === "CHARGED" ? true : false}
      />
    </Paper>
  );
};

export default OrderDetails;

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "auto",
  bgcolor: "white",
  borderRadius: "10px",
  p: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "25px",
  color: "#6B7A99",
  textAlign: "left",
};

const ProductPriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  color: "rgba(107, 122, 153, 0.5)",
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "14px",
    sm: "12px",
    xs: "12px",
  },
  lineHeight: {
    xl: "15px",
    lg: "15px",
    md: "19px",
    sm: "18px",
    xs: "17px",
  },
  alignContent: "center",
  alignItems: "center",
};

const OrderSummaryTypo = {
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "16px",
  color: "#2261A2",
  borderBottom: "1px solid #E4E4E4",
  paddingBottom: "4px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
};

const PaperStyle = {
  width: "100%",
  mx: "auto",
  height: "80px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
  borderRadius: "10px",
};
